export const ArrowDownIcon = ({ height = '24', width = '24', style = {}, rotate = 'rotate(180)'}) => {
  return (
    <svg
      width={width}
      height={height}
      transform={rotate}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7 10L12 15L17 10H7Z"
        fill="#8774FF"
      />
    </svg>
  );
};
