import qrcodeImg from '../../../../assets/images/qr-dowload-app.png';
import { DownloadTheAppContainerStyle } from './style';

export function DownloadTheAppComponent({ isOpen, onClose }) {
  return (
    <DownloadTheAppContainerStyle isOpen={isOpen} onClick={onClose}>
      {isOpen && (
        <div
          className="downloadApp__container"
          onClick={(e) => e.stopPropagation()}
        >
          <h1 className="title">
            Aponte a câmera do seu celular para o QR Code e baixe o app
            Blackbook!
          </h1>
          <img src={qrcodeImg} alt="" className="qrCode__img" />
          <ul>
            <li>Mais de 10 mil rotinas e medicamentos</li>
            <li>Busca prática, rápida e confiável</li>
            <li>Conteúdo 100% offline</li>
          </ul>
          <div className="store__container">
            <p>Ou acesse a loja direto do seu desktop:</p>
            <div className="store__buttons">
              <a
                href="https://apps.apple.com/br/app/blackbook/id1496811013"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="AppStore"
                  src="https://firebasestorage.googleapis.com/v0/b/public-blackbook/o/templates%2Fappstore.png?alt=media"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=br.com.blackbook"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="PlayStore"
                  src="https://firebasestorage.googleapis.com/v0/b/public-blackbook/o/templates%2Fplaystore.png?alt=media"
                />
              </a>
            </div>
          </div>
        </div>
      )}
    </DownloadTheAppContainerStyle>
  );
}
