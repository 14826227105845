import PropTypes from "prop-types";
import React, { useRef } from "react";
import { usePaywall } from "../../../services/hooks/handleRedirectToPaywall";
import { BBArrowRightIcon, BBLockGrey } from "../../quarks/icons";
import { colors } from "../../quarks/theme";
import { SidebarStyle } from "./style";
import { useTrack } from "../../../services/hooks/useTrack";
import { AvatarComponent } from "../../../blackbook-components/atoms/avatar/index";
import { useUser } from "../../../context/useUser";

export const MenuList = (props) => {
  const { redirectToPaywall } = usePaywall();
  const { trackEvent } = useTrack();
  const { userProperties } = useUser();

  const sidebarListRef = useRef([]);

  const hasSubItems = (item) => {
    return item.subItems && item.subItems.length > 0;
  };

  const handleClick = (item) => {
    props.onClick(item, props.hierarchy);
    if (!hasSubItems(item)) {
      props.onClose();
    }
  };

  function scrollHighlightedItem(index) {
    sidebarListRef?.current[index]?.scrollIntoView({
      block: "center",
      behavior: "smooth",
    });
  }

  const getClasses = (item, index) => {
    const isActive = props.hightlightedItem === item.key;

    if (isActive && index) {
      setTimeout(() => {
        scrollHighlightedItem(index);
      }, 100);
    }
    return isActive ? "active" : "";
  };

  const getIcon = (item) => {
    return (
      item.icon &&
      React.cloneElement(item.icon, {
        primaryColor:
          props.hightlightedItem === item.key ? colors.secondary : undefined,
      })
    );
  };

  return (
    <SidebarStyle className={props.className} hierarchy={props.hierarchy}>
      {props.hierarchy < 1 && (
        <AvatarComponent
          image={`https://firebasestorage.googleapis.com/v0/b/content-blackbook-dev/o/profiles%2F${userProperties?._id}?alt=media`}
          userName={userProperties?.name}
        />
      )}
      {props.items.map((item, index) => {
        if (props.isHome && !item.appearsInHome) return "";
        return (
          <li
            key={item.key}
            className={getClasses(item, index)}
            ref={(ref) => (sidebarListRef.current[index] = ref)}
          >
            <button
              onClick={() => handleClick(item)}
              className={getClasses(item)}
            >
              <span>
                {getIcon(item)} {item.text}
              </span>{" "}
              {hasSubItems(item) && (
                <div className="sub_item_arrow">
                  <BBArrowRightIcon />
                </div>
              )}
              {item.isBlocked && (
                <div
                  className="lock"
                  onClick={(e) => {
                    let eventProps = {};

                    if (item?._trackID === "therapeutic_group") {
                      eventProps = {
                        Origem: "Lista de drogas",
                        Especialidade: item?._parentSpecialty,
                        "Grupo terapêutico": item?.text,
                      };
                    }

                    if (item?._trackID === "routine") {
                      eventProps = {
                        Origem: "Lista de rotinas",
                        Especialidade: item?._parentSpecialty,
                        Rotina: item?._routineTitle,
                      };
                    }

                    trackEvent("Clicou no cadeado", eventProps);
                    e.stopPropagation();
                    redirectToPaywall();
                  }}
                >
                  <BBLockGrey />
                </div>
              )}
            </button>
          </li>
        );
      })}
      {props.DownloadAppButton}
    </SidebarStyle>
  );
};

export const SidebarComponent = (props) => {
  return <MenuList hierarchy={0} {...props} />;
};

SidebarComponent.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      icon: PropTypes.element,
      isActive: PropTypes.bool,
      sub_items: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          isActive: PropTypes.bool,
          sub_items: PropTypes.arrayOf(
            PropTypes.shape({
              text: PropTypes.string,
              isActive: PropTypes.bool,
            })
          ),
        })
      ),
    })
  ),
  onClick: PropTypes.func,
};
