import React from "react";
import styled from "styled-components";

interface IProps {
  title: string;
  icon: any;
  onClick: ()=> void;
  style?: React.CSSProperties;
}

const Container = styled.button`
  background-color: #6515ca;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
  gap: 10px;
  border: none;
  cursor: pointer;
  @media screen and (max-width: 1325px), screen and (max-height: 690px) {
    grid-column: auto !important;
  }
`;

const ImageContainer = styled.div`
  min-width: 24px;
  min-height: 24px;
  max-width: 48px;
  max-height: 48px;
  width: 50%;
  height: 50%;
`;

const Title = styled.p`
  color: white;
  font-size: 1.1vw;
  font-weight: 600;
  letter-spacing: 1px;
`;

export const FeatureButton = (props: IProps) => {
  return (
    <Container style={props.style} onClick={() => props.onClick()}>
      <ImageContainer>{props.icon}</ImageContainer>
      <Title>{props.title}</Title>
    </Container>
  );
};
