import { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { RiArrowRightSLine } from 'react-icons/ri';

import { socket } from '../../../services/socket';
import { useUser } from '../../../context/useUser';
import { paymentApi } from '../../../services/clients';

import { Container } from './style';
import { ModalPaymentInProcess } from '../../atoms/ModalPaymentInProcess';
import { Spinner } from '../../../assets/animations/spinner';
import { getToken } from '../../../services/tokenService';

export function PlanCard({
  loading,
  hasProcessingPayment,
}) {
  const { userProperties, activePlan, setActivePlan } = useUser();

  const [isOpenPaymentProcessModal, setIsOpenPaymentProcessModal] =
    useState(false);

  const history = useHistory();

  useEffect(() => {
    const token = getToken();
    if (userProperties._id && token) {
      const hendleSocket = (response) => {
        setActivePlan(response);
        console.log('SOCKET DEU BOM: ', response);
      };

      socket.on(`${userProperties._id}/update_plan`, hendleSocket);
      // return () => socket.off(`${userProperties._id}/update_plan`, hendleSocket);
    }
  }, [activePlan, userProperties]);

  function handlePushToManagePlan() {
    if (hasProcessingPayment) {
      setIsOpenPaymentProcessModal(true);
    } else {
      history.push('/manageplan');
    }
  }

  return (
    <Container>
      {loading ? (
        <div className="loading__div planBox">
          <Spinner height="1.5rem" stroke_width="5" />
        </div>
      ) : activePlan?.level_access === 3 || hasProcessingPayment ? (
        <div onClick={handlePushToManagePlan}>
          <div className="planBox">
            <div className="planBox__title">
              <p className="planBox__plan">PLANO</p>
              <p className="planBox__free">PREMIUM</p>
            </div>

            <div className="planBox__subtitle">
              <p className="planBox__subscribe">GERENCIAR</p>
              <p className="planBox__symbol">
                <RiArrowRightSLine />
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div onClick={() => history.push('plans')}>
          <div className="planBox">
            <div className="planBox__title">
              <p className="planBox__plan">PLANO</p>
              <p className="planBox__free">FREE</p>
            </div>

            <div className="planBox__subtitle">
              <p className="planBox__subscribe">ASSINAR</p>
              <p className="planBox__symbol">
                <RiArrowRightSLine />
              </p>
            </div>
          </div>
        </div>
      )}

      {isOpenPaymentProcessModal && (
        <ModalPaymentInProcess
          setIsModalOpen={setIsOpenPaymentProcessModal}
          contentIsForMobileUser={false}
        />
      )}
    </Container>
  );
}
