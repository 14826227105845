import { useState, useEffect, useContext } from 'react';
import { RegisterSelectComponent } from '../../../../components/atoms/registerInput';

import { ButtonSelectComponent } from '../../../../components/molecules/buttonSelectComponent';
import { RegisterOrganism } from '../../../../components/organisms/registerOrganism';
import { SignUpStageContext } from '../../../../context/useSignUpStage';
import { authApi } from '../../../../services/clients';
import { useTrack } from '../../../../services/hooks/useTrack';

export function SignUpActuationArea({ isDesktop = false, ...props }) {
  const { goForward } = useContext(SignUpStageContext);
  const { trackEvent } = useTrack();
  const [actuationArea, setActuationArea] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleGetActuationArea = async function () {
    setIsLoading(true);
    try {
      const response = await authApi.getActuationAreas();

      const formattedActuationArea = response?.data.map(
        (actuationArea) => ({
          key: actuationArea._id,
          value: actuationArea.title,
        })
      );

      setActuationArea(formattedActuationArea);
    } catch (error) {}
    setIsLoading(false);
  };

  const handleChangeActuationArea = (specialty) => {
    window.sessionStorage.setItem('actuation_area_id', specialty.key);
    trackEvent('Cadastro atuação - Próximo', {
      'Área escolhida': specialty.value,
    });
    goForward();
  };

  useEffect(() => {
    handleGetActuationArea();
  }, []);

  if (isDesktop) {
    return (
      // <ButtonSelectComponent
      //   label="Área de atuação"
      //   options={actuationArea}
      //   onChange={(s) => handleChangeActuationArea(s)}
      //   isOptionsLoaging={isLoading}
      // />
      <RegisterSelectComponent
        // error={errorMessage}
        className={props?.className}
        label="Área de atuação"
        type="select"
        // value={graduationYear}
        options={actuationArea.map(e => e.title)}
        onChange={(e) => handleChangeActuationArea(e.target.value)}
      />
    );
  }
  return (
    <RegisterOrganism title="Crie sua conta" onFinish={() => {}}>
      <ButtonSelectComponent
        label="Qual é a sua área de atuação?"
        options={actuationArea}
        onChange={(s) => handleChangeActuationArea(s)}
        isOptionsLoaging={isLoading}
      />
    </RegisterOrganism>
  );
}
