import styled from 'styled-components';
import { colors, fontSize, spacing } from '../../quarks/theme';

export const FooterStyle = styled.div`
    max-width: 100%;
    padding: ${spacing.medium} ${spacing.large};
    background-color: ${colors.black300};
    font-size: ${fontSize.medium};
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px -10px 20px rgba(0, 0, 0, 0.1);
    z-index: 999;
    ul {
        margin: ${spacing.none};
        padding: ${spacing.none};
        li {
            display: inline;
            padding: ${spacing.none} ${spacing.large};
            a {
                color: ${colors.gray};
            }
        }

        &.footer_links {
            li {
                border-right: 1px solid ${colors.gray};
                padding: 0 ${spacing.medium};

                &:last-child {
                    border-right: none;
                }
            }
        }
    }

    @media screen and (max-width: 480px) {
        ul li {
            padding: 0 8px;
        }
    }
`
