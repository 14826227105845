// import { useUser } from '../../context/useUser';

import { gql, useLazyQuery } from "@apollo/client";
import { useUser } from "../../context/useUser";

export const HANDLE_B2B_BANNER = gql`
  query B2bBanner($user_campaign: String!) {
    b2bBanner(user_campaign: $user_campaign) {
      title
      link
      description
      image
      bannerTitle  
    }
  }
`;

export function useNewsFeed() {
  const { userProperties } = useUser();

  const [handleB2BBanner] = useLazyQuery(HANDLE_B2B_BANNER, {
    fetchPolicy: 'cache-and-network',
  });

  async function handleGetNewsFeed() {
    if (userProperties?.indicator_code) {
      const a = await handleB2BBanner({ variables: { user_campaign: userProperties?.indicator_code } });

      return a.data.b2bBanner
    }
    return null
  }

  return {
    handleGetNewsFeed,
  };
}
