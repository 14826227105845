import { BannerSection } from "./style";

export function BannerTwoComponent(props){
  return (
    <BannerSection>
      <a class="bannerLink" href={props.banner_link_id || props.banner_external_link} data-bannerType="2">
        <div class="banner">
          <img src={`${process.env.REACT_APP_FIREBASE_URL}bannerImages%2F${props._id}.png?alt=media`} alt="" class="banner__image" /> 
          <svg
            class="svg"
            viewBox="77.506 79.802 269.589 336.712"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M 77.715 79.804 L 77.716 79.802 L 150.933 79.802 C 159.437 89.884 165.862 101.549 169.818 114.134 C 175.425 129.46 178.36 145.62 178.499 161.923 C 178.552 178.417 175.728 194.795 170.152 210.334 C 165.447 224.533 157.621 237.517 147.241 248.349 C 157.599 259.332 165.318 272.499 169.818 286.862 C 181.286 317.828 181.404 351.811 170.152 382.854 C 165.994 395.203 159.488 406.627 151.004 416.514 L 77.723 416.514 C 87.82 405.779 95.362 392.932 99.791 378.92 C 105.202 363.941 108.024 348.162 108.138 332.249 C 108.128 316.112 105.304 300.098 99.791 284.916 C 95.157 271.142 87.552 258.536 77.506 247.977 C 87.56 237.439 95.166 224.845 99.791 211.079 C 105.304 195.897 108.128 179.883 108.138 163.746 C 108.027 147.833 105.205 132.053 99.791 117.075 C 95.326 103.173 87.783 90.438 77.715 79.804 Z"
              fill="#7515ed"
            />
            <rect
              x="109.087"
              y="79.802"
              width="238.008"
              height="336.712"
              fill="#7515ed"
            />
          </svg>
          <div class="textArea">
            <p>{props.banner_text}</p>
          </div>
        </div>
      </a>
    </BannerSection>
  );
}