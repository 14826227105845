import styled from 'styled-components';

const titleStyle = `
    color: white;
    padding: 1rem 0;
    font-size: 1rem;
    line-height: 2rem;
    font-weight: 700;

    &.center {
        text-align: center;
    }
`;

const subTitleStyle = `
    font-size: 1.25rem;
    font-weight: 400;
    text-align: center;
    line-height: 2rem;
    color: white;

    strong {
        font-weight: 700;
    }

    &.center {
        text-align: center;
    }
`;

export const TitleStyle = styled.h1`
    ${titleStyle}
`;

export const LabelStyle = styled.label`
    ${titleStyle}
`;

export const SubTitleStyle = styled.h2`
    ${subTitleStyle}
`;