import { gql, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const LOGIN_B2B = gql`
  mutation LoginB2B($data: String!) {
    b2bLogin(data: $data) {
      user {
        _id
      }
      token
    }
  }
  
`;

const VALIDATE_B2B = gql`

  mutation ValidateB2B($data: String!) {
    b2bValidate(data: $data) {
      isEnabledToPromotion
      isUserExists
    }
  }
`

const REGISTER_B2B = gql`

  mutation RegisterB2B($data: String!) {
    b2bRegister(data: $data) {
      token
    }
  }
`

export function useB2B() {
  const [
    login,
    { },
  ] = useMutation(LOGIN_B2B);

  const [
    validate,
    { },
  ] = useMutation(VALIDATE_B2B)

  const [
    register,
    { },
  ] = useMutation(REGISTER_B2B)

  async function handleLoginB2B(data) {
    const response = await login({
      variables: {
        data: data
      },
    }).catch(e => {
      throw Error('errro')
    }
    )
    return response.data.b2bLogin;
  }

  async function handleValidateB2B(data) {
    const response = await validate({
      variables: {
        data: data
      },
    })

    return response?.data?.b2bValidate
  }

  async function handleRegisterB2B(data) {
    const response = await register({
      variables: {
        data: data
      },
    })

    return response?.data?.b2bRegister
  }

  return {
    handleLoginB2B,
    handleValidateB2B,
    handleRegisterB2B
  }
};
