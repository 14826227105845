export const CID = () => (
  <svg
    fill="#6F6F6F"
    xmlns="http://www.w3.org/2000/svg"
    id="Capa_1"
    enable-background="new 0 0 512 512"
    height="20"
    viewBox="0 0 512 512"
    width="20"
  >
    <path d="m440.371 64.129h-16.548v-16.548c0-8.556-6.96-15.516-15.516-15.516h-257.033v-8.533c0-12.975-10.557-23.532-23.533-23.532s-23.532 10.557-23.532 23.532v8.533h-32.58c-8.556 0-15.516 6.96-15.516 15.516v416.839c0 8.556 6.96 15.516 15.516 15.516h16.548v16.548c0 8.556 6.96 15.516 15.516 15.516h336.678c8.556 0 15.516-6.96 15.516-15.516v-416.838c0-8.556-6.96-15.517-15.516-15.517zm-321.162-40.597c0-4.705 3.828-8.532 8.533-8.532s8.532 3.827 8.532 8.532v8.533h-17.065zm321.678 472.952c0 .285-.231.516-.516.516h-336.678c-.285 0-.516-.231-.516-.516v-16.548h305.129c8.556 0 15.516-6.96 15.516-15.516v-344.697c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v344.697c0 .285-.231.516-.516.516h-336.677c-.285 0-.516-.231-.516-.516v-416.839c0-.285.231-.516.516-.516h32.581v24.565c0 12.975 10.557 23.531 23.533 23.531s23.532-10.556 23.532-23.531c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5c0 4.704-3.828 8.531-8.533 8.531s-8.532-3.827-8.532-8.531v-24.566h289.097c.285 0 .516.231.516.516v40.081c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-8.532h16.548c.285 0 .516.231.516.517z" />
    <path
      fill="#8774FF"
      d="m216.436 223.936c0 8.556 6.96 15.516 15.516 15.516h16.032c8.556 0 15.516-6.96 15.516-15.516v-32.581h32.581c8.556 0 15.516-6.96 15.516-15.516v-16.032c0-8.556-6.96-15.517-15.516-15.517h-32.581v-32.58c0-8.556-6.96-15.517-15.516-15.517h-16.032c-8.556 0-15.516 6.961-15.516 15.517v32.58h-32.581c-8.556 0-15.516 6.961-15.516 15.517v16.032c0 8.556 6.96 15.516 15.516 15.516h32.581zm-32.581-47.582c-.285 0-.516-.231-.516-.516v-16.032c0-.285.231-.517.516-.517h40.081c4.142 0 7.5-3.357 7.5-7.5v-40.08c0-.285.231-.517.516-.517h16.032c.285 0 .516.231.516.517v40.08c0 4.143 3.358 7.5 7.5 7.5h40.081c.285 0 .516.231.516.517v16.032c0 .284-.231.516-.516.516h-40.081c-4.142 0-7.5 3.358-7.5 7.5v40.081c0 .285-.231.516-.516.516h-16.032c-.285 0-.516-.231-.516-.516v-40.081c0-4.142-3.358-7.5-7.5-7.5z"
    />
    <path d="m352.193 288.581h-160.322c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h160.322c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5z" />
    <path d="m151.791 288.581h-24.049c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h24.049c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5z" />
    <path d="m352.193 336.678h-160.322c-4.142 0-7.5 3.357-7.5 7.5 0 4.142 3.358 7.5 7.5 7.5h160.322c4.142 0 7.5-3.358 7.5-7.5 0-4.143-3.358-7.5-7.5-7.5z" />
    <path d="m352.193 384.774h-160.322c-4.142 0-7.5 3.357-7.5 7.5 0 4.142 3.358 7.5 7.5 7.5h160.322c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5z" />
    <path d="m151.791 336.678h-24.049c-4.142 0-7.5 3.357-7.5 7.5 0 4.142 3.358 7.5 7.5 7.5h24.049c4.142 0 7.5-3.358 7.5-7.5 0-4.143-3.358-7.5-7.5-7.5z" />
    <path d="m151.791 384.774h-24.049c-4.142 0-7.5 3.357-7.5 7.5 0 4.142 3.358 7.5 7.5 7.5h24.049c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5z" />
  </svg>
);