import PropTypes from 'prop-types';
import { DrugBrandStyle } from "./style";

export const DrugBrandComponent = props => (
    <DrugBrandStyle {...props}>
        <strong>{props?.title}</strong> <small>{props?.laboratory?.title}</small>
    </DrugBrandStyle>
);

DrugBrandComponent.propTypes = {
    title: PropTypes.string,
    laboratory: PropTypes.string,
}