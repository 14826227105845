import styled from 'styled-components';
import { colors, spacing } from '../../../../blackbook-components/quarks/theme';

export const HeaderStyle = styled.div`
    color: ${colors.white};
    .navbar_area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    .logo_area {
        display: flex;
        align-items: center;
        height: 100%;
        padding-left: ${spacing.xlarge};
        padding-right: ${spacing.xlarge};
        svg {
            cursor: pointer;
            padding: ${spacing.medium};
            color: ${colors.primary};
        }
        @media screen and (max-width: 420px) {
            width: 32px;
            .icon {
                display: none;
            }
        }
    }

    .user__menu {
        display: flex;
        align-items: center;
        gap: 20px;
        padding: ${spacing.none} ${spacing.xlarge};

        a {
            color: white;
            white-space: nowrap;
            transition: all 0.3s;

            &:hover {
                filter: brightness(0.8);
            }
        }

        @media screen and (max-width: 420px) {
            padding-right: 20px;
        }
    }
`
