import { useEffect, useState } from "react";
import { planApi } from "../../../services/clients";
import { Container } from "./style";

export function HistoryTable() {
  const [planHistory, setPlanHistory] = useState(null)

  useEffect(()=>{
    handlePlanHistory()
  },[])

  async function handlePlanHistory(){
    try {
      const response = await planApi.getPlanHistory()
      setPlanHistory(response)
    } catch (error) {
      console.log(error);
    }
  }

  function parseResponse(item, tagType) {
    let temp = [];

    for (let i = 1; i <= 5; i++) {
      switch (tagType) {
        case "td":
          temp.push(
            <td>
              {item[`col${i}`].map((line)=>(
                <p>{line}</p>
              ))}
            </td>
          );
          break;
        case "th":
          temp.push(
            <th>
              {item[`col${i}`]}
            </th>
          );
          break;
        default:
          break;
      }
    }

    return temp;
  }

  return (
    planHistory &&
    <Container>
      <h1 className="historyTable__title">
        Detalhes do plano
      </h1>
      <table className="historyTable">
        <thead className="historyTable__header">{parseResponse(planHistory.headers, "th")}</thead>
        <tbody className="historyTable__body">
          {planHistory.rows.map((item) => (
            <tr className="historyTable__row">{parseResponse(item, "td")}</tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
}
