import styled from 'styled-components';
import { colors, fontSize, spacing } from '../../quarks/theme';

export const AccordionStyle = styled.div`
   position: relative;
   width: 100%;
   box-sizing: border-box;
   
   color: ${({isDisabled}) => isDisabled && colors.white100};
   pointer-events: ${({isDisabled}) => isDisabled && "none"};

   .stickyHeader{
      display: flex;
      align-items: center;

      position: sticky;
      top: ${spacing.none};
      z-index: 10;
      background-color: ${props => props.accordionColor};

      &.sub_accordion{
         top: 40px;
         z-index: 9;

         &.grandchild{
            position: initial;
         }
      }

      &.resume{
         background-color: ${colors.hardBlack};
      }
      &.blue{
         background-color: ${colors.darkBlue};
         &.sub_accordion{
            background-color: ${colors.hardBlue};
         }
      }
      &.green{
         background-color: ${colors.darkGreen};
         &.sub_accordion{
            background-color: ${colors.hardGreen};
            &.grandchild{
               border-bottom: none;
               background-color: ${colors.softGreen};
            }
         }
      }
      &.yellow{
         background-color: ${colors.darkYellow};
         &.sub_accordion{
            background-color: ${colors.hardYellow};
            &.grandchild{
               border-bottom: none;
               background-color: ${colors.softYellow};
            }
         }
      }
      &.orange{
         background-color: ${colors.darkOrange};
         &.sub_accordion{
            background-color: ${colors.hardOrange};
         }
      }
      &.red{
         background-color: ${colors.darkRed};
         &.sub_accordion{
            background-color: ${colors.hardRed};
         }
      }
      &.indigo{
         background-color: ${colors.darkIndigo};
         &.sub_accordion{
            background-color: ${colors.hardIndigo};
         }
      }

   }

   .title {
      width: 100%;
      text-align: left;
      font-family: Source Sans Pro;
      font-weight: bold;
      margin: 0;
      padding: ${spacing.small} ${spacing.none} ${spacing.small} ${spacing.medium};
      box-sizing: border-box;
      min-height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      svg {
         float: right;
         min-width: 40px;
      }
   }
   &.active {
      padding-bottom: ${spacing.small};
   }

   .accordion_content {
      background-color: ${props => props.bodyColor || colors.white100 };
      color: ${colors.black};
      max-width: 100%;
   }

   &.blue {
      border-bottom: solid 1px ${colors.darkBlue};
      &:last-child  {
         border-bottom: 0;
      }
      .accordion_content {
        background-color: ${colors.hardBlue};
      }
   }
   &.green {
      border-bottom: solid 1px ${colors.darkGreen};
      &:last-child  {
         border-bottom: 0;
      }
      .accordion_content {
        background-color: ${colors.hardGreen};
      }
   }
   &.yellow {
      border-bottom: solid 1px ${colors.darkYellow};
      &:last-child  {
         border-bottom: 0;
      }
      .accordion_content {
        background-color: ${colors.hardYellow};
      }
   }
   &.orange {
      border-bottom: solid 1px ${colors.darkOrange};
      &:last-child  {
         border-bottom: 0;
      }
      .accordion_content {
        background-color: ${colors.hardOrange};
      }
   }
   &.red {
      border-bottom: solid 1px ${colors.darkRed};
      &:last-child  {
         border-bottom: 0;
      }
      .accordion_content {
        background-color: ${colors.hardRed};
      }
   }
   &.indigo {
      border-bottom: solid 1px ${colors.darkIndigo};
      &:last-child  {
         border-bottom: 0;
      }
      .accordion_content {
        background-color: ${colors.hardIndigo};
      }
   }
   &.soft.blue {
      .blue {
         border-bottom: solid 1px ${colors.hardBlue};
      &:last-child  {
         border-bottom: 0;
      }
      }
      .accordion_content {
        background-color: ${colors.softBlue};
      }
   }
   &.soft.green {
      .green {
         border-bottom: solid 1px ${colors.hardGreen};
      &:last-child  {
         border-bottom: 0;
      }
      }
      .accordion_content {
        background-color: ${colors.softGreen};
      }
   }
   &.soft.yellow {
      .yellow {
         border-bottom: solid 1px ${colors.hardYellow};
         &:last-child  {
            border-bottom: 0;
         }
      }
      .accordion_content {
        background-color: ${colors.softYellow};
      }
   }
   &.soft.orange {
      .orange {
         border-bottom: solid 1px ${colors.hardOrange};
      &:last-child  {
         border-bottom: 0;
      }
      }
      .accordion_content {
        background-color: ${colors.softOrange};
      }
   }
   &.soft.red {
      .red {
         border-bottom: solid 1px ${colors.hardRed};
      &:last-child  {
         border-bottom: 0;
      }
      }
      .accordion_content {
        background-color: ${colors.softRed};
      }
   }
   &.soft.indigo {
      .indigo {
         border-bottom: solid 1px ${colors.hardIndigo};
      &:last-child  {
         border-bottom: 0;
      }
      }
      .accordion_content {
        background-color: ${colors.softIndigo};
      }
   }

   &.sub_accordion {
      padding: ${spacing.none} ${spacing.small};

      &.active {
         padding-bottom: ${spacing.small};
      }

      .title {
         font-size: ${fontSize.large};
         font-weight: normal;
      }
            
      &:last-child {
         border-bottom: none;
      }
   }
`
