import io from 'socket.io-client';

import { getToken } from './tokenService';

export const socket = io(process.env.REACT_APP_WEBSOCKET_API, {
  auth: {
    token: getToken(),
    _web: true
  },
});
