import { getUserToken } from "../tokenService";
import { BaseClient } from "./baseClient";

export class GiftCardApiClient extends BaseClient {

  constructor() {
    super(process.env.REACT_APP_GIFT_CARD_API);
  }

  validateGiftCard(giftCardCode) {
    return this.get(`/validate/${giftCardCode}`, {headers: {'Authorization': `${getUserToken()}`}});
  }
}

export const giftCardApi = new GiftCardApiClient();