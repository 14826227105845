import React from "react";
import styled from "styled-components";
//@ts-ignore
import { useHistory } from 'react-router-dom';
//@ts-ignore
import {useTrack} from '../../../services/hooks/useTrack'

interface IProps {
  title: string;
  data?: any;
  image?: any;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 0;
  min-height: 100%;
  min-width: 100px;
  @media only screen and (max-height: 650px) and (min-width: 1100px) {
    :last-child {
      margin-right: 200px;
    }
  }
  /* @media only screen and (max-width: 1100px), screen and (min-height: 1000px) {
    height: 150px;
  } */
`;

const Title = styled.p`
  color: white;
  font-size: 12px;
  margin-top: 5px;
  text-align: center;
  height: 10%;
  width: 100%;
  min-height: 2em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Button = styled.button`
  background-color: transparent;
  border: 2px dashed #6f6f6f;
  border-radius: 10px;
  width: 100%;
  height: calc(100% - 25px);
  display: grid;
  grid-template-rows: 1fr 1fr;
  padding: 10px;
  align-items: center;
  justify-items: center;
  cursor: pointer;
  @media screen and (max-height: 350px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Text = styled.p`
  color: #6f6f6f;
  width: 100%;
  font-size: 14px;
  @media screen and (max-height: 350px) {
    display: none;
  }
`;

const IconContainer = styled.div`
  min-width: 24px;
  min-height: 24px;
  max-width: 48px;
  max-height: 48px;
  width: 50%;
  height: 50%;
`;

const ImageContainer = styled.button`
  border-radius: 10px;
  overflow: hidden;
  height: 90%;
  width: 100%;
  cursor: pointer;
  border: none;
  padding: 0;
`;

export const MyBlackbookButton = (props: IProps) => {
  const history = useHistory();
  const { trackEvent } = useTrack();
  
  return (
    <Container>
      {props.image ? (
        <ImageContainer
          onClick={() => {
            trackEvent('Clicou em favorito', {Origem: 'Home', 'Tipo da Origem': 'Meu Blackbook', Favorito: props.title})
            if (props.data.type === "routine") {
              history.push(`/app/routines/${props.data.id}`, {
                trackOrigin: "Meu Blackbook",
                trackType: "Home",
              });
              return;
            } 

            if(props.data.type === "therapeutic_group"){
              history.push(`/app/drugs/${props.data.id}`, {
                trackOrigin: "Meu Blackbook",
                trackType: "Home",
              });
              return;
            }

            if(props.data.type === "active_principle"){
              history.push(`/app/drugs/${props?.data?.subId}/${props?.data?.id}`, {
                trackOrigin: "Meu Blackbook",
                trackType: "Home",
              });
              return;
            }
          }}
        >
          <img
            src={
              props.image.includes("firebase")
                ? props.image
                : require(`../../../assets/images/homeDrugs/${props.image}.webp`)
            }
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
            alt=""
          />
        </ImageContainer>
      ) : (
        <Button onClick={() => history.push("app/search")}>
          <Text>
            Adicionar <br /> conteúdo
          </Text>
          <IconContainer>
            <svg
              stroke="currentColor"
              fill="#8c8c8c"
              stroke-width="0"
              viewBox="0 0 24 24"
              height="100%"
              width="100%"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path fill="none" d="M0 0h24v24H0V0z"></path>
              <path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H8V4h12v12zm-7-2h2v-3h3V9h-3V6h-2v3h-3v2h3z"></path>
            </svg>
          </IconContainer>
        </Button>
      )}
      <Title>{props.title}</Title>
    </Container>
  );
};
