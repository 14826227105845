import { LogoStyle } from "./style";

export const LogoComponent = (props) => {
    return (
        <LogoStyle>
            <img
                alt="Blackbook"
                src="https://firebasestorage.googleapis.com/v0/b/public-blackbook/o/templates%2Flogo.png?alt=media"
            />
        </LogoStyle>
    )
}