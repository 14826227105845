import { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';

import { SignUpStageContext } from '../../context/useSignUpStage';
import { DesktopSignup } from './Desktop';
import { SignUpEmail } from './Steps/SignUpEmail';
import { SignUpGraduationYear } from './Steps/SignUpGraduationYear';
import { SignUpName } from './Steps/SignUpName';
import { SignUpPassword } from './Steps/SignUpPassword';
import { SignUpPhone } from './Steps/SignUpPhone';
import { SignUpSpecialties } from './Steps/SignUpSpecialties';
import { ThanksForSignUp } from './Steps/ThanksForSignUp';
import { UseTerms } from './Steps/UseTerms';
import { SignUpActuationArea } from './Steps/SignUpActuationArea';

export function SignUp() {
  const isDesktop = useMediaQuery({
    query: '(min-width: 717px)',
  });

  const [isDesktopLayoutOn, setIsDesktopLayoutOn] = useState(true);

  useEffect(() => {
    setIsDesktopLayoutOn(isDesktop);
  }, [isDesktop]);

  const history = useHistory();
  const { signUpStage } = useContext(SignUpStageContext);

  const stage = {
    0: <SignUpEmail />,
    1: <SignUpPassword />,
    2: <SignUpName />,
    3: <SignUpPhone />,
    4: <SignUpGraduationYear />,
    5: <SignUpActuationArea />,
    6: <SignUpSpecialties />,
    7: <UseTerms />,
    8: <ThanksForSignUp />,
  };

  if (isDesktopLayoutOn) {
    return <DesktopSignup />;
  }

  return <>{stage[signUpStage] || history.push('/')}</>;
}
