import { Container } from './style';

export const SubscriptionInfoText = () => {
  return (
    <Container>
      <h3 className="contentText__title">1. Rotinas</h3>
      <span>
        Acesso às melhores rotinas para aprimorar e agilizar a sua prática
        profissional no cuidado de saúde da criança e do adulto.
      </span>
      <h3 className="contentText__title">2. Drogas</h3>
      <span>
        O melhor guia de medicamentos com informações necessárias para sua
        decisão na hora das prescrições.
      </span>
      <h3 className="contentText__title">3. Prático e confiável</h3>
      <span>
        Atualizado por profissionais de referência. Faça buscas rápidas,
        eficientes e completas para o seu dia a dia de trabalho na Medicina e
        Enfermagem.
      </span>
      <h3 className="contentText__title">Assinaturas</h3>
      <span>
        O faturamento começa assim que a avaliação gratuita termina. Cancele
        antes do fim da avaliação e você não será cobrado.
      </span>
      <span>
        A assinatura é renovada automaticamente até que você faça o
        cancelamento.
      </span>
    </Container>
  );
};
