import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #333;
  max-width: 680px;
  margin: 0 auto;
  width: 100%;
  height: fit-content;
  box-shadow: 0 9px 12px rgba(0, 0, 0, 0.3);

  align-items: flex-start;
  justify-content: center;

  .overviewHeader{
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: white;
    border-bottom: 1px solid var(--yellow-500);
  }
  .overviewHeader__title{
    font-size: 1.25rem;
    font-weight: bold;
    padding: 1rem;
  }

  .header__icon {
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    transition: filter 0.2s;
    
    &:hover {
      filter: brightness(0.9);
    }
  }
  .overviewContent{
      width: calc(100% - 2rem);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 1rem;
      
  }
  .overviewContent__table{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 100%;

  }
  .table__row{
      width: 100%;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #999999;
      padding: 1rem 0;

      &:last-child {
        border: none;
      }
  }
  .table__header{
      color: white;
      white-space: nowrap;
      padding-right: 1rem;
      color: #999999;

      .changePayment__button {
        text-decoration: underline;
        cursor: pointer;
        transition: color 0.2s;

        &:hover {
          color: var(--purple-500);
        }
      }
  }
  .table__cell{
      color: white;
      word-break: break-all;
      text-align: right;
  }
`;
