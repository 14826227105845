import { NotFound } from "../../../components/molecules/notFound"
import  DefaultTemplate from "../../../components/templates/DefaultTemplate"

export function NotFoundSignedIn() {
    window.scrollTo(0, 0);

    return(
        <DefaultTemplate>
            <NotFound/>
        </DefaultTemplate>
    )
}