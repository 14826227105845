import { colors, fontSize, spacing } from '../../quarks/theme';
import styled from 'styled-components';

export const ContentSliderStyle = styled.div`
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
    position: relative;
    .swiper {
        height: calc(100vh - 122px);
    }
    .swiper-slide {
        width: 60%;
        margin-top: 50px;
    }
    .swiper-button-prev, .swiper-button-next {
        background-color: ${colors.primary};
        width: 40px;
        height: 40px;
        border-radius: 50%;
        font-size: ${fontSize.large};
        color: ${colors.white};
        box-shadow: 0 9px 12px rgba(0, 0, 0, 0.3);
        position: fixed;
        top: 50%;
        left: auto;
        margin: 0 24px;
        &::after {
            font-size: ${fontSize.large};
            font-weight: bold;
        }
    }
    .swiper-button-disabled {
        display: none;
    }
    .swiper-pagination {
        color: ${colors.white};
        margin: ${spacing.medium} 0;
        position: initial;
    }
`
