import styled from "styled-components";

export const BannerSection = styled.section`
  width: 100%;
  height: 100%;
  .bannerLink {
    height: 100%;
  }
  .banner {
    position: relative;
    height: 100%;
    max-height: 350px;
    z-index: -10;
    border-radius: 10px;
    overflow: hidden;
    font-family: "Open Sans", sans-serif;
    display: grid;
    grid-template-columns: 55% 1fr;
  }

  .svg {
    position: absolute;
    height: 100%;
    max-width: 100%;
    width: auto;
    z-index: -1;
    right: 0;
  }

  .textArea {
    box-sizing: border-box;
    /* padding: 14px; */
    /* position: absolute; */
    left: 0;
    height: 100%;
    width: auto;
    display: grid;
    grid-template-columns: 9fr 1fr;
    position: relative;
  }

  .textArea > p {
    box-sizing: border-box;
    padding: 14px;
    height: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-align: left;
    color: white;
    text-decoration: none;
    font-size: calc(13px + 1vw);
    line-height: calc(15px + 1.2vw);
    display: flex;
    align-items: center;
    @media screen and (max-height: 500px) {
      -webkit-line-clamp: 2;
      font-size: calc(9px + 1vw);
      line-height: calc(5px + 1.2vw);
      vertical-align: middle;
      display: flex;
    }
  }

  .banner__image {
    object-fit: cover;
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 60%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -2;
  }
`;
