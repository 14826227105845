import { useState } from 'react';
import { toast } from 'react-toastify';

import useIugu from '../../../services/hooks/iugu';
import { useIsDesktopOrTabletScreen } from '../../../services/hooks/useIsDesktopOrTabletScreen';
import { useTrack } from '../../../services/hooks/useTrack';
import { NewInput } from '../inputs/NewInput';

import { Form } from './style';

export function CreditCardPaymentComponent({
  showInstallments = true,
  maxInstallments,
  planPrice,
  handleSubmitPayment,
  selectedPlan
}) {
  const {trackEvent} = useTrack()
  const isDesktop = useIsDesktopOrTabletScreen()
  const Iugu = useIugu(process.env.REACT_APP_IUGU_KEY);
  const [errors, setErrors] = useState({});
  const [installments, setInstallments] = useState(1);
  const [creditCardObject, setCreditCardObject] = useState(null);

  function handleRenderInstallmentsOptions() {
    let options = [];

    for (let i = 1; i <= maxInstallments; i++) {
      options = [
        ...options,
        <option value={i} key={i}>
          {i} X de{' '}
          {(planPrice / 100 / i).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}
        </option>,
      ];
    }

    return options;
  }

  function handleShowErrors(err) {
    err?.number && toast.error("Número de cartão inválido.");
    err?.verification_value && toast.error("Codigo de verificação errado.");
    err?.expiration && toast.error("Data de expiração inválida.");
    err?.first_name && err?.last_name && toast.error("Nome inválido.");
    err?.adblock && toast.error(err.adblock, {autoClose: 5000});
  }

  async function handleCreateCardToken(event) {
    event.preventDefault();
    try {
      
      
      const response = await Iugu.createPaymentToken(event.target);
      
      selectedPlan && trackEvent('Paywall - Assinar', {
        'device': isDesktop? 'large':'small',
        'plano': `${selectedPlan?.store_identify}_${selectedPlan?.prices[0]?.store_identify}`,
        'parcelamento': installments,
        'erro disparado': response?.errors ? true : false,
        ...response.errors
      })
      
      if (response.errors) {
        handleShowErrors(response.errors)
        return setErrors(response.errors);
      }
      
      const method = {
        type: 'credit_card',
        card_token: response.id,
        value: planPrice,
        installments: installments,
      };
      
      handleSubmitPayment(method);
    } catch (error) {
      toast.error(
        'Ocorreu um erro! Tente novamente mais tarde.'
      );
    }
  }

  return (
    <Form id="payment-form" onSubmit={(e) => handleCreateCardToken(e)}>
      <h3 className="payment__title">Cartão de crédito</h3>
      <NewInput
        inputProp={{
          className: 'outline__input',
          label: 'Número do cartão',
          mask: '9999 9999 9999 9999',
          maskChar: null,
          type: 'text',
          'data-iugu': 'number',
          onChange: (e) => {
            errors.number && setErrors({ ...errors, number: undefined });
            setCreditCardObject({
              ...creditCardObject,
              number: e.target.value,
            });
          },
          value: creditCardObject?.number,
          isInvalid: errors?.number,
        }}
      />
      <div className="temp_123">
        <NewInput
          inputProp={{
            className: 'outline__input',
            label: 'CVV',
            mask: '9999',
            maskChar: null,
            type: 'text',
            'data-iugu': 'verification_value',
            onChange: (e) => {
              errors.verification_value &&
                setErrors({ ...errors, verification_value: undefined });
              setCreditCardObject({
                ...creditCardObject,
                verification_value: e.target.value,
              });
            },
            value: creditCardObject?.verification_value,
            isInvalid: errors?.verification_value,
          }}
        />
        <NewInput
          inputProp={{
            className: 'outline__input',
            label: 'Data de expiração',
            mask: '99/99',
            maskChar: null,
            type: 'text',
            'data-iugu': 'expiration',
            onChange: (e) => {
              errors.expiration &&
                setErrors({ ...errors, expiration: undefined });
              setCreditCardObject({
                ...creditCardObject,
                expiration: e.target.value,
              });
            },
            value: creditCardObject?.expiration,
            isInvalid: errors?.expiration,
          }}
        />
      </div>

      <NewInput
        inputProp={{
          className: 'outline__input',
          label: 'Nome completo',
          type: 'text',
          'data-iugu': 'full_name',
          onChange: (e) => {
            (errors.first_name || errors.last_name) &&
              setErrors({
                ...errors,
                last_name: undefined,
                first_name: undefined,
              });
            setCreditCardObject({
              ...creditCardObject,
              full_name: e.target.value,
            });
          },
          value: creditCardObject?.full_name,
          isInvalid: errors?.first_name || errors?.last_name,
        }}
      />

      {showInstallments && (
        <label className="installments__label" for="installments">
          <span> Parcelamento</span>
          <select
            name="installments"
            onChange={(e) => setInstallments(e.target.value)}
            value={installments}
          >
            {handleRenderInstallmentsOptions()}
          </select>
        </label>
      )}
    </Form>
  );
}
