import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

import { authApi } from '../../services/clients';
import { DefaultInput } from '../../components/atoms/inputs/DefaultInput';
import { ButtonComponent } from '../../components/atoms/buttonComponent';

import logo from '../../assets/images/logo.png';
import logoName from '../../assets/images/logo-text.svg';

import { Container } from './style';

export function ForgotPassword() {
  window.scrollTo(0, 0);
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      email: '',
    },

    validationSchema: Yup.object({
      email: Yup.string()
        .required('Campo obrigatório!')
        .email('Endereço de email inválido!'),
    }),

    onSubmit: async (values) => {
      try {
        await authApi.recoveryPassword(values.email);
        toast.success('E-mail de recuperar senha enviado!');
        history.push('/signin');
      } catch (error) {
        toast.error('Ocorreu um erro!');
        return error;
      }
    },
  });

  return (
    <Container>
      <div className="loginTopDiv">
        <img src={logo} alt="" className="loginTopDiv__image" />
        <img src={logoName} alt="" className="loginTopDiv__imageName" />
      </div>

      <form className="loginForm" onSubmit={formik.handleSubmit}>
        <div className="title">Informe o e-mail associado à sua conta</div>
        <main className="loginMain">
          <DefaultInput
            inputProp={{
              type: 'email',
              className: `form__input ${
                formik.touched.email && formik.errors.email ? '--error' : ''
              }
                `,
              placeholder: 'E-mail',
              name: 'email',
              onChange: formik.handleChange,
            }}
          />
          {formik.touched.email && formik.errors.email ? (
            <p className="form__error">{formik.errors.email}</p>
          ) : null}
          <ButtonComponent
            buttonProp={{
              type: 'submit',
            }}
            children="RECUPERAR SENHA"
          />
        </main>
      </form>
    </Container>
  );
}
