import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 100%;

  .paywall__content {
    position: relative;
    z-index: 3;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex-direction: column;
    padding: 48px;
    box-sizing: border-box;

    overflow-y: auto;
    overflow-x: hidden;

    .header__buttons {
      display: flex;
      gap: 10px;
      margin-bottom: 30px;

      button {
        border: none;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 15px;
        border-radius: 25px;
        max-width: 175px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        color: white;
        cursor: pointer;

        gap: 5px;
        transition: filter 0.2s;

        box-shadow: 0 9px 12px rgba(0, 0, 0, 0.3);

        svg {
          height: 14px;
        }

        &.gray {
          background-color: #4E4E4E;
        }

        &.purple {
          background-color: var(--purple-500);
        }

        &:hover {
          filter: brightness(0.9);
        }
      }
    }

    .content__first-box {
      color: white;
      display: flex;
      flex-direction: column;
      height: 100%;

      .plans-page__title {
        font-size: 48px;
        font-weight: 700;
        margin-bottom: 10px;
        margin-top: 42px;
      }

      .plans-page__subtitle {
        font-size: 18px;
        margin-bottom: 30px;
        font-weight: 300;

        b {
          font-weight: 700;
        }
      }
    }
  }
  
  .planCardsContainerHeader {
    display: flex;
  }
  .planCardsContainerHeader__giftCardButton {
    box-shadow: 0 9px 12px rgba(0, 0, 0, 0.3);
    background-color: #8774ff;
    width: 50%;
    padding: 1rem;
    font-size: 0.75rem;
    font-weight: bold;
    border: none;
    border-radius: 25px;
    margin: 0;
    color: white;
    transition: linear 0.2s;
    margin-top: 21px;
    &:hover {
      cursor: pointer;
      filter: brightness(0.9);
    }
  }
  
  .getBackToContent__button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    box-shadow: 0 9px 12px rgba(0, 0, 0, 0.3);
    background-color: var(--green-base);
    width: 50%;
    padding: 1rem;
    font-size: 0.75rem;
    font-weight: bold;
    border: none;
    border-radius: 25px;
    margin: 21px 0;
    color: white;
    transition: linear 0.2s;
    &:hover {
      cursor: pointer;
      filter: brightness(0.9);
    }
  }
  .planCardsContainerHeader__title {
    color: white;
    font-size: 2rem;
    margin-top: 15px;
    font-weight: 700;
    br {
      display: none;
    }
  }
  .plansCardsContainerHeader__subtitle {
    color: white;
    font-size: 1rem;
    font-weight: 300;
    text-align: center;
    margin-bottom: 1rem;
    max-width: 680px;
    align-self: center;
    display: flex;
    line-height: 1.5rem;
  }

  .planCardsContainerContent {
    gap: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
  }


  .download-apps__container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 20px;
    position: fixed;
    right: 20px;
    bottom: 50px;

    a:not(.whatsapp_icon) {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #080808;
      border: 1px solid #fff;
      border-radius: 5px;
      height: 48px;
      max-width: 147px;
    }
    img {
      width: 100%;
    }
  }

  .giftCard__button {
    background-color: var(--purple-500);

    margin-top: 40px;
    height: 40px;
    border-radius: 25px;
    border: none;
    text-transform: uppercase;
    color: white;
    font-weight: 700;
  }

  @media screen and (max-width: 1010px) {
    .paywall__content {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr auto;
      gap: 40px;
      padding: 20px;

      .header__buttons {
        position: absolute;
        top: 20px;
        width: 100%;
      }

      .content__first-box {
        justify-content: flex-end;
      }
    }

    .download-apps__container {
      justify-content: center;
      position: initial;
    }
  }
`;
