import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;

  background: rgba(0, 0, 0, 0.7);

  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 100;

  height: 100%;
  overflow-y: auto;

  .content {
    background-color: #383838;
    color: white;
    border-radius: 5px;
    height: auto;
    max-width: 680px;
    margin: 2.5rem;

    .content__header {
      border-bottom: 1px solid var(--yellow-500);
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      
      .header__title {
        padding: 1rem 1.25rem;
        font-size: 1.25rem;
        font-weight: bold;
      }

      .close__button {
        background: none;
        border: none;
        cursor: pointer;
        outline: none;

        transition: filter 0.2s;
        &:hover {
          filter: brightness(0.9);
        }
      }
    }

    .content__text {
      padding: 1rem 1.25rem;
      font-family: Source Sans Pro;
      font-size: 1rem;
      font-weight: 400;
      color: #F2F2F2;
      line-height: 1.25rem;

      .contentText__title {
        font-weight: 700;

        margin-bottom: 1rem;
        &:not(:first-child) {
          margin-top: 1rem;
        }
      }
    }

    .content__buttons {
      display: flex;
      flex-direction: column;
      padding: 0 10px;

      .btn {
        cursor: pointer;
        border: none;
        background-color: var(--purple-500);
        padding: 0.75rem;
        margin-bottom: 10px;
        border-radius: 100px;
        color: white;

        font-weight: 700;
        font-size: 0.75rem;
        font-family: Open Sans;

        transition: filter 0.2s;
        &:hover {
          filter: brightness(0.9);
        }
      }
    } 
  }


  .confirmCancel__modal {
    background-color: #383838;
    color: white;
    border-radius: 5px;
    height: auto;
    max-width: 350px;
    width: 100%;
    margin: 2.5rem;
    border: 1px solid var(--error);

    .confirmCancel__header {
      padding: 1rem 2rem 1rem 1rem;
      border-bottom: 1px solid var(--error);
      margin-left: 1rem;
      color: var(--error);
      font-weight: 700;
      text-align: center;
    }

    .confirmCancel__body {
      font-family: Source Sans Pro;
      text-align: center;
      padding: 1rem;
      
      h4 {
        line-height: 1.5rem;
        padding: 1rem 0 2rem;
      }

      .confirmCancel__buttons {
        display: flex;
        justify-content: space-between;
        gap: 0.5rem;

        button {
          width: 100%;
          border: none;
          padding: 1rem 0;
          border-radius: 100px;
          color: white;
          font-weight: 700;
          cursor: pointer;

          &.red {
            background-color: var(--error);
          }
  
          &.green {
            background-color: var(--green-base);
          }
        }
      }
    }

  }
`;
