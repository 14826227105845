import { useState, useEffect, useContext } from 'react';
import {
  getCountryCallingCode,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import { RegisterPhoneInputComponent } from '../../../../components/molecules/phoneInput';

import { RegisterOrganism } from '../../../../components/organisms/registerOrganism';
import { SignUpStageContext } from '../../../../context/useSignUpStage';
import { useTrack } from '../../../../services/hooks/useTrack';

export function SignUpPhone({
  styleClass = 'default',
  isDesktop = false,
  desktopCallback = () => null,
  desktopErrorMessage = '',
  phoneValue = '',
  onBlur
}) {
  const { goForward } = useContext(SignUpStageContext);
  const { trackEvent } = useTrack();

  const [phone, setPhone] = useState(phoneValue);
  const [country, setCountry] = useState('BR');
  const [isPhoneCompleted, setIsPhoneCompleted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  function handleChangePhone(value, countryLabel) {
    setPhone(value);

    desktopCallback('', '');
    const country_dial = getCountryCallingCode(countryLabel);
    const completedPhoneNumber = `+${country_dial}${value.replace(
      /[^A-Z0-9]/gi,
      ''
    )}`;

    if (countryLabel !== 'BR') {
      if (value.length < 1) {
        setIsPhoneCompleted(false);

        return;
      }
    } else if (
      !isValidPhoneNumber(completedPhoneNumber) ||
      completedPhoneNumber.length < 14
    ) {
      setIsPhoneCompleted(false);

      return;
    }

    desktopCallback(country_dial, value);

    setErrorMessage(null);
    setIsPhoneCompleted(true);
  }

  function sendPhoneToRegister() {
    if (phone) {
      window.sessionStorage.setItem('phone', phone);
      window.sessionStorage.setItem('country', country);
      window.sessionStorage.setItem(
        'country_dial',
        getCountryCallingCode(country)
      );
      if (getCountryCallingCode(country) == 55) {
        trackEvent('Cadastro telefone - Próximo', {
          Telefone: `(${phone.substring(0, 2)}) ${phone.substring(
            2,
            7
          )}-${phone.substring(7, 11)}`,
        });
      } else {
        trackEvent('Cadastro telefone - Próximo', { Telefone: phone });
      }
      goForward();
    } else {
      setErrorMessage('Telefone inválido');
    }
  }

  useEffect(() => {
    const phoneOnStorage = sessionStorage.getItem('phone');
    const countryOnStorage = sessionStorage.getItem('country');
    const countryDialOnStorage = sessionStorage.getItem('country_dial');
    if (phoneOnStorage && countryDialOnStorage && countryOnStorage) {
      setCountry(countryOnStorage);
      handleChangePhone(phoneOnStorage, countryOnStorage);
      sessionStorage.removeItem('phone');
      sessionStorage.removeItem('country');
      sessionStorage.removeItem('country_dial');
    }
  }, []);

  if (isDesktop) {
    return (
      <RegisterPhoneInputComponent
        styleClass={styleClass}
        label="Telefone"
        error={errorMessage || desktopErrorMessage}
        countryLabel={country}
        setCountryLabel={setCountry}
        phone={phone}
        onChange={(phoneData) => handleChangePhone(phoneData, country)}
        onBlur={onBlur}
      />
    );
  }

  return (
    <RegisterOrganism
      title="Crie sua conta"
      buttonProps={{
        disabled: !isPhoneCompleted,
        className: 'fullWidth',
        type: 'submit',
      }}
      onFinish={() => {
        sendPhoneToRegister();
      }}
    >
      <RegisterPhoneInputComponent
        autoFocus
        label="Estamos quase lá! Qual é o seu telefone?"
        error={errorMessage}
        countryLabel={country}
        setCountryLabel={setCountry}
        phone={phone}
        onChange={(phoneData) => handleChangePhone(phoneData, country)}
        onBlur={onBlur}
      />
    </RegisterOrganism>
  );
}
