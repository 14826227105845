import { Construction } from "../../components/molecules/construction";
import DefaultTemplate from "../../components/templates/DefaultTemplate";

export function UnderConstruction() {
  window.scrollTo(0, 0);
  return (
    <DefaultTemplate>
      <Construction />
    </DefaultTemplate>
  );
}
