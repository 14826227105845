import styled from 'styled-components';

export const Container = styled.div`
    background-color: black;
    color: white;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;

    .header {
        height: fit-content;
    }

    .header_logo{
        padding: 1rem;
    }

    .body {
        display: flex;
        flex-direction: column;
    }

    .img_div {
        display: flex;
        justify-content: center;
        padding: 1rem;
    }

    .mobile_img {
        height: 200px;
        width: 200px;
    }

    .textAndButton_div {
        padding: 1rem;
    }

    .text_div {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .register_text {
            align-self: center;
            font-size: 1rem;
            padding: 1.5rem 1.5rem 0 1.5rem;
        }

        .app_text {
            align-self: center;
            font-size: 2rem;
            padding: 1.5rem;
            font-weight: bold;
        }
    }

    .buttons_div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        
        a {
            border: 1px solid white;
            border-radius: 5px;
            margin: 5px;
        }
    }

    .home_button {
        display: flex;
        justify-content: center;
        margin-top: 10px;

        .button_link {
            background-color: unset;
            border: none;
            color: var(--purple-500);
            font-size: 0.6rem;

            &:hover {
                cursor: pointer;
                color: var(--purple-900);
            }
        }
    }

    @media screen and (min-width: 700px) {
        overflow-y: hidden;

        .body {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
        }

        .img_div {
            padding: 6rem 1rem 6rem 1rem;
        }

        .mobile_img {
            height: 300px;
            width: 300px;
        }

        .textAndButton_div {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .text_div {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .register_text {
                align-self: inherit;
                font-size: 1.5rem;
                padding: 1.5rem;
            }
    
            .app_text {
                align-self: inherit;
                font-size: 2.5rem;
                padding: 1.5rem;
            }
        }
    
        .buttons_div {
            display: flex;
            flex-direction: row;
            
            a {
                border: 1px solid white;
                border-radius: 5px;
                margin: 10px;
            }
        }
    }

    @media screen and (min-width: 1500px) {
        overflow-y: hidden;

        .img_div {
            padding: 6rem 1rem 6rem 1rem;
        }

        .mobile_img {
            height: 500px;
            width: 500px;
        }

        .textAndButton_div {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .text_div {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .register_text {
                align-self: center;
                font-size: 2rem;
                padding: 1.5rem;
            }

            .app_text {
                align-self: center;
                font-size: 2.5rem;
                padding: 1.5rem;
            }
        }
    
        .buttons_div {
            display: flex;
            flex-direction: row;
            
            a {
                border: 1px solid white;
                border-radius: 5px;
                margin: 15px;
            }
        }
    }

    @media screen and (max-width: 280px) {
        .buttons_div {
            img {
                width: 6.5rem;
                height: 2rem;
            }
        }

        .text_div {
            .app_text {
                align-self: auto;
            }
        }
    }
`
