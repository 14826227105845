import { fontSize, spacing } from '../../quarks/theme';
import styled from 'styled-components';

export const DrugBrandStyle = styled.p`
    position: relative;
    margin: ${spacing.medium} 0;
    small {
        vertical-align: text-top;
        font-size: ${fontSize.small};
        margin-left: ${spacing.small};
        font-weight: normal;
        font-style: italic;
    }
`
