export const CalcIcon = (color = "white") => (
  <svg
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
    id="_x31__px"
    enable-background="new 0 0 24 24"
    height="100%"
    viewBox="0 0 24 24"
    width="100%"
  >
    <path d="m19.5 24h-15c-1.378 0-2.5-1.122-2.5-2.5v-19c0-1.378 1.122-2.5 2.5-2.5h15c1.378 0 2.5 1.122 2.5 2.5v19c0 1.378-1.122 2.5-2.5 2.5zm-15-23c-.827 0-1.5.673-1.5 1.5v19c0 .827.673 1.5 1.5 1.5h15c.827 0 1.5-.673 1.5-1.5v-19c0-.827-.673-1.5-1.5-1.5z" />
    <path d="m17.5 8h-11c-.827 0-1.5-.673-1.5-1.5v-2c0-.827.673-1.5 1.5-1.5h11c.827 0 1.5.673 1.5 1.5v2c0 .827-.673 1.5-1.5 1.5zm-11-4c-.276 0-.5.224-.5.5v2c0 .276.224.5.5.5h11c.276 0 .5-.224.5-.5v-2c0-.276-.224-.5-.5-.5z" />
    <path d="m8 14c-.276 0-.5-.224-.5-.5v-3c0-.276.224-.5.5-.5s.5.224.5.5v3c0 .276-.224.5-.5.5z" />
    <path d="m9.5 12.5h-3c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h3c.276 0 .5.224.5.5s-.224.5-.5.5z" />
    <path d="m17.5 12.5h-3c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h3c.276 0 .5.224.5.5s-.224.5-.5.5z" />
    <path d="m17.5 17h-3c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h3c.276 0 .5.224.5.5s-.224.5-.5.5z" />
    <path d="m17.5 20h-3c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h3c.276 0 .5.224.5.5s-.224.5-.5.5z" />
    <path d="m6.5 20c-.128 0-.256-.049-.354-.146-.195-.195-.195-.512 0-.707l3-3c.195-.195.512-.195.707 0s.195.512 0 .707l-3 3c-.097.097-.225.146-.353.146z" />
    <path d="m9.5 20c-.128 0-.256-.049-.354-.146l-3-3c-.195-.195-.195-.512 0-.707s.512-.195.707 0l3 3c.195.195.195.512 0 .707-.097.097-.225.146-.353.146z" />
  </svg>
);
