import { colors, fontFamily } from '../../quarks/theme';
import styled from 'styled-components';

export const RoutineDetailStyle = styled.div`
    width: 100%;
    background-color: ${colors.white};
    box-sizing: border-box;
    padding: 0 0 70vh; // validar o padding bottom com LEOWOOOOSH depois
    overflow-y: scroll;

    ::-webkit-scrollbar-track{
        background-color: ${colors.black300};
        width: 15px;
    }

    ::-webkit-scrollbar-thumb{
        background-color: ${colors.white100};
        border-radius: 10px;
        border: 2px solid ${colors.black300};
    }


    .breadCrumb__container {
        height: fit-content;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        margin-bottom: -10px;

        position: sticky;
        top: 0;
        z-index: 50;
        font-family: ${fontFamily.primary};

        .breadCrumb__content{

            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            font-size: 16px;
            line-height: 18px;
            
            .tab__title {
                font-size: 18px;
                text-align: left;
                padding-left: 25px;
                margin-right: 10px;
            }

            h3:not(.tab__title){
                text-align: right;
            }
        }
    }

    .routine__card{
        &:nth-child(2){
            margin-top: 20px;
        }
    }

    div.carousel {
        width: 100%;
        overflow-x: hidden;
        position: relative;
        touch-action: pan-y;
    }

    a.calcLink {
        text-decoration: none;
        color: black;
        pointer-events: none;
    }

    div.carousel-items {
        margin: 10px 0;

        &:last-child {
            margin-bottom: 0;
        }

        &:first-child {
            margin-top: 0;
        }

        p {
            overflow-x: auto;
            cursor: grab;
            scroll-snap-type: x mandatory;
            scroll-behavior: smooth;
            width: 100%;
            display: flex;
            gap: 20px;
            padding-bottom: 5px;

            * {
                max-height: 50vh; /*required in iOS to no stretch the content */
                box-shadow: 1px 1px 3px #00000070;
                scroll-snap-stop: always;
            }

            img {
                height: max-content;
                margin: unset;
                &:first-child {
                    margin-left: auto;
                }
                &:last-child {
                    margin-right: auto;
                }
            }

            ::-webkit-scrollbar-track {
                background-color: ${colors.white};
                border-radius: 10px;
            }
            ::-webkit-scrollbar-thumb {
                border: 2px solid ${colors.white};
                border-radius: 10px;
                background-color: ${colors.primary};
            }
        }
    }

    div.scrollable-image {
        width: 100%;
        margin: 10px 0;

        img{
            max-width: 100%;
            object-fit: contain;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &:first-child {
            margin-top: 0;
        }
    }

    div.default-image {
        width: 100%;
        margin: 10px 0;
        display: flex;
        justify-content: center;
        
        &:last-child {
            margin-bottom: 0;
        }

        &:first-child {
            margin-top: 0;
        }

    }

    div.text-content {
        margin: 10px 0;
        
        &:last-child {
            margin-bottom: 0;
        }

        &:first-child {
            margin-top: 0;
        }

    }

    div.accordion-text-content {
        padding: 5px 10px;

        p:not(li > p) {
            text-indent: 0;
            margin: 0;
        }
        
        &:last-child {
            margin-bottom: 0;
        }

        &:first-child {
            margin-top: 0;
        }
    }

    img {
        max-height: 750px;
        max-width: 100%;
        object-fit: contain;
        display: flex;
        margin: auto;
        border-radius: 10px;
        &.svgImage {
            /* width: 379px; */
            max-height: 100%;
        }
    }

`
