import styled from 'styled-components';

export const LogoStyle = styled.div`
    img {
        width: 100%;
        max-width: 209px;

        @media screen and (max-width: 420px) {
            width: 32px;
            height: 27px;
            object-fit: cover;
            object-position: 0%;
        }
    }
`
