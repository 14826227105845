import styled from 'styled-components';


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 680px;
  margin: 0 auto;
  width: 100%;
  position: relative;
  z-index: 11;

  .userDetails__container{
    padding: 1rem 0;
    background-color: var(--gray-700);
    height: fit-content;
    box-shadow: 0 9px 12px rgba(0, 0, 0, 0.3);
  }

  div {
    width: -webkit-fill-available;
  }

  .home__modal {
    position: absolute;
    inset: 0;
    color: white;
    background-color: rgba(0, 0, 0, 0.8);
    
    
    display: flex;
    justify-content: center;
    align-items: center;


    
    .modal__content {
      padding: 1rem;
      background-color: var(--gray-500);
      max-width: 680px;
      height: auto;
    }
  }

  .container__header {
    text-align: left;
    font-size: 20px;
    margin-left: 1rem;
    font-weight: bold;
    color: var(--white);
  }
  
  @media screen and (min-width: 680px) {
    .userDetails__container {
      margin-bottom: 1rem;
      margin-top: 1rem;
      border-radius: 5px;
    }

    .home__modal {      
      align-items: flex-start;

      .modal__content {
        border-radius: 5px;
        margin-top: 8rem;
      }
    }
  }

  .selectImage__button {
    background-color: unset;
    border: solid 1px var(--purple-500);
    border-radius: 15px;
    color: var(--white);
    margin: 1rem;
    padding: 0.3rem;
    font-weight: bold;
    cursor: pointer;
    
    &:hover {
      background-color: var(--purple-500);
    }
  }
`;

export const NavigateToAppButton = styled.button`
  width: 100%;
  max-width: 680px;
  margin: 0 auto;
  border: none;
  border-radius: 100px;
  padding: 10px;
  min-height: 40px;
  background-color: var(--green-base);
  color: white;
  cursor: pointer;

  font-weight: 700;
  font-family: 'Open Sans', sans-serif;

  transition: all 0.2s;

  &:hover {
    filter: brightness(0.9);
  }

`
