import React, { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';

import { WebAppTemplate } from '../../components/templates/WebAppTemplate';
import {MyBlackbookCarousel} from '../../components/molecules/MyBlackbookCarousel';
import { useUser } from '../../context/useUser';
import { GET_HOME_INFO } from './query';
import { ContentWrapper, FullContainer, TopContentContainer, TopLeftContentContainer } from './style';
import { BannerOneComponent } from '../../components/templates/Banners/Banner1/Banner1Template';
import { BannerTwoComponent } from '../../components/templates/Banners/Banner2/Banner2Template';
import { SubscriptionBannerComponent } from '../../components/templates/Banners/SubscriptionBanner';
import { BBLoadingGif } from '../../blackbook-components/quarks/blackbook-loading';
import { useSidebar } from '../../context/useSidebar';
import {HomeFeatureList} from '../../components/molecules/HomeFeatureList'

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { useHistory } from 'react-router-dom';
import { useTrack } from '../../services/hooks/useTrack';
import { B2BBanner } from '../../components/templates/Banners/B2BBanner';
import { BlogBanner } from '../../components/templates/Banners/BlogBanner';

export function WebAppHome() {
  const { isSidebarOpen } = useSidebar();
  const { activePlan } = useUser();
  const { loading, error, data } = useQuery(GET_HOME_INFO);
  const { trackEvent } = useTrack();

  const [isUserScrollingSpecialties, setIsUserScrollingSpecialties] =
    useState();
  const [scrolledSpecialty, setScrolledSpecialty] = useState(null);

  const isUserPremium = activePlan?.name.toLowerCase().includes('premium');
  const isUserB2BAssociated = activePlan?.origin
    .toLowerCase()
    .includes('b2b_crmdf');
  const history = useHistory();

  function handleLinksNavigate(e) {
    e?.preventDefault();

    if (e.target.tagName === 'A') {
      const href = e.target.getAttribute('href');

      if (href.includes('http')) {
        trackEvent('Clicou no banner', {
          Destino: href,
          Origem: 'Banner',
          'Tipo da origem': 'Banner',
          'Tipo do destino': 'Link externo',
          'Link id': href,
        });

        window.open(href, '_blank');
      } else {
        history.push(href.replaceAll('/null', ''), {
          trackOrigin: 'Banner',
          trackType: 'Home',
          trackLink: href.replaceAll(/app\/routines\/|app\/drugs\//gi, ''),
        });
      }
    }
  }

  function handleBannerType(banner) {
    if (banner.banner_template_id === '6176fd183a7104d8171f28af') {
      return <BannerOneComponent {...banner} />;
    } else return <BannerTwoComponent {...banner} />;
  }

  useEffect(() => {
    const main = document.getElementsByTagName('main')[0];
    const mouseUpHandler = () => {
      if (isUserScrollingSpecialties) {
        trackEvent('Scrollou carrossel', { Qual: scrolledSpecialty });
        setIsUserScrollingSpecialties(false);
      }
    };
    main.addEventListener('mouseup', mouseUpHandler);
    return () => main.removeEventListener('mouseup', mouseUpHandler);
  });

  useEffect(() => {
    trackEvent('Acessou home web', { site: 'app.blackbook/app' });
  }, []);

  return (
    <WebAppTemplate home={true}>
      {loading || error || !data ? (
        <BBLoadingGif />
      ) : (
        <FullContainer>
          <TopContentContainer>
            <TopLeftContentContainer>
              <BlogBanner />
              <MyBlackbookCarousel />
            </TopLeftContentContainer>
            <HomeFeatureList />
          </TopContentContainer>
          <ContentWrapper isSidebarOpen={isSidebarOpen}>
            <section className="bannerSection">
              <Swiper
                autoplay={{ delay: 7000 }}
                slidesPerView={"auto"}
                centeredSlides={true}
                pagination={{
                  type: "bullets",
                  clickable: true,
                }}
                grabCursor={true}
                modules={[Pagination, Autoplay]}
                spaceBetween={10}
                className="slide"
              >
                {!isUserPremium ? (
                  <SwiperSlide key={0}>
                    <SubscriptionBannerComponent />
                  </SwiperSlide>
                ) : (
                  isUserB2BAssociated && (
                    <SwiperSlide key={0}>
                      <B2BBanner />
                    </SwiperSlide>
                  )
                )}

                {data?.home?.banners
                  ?.slice("")
                  .sort((a, b) => a.position - b.position)
                  .map((item, index) => (
                    <SwiperSlide key={index + 1} onClick={handleLinksNavigate}>
                      {({ isActive, isPrev, isNext }) => handleBannerType(item)}
                    </SwiperSlide>
                  ))}
              </Swiper>
            </section>
          </ContentWrapper>
        </FullContainer>
      )}
    </WebAppTemplate>
  );
}
