import PropTypes from 'prop-types';
import { useState } from "react";
import { FooterStyle } from "./style";

export const FooterComponent = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <FooterStyle {...props}>
            <ul className='footer_links'>
                {props.links && props.links.map((link) => (<li data-track={link.trackId}><a href={link.url} target="_blank" data-track={link.trackId}>{link.text}</a></li>))}
            </ul>
            <ul className='footer_social_links'>
                {props.social_links && props.social_links.map((link) => (<li data-track={link.trackId}><a href={link.url} target="_blank" data-track={link.trackId}>{link.icon}</a></li>))}
            </ul>
        </FooterStyle>
    )
}

FooterComponent.propTypes = {
    links: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string,
        url: PropTypes.string
    })),
    social_links: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.element,
        url: PropTypes.string
    }))
}