import styled from "styled-components";

export const SignInPage = styled.div`
  height: 100vh;
  background-color: #121212;
  display: grid;
  /* grid-template-columns: calc(246px + 10rem) 1fr; */
  grid-template-columns: 40% 60%;
  /* grid-template-rows: auto 20px; */
  box-sizing: border-box;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;

  .campaign__container {
    background-color: #121212;
    padding: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

   

    .logo__img {
      img {
        width: 100%;
      }
    }

    .campaign__text {
      display: flex;
      flex-direction: column;
      /* color: white; */
      gap: 2rem;
      /* align-items: center; */

      .title {
        color: white;
        font-weight: 700;
        font-size: 1.5rem
      }

      .description {
        color: #999;
        line-height: 1.5rem;
        font-size: 1rem;

        span {
          font-weight: 700;
          font-size: 1.1rem;
        }

        ul {
          padding-left: 1.5rem
        }
      }
    }
    
  }

  .register {
    background-color: #1e1e1e;
    padding: 5rem;
    position: relative;

    display: flex;
    flex-direction: column;

    .back__btn {
      color: var(--purple-500);
      position: absolute;
      left: 2.5rem;
      cursor: pointer;

      transition: all 0.2s;

      &:hover {
        filter: brightness(0.8);
      }
    }


    .page__title {
      color: white;
      font-weight: 700;
      border-bottom: 2px solid var(--yellow-500);
      padding-bottom: 1rem;
      font-size: 1.1rem;
    }

    .form {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      padding: 2rem 0;


      .loginForm__anchor {
        align-self: flex-end;
        font-size: 0.7rem;
        font-weight: 700;
        text-decoration: none;
        color: #8774ff;
        margin-top: 5px;
        background-color: transparent;
        border: none;

        &:hover {
          filter: brightness(0.8);
        }
      }

    }

    .next__btn {
      align-self: flex-end;
      border-radius: 35px;
      width: 100%;
      max-width: 200px;
      padding: 0.75rem;
      border: none;
      background-color: var(--purple-500);
      color: white;
      font-weight: 700;
      cursor: pointer;
      margin-top: 5rem;

      transition: all 0.2s;

      &:hover {
        filter: brightness(0.9);
      }

      &:disabled {
        filter: brightness(0.5);
        cursor: default;
      }
    }

    .helpers__container {

      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      gap: 1rem;
      margin-top: 1rem;
      color: var(--gray-100);
      font-size: 0.9rem;
      
      a {
        color: var(--purple-500);
        text-decoration: none;
      }
      button {
        padding: 0;
        font-size: 0.9rem;
        background-color: transparent;
        border: none;
        color: var(--purple-500);
        cursor: pointer;
      }
    }
  }


  .desktop-header__title {
    width: 100%;
    max-width: 1020px;
    font-size: 1.5rem;
    color: white;
    font-weight: 700;
    margin: 0 auto;
    padding-left: 1rem;
    margin-bottom: 1rem;
  }

  .steps__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 3rem;
    gap: 3rem;
    width: 100%;
    max-width: 1020px;
    margin: 0 auto;
  }

  .use-terms__container {
    text-align: center;
    width: 100%;

    color: white;

    font-size: 0.75rem;

    button {
      font-family: inherit;
      background-color: transparent;
      border: none;
      color: white;
      margin: 0;
      padding: 0;
      text-decoration: underline;
      text-decoration-color: var(--purple-500);
      cursor: pointer;

      &:hover {
        text-decoration-color: var(--yellow-500);
      }
    }
  }

  .mobile__description {
    background-color: #121212;
    padding: 2rem;
    display: none;
    flex-direction: column;
    align-items: center;
    /* gap: 2rem; */


    .logo__img {
      img {
        width: 100%;
      }
    }
    .title {
        color: white;
        font-weight: 700;
        font-size: 1.5rem
      }

    .description {
      color: #999;
      line-height: 1.5rem;
      font-size: 1rem;
      margin-bottom: 1rem;

      span {
        font-weight: 700;
        font-size: 1.1rem;
      }

      ul {
        padding-left: 1.5rem
      }
    }
  }

  @media screen and (max-width: 830px) {
    display: flex;
    flex-direction: column;
    

    .campaign__container {
      padding: 2rem;
      gap: 2rem;
      .campaign__text {
        .description:not(.show) {
          display: none;
        }
      }
    }

    .register {
      padding: 2rem;

      .back__btn {
        left: 2rem;
      }

      .back__btn + .page__title {
        padding-left: 2rem;
        /* background-color: red; */
      }
      .page__title {
        padding-left: 0;
      }

      .next__btn {
        margin-top: 2rem;
      }
    }

    .mobile__description {
      display: flex;
    }
  }

  .useAndTerms__modal {
    position: fixed !important;
    z-index: 100;

    h1 {
      text-align: center;
    }

    .content {
      text-align: justify;

      .terms__title {
        text-align: center;
      }
    }
  }

  .close-modal__button {
    background-color: transparent;
    border: none;
    border-radius: 0 10px 0 0;
    position: absolute;
    top: 0;
    right: 0;

  }
  .content {
    border: 1px solid var(--error);
    border-radius: 10px;
    padding: 2rem;
    color: white;
    background-color: rgb(36, 36, 36);
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    gap: 2rem;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    width: calc(100% - 4rem);
    box-sizing: border-box;
    max-width: 420px;
    text-align: center;

    h1 {
      color: var(--error);
      font-weight: 700;
      font-size: 1.25rem;
    }

    p {
      font-weight: 300;
      line-height: 1.5rem;


      a {
        color: var(--purple-500);
        font-weight: 700;
        text-decoration: none;
      }
    }

    .linkToSignin {
      font-size: 1rem;
      background-color: transparent;
      border: none;
      color: var(--purple-500);
      cursor: pointer;
      padding: 0;
    }

    .btn {
      display: flex;
      align-items: center;
      justify-content: center;

      border: none;
      font-weight: 700;
      font-size: 1.1rem;
      color: var(--purple-500);
      background-color: transparent;

      cursor: pointer;

      transition: all 0.2s;
      border-radius: 25px;
      padding: 10px 1rem;
      border: 1px solid var(--purple-500);

      &:hover {
        filter: brightness(0.8);
      }
    }


  }
  .success {

    .content {
      border-color: var(--green-base);
      overflow: hidden;

      h1 {

        color: var(--green-base);
      }

      p {
        font-size: 1.1rem;
      }

    }


    .round-time-bar {
      margin: 1rem;
      overflow: hidden;
      width: 100%;
      position: absolute;
      bottom: -1rem;
    }
    .round-time-bar div {
      height: 1rem;
      transform-origin: left center;
      background: var(--green-base);
      animation: roundtime calc(6 * 1s) linear forwards;
    }


    @keyframes roundtime {
      to {

        transform: scaleX(0);
      }
    }

  }


  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--gray-500);
  }
  ::-webkit-scrollbar-track {
      background-color: #121212;
  }

  .use-terms__container {
    .content {
      border: none;
      padding: 0;

      h1 {
        color: var(--purple-500);
        padding-top: 0;
      }
    }

    div {
      padding: 1rem;
    }
    div ::-webkit-scrollbar {
    width: 5px !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--gray-500);
  }
  ::-webkit-scrollbar-track {
      background-color: #121212;
  }
  }
`;
