import styled from "styled-components";

export const CardStyle = styled.div`
    background-color: #212121;
    color: white;
    height: 100%;
    overflow-x: scroll;
    overflow-y: scroll;
    padding: 15px;
    padding-right: 30px;
    border-radius: 10px;

    strong {
        font-weight: 700;
    }
    p {
        margin-bottom: 1rem;
        line-height: 1.5rem;

        &.terms__title {
            font-size: 1.5rem;
        }
    }
`;