import styled from "styled-components";
import { colors, fontSize, spacing } from "../../blackbook-components/quarks/theme";

export const TopContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height:70%;
  column-gap: 10px;
`;

export const FullContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  gap: 10px;
`;

export const TopLeftContentContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 4fr;
  @media screen and (max-height: 500px) {
    grid-template-rows: 1fr;
  }
  max-height: 100%;
  min-height: 100%;
  grid-gap: 10px;
  border-right: 1px solid #6f6f6f;
  padding-right: 10px;
`;

export const ContentWrapper = styled.div`
  height: calc(100% - 40px);
  min-height: 30%;
  max-height: calc(30vh - 90px);
  padding-right: 10px;

  overflow-y: auto;
  overflow-x: hidden;

  background-color: ${colors.black100};

  .bannerSection {
    height: calc(100% - 16px);
    width: 100%;
    position: relative;
    border-radius: 10px;
    .swiper {
      height: 100%;
      overflow: visible;
    }
    .swiper-slide {
      z-index: -10;
      width: 100%;
      background-color: #7515ed;
      border-radius: 10px;
    }
    .swiper-button-disabled {
      display: none;
    }
    .swiper-pagination {
      position: absolute;
      bottom: 0;
      margin-bottom: -16px;
      text-align: center;
      transition: 0.3s opacity;
      transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      z-index: 10;
      .swiper-pagination-bullet {
        background: ${colors.disableGray};
        opacity: unset;
      }
      .swiper-pagination-bullet-active {
        background: ${colors.secondary};
      }
    }
  }
`;