import { useContext } from "react";
import { FaArrowLeft } from "react-icons/fa";

import { SignUpStageContext } from "../../../context/useSignUpStage";

export function BackButtonComponent(props) {

  const { goBack } = useContext(SignUpStageContext);
  return (
    <FaArrowLeft className="arrowIcon" {...props} onClick={()=>
      props.onClick ? props.onClick() : goBack()
    } />
  );
}
