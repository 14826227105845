import axios from 'axios';

export class BaseClient {

  constructor(baseURL, token) {
    const headers = {
      'Content-Type': 'application/json'
    };

    if (token) {
      headers['Authorization'] = `${token}`;
    }

    this.client = axios.create({
      baseURL: baseURL,
      responseType: 'json',
      headers: headers,
    });
  }


  async get(route, config) {
    try {
      const result = await this.client.get(route, config);

      return result.data;

    } catch (error) {
      throw error
    }
  }

  async post(route, data, config) {
    try {
      const result = await this.client.post(route, data, config);

      return result.data;

    } catch (error) {
      throw { message: error?.response?.data?.message[0] };
    }
  }

  async postDefault(route, data, config) {
    try {
      const result = await this.client.post(route, data, config);

      return result.data;

    } catch (error) {
      throw error;
    }
  }
  

  async put(route, data, config) {
    try {
      const result = await this.client.put(route, data, config);

      return result.data;

    } catch (error) {
      throw { message: error?.response?.data?.message[0] };
    }
  }

  async delete(route, data, config) {
    try {
      const result = await this.client.delete(route, data, config);

      return result.data;

    } catch (error) {

    }
  }
}