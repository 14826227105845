import { useHistory, useParams } from 'react-router';

import logo from "../../assets/images/logo.png";
import logoName from "../../assets/images/logo-text.svg";
import { handleRemoveIndicationCouponOfLocalStorage } from '../../services/cache/indicationCoupon';
import { useGetQuery } from '../../services/hooks/useGetQuery';
import { handleSetIndicatorCodeOnLocalStorage } from '../../services/indicatorCode';

import { Container } from './style';
import { ButtonListContainerComponent } from '../../components/molecules/buttonListComponent';
import { useTrack } from '../../services/hooks/useTrack';
import { useEffect } from 'react';

export function LandingPage() {
  window.scrollTo(0, 0);
  const history = useHistory();
  const { indicator_code } = useParams();
  const query = useGetQuery();

  if (indicator_code) {
    handleSetIndicatorCodeOnLocalStorage(indicator_code);
  }

  if (!query.get('c')) {
    handleRemoveIndicationCouponOfLocalStorage()
  }

  useEffect(() => {
    history.push('/signin')

  }, [])

  return (
    <Container />
  );
}
