import { useState, useEffect, useContext } from 'react';

import { RegisterOrganism } from '../../../../components/organisms/registerOrganism';
import { RegisterInputComponent } from '../../../../components/atoms/registerInput';
import { SignUpStageContext } from '../../../../context/useSignUpStage';
import { useTrack } from '../../../../services/hooks/useTrack';

var regexp = /[a-zA-Z]+\s+[a-zA-Z]+/;

export function SignUpName({ isDesktop = false }) {
  const { goForward } = useContext(SignUpStageContext);
  const { trackEvent } = useTrack();

  const [name, setName] = useState('');
  const [isNameCompleted, setIsNameCompleted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChangeName = (inputName) => {
    setName(inputName);
    setIsNameCompleted(regexp.test(inputName));
  };

  function sendNameToRegister() {
    if (isNameCompleted) {
      window.sessionStorage.setItem('name', name);
      trackEvent('Cadastro nome - Próximo', { Nome: name });
      goForward();
    } else {
      setErrorMessage('Nome inválido');
    }
  }

  useEffect(() => {
    const value = sessionStorage.getItem('name');
    if (value) {
      handleChangeName(value);
      sessionStorage.removeItem('name');
    }
  }, []);

  if (isDesktop) {
    return (
      <RegisterInputComponent
        autoFocus
        error={errorMessage}
        helper="Digite nome e sobrenome"
        label="Nome completo"
        type="text"
        value={name}
        onChange={(e) => handleChangeName(e.target.value)}
      />
    );
  }
  return (
    <RegisterOrganism
      title="Crie sua conta"
      buttonProps={{
        disabled: !isNameCompleted,
        className: 'fullWidth',
        type: 'submit',
      }}
      onFinish={() => {
        sendNameToRegister();
      }}
    >
      <RegisterInputComponent
        autoFocus
        error={errorMessage}
        helper="Digite nome e sobrenome"
        label="Qual é o seu nome completo?"
        type="text"
        value={name}
        onChange={(e) => handleChangeName(e.target.value)}
      />
    </RegisterOrganism>
  );
}
