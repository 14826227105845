export const colors = {
    primary: "#8774FF",
    secondary: "#FCDE17",
    light: "#E6E6E6",
    white: "#FFFFFF",
    white100: "#BAB9B9",
    darkGray: "#444444",
    disableGray: "#6F6F6F",
    gray: "#999999",
    black: "#000000",
    black100: "#121212",
    black150: "#1e1e1e",
    black200: "#212121",
    black300: "#232323",
    black315: "#272727",
    black325: "#2C2C2C",
    black350: "#333333",
    black400: "#383838",
    black500: "#545454",
    error: "#D53B30",
    darkBlue: "#486278",
    hardBlue: "#769cab",
    softBlue: "#c5d6de",
    darkGreen: "#57755d",
    hardGreen: "#8da894",
    softGreen: "#c8dbcd",
    darkYellow: "#b08756",
    hardYellow: "#e3b278",
    softYellow: "#fcddb8",
    darkOrange: "#bf6e54",
    hardOrange: "#f29779",
    softOrange: "#ffc5b2",
    darkRed: "#b5605b",
    hardRed: "#e88882",
    softRed: "#ffc5c2",
    darkIndigo: "#695e7f",
    hardIndigo: "#a089b2",
    softIndigo: "#cdbad4",
    darkBlack: "#383838",
    hardBlack: "#545454",
    softBlack: "#BAB9B9",
}

export const routineColorSelector = (color) => {
    const themes = {
        black: {
            dark: "#75777f",
            hard: "#babcc5",
            soft: "#d9d9d9",
            base: "#6F6F6F"
        },
        blue: {
            dark: "#486278",
            hard: "#769cab",
            soft: "#c5d6de",
            base: "#1661A1"
        },
        cyan: {
            dark: "#57755D",
            hard: "#8DA894",
            soft: "#C8DBCD",
            base: "#1F5C2C"
        },
        brown: {
            dark: "#A77559",
            hard: "#C29E8A",
            soft: "#E3CDC1",
            base: "#8C502E"
        },
        green: {
            dark: "#599666",
            hard: "#8BC297",
            soft: "#C0DFC7",
            base: "#1D9637"
        },
        orange: {
            dark: "#B08756",
            hard: "#E3B278",
            soft: "#FCDDB8",
            base: "#C47212"
        },
        red: {
            dark: "#b5605b",
            hard: "#e88882",
            soft: "#ffc5c2",
            base: "#B52D24"
        },
        indigo: {    
            dark: "#695e7f",
            hard: "#a089b2",
            soft: "#cdbad4",
            base:"#50318F"
        },
        default: {
            dark: "#75777f",
            hard: "#babcc5",
            soft: "#d9d9d9",
        },
    }

    return themes[color] || themes.default
}

export const fontFamily = {
    primary: "'Open Sans', sans-serif",
    secondary: "'Source Sans Pro', sans-serif",
}

export const fontSize = {
    small: "12px",
    medium: "14px",
    large: "16px",
    xlarge: "18px",
    xxlarge: "20px",
    xxxlarge: "24px",
}

export const spacing = {
    none: "0px",
    small: "5px",
    medium: "10px",
    mlarge: "15px",
    large: "16px",
    xlarge: "20px",
    xxlarge: "24px",
    xxxlarge: "30px",
    xxxxlarge: "54px",
}

export const borderRadius = {
    none: "0px",
    circle: "50%",
    none: "0px",
    small: "5px",
    medium: "10px",
    large: "30px",
}

