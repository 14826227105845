import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  inset: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: center;

  .modal__content {
    max-width: 400px;

    border-radius: 5px;
    border: 1px solid var(--error);
    background-color: #2E2E2E;
    margin: 0 1rem;

    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
    font-family: Source Sans Pro;

    .header {
      display: flex;
      text-align: center;
      position: relative;

      h1 {
        padding: 1rem 3rem;
        font-weight: bold;
        /* font-size: 1.25rem; */
        color: var(--error);
      }

      button {
        position: absolute;
        top: 0;
        right: 0;
        background-color: transparent;
        border: none;
        cursor: pointer;

        transition: filter 0.2s;

        &:hover {
          filter: brightness(0.9);
        }
      }
    }

    .body {
      padding: 0 1rem 1.5rem;
      font-weight: 300;
      color: #FFF
    }
  }
`;
