import styled from 'styled-components';
import { colors } from '../../quarks/theme';
import { HEADER_HEIGHT } from '../../quarks/sizes';

export const NavbarStyle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${colors.black300};
    height: ${HEADER_HEIGHT}px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`
