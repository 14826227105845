import PropTypes from 'prop-types';
import React, { useRef } from "react";
import { FaCaretRight, FaCaretLeft, FaAngleDown } from 'react-icons/fa';
import { ContentSidebarStyle } from "./style";

const ItemList = props => {
    const [isOpen, setIsOpen] = React.useState(true);
    const hasSubItems = (item) => {
        return item.subItems && item.subItems.length > 0;
    }

    const handleClick = (item, index)=> {
        props.onClick({...item, index: index});
    }

    const isItemActive = (item) => {
        return item.isActive;
    }

    const getClasses = (item, index) => {
        let classes = [];
        if (item.className) {
            classes.push(item.className);
        }
        if (isItemActive(item)) {
            classes.push('active');
            scrollHighlightedItem(index);
        }
        return classes.join(" ");
    }

    const getIcon = (item) => {
        return item.icon && React.cloneElement(item.icon, {primaryColor: item.color || props.color});
    }

    const listItemsRef = useRef([])

    function scrollHighlightedItem(index) {
        listItemsRef?.current[index]?.scrollIntoView({block:'center', behavior: 'smooth'});
    }

    return (
        <ContentSidebarStyle {...props} active={props.isActive} isOpen={isOpen}>
            <ul>
                {props.items.map((item, index) => (
                    <li key={index} className={getClasses(item, index)} style={{borderColor: props.color}} ref={ref => listItemsRef.current[index] = ref}>
                        <button 
                            onClick={() => handleClick(item, index)} 
                            className={getClasses(item)}>
                            <span style={{color: item.color}}>{getIcon(item)} {item.text}</span> {hasSubItems(item) && <FaAngleDown />}
                        </button>
                        {(hasSubItems(item) && isItemActive(item)) && <ItemList 
                            items={item.subItems} 
                            className={`sub_item_menu`}
                            isActive={isItemActive(item)}
                            color={item.color}
                            onClick={props.onClick} />
                        }
                    </li>
                ))}
            </ul>
            <button onClick={() => setIsOpen(!isOpen)}>
                {isOpen ? <FaCaretLeft /> : <FaCaretRight />}
            </button>
        </ContentSidebarStyle>
    )
}

export const ContentSidebarComponent = (props) => {
    return (
        <ItemList {...props} isActive={true} />
    )
}

ContentSidebarComponent.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string,
        icon: PropTypes.element,
        isActive: PropTypes.bool,
        className: PropTypes.string,
        color: PropTypes.string,
        sub_items: PropTypes.arrayOf(PropTypes.shape({
            text: PropTypes.string,
            isActive: PropTypes.bool,
            className: PropTypes.string,
            sub_items: PropTypes.arrayOf(PropTypes.shape({
                text: PropTypes.string,
                isActive: PropTypes.bool,
                className: PropTypes.string,
            })),
        })),
    })),
    onClick: PropTypes.func,
}