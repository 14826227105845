import styled from 'styled-components';

export const HeaderStyle = styled.div`
    display: grid;
    grid-template-columns: 10% 80% 10%;
    justify-items: center;
    align-items: center;
    padding: 0 1rem;

    .arrowIcon {
      justify-self: end;
      font-size: 1.3rem;
      color: #8774FF;
      cursor: pointer;
    }

    .headerText {
        color: white;
        padding: 1.5rem;
    }
`