import styled from 'styled-components';

export const ChildrenComponent = styled.div`
  display: flex;
  /* overflow-y: hidden; */
  overflow-x: hidden;
  height: calc(100vh - 124px);
  min-height: calc(100vh - var(--footer-height) - 54px);

  @media screen and (min-width: 990px) {
    min-height: calc(100vh - var(--footer-height) - 80px);
    height: calc(100vh - var(--footer-height) - 80px);
  }
`;
