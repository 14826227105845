import { SubAccordionComponent } from "../../../blackbook-components/atoms/accordion";

function parseHTML(content, popup) {
  return { __html: content };
}

export const defaultTemplateSection = (acordionContent, acordionTitle, type) =>
  acordionContent?.length > 0 && (
    <SubAccordionComponent title={acordionTitle} type={type}>
      {acordionContent?.map((item) => (
        <div
          className="content__style"
          dangerouslySetInnerHTML={parseHTML(item.content)}
        ></div>
      ))}
    </SubAccordionComponent>)