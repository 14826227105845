import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { ConfigIcon } from '../../../assets/icons/ConfigIcon';
import { useUser } from '../../../context/useUser';
import { paymentApi } from '../../../services/clients';
import { ModalPaymentInProcess } from '../../atoms/ModalPaymentInProcess';
import { ConfigUserPlan } from '../ConfigUserPlan';

import { Container } from './style';

function pad(n) {
  return n < 10 ? '0' + n : n;
}

function parseTimestampToDate(timestamp) {
  const date = new Date(timestamp);
  return (
    pad(date.getDate()) +
    '/' +
    pad(date.getMonth() + 1) +
    '/' +
    date.getFullYear()
  );
}

export function PlanManagerComponent() {
  const { activePlan } = useUser();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paymentInfos, setPaymentInfos] = useState(null);
  const [userPurchasedOnAnotherPlatform, setUserPurchasedOnAnotherPlatform] =
    useState(false);
  const [platformForText, setPlatformForText] = useState('');

  const history = useHistory();

  useEffect(() => {
    async function getUserPayment() {
      if (activePlan.origin.includes('b2b')) {
        setPlatformForText('b2b');
      } else if (activePlan.origin !== 'payment-api') {
        setPlatformForText('mobile');
        setUserPurchasedOnAnotherPlatform(true);
      } else {
        const response = await paymentApi.getUserPayment(activePlan.origin_id);
        setPaymentInfos(response);
        setUserPurchasedOnAnotherPlatform(false);
      }
    }

    if (activePlan) {
      getUserPayment();
    }
  }, [activePlan]);

  return (
    <>
      {activePlan && (
        <Container>
          <section className="overviewHeader">
            <h1 className="overviewHeader__title">
              PLANO {activePlan.name.toUpperCase()}
            </h1>
            <button
              type="button"
              className="header__icon"
              onClick={() => setIsModalOpen(true)}
            >
              <ConfigIcon />
            </button>
          </section>
          <section className="overviewContent">
            <table className="overviewContent__table">
              <tbody className="overviewContent__table">
                {paymentInfos &&
                  paymentInfos?.payment_info?.methods[0]?.type !==
                    'gift_card' && (
                    <tr className="table__row">
                      <th className="table__header">
                        Cartão de crédito{' '}
                        <span
                          className="changePayment__button"
                          onClick={() => history.push('/paymentmethod')}
                        >
                          (editar)
                        </span>
                      </th>
                      <td className="table__cell">
                        {paymentInfos?.payment_info?.methods[0]?.details ||
                          '---- ----'}
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
          </section>
        </Container>
      )}

      {isModalOpen &&
        (userPurchasedOnAnotherPlatform ? (
          <ModalPaymentInProcess
            setIsModalOpen={setIsModalOpen}
            platformForText={platformForText}
            contentIsForAnotherPlatform={userPurchasedOnAnotherPlatform}
          />
        ) : (
          <ConfigUserPlan closeModal={() => setIsModalOpen(false)} />
        ))}
    </>
  );
}
