
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import styled from "styled-components";
import {
  BbDrugIcon,
  BBArrowSeeAll,
  BbRoutineIcon
} from "../../../blackbook-components/quarks/icons";
import { useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation } from 'swiper';
import MainTheme from '../../../styles/MainTheme';

const PageContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ContentHeaderTitleContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: row;
  align-items: center;
  margin-right: 15px;
`;

const ContentHeaderTitle = styled.text`
  color: ${MainTheme.color.primaryFont};
  font-weight: bold;
  font-size: 24px;
  text-align: left;
`;

const ContentHeaderDescription = styled.div`
  color: ${MainTheme.color.grayFont};
  font-weight: normal;
  font-size: 16px;
  text-align: left;
`;

const ViewAll = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 268px;
  height: 210px;
  background: linear-gradient(
    180deg,
    #8774ff 0%,
    #8774ff 50%,
    #5f51b3 100%
  );
  border-radius: 10px;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  border: none;
  svg {
    margin-left: 10px;
    margin-top: 1px;
    height: 100%;
    width: 15px;
    path {
      fill: #fff;
    }
  }
`

const ContentSliderStyle = styled.div`
    max-width: 100%;
    max-height: 100%;
    position: relative;
    border-radius: 10px;
    
    .swiper {
      padding-right: 268px;
      padding-top: 5px;
    }
    .swiper-slide {
      width: auto;
      > div{ 
        max-width: 268px;
        min-width: 268px;
      }
    }
    .swiper-button-prev, .swiper-button-next {
        background-color: ${MainTheme.color.primary};
        width: 40px;
        height: 40px;
        border-radius: 50%;
        font-size: 20px;
        color: #fff;
        box-shadow: 0 9px 12px rgba(0, 0, 0, 0.3);
        position: absolute;
        top: calc(50% - 20px);
        left: auto;
        margin: 0 24px;
        &::after {
          font-size: 20px;
          font-weight: bold;
        }
    }
    .swiper-button-disabled {
        display: none;
    }
    .swiper-pagination {
        color: #fff;
        margin: 0px;
        position: initial;
    }
`
const ContentGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(212px, 1fr));
  /* flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around; */
  row-gap: 20px;
  column-gap: 10px;
  margin-right: 10px;
  margin-top: 10px;
`;

export function MyBlackbookSession({
  type,
  title,
  qtdFavorites,
  data,
  isFilteredMode,
  handleSeeAll,
  isVisible
}) {  
  function getIconByType() {
    const iconsMap = {
      drugs: <BbDrugIcon width="32" heigth="32"/>,
      routines: <BbRoutineIcon width="32" heigth="32"/>,
    };
    
    return iconsMap[type] || null;
  }
  
  if(!isVisible) {
    return null;
  }

  return (
    <PageContentContainer>
      <ContentHeaderTitleContainer>
        {getIconByType()}
        <ContentHeaderTitle>{title}</ContentHeaderTitle>
        <ContentHeaderDescription>{qtdFavorites === 1 ? `(${qtdFavorites} favorito)` : `(${qtdFavorites} favoritos)`}</ContentHeaderDescription>
      </ContentHeaderTitleContainer>
      {isFilteredMode ? 
        <ContentGridContainer>
          {data.map((item, index) => (
            React.cloneElement(item, {
              key: index,
            })
          ))}
        </ContentGridContainer>
      :
        <ContentSliderStyle>
          <Swiper
            slidesPerView={'auto'}
            centeredSlides={false}
            speed={100}
            grabCursor={true}
            navigation={true}
            modules={[Navigation]}
            spaceBetween={10}
            className="slide"
          >
            { data.
              slice(0, 10).
              map((item, index) => (
              <SwiperSlide key={index}>
                {({ isActive, isPrev, isNext }) => 
                    React.isValidElement(item)
                && React.cloneElement(item, {
                    isCurrent: isActive,
                    isPrev: isPrev, 
                    isNext: isNext
                  })
                }
              </SwiperSlide>
            ))}
            {data.length > 10 ?
              <SwiperSlide key={12}>
                  {({ isActive, isPrev, isNext }) => 
                  React.cloneElement(
                    <ViewAll
                      onClick={() => handleSeeAll(type)}
                    >
                      VER <br/> TODAS 
                      <BBArrowSeeAll />
                    </ViewAll>, 
                    {
                      isCurrent: isActive,
                      isPrev: isPrev, 
                      isNext: isNext
                    }
                  )
                  }
              </SwiperSlide>
              : null
            }
          </Swiper>
        </ContentSliderStyle>
      }
    </PageContentContainer>
  );
}
