import { useHistory, useLocation } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';

import { useUser } from '../../../context/useUser';
import { useSidebar } from '../../../context/useSidebar';

import { ContainerComponent } from '../../../blackbook-components/atoms/container';
import { HeaderComponent } from '../../../blackbook-components/molecules/header';
import {
  MenuList,
  SidebarComponent,
} from '../../../blackbook-components/molecules/sidebar';
import { FooterComponent } from '../../../blackbook-components/molecules/footer';

import { FaFacebookF, FaInstagram, FaLinkedin } from 'react-icons/fa';

import { Container, SidebarButton, SidebarButtonsDiv } from './style';
import { useLazyQuery } from '@apollo/client';
import { SearchComponent } from './SearchComponent';
import _ from 'lodash';
import { HANDLE_SEARCH } from './query';
import { useTrack } from '../../../services/hooks/useTrack';
import { DownloadTheAppComponent } from './DownlaodTheAppComponent';
import {
  BBQRCode,
  BbPremiumIcon,
} from '../../../blackbook-components/quarks/icons';
import { socket } from '../../../services/socket';
import { getToken } from '../../../services/tokenService';
import { ReferralButton } from '../../molecules/ReferralButton';

export function WebAppTemplate({
  home,
  inputValue = '',
  setInputValue = () => null,
  setIsSearchComponentOpen = () => null,
  children,
  handlePressKey = () => null,
}) {
  const {
    isSidebarOpen,
    setIsSidebarOpen,
    subSidebarItems,
    isSubsideBarOpen,
    hightlightedItem,
    closeSidebarItems,
    handleClickTemp,
    items,
  } = useSidebar();

  const { userProperties, activePlan, setActivePlan } = useUser();
  const { trackEvent } = useTrack();

  const [searchResult, setSearchResult] = useState(null);
  // const [inputValue, setInputValue] = useState('');

  const location = useLocation();
  const history = useHistory();

  function handleUpdateUserPlanState(e) {
    if (e?.level_access > activePlan?.level_access) {
      setActivePlan(e);
      history.go(location.pathname);
    }
  }

  useEffect(() => {
    const token = getToken();
    if (userProperties?._id && token) {
      const hendleSocket = (response) => {
        handleUpdateUserPlanState(response);
      };

      socket.on(`${userProperties?._id}/update_plan`, hendleSocket);
      // return () => socket.off(`${userProperties?._id}/update_plan`, hendleSocket);
    }
  }, [userProperties]);

  const [handleSearch, searchResponse] = useLazyQuery(HANDLE_SEARCH, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ search }) => {
      setSearchResult(search);

      const trackPayload = {
        'Número de resultados em Drogas': search.drugs.length,
        'Número de resultados em Rotinas': search.routines.length,
        Termo: inputValue,
      };

      trackEvent('Realizou uma busca', trackPayload);
    },
  });

  useEffect(() => {
    const clickableLogoArea = document.querySelector('.logo_area');
    const clickableLogoAreaCallback = () => {
      if (isSidebarOpen) {
        trackEvent('Contraiu o menu lateral');
      } else {
        trackEvent('Expandiu o menu lateral');
      }
    };
    clickableLogoArea.addEventListener('click', clickableLogoAreaCallback);
    return () =>
      clickableLogoArea.removeEventListener('click', clickableLogoAreaCallback);
  });

  function closeComponents() {
    closeSidebarItems();
    setIsDownloadTheAppComponentOpen();
  }

  const [isDownloadTheAppComponentOpen, setIsDownloadTheAppComponentOpen] =
    useState(false);
  return (
    <ContainerComponent>
      <HeaderComponent
        handlePressKey={handlePressKey}
        userName={userProperties?.name}
        onClick={closeComponents}
        isOpen={isSidebarOpen}
        onLogoClick={(obj) => setIsSidebarOpen(obj.isOpen)}
        avatar={`https://firebasestorage.googleapis.com/v0/b/content-blackbook-dev/o/profiles%2F${userProperties?._id}?alt=media`}
        inputValue={inputValue}
        onChange={(e) => setInputValue(e?.target?.value)}
        onClearInput={() => setInputValue('')}
        onInputFocus={() => {
          if (location.pathname.includes('/search')) {
            setIsSearchComponentOpen(true);
          } else {
            history.push('/app/search');
          }
        }}
      />

      <SearchComponent
        response={{ ...searchResponse, searchResult: searchResult }}
        inputValue={inputValue}
      />

      <DownloadTheAppComponent
        isOpen={isDownloadTheAppComponentOpen}
        onClose={() => setIsDownloadTheAppComponentOpen(false)}
      />

      <Container isSidebarOpen={isSidebarOpen}>
        {isSidebarOpen && (
          <>
            <SidebarComponent
              isHome={home}
              className="fullHeight sideBarComponent"
              onClick={handleClickTemp}
              onClose={closeSidebarItems}
              items={items}
              hightlightedItem={hightlightedItem[0]}
              openDownloadComponent={() =>
                setIsDownloadTheAppComponentOpen(true)
              }
              DownloadAppButton={
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                  }}
                >
                  <SidebarButtonsDiv>
                    {/* comentado esperando lançamento do indique e ganhe */}
                    {/* <ReferralButton /> */}
                    {activePlan?.name === 'Free' && (
                      <SidebarButton
                        active={true}
                        className="subscript__button"
                        onClick={() => {
                          trackEvent('Paywall - Clicou em Assine agora');
                          history.push('/home');
                        }}
                      >
                        <BbPremiumIcon />
                        <span>Assine agora!</span>
                      </SidebarButton>
                    )}
                    <SidebarButton
                      active={isDownloadTheAppComponentOpen}
                      className="downloadApp__button"
                      onClick={() => {
                        trackEvent('Clicou em baixe agora o app');
                        setIsDownloadTheAppComponentOpen(true);
                      }}
                    >
                      <BBQRCode
                        color={
                          isDownloadTheAppComponentOpen ? 'black' : 'white'
                        }
                      />
                      <span>Baixe o app!</span>
                    </SidebarButton>
                  </SidebarButtonsDiv>
                </div>
              }
            />
            {subSidebarItems?.map(
              (subItems, idx) =>
                isSubsideBarOpen[idx] && (
                  <MenuList
                    onClick={handleClickTemp}
                    className="sub_item_menu"
                    hierarchy={idx + 1}
                    items={subItems}
                    hightlightedItem={hightlightedItem[idx + 1]}
                  />
                )
            )}
          </>
        )}
        <main className="content">
          {children}
          {isSubsideBarOpen[0] && (
            <div className="contentOverlay" onClick={closeSidebarItems} />
          )}
        </main>
      </Container>
      <FooterComponent
        onClick={(e) => {
          closeSidebarItems();
          trackEvent(`Clicou em ${e.target.dataset.track}`);
        }}
        links={[
          {
            text: 'Sobre a Blackbook',
            url: '#',
            trackId: 'Sobre a Blackbook',
          },
          {
            text: 'Termos de uso',
            url: '#',
            trackId: 'Termos de uso',
          },
          {
            text: 'Contato',
            url: 'https://wa.me/5531996887607?text=Olá,%20preciso%20de%20ajuda.',
            trackId: 'Contato',
          },
        ]}
        social_links={[
          {
            icon: <FaFacebookF data-track={'Facebook'} />,
            url: 'https://www.facebook.com/BlackbookEditora/',
            trackId: 'Facebook',
          },
          {
            icon: <FaInstagram data-track={'Instagram'} />,
            url: 'https://www.instagram.com/blackbookoficial/',
            trackId: 'Instagram',
          },
          {
            icon: <FaLinkedin data-track={'Linkedin'} />,
            url: 'https://www.linkedin.com/company/blackbookoficial/',
            trackId: 'Linkedin',
          },
        ]}
      />
    </ContainerComponent>
  );
}
