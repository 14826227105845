import { gql } from "@apollo/client";

export const MY_BLACKBOOK_QUERY = gql`
  query MyBlackbook {
    myBlackbook {
      drugs {
        item_id
        image
        type
        sub_type
        title
        legend
        legend_id
        legend_type
        created_at
        home_image
        sub_items {
          sub_item_id
          title
          legend
          legend_id
          legend_type
          type
          color
          created_at
          position
        }
        icons {
          _id
          title
          tooltip
        }
      }
      routines {
        item_id
        image
        type
        sub_type
        title
        legend
        legend_id
        legend_type
        created_at
        home_image
        sub_items {
          sub_item_id
          title
          legend
          legend_id
          legend_type
          type
          color
          created_at
          position
        }
      }
    }
  }
`;

export const MY_BLACKBOOK_HOME_QUERY = gql`
  query MyBlackbookHome {
    myBlackbookHome {
      item_id
      image
      type
      sub_type
      title
      legend
      legend_id
      legend_type
      created_at
      home_image
      sub_items {
        sub_item_id
        title
        legend
        legend_id
        legend_type
        type
        color
        created_at
        position
      }
      icons {
        _id
        title
        tooltip
      }
    }
  }
`;

export const FAVORITES_BY_TYPE_QUERY = gql`
  query FavoritesByType($type: String!, $item: String) {
    favoritesByType(type: $type, item: $item) {
      therapeutic_group
      tg_ap {
        therapeuticGroupId
        isTGFavorite
        activePrinciplesFavorites
      }
      routine
      routine_cards {
        cards
        routineId
        createdAt
      }
    }
  }
`

export const ADD_MY_BLACKBOOK_MUTATION = gql`
  mutation AddMyBlackbook($type: String!, $item: String!, $subItem: String) {
    addMyBlackbook(type: $type, item: $item, subItem: $subItem)
  }
`

export const REMOVE_MY_BLACKBOOK_MUTATION = gql`
  mutation RemoveMyBlackbook($type: String!, $item: String!, $subItem: String) {
    removeMyBlackbook(type: $type, item: $item, subItem: $subItem)
  }
`