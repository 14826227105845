import styled from "styled-components";

export const BannerSection = styled.section`
  width: 100%;
  height: 100%;

  .loading__container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .corner__text {
    position: absolute;
    background-color: #6515ca;
    border-radius: 10px 0 10px 0;
    padding: 3px 10px;
    color: white;
    font-weight: 700;
    font-size: 14px;
  }

  .bannerLink {
    height: 100%;
    cursor: pointer;
  }
  .banner {
    position: relative;
    height: 100%;
    max-height: 350px;
    z-index: -10;
    border-radius: 10px;
    overflow: hidden;
    font-family: "Open Sans", sans-serif;
    display: grid;
    grid-template-columns: 55% 1fr;
  }

  .svg {
    position: absolute;
    height: 100%;
    max-width: 100%;
    width: auto;
    z-index: -1;
    right: 0;
  }

  .textArea {
    box-sizing: border-box;
    /* padding: 14px; */
    /* position: absolute; */
    left: 0;
    height: 100%;
    width: auto;
    display: grid;
    grid-template-columns: 9fr 1fr;
    position: relative;
  }

  .textArea > p {
    box-sizing: border-box;
    padding: 20px;
    height: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-align: left;
    color: black;
    text-decoration: none;
    font-size: calc(5px + 1.1vw);
    line-height: calc(5px + 1.3vw);
    display: flex;
    align-items: center;

    background-color: white;
    max-width: 90%;
    @media screen and (max-height: 500px) {
      -webkit-line-clamp: 2;
      font-size: calc(1px + 1.1vw);
      line-height: calc(1px + 1.3vw);
      vertical-align: middle;
      display: flex;
    }
  }

  .banner__image {
    object-fit: cover;
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 60%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -2;
  }
`;
