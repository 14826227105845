import styled from 'styled-components';

export const Container = styled.div`
background-color: rgba(0, 0, 0, 0.4);
height: 100vh;
width: 100%;
position: absolute;
inset: 0;
display: flex;
align-items: center;
justify-content: center;

.plansInfo__div {
    box-shadow: 0 9px 12px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    margin: 2rem 2.5rem;
    line-height: 1.25rem;
    background-color: #333333;
    position: absolute;
    font-size: 0.9rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: #F2F2F2;
    z-index: 999;

    .closePlansInfo__button {
      color: #8774FF;
      font-size: 1.3rem;
      :hover {
        cursor: pointer;
        filter: brightness(0.9);
      }
    }

  }

  @media screen and (max-width: 820px) and (min-width: 480px) {
    .plansInfo__div {
      width: 50%;
      font-size: 1rem;
    }
  }

  @media screen and (min-width: 900px) {
    .plansInfo__div {
      width: 50%;
      font-size: 1rem;
    }
  }
`