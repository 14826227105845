import styled from 'styled-components';

export const ThanksForSignUpStyle = styled.div`
    padding: 0 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    overflow-y: auto;
   
    gap: 2rem;

    .thanksPage__footer {
        font-size: 1rem;
        color: white;
        font-weight: 400;
        line-height: 1.75rem;
        text-align: center;

        max-width: 400px;
    }

    .buttonArea {
        text-align: center;

        display: flex;
        flex-direction: column;
        margin: 0 auto;
        
        min-width: 200px;
        gap: 1.25rem;
    }
`;