import { Container } from './style.js';

export function Spinner({ height = '48px', stroke_width = '1' }) {
  return (
    <Container>
      <svg 
        class="spinner"
        viewBox="0 0 50 50"
        style={{ height: height }}
      >
        <circle
          class="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          stroke-width={stroke_width}
        ></circle>
      </svg>
    </Container>
  );
}
