import { useEffect, useRef, useState } from "react";
import { colors, fontFamily, routineColorSelector }from "../../../blackbook-components/quarks/theme";
import { tableStyle } from "../../templates/DrugsSections/tableStyle";

export function AccordionTable({ extra, popup, onClose }) {
  const dynamicStyles = `
  :root { 
    --dark-color: ${routineColorSelector(extra.color).dark || colors.hardGreen} !important;
    --hard-color: ${routineColorSelector(extra.color).hard || colors.hardGreen} !important;
    --soft-color: ${routineColorSelector(extra.color).soft || colors.softGreen} !important;

  }
  
  body {
    font-family: ${fontFamily.secondary} !important;
    line-height: 1.3 !important;

  }
  ${tableStyle}
`;

  const dynamicStylesPopup = `
  :root { 
    --dark-color: ${routineColorSelector(extra.color).dark || colors.darkGreen} !important;
    --hard-color: ${routineColorSelector(extra.color).hard || colors.hardGreen} !important;
    --soft-color: ${routineColorSelector(extra.color).soft || colors.softGreen} !important;

  }
  
  body {
    font-family: ${fontFamily.secondary} !important;
    line-height: 1.3 !important;
  }

  img{
    max-width: fit-content!important;
    display: block !important;
    margin: 0 auto !important;
  }

  .title-table{
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
  }

  .title-span{
    height: unset !important;
  }

  .table-close{
    height: 40px !important;
    width: 40px !important;
    cursor: pointer !important;
    margin-top: -7px !important;
    margin-right: -4px !important;
    border: none !important;
    outline: none !important;
    background-color:transparent !important;
    color: white !important;
    font-size: 30px !important;
    font-weight: 300 !important;
  }

  .table-multi-columns{
    overflow: scroll;
    max-height: calc(100vh - 53px) !important;
  }

  .table-multi-columns::-webkit-scrollbar{
      display: block !important;
      width: 15px !important;
      height: 15px !important;
  }

  .table-multi-columns::-webkit-scrollbar-track{
  }
  
  .table-multi-columns::-webkit-scrollbar-thumb:horizontal{
    background: ${routineColorSelector(extra.color).hard || colors.hardGreen} !important;
    border: 5px solid ${routineColorSelector(extra.color).dark || colors.darkGreen} !important;
    border-width: 5px 0 0 2px !important;
  }
  
  .table-multi-columns::-webkit-scrollbar-thumb:vertical{
    background: ${routineColorSelector(extra.color).hard || colors.hardGreen} !important;
    border: 5px solid ${routineColorSelector(extra.color).dark || colors.darkGreen} !important;
    border-width: 2px 0 0 5px !important;
  }

  .table-multi-columns::-webkit-scrollbar-corner{
    background: ${routineColorSelector(extra.color).dark || colors.darkGreen} !important;
  }

  ${tableStyle}
`;

  const [tableHeight, setTableHeight] = useState("");
  const tableRef = useRef(null);

  useEffect(() => {
    new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setTimeout(() => {
          setTableHeight(
            entry.target?.contentDocument?.documentElement?.offsetHeight
          );
        }, 100);
      });
    }).observe(tableRef.current);
  }, []);

  return (
    <div className="dosageContent" style={{ height: !popup && tableHeight}}>
      <iframe
        ref={tableRef}
        title={extra.title}
        style={{
          padding: "0",
          width: "100%",
          height: !popup && tableHeight,
          maxHeight: popup ? "calc(100vh - 200px)" : tableHeight,
        }}
        onLoad={
          (e)=> {
            if(popup){
              let configScript = ""
              Array.from(
                e.target.contentWindow.document.getElementsByTagName(
                  "script"
                )
              ).forEach((script) => {
                if (script.textContent.includes("freezeTable")) {
                  configScript = script.textContent;
                }
              });
              const table =
                e.target.contentWindow.document.getElementsByTagName("TABLE")[0];
              // Set table height
              const baseHeight = table.offsetHeight;
              const trueHeight = baseHeight + 68;
              tableRef.current.style.height = `${trueHeight}px`;
  
              // Get table configs
              let configsAux = "";
              const rawScript = configScript.match(/\(\{[^}]*\}\)/i)[0];
              const rawProps = rawScript
                .match(/\{[^}]*\}/i)[0]
                ?.replace(/[\r\n]/gm, "");
              const propsArray = rawProps
                ?.replace(/(\s*,?\s*})*$/, "}")
                .split(/'/);
              propsArray.forEach((item) => {
                item = item?.replace(/\/\*[\s\S]*?\*\/|\/\/.*/g, "");
                if (!item.includes(":") && !item.includes("{")) {
                  item = `"${item}"`;
                }
                configsAux += item;
              });
              const config = JSON.parse(configsAux);
  
              // Set sticky table
              const tableHeader = table.getElementsByTagName("thead")[0];
              const tableBody = table.getElementsByTagName("tbody")[0];
              let verticalShadowWidth = 0;
              let rowSpanAux = 0;
  
              // Sticks header at y-axis
              if (config.scrollable && tableHeader) {
                tableHeader.style.position = "sticky";
                tableHeader.style.top = "-1px";
                tableHeader.style.zIndex = 2;
                tableHeader.style.boxShadow =
                  "0px 3px 5px 0px rgba(0, 0, 0, 0.26)";
              }
  
              // Sticks tableBody columns at X-axis
              Array.from(tableBody.children).forEach((rows) => {
                for (let i = 0; i < config.columnNum; i++) {
                  if (rows.children[i - 1]?.rowSpan > 1) {
                    rows.children[i].style.position = "sticky";
                    rows.children[i].style.left =
                      rows.children[i - 1].offsetWidth + -1 + "px";
                    rows.children[i].style.zIndex = 1;
                    rowSpanAux = rows.children[i - 1].rowSpan;
                    return;
                  }
                  if (rowSpanAux >= config.columnNum) {
                    rowSpanAux--;
                    rows.children[i].style.left =
                      tableHeader?.children[i]?.children[i]?.offsetWidth + -1 + "px";
                    rows.children[i].style.position = "sticky";
                    rows.children[i].style.zIndex = 1;
                    i++;
                  } else {
                    if (rows.children[i]) {
                      rows.children[i].style.left =
                        i <= 0
                          ? "-1px"
                          : rows.children[i - 1].offsetWidth + -1 + "px";
                      rows.children[i].style.position = "sticky";
                      rows.children[i].style.zIndex = 1;
                    }
                  }
                }
              });
  
              // Sticks tableHeader columns at X-axis
              for (let i = 0; i < config.columnNum; i++) {
                if (config.freezeColumnHead && tableHeader?.getElementsByTagName('th').length > 0) {
                  tableHeader.getElementsByTagName("th")[i].style.position =
                    "sticky";
                  tableHeader.getElementsByTagName("th")[i].style.left =
                    i <= 0
                      ? "-1px"
                      : tableHeader.getElementsByTagName("th")[i - 1]
                          .offsetWidth +
                        -1 +
                        "px";
                  tableHeader.getElementsByTagName("th")[i].style.zIndex = 3;
                } else break
                verticalShadowWidth +=
                  tableHeader.getElementsByTagName("th")[i].offsetWidth;
              }
  
              //Generates a shadow for the body columns
              const verticalShadow = document.createElement("div");
              verticalShadow.style.height = "auto";
              verticalShadow.style.width = verticalShadowWidth + "px";
              verticalShadow.style.left = "5px";
              verticalShadow.style.top =
                (!config.freezeColumnHead && tableHeader.offsetHeight) +
                table.parentNode.previousElementSibling.offsetHeight +
                "px";
              verticalShadow.style.bottom = "20px";
              verticalShadow.style.position = "absolute";
              verticalShadow.style.boxShadow =
                "rgba(0, 0, 0, 0.26) 3px 0px 5px 0px";
              verticalShadow.style.zIndex = 4;
              tableBody.appendChild(verticalShadow);
  
              const closeButton = document.createElement("button");
              closeButton.innerHTML = "x";
              closeButton.setAttribute('class', 'table-close')
              closeButton.addEventListener('click', () =>{
                onClose()
              })
              table.parentNode.previousElementSibling.appendChild(closeButton);
            }
          }
        }
        srcDoc={
          popup
            ? `${extra.content
                ?.replace("{{{table_title}}}", extra.title)
                ?.replace("{{{css_table}}}", dynamicStylesPopup)
                ?.replace("{{{js_table}}}", "")}`
            : `${extra.content?.replace("{{{css_table}}}", dynamicStyles)}`
        }
      />
    </div>
  );
}
