export const INDICATION_COUPON_KEY = 'INDICATION_COUPON'

export const handleSetIndicationCouponOnLocalStorage = (code) => {
  localStorage.setItem(INDICATION_COUPON_KEY, code)
}

export const handleRemoveIndicationCouponOfLocalStorage = () => {
  localStorage.removeItem(INDICATION_COUPON_KEY);
}

export const getIndicationCoupon = () => (localStorage.getItem(INDICATION_COUPON_KEY) || null);
