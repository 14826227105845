import styled from "styled-components";

export const BannerSection = styled.section`
height: 100%;
width: 100%;
  .subscriptionBanner__Link {
    text-decoration: none;
    height: 100%;
  }

  .subscriptionBanner {
    height: 100%;

    display: grid;
    grid-template-columns: 10px 2fr 1fr 10px;
    align-items: center;
    box-sizing: border-box;
    pointer-events: none;

    border-radius: 10px;
    background-color: #fcde17;
  }

  .subscriptionBanner__textArea {
    font-weight: bold;
    font-size: calc(13px + 1.5vw);
    line-height: calc(15px + 1.5vw);
    text-align: center;
    grid-row-start: 1;
    grid-column-start: 2;
  }

  .subscriptionBanner__textArea > p {
    color: black;
    font-family: "Open Sans", sans-serif;
  }

  .subscriptionBanner__image {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-row-start: 1;
    grid-column-start: 3;
    overflow: hidden;
    height: 100%;
  }

  .image__imageFile {
    width: 100%;
    height: auto;
  }
`;
