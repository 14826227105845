import styled from "styled-components";
import { borderRadius, colors, fontFamily, fontSize, spacing } from '../../blackbook-components/quarks/theme';

export const FilterContainer = styled.div`
  background-color: #1e1e1e;
  display: flex;
  gap: 10px;
  margin-bottom: 10px;

  button {
    border: 1px solid ${colors.primary};
    color: ${colors.primary};
    background-color: transparent;
    border-radius: 25px;
    flex: 1;
    max-width: 130px;
    height: 24px;
    cursor: pointer;

    transition: all 0.2s;
    &:hover {
      background-color: ${colors.primary};
      color: white;
    }
    
    &.active {
      background-color: ${colors.primary};
      color: white;
    }


  }

`
