import styled from "styled-components";

export const ContentWrapper = styled.section`
  height: 100%;
  display: flex;

  



`;
