import styled from "styled-components";

export const Container = styled.div`
  max-width: 680px;
  margin: auto;
  height: calc(100vh - 10vh);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  .loginTopDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
  }

  .loginTopDiv__image {
    width: 46px;
    height: 38px;
    margin-top: 3rem;
    margin-bottom: 12px;
  }

  .loginTopDiv__imageName {
    width: 200px;
    height: 21px;
  }

  .loginForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    background-color: #333333;
    border-radius: 5px;
    font-size: 1.1rem;
    margin-top: 3rem;
    margin-bottom: 6rem;
    box-shadow: 0 9px 12px rgba(0, 0, 0, 0.3);

    .title {
      color: #999;
      width: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      font-size: 14px;
      max-width: fit-content;
      text-align: center;
    }

    .loginForm__anchor:active {
      color: black;
    }

    .form__error {
      margin-top: -10px;
      margin-bottom: 5px;
      font-size: .8rem;
      align-self: flex-start;
      color: var(--red-300);
    }
  }
`;
