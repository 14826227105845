import { Spinner } from "../../../assets/animations/spinner";
import { InputContainerStyle } from "../../atoms/registerInput/style";
import { LabelStyle } from "../../atoms/titleComponent/style";
import { ButtonListContainerComponent } from "../buttonListComponent";
import { ButtonListContainerStyle } from "./style";

export const ButtonSelectComponent = (props) => {
    const buttons = props.options.map(option => ({
        text: option.value,
        className: "",
        onClick: () => props.onChange(option)
    }));

    return (
        <InputContainerStyle>
            <LabelStyle>{props.label}</LabelStyle>
            {props.isOptionsLoaging ? <Spinner stroke_width={5}/> :
            <ButtonListContainerStyle>
                <ButtonListContainerComponent items={buttons} />
            </ButtonListContainerStyle>
            }
        </InputContainerStyle>
    );
}


export const RegisterInputComponent = props => {
}