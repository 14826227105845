import styled from "styled-components";
import { SIDEBAR_WIDTH } from "../../../blackbook-components/quarks/sizes";
import { colors } from "../../../blackbook-components/quarks/theme";

export const Container = styled.div`
  display: ${({ isSidebarOpen }) => (isSidebarOpen ? "grid" : "flex")};

  grid-template-columns: ${SIDEBAR_WIDTH}px 1fr;

  position: fixed;
  top: 50px;
  bottom: 37px;
  right: 0;
  left: 0;
  /* min-height: calc(100vh - 86px); */
  /* height: calc(100vh - 86px); */


  .content {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
  }
  .contentOverlay {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 60;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
  }
  .sideBarComponent {
    li > ul {
      z-index: 99;
    }
  }
`;

export const SidebarButtonsDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;

export const SidebarButton = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  padding: 0 20px;
  box-sizing: border-box;
  align-items: center;
  height: 40px;
  border-radius: 55px;
  border: none;
  background-color: ${({ active }) =>
    active ? colors.secondary : colors.primary};
  color: ${({ active }) => (active ? colors.black : colors.white)};
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;

  z-index: ${({ active }) => (active ? 9999 : 1)};

  transition: all 0.2s;

  :hover {
    svg {
      animation-duration: 0.2s;
      animation-name: jump;
      animation-iteration-count: 2;
      animation-direction: alternate;
    }
  }
`;
