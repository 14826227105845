import jwtDecode from 'jwt-decode';
import { getUserToken, TOKEN_KEY } from './tokenService';

const JwtDecode = () => {
  let token = getUserToken(TOKEN_KEY);
  if(!token){
    return false;
  }
  token = jwtDecode(token);
  return token;
};

export default JwtDecode;
