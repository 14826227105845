import styled from "styled-components";

export const PhoneInputAreaStyle = styled.div`
    position: relative;
    display: flex;
    align-items: center;

    .countryDial {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;
        position: relative;
        width: 70px;
        margin-right: 10px;
        padding: 0 15px 0 10px;
        border: none;
        border-bottom: 2px solid #8774FF;
        background-color: transparent;
        height: 50px;
        font-size: 0.75rem;
        color: white;
        cursor: pointer;

        img {
            width: 32px;
            height: 23px;
        }

        &::after {
            content: "";
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid #8774FF;
            position: absolute;
            top: 45%;
            right: 6px;
            z-index: 98;
        }
    }

    ul {
        max-height: 350px;
        position: absolute;
        color: white;
        top: 100%;
        left: 0;
        background-color: #1e1e1e;
        display: none;
        overflow-y: scroll;
        border-radius: 5px;

        &.active {
            display: block;
            z-index: 10;
        }

        li {
            padding: 5px;
            cursor: pointer;

            &:hover {
                background-color: #333333;
            }

            img {
                padding: 0 5px;
            }
        }
    }
`;

export const PhoneInputOutlined = styled.div`
    position: relative;
    display: flex;
    gap: 1rem;
    height: 100%;

    .countryDial {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;
        position: relative;
        width: 70px;
        margin-right: 10px;
        /* padding: 0 15px 0 10px; */
        background-color: transparent;
        /* height: 50px; */
        /* font-size: 0.75rem; */
        color: white;
        cursor: pointer;

        border: 1px solid var(--gray-100);
        background-color: transparent;
        color: white;


        border: 1px solid
        ${({ isValid, isInvalid }) =>
            isValid
                ? `#1D9637`
                : isInvalid
                    ? `var(--error) !important`
                    : 'var(--gray-100)'};
        background-color: unset;
        color: ${({ isValid, isInvalid }) =>
            isValid ? `#1D9637` : isInvalid ? `var(--error) !important` : 'white'};

        font-size: 1rem;
        font-family: Source Sans Pro;

        height: 100%;
        padding: 0 0.7rem;
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;

        transition: border-color 0.2s;

        &:hover {
        border-color: var(--purple-500);
        }

        &:focus {
        border-color: var(--yellow-500);
        outline: none;
        }

        &::placeholder {
        color: var(--gray-100);
        font-size: 0.75rem;
        }


        
        img {
            width: 32px;
            height: 23px;
        }

        &::after {
            content: "";
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid #8774FF;
            position: absolute;
            top: 45%;
            right: 6px;
            z-index: 98;
        }
    }

    ul {
        max-height: 350px;
        position: absolute;
        color: white;
        top: 100%;
        left: 0;
        background-color: #1e1e1e;
        display: none;
        overflow-y: scroll;
        border-radius: 5px;

        &.active {
            display: block;
            z-index: 10;
        }

        li {
            padding: 5px;
            cursor: pointer;

            &:hover {
                background-color: #333333;
            }

            img {
                padding: 0 5px;
            }
        }
    }
    /* display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  width: 100%;
  height: 100%; */
  h3 {
    color: #999;

    transition: transform 0.2s ease-in-out, background 0.3s ease-in-out;
    transform: ${({ isActive }) =>
        isActive ? 'translateY(-175%)' : 'translateY(0%)'};
    padding: 0 0.5rem;
    background-color: ${({ isActive }) =>
        isActive ? '#1e1e1e' : 'transparent'};
    width: fit-content;
    margin-left: 0.5rem;
    font-size: 0.75rem;
    z-index: 1;
    border-radius: 3px;
    position: absolute;
  }
`