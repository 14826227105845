import { SubAccordionComponent } from "../../../blackbook-components/atoms/accordion";

function parseHTML(content, popup) {
  return { __html: content };
}

export const specialTemplates = {
  default: (special, title, grandchild) =>
    special?.observation.length > 0 && (
      <SubAccordionComponent title={title} type={`yellow ${grandchild}`}>
        {grandchild && special.item.is_adjust &&
          <hr className="default__separator special_situation" />
        }
        <div
          className={`content__style ${special.item.is_adjust ? "specialContent" : ""}`}
          dangerouslySetInnerHTML={parseHTML(special?.observation)}
        ></div>
      </SubAccordionComponent>
    ),
  icon: (special, title, iconPopup) =>
    (special?.observation.length > 0 || special?.icon.icon.length > 0) && (
      <SubAccordionComponent title={title} type="yellow">
        <div className="special">
          <div className="special__header">
            <img
              src={`data:image/png;base64, ${special.icon.icon}`}
              alt=""
              className="special__icon"
              onClick={()=>iconPopup(special.icon)}
            />
            <p>
              {special.icon.tooltip}
            </p>
          </div>
          {
            special.observation.length > 0 &&
            <>
              <hr className="default__separator special_situation with__icon" />
              <div
                className="content__style specialContent iconContent"
                dangerouslySetInnerHTML={parseHTML(special?.observation)}
              ></div>
            </>
          }
        </div>
      </SubAccordionComponent>
    ),
};
