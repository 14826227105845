import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { usePaywall } from '../../../services/hooks/handleRedirectToPaywall';
import { CardComponent } from '../card';
import { spacing } from '../../quarks/theme';
import { BBLock } from '../../quarks/icons';
import { AccordionStyle } from './style';
import { useTrack } from '../../../services/hooks/useTrack';

const TIME_TO_RENDER_ACCORDION_BODY = 100;

const Accordion = (props) => {
  const [isOpen, setIsOpen] = useState(props.isOpen || null);
  const [isFistOpenTime, setIsFistOpenTime] = useState(true);

  const myRef = useRef(null);

  function scrollHandler(e) {
    setTimeout(() => {
      props?.scrollAccordionIntoView(
        myRef.current.offsetTop - props.hierarchy * 40
        );
      }, TIME_TO_RENDER_ACCORDION_BODY * (props.hierarchy + 1));
    props.scrollSiblings && props.scrollSiblings(e)
  }

  useEffect(() => {
    setIsOpen(props.isOpen);
    if (!isFistOpenTime) {
      scrollHandler();
    }
    setIsFistOpenTime(false);
  }, [props.isOpen ]);

  return (
    <AccordionStyle
      {...props}
      className={`${props.className} ${
        isOpen ? 'active' : ''
      }`}
      ref={myRef}
    >
      <div className={`${props.className} stickyHeader`}>
        {props.icon}
        <h3
          className="title"
          onClick={(e) => {
            props?.onOpenCloseCallback?.() || setIsOpen(!isOpen);
            scrollHandler(e);
          }}
        >
          {props.title}
          {isOpen ? <FaAngleUp /> : <FaAngleDown />}
        </h3>
      </div>
      {isOpen && (
        <div className={`accordion_content`}>
          {props?.children?.length > 0
            ? props.children.map(
                (elem) =>
                  React.isValidElement(elem) &&
                  React.cloneElement(elem, {
                    hierarchy: props.hierarchy + 1,
                    scrollSiblings: props.scrollSiblings,
                    scrollAccordionIntoView: (e, h) =>
                      props.scrollAccordionIntoView(
                        e + myRef?.current?.offsetTop, h
                      ),
                  })
              )
            : React.isValidElement(props.children)
            ? React.cloneElement(props.children, {
                scrollAccordionIntoView: (e) =>
                  props.scrollAccordionIntoView(e + myRef?.current?.offsetTop),
              })
            : null}
            {
              props.text && <div dangerouslySetInnerHTML={{ __html:props.text}}></div>
            }
        </div>
      )}
    </AccordionStyle>
  );
};

export const AccordionComponent = (props) => {
  const {redirectToPaywall} = usePaywall()
  const {trackEvent} = useTrack()

  return (
    <CardComponent
      className={['accordion', 'drugAccordion', props.className].join(' ')}
      padding={`${spacing.none} ${spacing.small}`}
      backgroundColor={props.accordionColor}
      margin={props.margin}
    >
      {props.isPaid && props.className !== "resume" 
      ? 
      (
        <AccordionStyle {...props} className={`${props.className}`} color={props.bodyColor}>
          <div className={`${props.className} stickyHeader`}>
            <h3
              className="title"
              style={{color: '#fff'}}
              onClick={(e) => {
                // redirect to plans page
                let eventProps = {
                  "Origem": props?.item_type,
                };
                
                if(props?.item_type === 'Drogas') {
                  eventProps = {
                    ...eventProps,
                    'Grupo terapêutico': props?.therapeutic_group_title,
                    'Principio Ativo': props?.active_principle_title,
                    'Seção': props?.title
                  }
                }

                if(props?.item_type === 'Rotinas') {
                  eventProps = {
                    ...eventProps,
                    'Rotina': props?.routine_title,
                    'Tab': props?.tab_title,
                    'Card': props?.card_title,
                    'Seção': props?.title
                  }
                }

                trackEvent(
                  "Clicou no cadeado",
                  eventProps
                );
                redirectToPaywall()
              }}
            >
              {props.title}
              <BBLock />
            </h3>
          </div>
        </AccordionStyle>
      ) : (
        <Accordion hierarchy={0} {...props} />
      )}
    </CardComponent>
  );
};

export const SubAccordionComponent = (props) => {
  return (
    <Accordion
      {...props}
      className={['soft', 'sub_accordion', props.className, props.type].join(
        ' '
      )}
    />
  );
};

AccordionComponent.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};
