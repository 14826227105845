import React from "react";
import styled from "styled-components";
//@ts-ignore
import { BbCloseIcon } from "../../../blackbook-components/quarks/icons";
import { ReferralStatistics } from "../ReferralStatistics";
import {toast} from 'react-toastify';
//@ts-ignore
import {useTrack} from '../../../services/hooks/useTrack'

interface IProps {
  setIsOpen: () => void;
  indicationInfo: any;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
`;

const FormContainer = styled.div`
  width: 100%;
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 18px;
  width: 18px;
  right: 0;
  display: flex;
  align-items: center;
  padding: 0;
  margin-bottom: 20px;
`;

const CopyContainer = styled.div`
  background-color: #2c2c2c;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const CopyTitleContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const CopyTitle = styled.p`
  color: white;
  font-weight: 700;
  font-size: 14px;
`;

const CopyContent = styled.div`
  background-color: white;
  border-radius: 5px;
  padding: 5px;
`;

const Copy = styled.p`
  user-select: text;
  cursor: text;
  text-align: left;
`;

const CopyButton = styled.button`
  background-color: #8774ff;
  border: none;
  border-radius: 30px;
  color: white;
  padding: 5px;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
`;

export const ReferralForm = (props: IProps) => {
  const {trackEvent} = useTrack();
  
  return (
    <Container>
      <CloseButton onClick={(e) => {
        e.stopPropagation();
        props.setIsOpen()
        }}>
        <BbCloseIcon width={18} height={18} />
      </CloseButton>
      <FormContainer>
        <CopyContainer>
          <CopyTitleContainer>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.3373 4.575C13.3245 4.575 14.1248 3.77471 14.1248 2.7875C14.1248 1.80029 13.3245 1 12.3373 1C11.3501 1 10.5498 1.80029 10.5498 2.7875C10.5498 3.77471 11.3501 4.575 12.3373 4.575Z"
                stroke="white"
                stroke-width="1.91"
                stroke-miterlimit="10"
              />
              <path
                d="M2.7875 9.3499C3.77471 9.3499 4.575 8.54961 4.575 7.5624C4.575 6.57519 3.77471 5.7749 2.7875 5.7749C1.80029 5.7749 1 6.57519 1 7.5624C1 8.54961 1.80029 9.3499 2.7875 9.3499Z"
                stroke="white"
                stroke-width="1.91"
                stroke-miterlimit="10"
              />
              <path
                d="M12.3373 14.125C13.3245 14.125 14.1248 13.3248 14.1248 12.3375C14.1248 11.3503 13.3245 10.55 12.3373 10.55C11.3501 10.55 10.5498 11.3503 10.5498 12.3375C10.5498 13.3248 11.3501 14.125 12.3373 14.125Z"
                stroke="white"
                stroke-width="1.91"
                stroke-miterlimit="10"
              />
              <path
                d="M10.7377 3.5874L4.3877 6.7624M10.7377 11.5374L4.3877 8.3624"
                stroke="white"
                stroke-width="1.91"
                stroke-miterlimit="10"
              />
            </svg>

            <CopyTitle>Compartilhar o seu link</CopyTitle>
          </CopyTitleContainer>
          <CopyContent>
            <Copy>{props?.indicationInfo?.url}</Copy>
          </CopyContent>
          <CopyButton onClick={() => {
            navigator.clipboard.writeText(props?.indicationInfo?.url);
            toast.success('Link copiado com sucesso!');
            trackEvent('Indique e ganhe: Copiou o link', {link: props?.indicationInfo?.url} )
          }}>Convidar amigos</CopyButton>
        </CopyContainer>
        <ReferralStatistics invitationData={props?.indicationInfo?.invitationData}/>
      </FormContainer>
    </Container>
  );
};
