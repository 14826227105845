import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from './style';
import { BiInfoCircle } from 'react-icons/bi';
import { DescriptionPlansModal } from '../descriptionPlansModal';
import { getIndicationCoupon } from '../../../services/cache/indicationCoupon';
import { promotionApi } from '../../../services/clients';
import { useTrack } from '../../../services/hooks/useTrack';
import { useIsDesktopOrTabletScreen } from '../../../services/hooks/useIsDesktopOrTabletScreen';

export function PlanComponent({ planParent, plan }) {
  const { trackEvent } = useTrack()
  const isDesktop = useIsDesktopOrTabletScreen()

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [planWithCoupon, setPlanWithCoupon] = useState(null);

  const calcPlan = (value) =>
    (value / 100).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

  const history = useHistory();

  const planName = plan?.name.toUpperCase();

  function onClickPlan() {
    history.push(
      `payment/${planParent?.store_identify}_${plan?.store_identify}`
    )

    const periodText = plan.availablePeriod === 1 ? '1 mês' : `${plan.availablePeriod} meses`

    trackEvent(`Paywall - Selecionou ${periodText}`,{'device':isDesktop? 'large':'small'})
    trackEvent('Paywall - Iniciar teste grátis', {'plano': plan.name, 'device': isDesktop? 'large' : 'small'})
  }

  // função comentada para implementação do redirect para pagamento quando se há cupom (pode ser que volte a ser imlpementada)
  // async function handleValidateCoupon(coupon) {
  //   try {
  //     const requestBody = [
  //       {
  //         product_type: 'plan',
  //         product_identify: `${planParent.store_identify}_${plan.store_identify}`,
  //         quantity: plan.availablePeriod,
  //         total_value: plan.totalValue,
  //         unit_value: plan.valuePerMonth,
  //       },
  //     ];
  //     const response = await promotionApi.validateCoupon(coupon, requestBody);

  //     setPlanWithCoupon(response);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  // useEffect(() => {
  //   const storedCoupon = getIndicationCoupon();

  //   if (storedCoupon) {
  //     handleValidateCoupon(storedCoupon);
  //   }
  // }, []);

  return (
    <>
      <Container
        isAnnualPlan={plan?.name === 'Anual'}
      >
        {/* <div className="planCardsContainerBody__title"> */}
          {/* <div className="info__div" onClick={() => setIsModalOpen(true)}>
            <BiInfoCircle />
          </div> */}
          <h1 className="title__content">{planName}</h1>
        {/* </div> */}
        <div className="planCardsContainerBody__content">
          <h2
            className="content__price"
          >
            {calcPlan(
              planWithCoupon
                ? planWithCoupon.payed_value / plan.availablePeriod
                : plan?.valuePerMonth
            )?.split('').map(e => (<span>{e}</span>))}
          </h2>

          {plan?.availablePeriod >= 3 ? (
            <div className="subtitle_div">
              <h4 className="contentPrice__subtitle">
                cada mês,{isDesktop ? '' : <br />} total {calcPlan(
                  planWithCoupon ? planWithCoupon.payed_value : plan?.totalValue
                )}
              </h4>
            </div>
          ) : (
            <div className="subtitle_div">
              <h4 className="contentPrice__subtitle">por mês.</h4>
            </div>
          )}
          <p className="content__description">{plan?.description}</p>

          {planWithCoupon && (
            <p className="coupon__description">
              {planWithCoupon.coupon_feedback}
            </p>
          )}
        </div>

        <div className="button__div">
          <button
            className="subcribe__button"
            onClick={onClickPlan}
          >
            ASSINAR
          </button>
        </div>
      </Container>

      {isModalOpen && <DescriptionPlansModal setIsModalOpen={setIsModalOpen} />}
    </>
  );
}
