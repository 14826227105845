import { getUserToken } from "../tokenService";
import { BaseClient } from "./baseClient";

export class PromotionApiClient extends BaseClient {

  constructor() {
    super(process.env.REACT_APP_PROMOTION_API);
  }

  async validateCoupon(coupon, body) {
    return this.postDefault(`/coupons/validate/${coupon}`, body, {headers: {'Authorization': `${getUserToken()}`}})
  }

}

export const promotionApi = new PromotionApiClient();