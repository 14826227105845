import styled from "styled-components";

export const SuggestionContainer = styled.div`
  position: absolute;
  z-index: 100;

  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  

  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 10px 0 0 10px;


  ul {
    max-width: 500px;
    width: 100%;

    li {
      box-sizing: border-box;
      font-size: 14px;
      color: white;
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 0 10px 0;
      background-color: #1e1e1e;
      
      cursor: pointer;
      transition: all 0.2s;
      
      &:hover {
        filter: brightness(1.4);
        
      }
      
      span {
        display: flex;
        padding: 10px 0;
        flex: 1;
      }
      
      .addToInput__container {
        background-color: #1e1e1e;
        padding: 5px;
        border-radius: 5px;
        
        cursor: pointer;
        transition: all 0.2s;
        
        &:hover {
          filter: brightness(1.4);
        }
        
        
      }
    }
  }
`