import styled from "styled-components";
import { SIDEBAR_WIDTH } from "../../../blackbook-components/quarks/sizes";
import { colors } from "../../../blackbook-components/quarks/theme";

import defaultBackgroundImage from '../../../assets/images/newbg.png'

export const Container = styled.div`
  display: ${({ isSidebarOpen }) => (isSidebarOpen ? "grid" : "flex")};

  grid-template-columns: ${SIDEBAR_WIDTH}px 1fr;

  min-height: calc(100vh - 86px);
  height: calc(100vh - 86px);

  .content {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .bgs {
      position: absolute;
      inset: 0;
      top: 50px;
      height: calc(100% - 86px);

      .bg1 {
        height: 100%;
        width: 100%;
        position: absolute;
        background-image: 
          linear-gradient(to right,transparent 80%, #121212 200%),
          url(${defaultBackgroundImage});
        background-repeat: no-repeat;

        background-size: cover;
        z-index: 1;
      }
      .bg2 {
        z-index: 0;
        height: 100%;
        width: 60vw;
        position: absolute;
        right: 0;
        background-image: ${({backgroundImage}) => 
          backgroundImage ?
          `linear-gradient(to left,transparent 50%,#121212 50vw),
          url(${backgroundImage})` : 
          ``
        };
        background-repeat: no-repeat;
        background-position: right center;
        background-size: cover;
      }
    }
  }
  
  .contentOverlay {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 60;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
  }
  .sideBarComponent {
    li > ul {
      z-index: 99;
    }
  }


  @media screen and (max-width: 1010px) {
    .content {

      .bgs {
        display: flex;
        flex-direction: column-reverse;

        .bg1 {
          position: relative;
          background-image: none;
        }
        
        .bg2 {
          position: relative;
          max-width: 100vw;
          background-image: ${({backgroundImage}) => 
          backgroundImage ?
          `linear-gradient(to bottom,transparent 12%,#121212 41vh),
          url(${backgroundImage})` : 
          ``
        };
          height: inherit;
          width: 100%;
          background-size: cover;
          background-position: center top;
          height: 100%;
        }
      }
    }
  }
`;


export const DownloadAppButton = styled.button`
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius: 55px;
    border: none;
    background-color: ${({ active }) => active ? colors.secondary : colors.primary};
    color: ${({ active }) => active ? colors.black : colors.white};
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;

    z-index: ${({ active }) => active ? 9999 : 1};

    transition: all 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
`