import styled from 'styled-components';

export const ModalContainer = styled.div`
  position: absolute;
  background-color: rgba(0,0,0,0.6);

  overflow: hidden;
  inset: 0;
  padding: 0;
  margin: 0;

  .content {
    margin: 3rem auto;
    max-width: 720px;
    z-index: 99;
    position: relative;

    background-color: #121212;

    .close-modal__button {
      position: absolute;
      right: 0;

      padding: 1rem;
      cursor: pointer;
    }
  }

`;
