import { TitleStyle, SubTitleStyle } from "./style";

export const TitleComponent = props => (
    <TitleStyle {...props}>
        {props.children}
    </TitleStyle>
);

export const SubTitleComponent = props => (
    <SubTitleStyle {...props}>
        {props.children}
    </SubTitleStyle>
);