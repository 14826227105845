import { useState, useContext } from 'react';
import { useFormik } from 'formik';
import { useHistory, Link } from 'react-router-dom';
import * as Yup from 'yup';

import { toast } from 'react-toastify';

import { ButtonComponent } from '../../atoms/buttonComponent';
import { DefaultInput } from '../../atoms/inputs/DefaultInput';

import { EyeIconOn } from '../../../assets/icons/EyeIconOn';
import { EyeIconOff } from '../../../assets/icons/EyeIconOff';

import { Container } from './style';
import { UserContextAplication } from '../../../context/useUser';
import { useTrack } from '../../../services/hooks/useTrack';

export function SignInForm() {
  const { handleClearUserData, handleLogin } = useContext(
    UserContextAplication
  );
  const { trackEvent } = useTrack();
  const [isActivated, setIsActivated] = useState(false);
  const history = useHistory();

  handleClearUserData();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },

    validationSchema: Yup.object({
      email: Yup.string()
        .required('Campo obrigatório!')
        .email('Endereço de email inválido!'),
      password: Yup.string().required('Campo obrigatório!'),
    }),

    onSubmit: async (values) => {
      try {
        await handleLogin({
          email: values.email,
          password: values.password,
        });
      } catch (error) {
        toast.error('Ocorreu um erro! Dados incorretos.');

        trackEvent('Erro no login', {
          email: values.email,
          site: 'app.blackbook/signin',
          origem: 'login',
          erro: error?.toString(),
        });

        return error;
      }
    },
  });

  return (
    <Container>
      <form className="loginForm" onSubmit={formik.handleSubmit}>
        <DefaultInput
          inputProp={{
            onblur: (e) => {
              trackEvent('Preencheu email', {
                email: formik.values.email,
                site: 'app.blackbook/signin',
                origem: 'login',
              });
            },
            type: 'email',
            className: `form__input ${
              formik.touched.email && formik.errors.email ? '--error' : ''
            }`,
            placeholder: 'E-mail',
            name: 'email',
            onChange: formik.handleChange,
          }}
        />
        {formik.touched.email && formik.errors.email ? (
          <p className="form__error">{formik.errors.email}</p>
        ) : null}
        <div className="passwordContainer">
          <DefaultInput
            inputProp={{
              onblur: (e) => {
                trackEvent('Preencheu senha', {
                  site: 'app.blackbook/signin',
                  origem: 'login',
                });
              },
              type: `${isActivated ? 'text' : 'password'}`,
              className: `form__input ${
                formik.touched.password && formik.errors.password
                  ? '--error'
                  : ''
              }`,
              placeholder: 'Senha',
              name: 'password',
              onChange: formik.handleChange,
            }}
            adornment={isActivated ? <EyeIconOn /> : <EyeIconOff />}
            handleAdornmentClick={() => setIsActivated(!isActivated)}
          />
        </div>
        {formik.touched.password && formik.errors.password ? (
          <p className="form__error">{formik.errors.password}</p>
        ) : null}
        <Link to="/forgotpassword" className="loginForm__anchor">
          Esqueceu a senha?
        </Link>
        <ButtonComponent
          buttonProp={{
            type: 'submit',
          }}
          children="ENTRAR"
        />
      </form>

      <button
        className="createAccount__button"
        onClick={() => {
          trackEvent('Entrada - Cadastrar');
          history.push('/signup');
        }}
      >
        CRIAR UMA CONTA
      </button>
    </Container>
  );
}
