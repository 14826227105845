import InputMask from 'react-input-mask';

import { Container, InputAdornment } from './style';

export function DefaultInput({
  inputProp,
  adornment = null,
  handleAdornmentClick,
}) {
  if (!inputProp?.className) {
    inputProp.className = 'default__input';
  }
  return (
    <>
      <Container>
        {inputProp?.label && <h3>{inputProp.label}</h3>}
        <InputMask
          onBlur={() => {
            if (inputProp?.onblur) {
              inputProp?.onblur();
            }
          }}
          {...inputProp}
        />
        {adornment && (
          <InputAdornment type="button" onClick={handleAdornmentClick}>
            {adornment}
          </InputAdornment>
        )}
      </Container>
    </>
  );
}
