import { gql, useLazyQuery } from "@apollo/client";

export const HANDLE_BLOG_BANNER = gql`
  query BlogBanner {
    blogBanner {
      bannerTitle
      description
      image
      link
      title
    }
  }
`;

export function useBlogFeed() {
  const [handleBlogBanner] = useLazyQuery(HANDLE_BLOG_BANNER, {
    fetchPolicy: "cache-and-network",
  });

  async function getBlogBanner() {
    const data = handleBlogBanner();
    return (await data).data.blogBanner;
  }

  return { getBlogBanner };
}
