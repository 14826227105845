import { Footer } from './style';
import { WhatsAppIcon } from '../../../assets/icons/WhatsAppIcon';
import { useTrack } from '../../../services/hooks/useTrack';
import { useIsDesktopOrTabletScreen } from '../../../services/hooks/useIsDesktopOrTabletScreen';

export function FooterComponent({ footerProp }) {
  const { trackEvent } = useTrack();
  const isDesktop = useIsDesktopOrTabletScreen();
  function handleTrack(event) {
    trackEvent(event,{'device':isDesktop? 'large':'small'});
  }
  return (
    <Footer {...footerProp}>
      <a
        onClick={() => handleTrack("Paywall - Baixar o app")}
        href="https://apps.apple.com/br/app/blackbook/id1496811013"
        target="_blank"
        rel="noreferrer"
      >
        <img
          alt="AppStore"
          src="https://firebasestorage.googleapis.com/v0/b/public-blackbook/o/templates%2Fappstore.png?alt=media"
        />
      </a>
      <a
        onClick={() => handleTrack("Paywall - Baixar o app")}
        href="https://play.google.com/store/apps/details?id=br.com.blackbook"
        target="_blank"
        rel="noreferrer"
      >
        <img
          alt="PlayStore"
          src="https://firebasestorage.googleapis.com/v0/b/public-blackbook/o/templates%2Fplaystore.png?alt=media"
        />
      </a>
      <a
        onClick={() => handleTrack("Paywall - Whatsapp")}
        href="https://wa.me/5531996887607?text=Olá,%20preciso%20de%20ajuda."
        className="whatsapp_icon"
        target="_blank"
        rel="noreferrer"
      >
        <WhatsAppIcon />
      </a>
    </Footer>
  );
}
