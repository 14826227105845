import React from "react"

import PropTypes from "prop-types";

import { CardComponent } from "../../atoms/card";
import { BbPopupIcon, BbTableIcon } from "../../quarks/icons";
import { PopupButtonContainer } from "./style";

export const PopupButtonComponent = (props) => {
  return (
    <CardComponent padding={"0px"} margin={'0 0 10px 0'}>
      <PopupButtonContainer theme={props.theme}>
        <div className="buttonContainer" onClick={props.clickCallback}>
          <p>{props.title}</p>
          <div className="iconContainer">
            <BbPopupIcon />
          </div>
        </div>
      </PopupButtonContainer>
    </CardComponent>
  );
};

PopupButtonComponent.propTypes = {
  theme: PropTypes.shape({
    color: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
}