import { useMutation, useLazyQuery } from "@apollo/client";
import { ADD_MY_BLACKBOOK_MUTATION, MY_BLACKBOOK_HOME_QUERY, FAVORITES_BY_TYPE_QUERY, MY_BLACKBOOK_QUERY, REMOVE_MY_BLACKBOOK_MUTATION } from "../clients/bffConfig/querys/MyBlackbookQuery";

export function useMyBlackbook() {
  const [
    addMyBlackbook,
    { },
  ] = useMutation(ADD_MY_BLACKBOOK_MUTATION);

  const [
    removeMyBlackbook,
    { },
  ] = useMutation(REMOVE_MY_BLACKBOOK_MUTATION)

  const [findMyBlackbookData] = useLazyQuery(MY_BLACKBOOK_QUERY, {
    fetchPolicy: "no-cache",
  })

  const [findMyBlackbookHomeData] = useLazyQuery(MY_BLACKBOOK_HOME_QUERY, {
    fetchPolicy: "no-cache",
  })

  const [findFavoritesByType] = useLazyQuery(FAVORITES_BY_TYPE_QUERY, {
    fetchPolicy: "no-cache",
  })

  async function getMyBlackbook() {
    try {
      const response = await findMyBlackbookData();
  
      return response?.data?.myBlackbook
    } catch (error) {
      console.error(`[getMyBlackbook error] `, error);
      return null;
    }
  }

  async function getMyBlackbookHome() {
    try {
      const response = await findMyBlackbookHomeData();
  
      return response?.data?.myBlackbookHome
    } catch (error) {
      console.error(`[getMyBlackbookHome error] `, error);
      return null;
    }
  }

  async function getFavoritesByType(type, item_id) {
    try {
      const response = await findFavoritesByType({
        variables: {
          type: type,
          item: item_id,
        }
      });
  
      return response?.data?.favoritesByType
    } catch (error) {
      console.error(`[getFavoritesByType error] `, error);
      return null;
    }
  }

  async function removeFavorite(type, item_id, subItem_id) {
    try {
      await removeMyBlackbook({
        variables: {
          type: type,
          item: item_id,
          subItem: subItem_id,
        }
      });
  
      return true;
    } catch (error) {
      console.error(`[getMyBlackbook error] `, error);
      return null;
    }
  }

  async function addFavorite(type, item_id, subItem_id) {
    try {
      await addMyBlackbook({
        variables: {
          type: type,
          item: item_id,
          subItem: subItem_id,
        }
      });
  
      return true;
    } catch (error) {
      console.error(`[getMyBlackbook error] `, error);
      return null;
    }
  }

  return {
    getMyBlackbook,
    getMyBlackbookHome,
    removeFavorite,
    addFavorite,
    getFavoritesByType
  }
};
