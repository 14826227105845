import { SubAccordionComponent } from "../../../blackbook-components/atoms/accordion";
import { DrugBrandComponent } from "../../../blackbook-components/atoms/drug-brand";

export const brandsTemplates = {
  default: ({brands, title, isOpen}) =>
    brands?.length > 0 && (
      <SubAccordionComponent isOpen={isOpen} title={title} type="blue">
        {brands?.map((section) => {
          return (
            <>
              <div className="brandsSection" data-parent="brands" id={section._id}>
                <DrugBrandComponent {...section}/>
                {section.presentations.map((presentations) => {
                  return (
                    <>
                      <p className="brandsSection__presentation">
                        {presentations.parsed_text}
                      </p>
                      {presentations.observations && (
                        <div
                          className="brandsSection__presentation"
                          dangerouslySetInnerHTML={{
                            __html: presentations.observations,
                          }}
                        ></div>
                      )}
                    </>
                  );
                })}
              </div>
              <hr className="default__separator brand" />
            </>
          );
        })}
      </SubAccordionComponent>
    ),
  essentials: ({brands, isOpen, iconPopup}) =>
    brands?.length > 0 && (
      <SubAccordionComponent isOpen={isOpen} title="Essenciais" type="blue">
        {brands?.map((essential) => {
          return (
            <div className="brandsSection essentials" data-parent="brands" id={essential._id}>
              <img
                src={`data:image/png;base64, ${essential.classification.icon.icon}`}
                alt=""
                className="brandsSection__icon"
                onClick={()=>iconPopup(essential.classification.icon)}
              />
              <div className="essentials__content">
                {essential.presentations.map((presentations) => {
                  return (
                    <>
                      <p className="brandsSection__presentation">
                        {presentations.parsed_text}
                      </p>
                      {presentations.observations && (
                        <div
                          className="brandsSection__presentation"
                          dangerouslySetInnerHTML={{
                            __html: presentations.observations,
                          }}
                        ></div>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          );
        })}
      </SubAccordionComponent>
    ),
  doubt: ({brands, isOpen}) =>
    brands?.length > 0 && (
      <SubAccordionComponent isOpen={isOpen} title="Inconsistentes" type="blue">
        {brands?.map((doubt) => (
          <div className="doubt" data-parent="brands" id={doubt._id}>
            <h3 className="doubt__title">{doubt.title}</h3>
            <sup className="doubt__lab">{doubt.laboratory.title}</sup>
          </div>
        ))}
      </SubAccordionComponent>
    ),
  discontinued: ({brands, isOpen}) =>
    brands?.length > 0 && (
      <SubAccordionComponent isOpen={isOpen} title="Descontinuados" type="blue">
        {brands?.map((discontinued) => (
          <h3 className="discontinued"  data-parent="brands" id={discontinued._id}>{discontinued.title}</h3>
        ))}
      </SubAccordionComponent>
    ),
};