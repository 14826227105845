export const ConfigIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.5917 30.45C27.9417 30.45 29.0416 29.35 29.0416 28C29.0416 26.65 27.9417 25.55 26.5917 25.55C25.2316 25.55 24.1417 26.65 24.1417 28C24.1417 29.35 25.2316 30.45 26.5917 30.45ZM31.8307 28.1484C31.8063 28.4807 31.9489 28.8033 32.2112 29.0088L33.2717 29.84C33.4017 29.95 33.4416 30.13 33.3516 30.29L31.9516 32.71C31.8616 32.86 31.6916 32.92 31.5216 32.86L30.315 32.3746C29.9848 32.2417 29.6125 32.3008 29.3086 32.4862C29.2415 32.5271 29.1736 32.5666 29.1047 32.6045C28.8001 32.7726 28.571 33.0599 28.5208 33.4041L28.3316 34.7C28.3116 34.87 28.1616 35 27.9916 35H25.1917C25.0117 35 24.8717 34.87 24.8417 34.7L24.6608 33.4135C24.6117 33.0641 24.3783 32.7732 24.0698 32.602C24.0031 32.565 23.9374 32.5266 23.8727 32.4868C23.57 32.3008 23.1989 32.2411 22.8691 32.373L21.6517 32.86C21.5017 32.92 21.3117 32.86 21.2317 32.71L19.8317 30.29C19.7417 30.13 19.7817 29.95 19.9117 29.84L20.9721 29.0088C21.2344 28.8033 21.377 28.4807 21.3526 28.1484L21.347 28.0728C21.3434 28.0243 21.3434 27.9757 21.3469 27.9272L21.353 27.8436C21.3771 27.5102 21.2331 27.1868 20.9692 26.9817L19.9117 26.16C19.7817 26.05 19.7417 25.86 19.8317 25.71L21.2317 23.29C21.3117 23.13 21.5017 23.07 21.6517 23.13L22.8671 23.6231C23.1979 23.7573 23.5709 23.6979 23.874 23.5093C23.9339 23.472 23.9946 23.4359 24.0563 23.4013C24.3702 23.2247 24.6125 22.9295 24.6623 22.5728L24.8417 21.29C24.8717 21.13 25.0117 21 25.1917 21H27.9916C28.1616 21 28.3116 21.13 28.3316 21.29L28.5192 22.582C28.5703 22.9338 28.8082 23.2254 29.1184 23.3988C29.1821 23.4344 29.2451 23.4715 29.3073 23.5099C29.6116 23.6979 29.9858 23.7567 30.317 23.6216L31.5216 23.13C31.6916 23.07 31.8616 23.13 31.9516 23.29L33.3516 25.71C33.4416 25.86 33.4017 26.05 33.2717 26.16L32.2141 26.9817C31.9502 27.1868 31.8062 27.5102 31.8303 27.8436L31.8364 27.9272C31.8399 27.9757 31.8399 28.0243 31.8363 28.0728L31.8307 28.1484Z"
        fill="#8774FF"
      />
      <path
        d="M17.3817 20.07C18.2517 20.07 18.9517 19.37 18.9517 18.5C18.9517 17.63 18.2517 16.92 17.3817 16.92C16.9626 16.92 16.5608 17.0865 16.2644 17.3828C15.9681 17.6791 15.8017 18.081 15.8017 18.5C15.8017 19.37 16.5117 20.07 17.3817 20.07ZM20.7517 18.5C20.7329 18.7747 20.8534 19.0405 21.0723 19.2075L21.6917 19.68C21.7617 19.75 21.7817 19.87 21.7217 19.97L20.8217 21.53C20.7717 21.63 20.6517 21.67 20.5517 21.63L19.842 21.3448C19.5834 21.241 19.2912 21.2636 19.0516 21.406C18.8235 21.5416 18.6675 21.7715 18.6256 22.0335L18.5017 22.81C18.4817 22.92 18.3917 23 18.2817 23H16.4817C16.3617 23 16.2717 22.92 16.2517 22.81L16.1506 22.0584C16.1134 21.7819 15.9503 21.5382 15.7089 21.3984C15.4706 21.2604 15.1824 21.2389 14.9263 21.34L14.1917 21.63C14.1017 21.67 13.9917 21.63 13.9317 21.53L13.0317 19.97C12.9717 19.87 13.0017 19.75 13.0817 19.68L13.6838 19.211C13.9013 19.0416 14.0204 18.7751 14.0017 18.5C14.0204 18.2249 13.9013 17.9584 13.6838 17.789L13.0817 17.32C13.0017 17.25 12.9717 17.13 13.0317 17.03L13.9317 15.47C13.9917 15.37 14.1017 15.33 14.1917 15.37L14.9149 15.658C15.1719 15.7604 15.4616 15.74 15.7018 15.6028C15.9475 15.4624 16.1138 15.2155 16.1515 14.935L16.2517 14.19C16.2717 14.08 16.3617 14 16.4817 14H18.2817C18.3917 14 18.4817 14.08 18.5017 14.19L18.6256 14.9665C18.6675 15.2285 18.8235 15.4584 19.0516 15.594C19.2912 15.7364 19.5834 15.759 19.842 15.6552L20.5517 15.37C20.6517 15.33 20.7717 15.37 20.8217 15.47L21.7217 17.03C21.7817 17.13 21.7617 17.25 21.6917 17.32L21.0723 17.7925C20.8534 17.9595 20.7329 18.2253 20.7517 18.5Z"
        fill="#6F6F6F"
      />
    </svg>
  );
};
