export const EyeIconOff = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.70711 4.12132C2.31658 3.7308 2.31658 3.09763 2.70711 2.70711C3.09763 2.31658 3.7308 2.31658 4.12132 2.70711L21.2881 19.8738C21.6786 20.2644 21.6786 20.8975 21.2881 21.2881C20.8975 21.6786 20.2644 21.6786 19.8738 21.2881L2.70711 4.12132Z"
        fill="#909090"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.69109 7.10528C4.04828 8.33335 2.7568 10.0284 2 12C3.57273 16.0973 7.45455 19 12 19C13.6265 19 15.168 18.6283 16.5491 17.9633L14.4899 15.9041C13.7746 16.3862 12.919 16.6667 12 16.6667C9.49091 16.6667 7.45455 14.576 7.45455 12C7.45455 11.1004 7.70289 10.26 8.13298 9.54717L5.69109 7.10528ZM9.489 10.9032C9.34973 11.2398 9.27273 11.6105 9.27273 12C9.27273 13.5493 10.4909 14.8 12 14.8C12.4049 14.8 12.7889 14.71 13.1341 14.5482L9.489 10.9032ZM12.0286 9.20015L14.7255 11.897C14.6733 10.4053 13.491 9.21548 12.0286 9.20015ZM16.311 13.4825C16.4631 13.0167 16.5455 12.5182 16.5455 12C16.5455 9.424 14.5091 7.33333 12 7.33333C11.4532 7.33333 10.9288 7.43263 10.4431 7.61462L8.44705 5.61859C9.55898 5.21791 10.7546 5 12 5C16.5455 5 20.4273 7.90267 22 12C21.3668 13.6496 20.3593 15.1056 19.0849 16.2565L16.311 13.4825Z"
        fill="#909090"
      />
    </svg>
  );
};
