/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  BBArrowRightIcon,
  BBBackArrow,
  BbCloseIcon,
  BBForwardArrow,
  BBLock,
  //@ts-ignore
} from '../../../blackbook-components/quarks/icons';
//@ts-ignore
import { Spinner } from '../../../assets/animations/spinner';
//@ts-ignore
import { usePaywall } from '../../../services/hooks/handleRedirectToPaywall';
//@ts-ignore
import { useTrack } from '../../../services/hooks/useTrack';

interface IProps {
  data: any;
  widthToAdjust: number;
  closeFunction: () => void;
  title: string;
  loading: boolean;
}

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -50px;
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const FeatureList = styled.div`
  height: 75%;
  width: 30%;
  background-color: #232323;
  border-radius: 15px;
  border: 1px solid var(--gray-500);
  padding: 10px 0;

  .featureList__content {
    padding: 0 15px 20px 15px;
    height: 100%;
    box-sizing: border-box;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 15px;
      border: 1px solid var(--gray-500);
      border-top: none;
      border-bottom: none;
      border-right: none;
    }

    ::-webkit-scrollbar-thumb {
      background-color: var(--gray-500);
      border: 1px solid var(--gray-500);
      border-bottom: none;
      border-top: none;
      box-shadow: inset 0 0 0 4px #232323;
      border-right: none;
    }
  }
`;

const TitleContainer = styled.div`
  width: 100%;
  padding: 10px 0 20px 0;
  position: sticky;
  top: 0;
  background-color: #232323;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 9;
`;

const Title = styled.p`
  color: white;
  font-weight: 700;
  font-size: 18px;
  white-space: pre-wrap;
  text-align: center;
`;

const BackButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 18px;
  margin-right: 10px;
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 18px;
  right: 0;
  display: flex;
  align-items: center;
`;

const ButtonContainer = styled.button`
  width: 100%;
  border: none;
  border-top: 1px solid white;
  :last-child {
    border-bottom: 1px solid white;
  }
  padding: 10px 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
  cursor: pointer;

  .paid__button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-right: 20px;

    transition: filter 0.2s;
    &:hover {
      filter: brightness(0.9);
    }
  }
`;

const Button = styled.p`
  color: white;
  margin-right: 5px;
  text-align: left;
  flex: 1;
  font-size: 14px;
  div {
    align-items: flex-start;
    span {
      color: white !important;
      text-align: left;
    }
  }
`;

export const FeatureSelectionModal = (props: IProps) => {
  const [contentToShow, setContentToShow] = useState<any>([]);
  const [previousContent, setPreviousContent] = useState<any>([]);
  const [title, setTitle] = useState(props.title);
  const [finalList, setFinalList] = useState(false);
  const [parsing, setParsing] = useState(false);
  const { redirectToPaywall } = usePaywall();
  const { trackEvent } = useTrack();

  const Final = () =>
    !finalList
      ? contentToShow?.map((content: any) => (
          <ButtonContainer
            onClick={(e) => {
              e.stopPropagation();
              setPreviousContent(contentToShow);
              propsParser({ data: content?.subItems, bypass: true });
              const stringToSet = `${title}\n${content.text}`;
              setTitle(stringToSet);
            }}
          >
            <Button>{content.text}</Button>
            <BBArrowRightIcon height="14" width="10" />
          </ButtonContainer>
        ))
      : contentToShow?.map((content: any) => (
          <ButtonContainer
            onClick={() => {
              content.onClick();
            }}
          >
            <Button>{content.text}</Button>
            {content?.isBlocked && (
              <button
                className="paid__button"
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  trackEvent('Clicou no cadeado', {
                    Origem: `Lista de especialidades`,
                    'Tipo da origem': `Home`,
                    Especialidade: content?._parentSpecialty
                      ? content?._parentSpecialty
                      : title,
                    Titulo: content?._routineTitle
                      ? content?._routineTitle
                      : content?.text,
                    track_id: content?._trackID || undefined,
                  });
                  redirectToPaywall();
                }}
              >
                <BBLock />
              </button>
            )}
            <div style={{ zIndex: 2 }}>
              <BBForwardArrow />
            </div>
          </ButtonContainer>
        ));

  function propsParser(propToParse: any) {
    setParsing(true);
    const data = propToParse.data;
    const subItems = propToParse.data.subItems;
    setContentToShow(data);
    if (!subItems && !propToParse.bypass) {
      setFinalList(false);
    } else {
      setFinalList(true);
      if (propToParse.bypass) {
        setParsing(false);
        return;
      }
      setContentToShow(data.subItems);
    }
    setParsing(false);
  }

  useEffect(() => {
    if (!props.loading) {
      const temp = { ...props, data: props.data() };
      propsParser(temp);
    }
  }, [props.loading]);

  return (
    <Container
      onClick={() => {
        props.closeFunction();
        setPreviousContent([]);
        setTitle(props.title);
      }}
      style={{
        paddingRight: `${props.widthToAdjust + 50}px`,
      }}
    >
      <FeatureList
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="featureList__content">
          {props.loading || parsing ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              <Spinner stroke_width="5" />
            </div>
          ) : (
            <>
              <TitleContainer>
                {previousContent.length > 0 && (
                  <BackButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setContentToShow(previousContent);
                      setPreviousContent([]);
                      setTitle(props.title);
                      setFinalList(false);
                    }}
                  >
                    <BBBackArrow color="#8774FF" />
                  </BackButton>
                )}
                <Title>{title}</Title>
                <CloseButton
                  onClick={() => {
                    props.closeFunction();
                    setPreviousContent([]);
                    setTitle(props.title);
                  }}
                >
                  <BbCloseIcon />
                </CloseButton>
              </TitleContainer>
              <Final />
            </>
          )}
        </div>
      </FeatureList>
    </Container>
  );
};
