import { borderRadius, colors, fontFamily, fontSize, spacing } from '../../quarks/theme';
import styled from 'styled-components';

export const ContentBannerStyle = styled.div`
display: flex;
flex-direction: column;
overflow: hidden;

.paid__button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-right: 20px;
    margin-top: 6px;
    position: absolute;
    top: 0;
    right: 0;

    transition: filter 0.2s;
    &:hover {
        filter: brightness(0.9);
    }

    svg >:not(.favorite_icon){
        fill: ${colors.primary};
    }
}

.image {
    height: 180px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.title {
    display: flex;
    flex: 1;
    align-items: end;
    padding: 26px 20px 10px 10px;
    position: relative;
    h2 {
        color: ${colors.black};
        font-size: 18px;
        font-family: ${fontFamily.secondary};
        line-height: 1.3;
        svg {
            margin-right: 5px;
        }
    }
}
`
