import { AccountOverview } from "./style";

export function AccountOverviewComponent({user}) {
  return (
    <AccountOverview>
      <section className="overviewHeader">
      </section>
      <section className="overviewContent">
        <table className="overviewContent__table">
          <tbody className="overviewContent__table">
            <tr className="table__row">
              <th className="table__header">Nome de usuário</th>
              <td className="table__cell">{user.name}</td>
            </tr>
            <tr className="table__row">
              <th className="table__header">Email</th>
              <td className="table__cell">{user.auth.email}</td>
            </tr>
            <tr className="table__row">
              <th className="table__header">Telefone</th>
              <td className="table__cell">{user.phone_number}</td>
            </tr>
          </tbody>
        </table>
          <h2 className="overviewHeader__subtitle">Para editar dados pessoais, acesse pelo aplicativo.</h2>
      </section>
    </AccountOverview>
  );
}
