/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { PlanComponent } from '../../components/molecules/planComponent';
import { Container } from './style';
import DefaultTemplate from '../../components/templates/DefaultTemplate';
import { planApi, promotionApi } from '../../services/clients';
import { getUserToken } from '../../services/tokenService';
import { useHistory, useLocation } from 'react-router-dom';
import { getGiftCardOfLocalStorage } from '../../services/cache/giftCard';
import { getIndicationCoupon } from '../../services/cache/indicationCoupon';
import { usePaywall } from '../../services/hooks/handleRedirectToPaywall';
import { BackArrow } from '../../assets/icons/BackArrow';
import { useTrack } from '../../services/hooks/useTrack';
import { useIsDesktopOrTabletScreen } from '../../services/hooks/useIsDesktopOrTabletScreen';
import { NewDefaultTemplate } from '../../components/templates/NewDefaultTemplate';
import { WhatsAppIcon } from '../../assets/icons/WhatsAppIcon';
import { BBBackArrow } from '../../blackbook-components/quarks/icons';

import doctorBackground from '../../assets/images/background-doctor-1.png';

export function Plans() {
  const { trackEvent } = useTrack();
  const isDesktop = useIsDesktopOrTabletScreen();

  const history = useHistory();

  if (getGiftCardOfLocalStorage()) {
    history.push('/giftcard');
  }

  const [plans, setPlans] = useState(null);

  const getPlans = async () => {
    const response = await planApi.getSuggestedPlans();

    const storedCoupon = getIndicationCoupon();

    if (storedCoupon) {
      try {
        const anualPlan = response.prices.find((p) => p.availablePeriod === 12);

        if (anualPlan) {
          const requestBody = [
            {
              product_type: 'plan',
              product_identify: `${response.store_identify}_${anualPlan.store_identify}`,
              quantity: anualPlan.availablePeriod,
              total_value: anualPlan.totalValue,
              unit_value: anualPlan.valuePerMonth,
            },
          ];

          const res = await promotionApi.validateCoupon(
            storedCoupon.toUpperCase(),
            requestBody
          );
          if (res) {
            history.push(
              `payment/${response?.store_identify}_${anualPlan?.store_identify}`
            );
          }
        }
      } catch (error) {}
    }

    trackEvent('Paywall - Experimente 7 dias grátis', {
      device: isDesktop ? 'large' : 'small',
    });
    return setPlans(response);
  };

  useEffect(() => {
    if (!plans) {
      getPlans();
    }
  }, []);

  const { getBackToContent, itCanGetBackToContent } = usePaywall();

  function handleRedirectToGiftCardPage() {
    history.push('giftcard');
    trackEvent('Paywall - Tenho um gift card', {
      device: isDesktop ? 'large' : 'small',
    });
  }

  function handleBack() {
    itCanGetBackToContent() ? getBackToContent() : history.push('/app')
  }

  return (
    <NewDefaultTemplate backgroundImage={doctorBackground}>
      <Container>
        <div className="paywall__content">
          <div className="content__first-box">
            <div className="header__buttons">
              <button className="gray" onClick={handleBack}>
                <BBBackArrow color="white" />
                acessar conteúdo
              </button>
              {isDesktop && (
                <button
                  onClick={handleRedirectToGiftCardPage}
                  className="purple"
                >
                  tenho um gift card
                </button>
              )}
            </div>
            <h1 className="plans-page__title">
              Seja um assinante
              <br />
              Premium
            </h1>
            <h3 className="plans-page__subtitle">
              <b>O conteúdo mais confiável,</b> sempre ao
              <br />
              seu lado na tomada de decisões clínicas
            </h3>
            <div className="planCardsContainerContent">
              {plans?.prices
                ?.sort((a, b) => b.availablePeriod - a.availablePeriod)
                ?.map((plan) => (
                  <PlanComponent
                    key={plan.name}
                    planParent={{
                      name: plans.name,
                      store_identify: plans.store_identify,
                    }}
                    plan={plan}
                  />
                ))}
            </div>
            {!isDesktop && (
              <button
                onClick={handleRedirectToGiftCardPage}
                className="giftCard__button"
              >
                tenho um gift card
              </button>
            )}
          </div>

          <div className="download-apps__container">
            <a
              href="https://apps.apple.com/br/app/blackbook/id1496811013"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="AppStore"
                src="https://firebasestorage.googleapis.com/v0/b/public-blackbook/o/templates%2Fappstore.png?alt=media"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=br.com.blackbook"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="PlayStore"
                src="https://firebasestorage.googleapis.com/v0/b/public-blackbook/o/templates%2Fplaystore.png?alt=media"
              />
            </a>
            <a
              // onClick={() => handleTrack('Paywall - Whatsapp')}
              href="https://wa.me/5531996887607?text=Olá,%20preciso%20de%20ajuda."
              className="whatsapp_icon"
              target="_blank"
              rel="noreferrer"
            >
              <WhatsAppIcon />
            </a>
          </div>
        </div>
      </Container>
    </NewDefaultTemplate>
  );
}
