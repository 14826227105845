import { createContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTrack } from '../services/hooks/useTrack';

const SignUpStageContext = createContext();

const SignUpStageProvider = ({ children }) => {
  const history = useHistory();
  const { trackEvent } = useTrack();

  const [signUpStage, setSignUpStage] = useState(getSignUpStage() || 0);

  const signUpStageMessages = {
    "-1" : "Cadastro email - Voltar",
    "0" : "Cadastro senha - Voltar",
    "1": "Cadastro nome - Voltar",
    "2": "Cadastro telefone - Voltar",
    "3": "Cadastro ano de formatura - Voltar",
    "4": "Cadastro atuação - Voltar",
    "5": "Cadastro especialidade - Voltar",
    "6": "Cadastro termos de uso - Voltar"
  }

  function getSignUpStage() {
    return parseInt(window.sessionStorage.getItem('signupstage'));
  }

  function goForward() {
    let stage = signUpStage + 1;
    if(stage === 6){
      const actuation_area_id = window.sessionStorage.getItem('actuation_area_id');
      if(actuation_area_id !== "5f7c6e1938d2acd4bf8afec6"){
        stage++;
        window.sessionStorage.removeItem('specialties');
      }
    }
    setSignUpStage(stage);
    window.sessionStorage.setItem('signupstage', stage);
  }

  function reset() {
    setSignUpStage(0);
    window.sessionStorage.setItem('signupstage', 0);
    window.sessionStorage.removeItem('email')
    window.sessionStorage.removeItem('name')
    window.sessionStorage.removeItem('graduationYear')
    window.sessionStorage.removeItem('password')
    window.sessionStorage.removeItem('phone')
    window.sessionStorage.removeItem('country')
    window.sessionStorage.removeItem('country_dial')
    window.sessionStorage.removeItem('actuation_area_id')
    window.sessionStorage.removeItem('specialties')
  }

  function goBack() {
    let stage = signUpStage - 1;

    if(stage === 6){
      const actuation_area_id = window.sessionStorage.getItem('actuation_area_id');
      if(actuation_area_id !== "5f7c6e1938d2acd4bf8afec6"){
        stage--;
        window.sessionStorage.removeItem('specialties');
      }
    }

    trackEvent(signUpStageMessages[stage])
 
    if (stage < 0) {
      return history.goBack();
    }

    setSignUpStage(stage);
    window.sessionStorage.setItem('signupstage', stage);
  }

  const context = { signUpStage, getSignUpStage, goForward, goBack, reset };

  return (
    <>
      <SignUpStageContext.Provider value={context}>
        {children}
      </SignUpStageContext.Provider>
    </>
  );
};

export { SignUpStageProvider, SignUpStageContext };
