import { useState, useRef, useCallback, useEffect } from "react";
import { useHistory } from 'react-router-dom';

import { PopupOptionsComponent } from "../../molecules/popupOptions";
import { UserSectionComponent } from "../../molecules/headerUserSection";
import logo from "../../../assets/images/logo-white2.svg";

import { Header } from "./style";
import { useUser } from "../../../context/useUser";

export function HeaderComponent() {
  const history = useHistory();

  const {activePlan} = useUser()

  const [isOpen, setIsOpen] = useState(false)

  const ref = useRef(null)

  const clickListener = useCallback(
    (e) => {
      if (!(ref.current)?.contains(e.target)) {
        setIsOpen(false);
      }
    },
    [ref.current],
  )

  useEffect(() => {
    if (isOpen) { document.addEventListener('click', clickListener)
    return () => {
      document.removeEventListener('click', clickListener)
    }
  }
  }, [isOpen])

  const togglePopup = () => {
    if (window.innerWidth <= 990) {
      setIsOpen(!isOpen);
    }
  }

  return (
    <Header>
      <div className="headerContainer">
        <img src={logo} alt="" className="headerContainer__logo" onClick={() => history.push('/home')} />
          <UserSectionComponent togglePopup={togglePopup} showPlans={activePlan?.name === 'Free'}/>
          {isOpen ? <PopupOptionsComponent isOpen={isOpen} ref={ref} showPlans={activePlan?.name === 'Free'}/> : null}
        </div>
    </Header>
  );
}
