import { gql } from "@apollo/client";

export const GET_ROUTINE = gql`
  query Query($routineId: String!) {
    routine_populated(routineId: $routineId) {
      _id
      title
      legend
      image
      access_level
      is_blocked
      tabs {
        _id
        title
        html_title
        color
        class_css
        position
        cards {
          _id
          title
          position
          is_show_in_navigation
          color
          class_css
          contents {
            _id
            title
            position
            text
            is_open
            is_show_in_navigation
            component_type
            content_type
            children {
              _id
              title
              position
              text
              is_open
              is_show_in_navigation
              component_type
              content_type
            }
          }
        }
      }
    }
  }
`;
