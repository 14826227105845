import { createContext, useContext, useState } from "react";

const PopupContext = createContext();

export const PopupProvider = ({ children }) => {
  const [popup, setPopup] = useState(null);

  const context = {popup, setPopup}

  return (
    <>
      <PopupContext.Provider value={context}>
        {children}
      </PopupContext.Provider>
    </>
  )
};

export function usePopup() {
  const context = useContext(PopupContext);
  return context;
}
