import _ from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import { Spinner } from '../../../assets/animations/spinner';
import {
  getIndicationCoupon,
  handleRemoveIndicationCouponOfLocalStorage,
} from '../../../services/cache/indicationCoupon';

import { promotionApi } from '../../../services/clients';
import { NewInput } from '../inputs/NewInput';


export function CouponInput({ selectedPlan, setAppliedCoupon, appliedCoupon }) {
  const [coupon, setCoupon] = useState(getIndicationCoupon());
  const [isValid, setIsValid] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [invalidMessage, setInvalidMessage] = useState('');

  const debouceFunction = useCallback(
    _.debounce(async (e) => {
      handleValidateCoupon(e);
    }, 1000),
    []
  );

  async function handleValidateCoupon(e) {
    setInvalidMessage('');
    setIsLoading(true);
    try {
      const requestBody = [
        {
          product_type: 'plan',
          product_identify: `${selectedPlan.store_identify}_${selectedPlan.prices[0].store_identify}`,
          quantity: selectedPlan.prices[0].availablePeriod,
          total_value: selectedPlan.prices[0].totalValue,
          unit_value: selectedPlan.prices[0].valuePerMonth,
        },
      ];

      const response = await promotionApi.validateCoupon(e.toUpperCase(), requestBody);

      setAppliedCoupon(response);
      setIsValid(true);
    } catch (error) {
      setInvalidMessage(error?.response?.data?.message);
      setIsInvalid(true);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (getIndicationCoupon()) {
      handleValidateCoupon(getIndicationCoupon());
      handleRemoveIndicationCouponOfLocalStorage();
    }
  }, []);

  function handleChangeCouponInput(e) {
    setCoupon(e.target.value);
    setAppliedCoupon(null);
    setIsValid(false);
    setIsInvalid(false);
    setInvalidMessage('');
    if (!!e.target.value) {
      debouceFunction(e.target.value);
    }
  }

  return (
    <NewInput
      inputProp={{
        className: 'outline__input',
        type: 'text',
        onChange: (e) => handleChangeCouponInput(e),
        value: coupon,
        isValid: isValid,
        isInvalid: isInvalid,
      }}
      adornment={
        <span>
          {isLoading ? (
            <Spinner height="2rem" />
          ) : (
            (!!isValid || !!isInvalid) &&
            (invalidMessage || appliedCoupon?.coupon_feedback)
          )}
        </span>
      }
    />
  );
}
