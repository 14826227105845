import styled from 'styled-components';
import { colors, spacing } from "../../quarks/theme";

export const PopupButtonContainer = styled.div`
  .buttonContainer {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    min-height: 40px;
    padding-left: ${spacing.medium};
    color: ${({ theme }) => theme.fontColor || "black"};
    font-weight: bold;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 100%;

    p {
      width: 100%;
      padding-left: ${spacing.small};
      margin-bottom: 0 !important;
    }

    background-color: ${({ theme }) => theme.color || colors.darkGray};

    cursor: pointer;
    path {
      fill: ${({ theme }) => theme.fontColor || "black"};
    }
  }

  .iconContainer {
    height: 100%;
    width: 100%;
    max-width: 48px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;