import React from 'react'
import { BbCloseIcon } from '../../../blackbook-components/quarks/icons'
import { ModalContainer } from './style'

export function Modal({ onClose, children, className = '' }) {
  return (
    <ModalContainer className={className} onClick={onClose}>
      <div onClick={(e) => e.stopPropagation()} className="content">
        <button className='close-modal__button' type='button' onClick={onClose}><BbCloseIcon /></button>
        {children}
      </div>
    </ModalContainer>
  )
}
