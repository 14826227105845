import styled from "styled-components";

export const UserSection = styled.div`
  display: flex;

  .userSection__options {
    display: none;
  }

  .userSection__avatar {
    width: 40px;
    height: 40px;
    border-radius: 200px;
    &:hover {
      cursor: pointer;
    }
  }

  @media screen and (min-width: 990px) {
    .userSection__avatar {
      width: 60px;
      height: 60px;
    }
    
    .userSection__options {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-right: 1rem;
    }

    .userSection__optionList {
      display: flex;
      list-style: none;
    }

    .optionList__option {
      font-size: 1.1rem;
      margin-left: 1rem;
      color: white;

      
    }

    .userSection__verticalLine {
      border-left: 4px solid var(--purple-500);
      height: 50px;
      margin-left: 1rem;
    }

    .option__content {
      color: white;
      text-decoration: none;
      cursor: pointer;
      &.active {
        color: var(--yellow-500);
      }
    }
  }
`;
