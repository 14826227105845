import React, { useEffect, useState } from "react";
import { UserAplicationProvider } from "./context/useUser";

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

import { Routers } from "./routes";
import "./styles/global.css";

import TagManager from "react-gtm-module";
import { SignUpStageProvider } from "./context/useSignUpStage";

import { SidebarSpecialtiesProvider } from "./context/useSidebar";
import { PopupProvider } from "./context/usePopup";

import { RefreshToken } from "./services/clients/bffConfig/RefreshToken";
import { getUserToken, handleSetAnonymousToken } from "./services/tokenService";
import { useTrack } from "./services/hooks/useTrack";
import { useIsDesktopOrTabletScreen } from "./services/hooks/useIsDesktopOrTabletScreen";

function App() {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_TAG_MANAGER_ID,
  };
  
  TagManager.initialize(tagManagerArgs);

  const [isTokenLoaded, setIsTokenLoaded] = useState(false)
  const {trackEvent} = useTrack();
  const isDesktop = useIsDesktopOrTabletScreen()

  const handleTokens = () => {
    if(getUserToken()){
      handleRefreshToken()
    } else {
      handleSetAnonymousToken()
    }
    setIsTokenLoaded(true)
  }

  async function handleRefreshToken() {
    try {
      await RefreshToken()
    } catch (error) {
      console.log(error)
    }
  }

  function getOriginToEvent(origin) {
    if(origin.includes('blackbook.com.br') && !origin.includes('app')){
      return 'landing page';
    }
    if(origin.includes('blackbook.com.br')){
      return 'interno';
    }
    if(!origin.includes('blackbook.com.br')) {
      return 'externo';
    }
    return 'indefinido';
  }

  useEffect(() => {
    handleTokens()
    const origin = document.referrer
    console.log('origin',origin)
    if(!sessionStorage.getItem('SESSION_STARTED')){
      sessionStorage.setItem('SESSION_STARTED',true)
      trackEvent('Acessou app web',{
        'origem': getOriginToEvent(origin), 
        'url': origin,
        'device': isDesktop ? 'large' : 'small',
        'site': window.location.href
      })
    }
  }, [])

  if(!isTokenLoaded) {
    return (
      <div>Loading...</div>
    )
  }

  return (
    <UserAplicationProvider>
      <SignUpStageProvider>
        <SidebarSpecialtiesProvider>
          <PopupProvider>
            <Routers />
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable={false}
              pauseOnHover
              style={{ lineHeight: "1.5rem" }}
            />
          </PopupProvider>
        </SidebarSpecialtiesProvider>
      </SignUpStageProvider>
    </UserAplicationProvider>
  );
}

export default App;
