import styled from "styled-components";
import MainTheme from "../../styles/MainTheme";

export const Main = styled.main`
  display: flex;
  flex-direction: row;
  padding: 24px 24px;
  gap: 24px;
  @media screen and (max-width: 1025px) {
    display: grid;
    /* grid-template-rows: minmax(0, 1fr) minmax(0, 1fr); */
    grid-auto-rows: minmax(0,1fr);
    max-height: calc(100vh - 130px);
  }
`;

export const Container = styled.div`
  max-height: calc(100vh - 130px);
  height: 100%;
  width: 50%;
  background-color: ${MainTheme.color.blackBackground};
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1025px) {
    width: 100%;
  }
`;

export const CalculatorContainer = styled.section`
  background-color: ${MainTheme.color.blackSettings};
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 5px;
  gap: 10px;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 130px);
  @media screen and (max-width: 1025px) {
    width: 100%;
  }
  box-sizing: border-box;
`;

export const TitleContainer = styled.div``;

export const CalculatorTitle = styled.h1`
  color: white;
  font-size: 2rem;
  font-weight: 700;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: scroll;
`;

export const ClearButton = styled.button`
  background-color: ${MainTheme.color.primary};
  color: white;
  border: none;
  border-radius: 5px;
  width: 25%;
  align-self: flex-end;
  font-weight: 700;
  cursor: pointer;
`;