import React, { useState } from "react";
import styled from "styled-components";
//@ts-ignore
import giftIcon from "../../../assets/icons/gift.svg";
import { ReferralModal } from "../ReferralModal";
//@ts-ignore
import {useTrack} from "../../../services/hooks/useTrack"
//@ts-ignore
import { useUser } from "../../../context/useUser"

interface IButtonProps {
  isOpen: boolean;
}

const Container = styled.button<IButtonProps>`
  width: ${({ isOpen }) => (isOpen ? "calc(100% + 20px)" : "100%")};
  height: 40px;
  background-color: #fcde17;
  border-radius: 55px;
  ${({ isOpen }) =>
    isOpen && "border-top-right-radius: 0;border-bottom-right-radius: 0;"}
  border: none;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 0 20px;
  z-index: 9999999;
  cursor: pointer;
  transition: all 0.2s;
  :hover {
    img {
      animation-duration: 0.2s;
      animation-name: jump;
      animation-iteration-count: 2;
      animation-direction: alternate;
    }
  }
`;

const Title = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: black;
`;

const Icon = styled.img`
width: 24px;
  @keyframes jump {
    from {
      translate: 0 0;
    }
    to {
      translate: 0 -5px;
    }
  }
`;

export const ReferralButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {trackEvent} = useTrack();
  const { activePlan, indicationInfo } = useUser();

  const originPlansNotAvailable = ['app_store', 'play_store', 'b2b_crmdf']

  return (
    <>
      {indicationInfo?.url && (activePlan?.level_access <= 1 || !originPlansNotAvailable.includes(activePlan?.origin)) ?
        <>
          <ReferralModal indicationInfo={indicationInfo} isOpen={isModalOpen} setIsOpen={() => setIsModalOpen(false)} />
          <Container isOpen={isModalOpen} onClick={() =>{
            !isModalOpen && trackEvent('Clicou em indique e ganhe')
            setIsModalOpen(!isModalOpen)
            }}
            className={isModalOpen ? 'buttonClicked' : ''}>
            <Icon src={giftIcon} />
            <Title>Indique e GANHE</Title>
          </Container>
        </>
        : null
      }
    </>
  );
};
