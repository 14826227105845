import { gql } from "@apollo/client";

export const HANDLE_SEARCH = gql`
  query Search($term: String!) {
    search(term: $term) {
      routines {
        length
        data {
          id
          item_id
          type
          sub_type
          title
          legend
          legend_id
          legend_type
          rank_points
          free_access
          is_blocked
          keywords
          search_keys
          sub_items {
            id
            sub_item_id
            title
            legend
            legend_id
            legend_type
            type
            color
            icon
            keywords
            rank_points
            content_keywords
          }
        }
      }
      drugs {
        length
        data {
          id
          item_id
          type
          sub_type
          title
          legend
          legend_id
          legend_type
          rank_points
          free_access
          is_blocked
          keywords
          search_keys
          sub_items {
            id
            sub_item_id
            title
            legend
            legend_id
            legend_type
            type
            color
            icon
            keywords
            rank_points
            content_keywords
          }
        }
      }
      cid {
        length
        data {
          id
          item_id
          type
          sub_type
          title
          legend
          legend_id
          legend_type
          rank_points
          free_access
          is_blocked
          keywords
          search_keys
          sub_items {
            id
            sub_item_id
            title
            legend
            legend_id
            legend_type
            type
            color
            icon
            keywords
            rank_points
            content_keywords
          }
        }
      }
      calculator {
        length
        data {
          id
          item_id
          type
          sub_type
          title
          legend
          legend_id
          legend_type
          rank_points
          free_access
          is_blocked
          keywords
          search_keys
          sub_items {
            id
            sub_item_id
            title
            legend
            legend_id
            legend_type
            type
            color
            icon
            keywords
            rank_points
            content_keywords
          }
        }
      }
    }
  }
`;