import styled from 'styled-components';

export const RegisterOrganismStyle = styled.div`
    height: 100%;
    display: grid;
    grid-template-rows: 90px 1fr;

   >* {
      padding: 0 10px;
      margin-bottom: 0.5rem;
   }
   
   form {
      display: flex;
      max-height: -webkit-fill-available;
      flex-direction: column;
      justify-content: space-around;
   }

   .nextStepButtonContainer {
      /* margin: 0 15vw 0 0; */
      align-items: center;
      max-width: 1020px;
      margin: 0 auto;
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      padding: 1rem;
      box-sizing: border-box;
      color: white;
      padding-top: 3rem;

      button {
         width: 194px;

      }
   }
`
