import { getUserToken } from "../tokenService";
import { BaseClient } from "./baseClient";

export class PaymentApiClient extends BaseClient {

  constructor() {
    super(process.env.REACT_APP_PAYMENT_API);
  }

  payment(data) {
    return this.postDefault('/payment', data, {headers: {'Authorization': `Bearer ${getUserToken()}`}});
  }

  cancelPlans() {
    return this.post('/payment/cancel/', {}, {headers: {'Authorization': `Bearer ${getUserToken()}`}});
  }

  getUserPayment(paymentId) {
    return this.get(`/payment/user/${paymentId}`, {headers: {'Authorization': `Bearer ${getUserToken()}`}})
  }

  changePaymentMethod(payload) {
    return this.put('/payment/user/method', payload, {headers: {'Authorization': `Bearer ${getUserToken()}`}})
  }

  async userHasPaymentProcessing() {
    const data = await this.get('/payment/has-processing-payment', {headers: {'Authorization': `Bearer ${getUserToken()}`}});
    return data?.has_processing_payment;
  }
}
 
export const paymentApi = new PaymentApiClient();