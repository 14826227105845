import { borderRadius, colors, fontSize, spacing } from '../../quarks/theme';
import styled from 'styled-components';

export const DrugResumeStyle = styled.div`
    font-family: Source Sans Pro, sans-serif;
    font-size: ${fontSize.large}; 
    padding: 10px;
    &>div {
        padding: ${spacing.medium} ${spacing.none};
        
        &:last-child {
            padding-bottom: ${spacing.none};
            border: none;
        }
        &:first-child {
            padding-top: ${spacing.none};
        }
    }

    .resume__separator {
        margin: 0;
        border: 0;
        height: 5px;
        width: 100%;
        background-image: 
            radial-gradient(1px 1px at center, ${colors.black500} 1px, transparent 2px), 
            radial-gradient(1px 1px at center, ${colors.black500} 1px, transparent 2px);
        background-size: 12px 5px;

        &:last-child {
            display: none;
        }
    }

    p {
        line-height: 1.3;
        margin-bottom: ${spacing.small};
    }
    p:last-child {
        margin-bottom: ${spacing.none};
    }
    h4 {
        margin: ${spacing.none};
        padding: ${spacing.none};
        font-weight: normal;
    }
    ul {
        padding-left: ${spacing.medium};
        margin: ${spacing.medium} ${spacing.medium};
        li {
            margin: ${spacing.small} ${spacing.none};
        }
    }
    ul, li {
        &:last-child {
            margin-bottom: ${spacing.none};
        }
        
        &:first-child {
            margin-top: ${spacing.none};
        }
    }
    .brands_list, .prices_list, .icons_list {
        list-style: none;
        padding: ${spacing.none};
        p {
            width: 100%;
            padding: ${spacing.none};
            margin: ${spacing.none};
        }
    }
    .brands_list {
        margin: ${spacing.small} ${spacing.medium} ${spacing.none};
    }
    .prices_list {
        margin: ${spacing.none};
    }
    .icons_list {
        margin: ${spacing.none};
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        li {
            margin: ${spacing.none};
            display: inline-block;
            height: 50px;
            width: 50px;
            border-radius: ${borderRadius.small};
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    
`