import { useState, useRef, useCallback, useEffect } from 'react';
import _ from 'lodash';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import DefaultTemplate from '../../components/templates/DefaultTemplate';
import { NewInput } from '../../components/atoms/inputs/NewInput';
import { giftCardApi, paymentApi } from '../../services/clients';
import {
  getNTimesTried,
  handleSetNTimesTriedOnLocalStorage,
  handleRemoveNTimesTriedOfLocalStorage,
} from '../../services/tokenService';

import { Spinner } from '../../assets/animations/spinner';
import { Container } from './style';
import {
  getGiftCardOfLocalStorage,
  handleRemoveGiftCardOfLocalStorage,
} from '../../services/cache/giftCard';
import { useSidebar } from '../../context/useSidebar';
import { useUser } from '../../context/useUser';
import { useTrack } from '../../services/hooks/useTrack';
import { useIsDesktopOrTabletScreen } from '../../services/hooks/useIsDesktopOrTabletScreen';
import { NewDefaultTemplate } from '../../components/templates/NewDefaultTemplate';
import { WhatsAppIcon } from '../../assets/icons/WhatsAppIcon';

import giftCardBG from '../../assets/images/gift-card-img.png';
import { BBBackArrow } from '../../blackbook-components/quarks/icons';
import { Modal } from '../../components/atoms/Modal';

function encodeTemp(number) {
  return (157 - number) * 93;
}

function decodeTemp(number) {
  return 157 - number / 93;
}

const encodeBase64 = (data) => {
  return encodeTemp(data).toString();
};
const decodeBase64 = (data) => {
  return decodeTemp(parseInt(data));
};

async function isValidateTimesTriedIsMoreThan3() {
  const nTimes = getNTimesTried();
  if (!nTimes) {
    const encryptedNTimes = encodeBase64(1);
    handleSetNTimesTriedOnLocalStorage(encryptedNTimes);
    return false;
  }

  try {
    const decryptedNTimes = decodeBase64(nTimes);

    if (decryptedNTimes >= 3) {
      return true;
    } else {
      const nTimesPlusOne = decryptedNTimes + 1;

      const encryptedNTimes = encodeBase64(nTimesPlusOne);

      handleSetNTimesTriedOnLocalStorage(encryptedNTimes);
      return false;
    }
  } catch (error) {
    console.log(error);
  }
}

export function GiftCard() {
  const debouceFunction = useCallback(
    _.debounce(async (e) => {
      if (await isValidateTimesTriedIsMoreThan3()) {
        setShowCaptcha(true);
        return null;
      }
      handleValidateGiftCard(e);
    }, 1000),
    []
  );
  const captchaRef = useRef();
  const history = useHistory();

  const { fetchUserData, activePlan } = useUser();
  const { fetchSpecialties } = useSidebar();
  const { trackEvent } = useTrack();
  const isDesktop = useIsDesktopOrTabletScreen();

  const [giftCardCode, setGiftCardCode] = useState(null);
  const [showCaptcha, setShowCaptcha] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');

  const [paymentPayload, setPaymentPayload] = useState(null);

  async function handleValidateGiftCard(value) {
    setIsLoading(true);
    trackEvent('Paywall - Inseriu gift card', {
      'Gift card': value,
      device: isDesktop ? 'large' : 'small',
    });
    try {
      const response = await giftCardApi.validateGiftCard(value);
      setIsValid(true);

      setValidationMessage(response?.feedback);
      setPaymentPayload(response?.payment_payload);
      localStorage.setItem(
        'GIFT_TIME',
        response.payment_payload.cart.items[0].quantity
      );
    } catch (error) {
      setIsInvalid(true);
      setValidationMessage(error?.response?.data?.message);
    }

    setIsLoading(false);
  }

  async function handleRedirectToWebApp() {
    await fetchUserData(true);
    await fetchSpecialties();
    history.push('/thanks/giftcard_contratado');
  }

  async function handleBuyWithGiftCard() {
    setIsLoading(true);
    try {
      await paymentApi.payment(paymentPayload);

      await handleRedirectToWebApp();

      toast.success('Ativação feita com sucesso!');
      handleRemoveNTimesTriedOfLocalStorage();
    } catch (error) {
      if (error?.response?.data?.message === 'Pagamento duplicado.') {
        toast.error(
          'Sua última compra está sendo processada. Tente novamente mais tarde.'
        );
      } else {
        toast.error('Ocorreu um erro!');
      }
    }
    setIsLoading(false);
  }

  function handleChangeGiftCardInput(e) {
    if (captchaRef?.current && (isValid || isInvalid)) {
      captchaRef.current.reset();
    }

    setIsInvalid(false);
    setIsValid(false);

    const valueAdapted = e.target.value.toUpperCase();

    setGiftCardCode(valueAdapted);
    if (!showCaptcha) {
      debouceFunction(valueAdapted);
    }
  }

  function handleValidatedCaptcha() {
    handleValidateGiftCard(giftCardCode);
  }

  async function handleValidateIfUserIsB2B() {
    if (activePlan?.origin?.includes('b2b')) {
      setIsModalOpen(true);
    }
  }

  useEffect(() => {
    if (activePlan) {
      handleValidateIfUserIsB2B();
    }
  }, [activePlan]);

  useEffect(() => {
    if (getGiftCardOfLocalStorage()) {
      handleChangeGiftCardInput({
        target: { value: getGiftCardOfLocalStorage() },
      });
      handleRemoveGiftCardOfLocalStorage();
    }
  }, []);

  return (
    <NewDefaultTemplate backgroundImage={giftCardBG}>
      <Container>
        <div className="paywall__content">
          <div className="content__first-box">
            <div className="header__buttons">
              <button className="gray" onClick={() => history.goBack()}>
                <BBBackArrow color="white" />
                {isDesktop ? 'voltar' : ''}
              </button>
            </div>

            <div>
              <h1 className="plans-page__title">Tenho um gift card!</h1>
              <h3 className="plans-page__subtitle">
                <b>O conteúdo mais confiável,</b> sempre ao
                <br />
                seu lado na tomada de decisões clínicas
              </h3>

              <div className="giftCardSection">
                <div style={{ width: '100%' }}>
                  <h3 style={{ marginBottom: '10px' }}>INSIRA SEU GIFT CARD</h3>
                  <NewInput
                    inputProp={{
                      className: 'outline__input',
                      type: 'text',
                      onChange: (e) => handleChangeGiftCardInput(e),
                      value: giftCardCode,
                      isValid: isValid,
                      isInvalid: isInvalid,
                    }}
                    adornment={
                      <span>
                        {isLoading ? (
                          <Spinner height="2rem" />
                        ) : (
                          (!!isValid || !!isInvalid) && validationMessage
                        )}
                      </span>
                    }
                  ></NewInput>
                </div>

                {showCaptcha && (
                  <div style={{ marginTop: '2rem' }}>
                    <ReCAPTCHA
                      ref={captchaRef}
                      sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                      onChange={handleValidatedCaptcha}
                    />
                  </div>
                )}
              </div>
              <button
                disabled={isInvalid || !giftCardCode || isLoading || !isValid}
                onClick={handleBuyWithGiftCard}
                className="giftCardSection__sendButton"
              >
                ASSINAR
              </button>
            </div>
            <p className="footer__text">
              O faturamento começa assim que a avaliação gratuita de{' '}
              <b>7 dias</b> termina. Cancele antes do fim da avaliação e você
              não será cobrado. A assinatura é renovada automaticamente até que
              você faça o cancelamento. <b>Cancele a qualquer momento.</b>{' '}
              Válido para novos assinantes.
            </p>
          </div>

          <div className="download-apps__container">
            <a
              href="https://apps.apple.com/br/app/blackbook/id1496811013"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="AppStore"
                src="https://firebasestorage.googleapis.com/v0/b/public-blackbook/o/templates%2Fappstore.png?alt=media"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=br.com.blackbook"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="PlayStore"
                src="https://firebasestorage.googleapis.com/v0/b/public-blackbook/o/templates%2Fplaystore.png?alt=media"
              />
            </a>
            <a
              // onClick={() => handleTrack('Paywall - Whatsapp')}
              href="https://wa.me/5531996887607?text=Olá,%20preciso%20de%20ajuda."
              className="whatsapp_icon"
              target="_blank"
              rel="noreferrer"
            >
              <WhatsAppIcon />
            </a>
          </div>
        </div>
        {isModalOpen && (
          <Modal className="modal__unauthorized" onClose={() => {history.push('/app')}}>
            <h1>Não é possível resgatar seu GiftCard!</h1>
            <p>Você possui um assinatura através de um dos nossos parceiros.</p>

            <button
              className="btn"
              onClick={() =>
                window.open(
                  'https://wa.me/5531996887607?text=Olá,%20preciso%20de%20ajuda.',
                  '_blank'
                )
              }
            >
              PRECISO DE AJUDA
            </button>
          </Modal>
        )}
      </Container>
    </NewDefaultTemplate>
  );
}
