import { useState, useEffect, useContext } from 'react';

import { RegisterOrganism } from '../../../../components/organisms/registerOrganism';
import { RegisterSelectComponent } from '../../../../components/atoms/registerInput';
import { SignUpStageContext } from '../../../../context/useSignUpStage';
import { useTrack } from '../../../../services/hooks/useTrack';

export function SignUpGraduationYear({ isDesktop = false }) {
  const { goForward } = useContext(SignUpStageContext);
  const { trackEvent } = useTrack();

  const [graduationYear, setGraduationYear] = useState('');
  const [isGraduationYearCompleted, setIsGraduationYearCompleted] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const currentYear = new Date().getFullYear();

  function generateArrayOfYears() {
    const max = currentYear + 9;
    const min = 1970;
    const years = [''];

    for (var i = max; i >= min; i--) {
      years.push(i);
    }
    return years;
  }

  function handleChangeGraduationYear(inputGraduationYear) {
    setGraduationYear(inputGraduationYear);
    setIsGraduationYearCompleted(inputGraduationYear !== '');
  }

  function sendGraduationYearToRegister() {
    if (graduationYear) {
      window.sessionStorage.setItem('graduationYear', graduationYear);
      trackEvent('Cadastro ano de formatura - Próximo', {
        'Ano formatura': graduationYear,
      });
      goForward();
    } else {
      setErrorMessage('Ano de formatura inválido');
    }
  }

  useEffect(() => {
    const value = sessionStorage.getItem('graduationYear');
    if (value) {
      handleChangeGraduationYear(value);
      sessionStorage.removeItem('graduationYear');
    }
  }, []);

  if (isDesktop) {
    return (
      <RegisterSelectComponent
        error={errorMessage}
        description="Se for estudante, informe o ano previsto"
        label="Ano de formatura"
        type="select"
        value={graduationYear}
        options={generateArrayOfYears()}
        onChange={(e) => handleChangeGraduationYear(e.target.value)}
      />
    );
  }

  return (
    <RegisterOrganism
      title="Crie sua conta"
      buttonProps={{
        disabled: !isGraduationYearCompleted,
        className: 'fullWidth',
        type: 'submit',
      }}
      onFinish={() => {
        sendGraduationYearToRegister();
      }}
    >
      <RegisterSelectComponent
        error={errorMessage}
        description="Se for estudante, informe o ano previsto"
        label="Qual é o seu ano de formatura?"
        type="select"
        value={graduationYear}
        options={generateArrayOfYears()}
        onChange={(e) => handleChangeGraduationYear(e.target.value)}
      />
    </RegisterOrganism>
  );
}
