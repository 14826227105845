export const GIFT_CARD_KEY = 'GIFT_CARD'

export const handleSetGiftCardOnLocalStorage = (code) => {
  localStorage.setItem(GIFT_CARD_KEY, code)
}

export const handleRemoveGiftCardOfLocalStorage = () => {
  localStorage.removeItem(GIFT_CARD_KEY);
}

export const getGiftCardOfLocalStorage = () => (localStorage.getItem(GIFT_CARD_KEY) || null);
