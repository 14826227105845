import { useEffect, useRef, useState } from 'react';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input';
import ptBrLabels from 'react-phone-number-input/locale/pt-BR.json';
import flags from 'country-flag-icons/react/3x2';

import { SimpleRegisterInput } from '../../atoms/registerInput';
import { InputContainerStyle } from '../../atoms/registerInput/style';
import { LabelStyle } from '../../atoms/titleComponent/style';
import { PhoneInputAreaStyle, PhoneInputOutlined } from './style';
import { NewInput } from '../../atoms/inputs/NewInput';

function getCountryName(country) {
  return ptBrLabels[country];
}

function getCountriesList() {
  const countries = getCountries().sort((previous, next) =>
    getCountryName(previous).localeCompare(getCountryName(next))
  );
  return countries;
}

export const RegisterPhoneInputComponent = (props) => {
  const countryList = getCountriesList();
  const [isOpen, setIsOpen] = useState(false);

  const changeFlag = (country) => {
    props.setCountryLabel(country);
    setIsOpen(false);
  };

  const handleOnChangePhone = (e) => {
    props.onChange(e.target.value);
    // .replace(/[^\d]/g, ''))
  };

  function handleGetFlag(e) {
    const TagName = flags[e];
    return <TagName width={32} height={24} />;
  }
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  if (props?.styleClass === 'outline') {
    return (
      <PhoneInputOutlined>
          <div ref={ref}>
            <div className="countryDial" onClick={(e) => setIsOpen(!isOpen)}>
              {handleGetFlag(props.countryLabel)}{' '}
              <span>{`+${getCountryCallingCode(props.countryLabel)}`}</span>
            </div>
            <ul className={`${isOpen && 'active'}`}>
              {countryList.map((country) => (
                <li key={country} onClick={(e) => changeFlag(country)}>
                  {handleGetFlag(country)} {getCountryName(country)}{' '}
                  {`+${getCountryCallingCode(country)}`}
                </li>
              ))}
            </ul>
          </div>
          <NewInput
            inputProp={{
              ...props,
              className: 'outline__input default__active',
              isActiveByDefault: true,
              name: 'phone',
              value: props.phone,
              onblur: () => {
                if(props.onBlur){
                  props.onBlur();
                }
              },
              mask: props.countryLabel === 'BR' ? '(99) 99999-9999' : null,
              type: props.countryLabel !== 'BR' ? 'number' : 'text',
              onChange: (e) => handleOnChangePhone(e),
              id: 'phone',
              isInvalid: props?.error,
              helper: props?.error || null,
            }}
          />
      </PhoneInputOutlined>
    );
  }
  return (
    <InputContainerStyle>
      <LabelStyle>{props.label}</LabelStyle>
      <PhoneInputAreaStyle>
        <div ref={ref}>
          <div className="countryDial" onClick={(e) => setIsOpen(!isOpen)}>
            {handleGetFlag(props.countryLabel)}{' '}
            <span>{`+${getCountryCallingCode(props.countryLabel)}`}</span>
          </div>
          <ul className={`${isOpen && 'active'}`}>
            {countryList.map((country) => (
              <li onClick={(e) => changeFlag(country)}>
                {handleGetFlag(country)} {getCountryName(country)}{' '}
                {`+${getCountryCallingCode(country)}`}
              </li>
            ))}
          </ul>
        </div>
        <SimpleRegisterInput
          {...props}
          value={props.phone}
          mask={props.countryLabel === 'BR' ? '(99) 99999-9999' : null}
          type={props.countryLabel !== 'BR' && 'number'}
          onChange={(e) => handleOnChangePhone(e)}
        />
      </PhoneInputAreaStyle>
    </InputContainerStyle>
  );
};
