import styled from 'styled-components';


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 680px;
  margin: 0 auto;
  width: 100%;

  .userDetails__container{
    padding: 1rem;
    background-color: var(--gray-700);
    height: fit-content;
    box-shadow: 0 9px 12px rgba(0, 0, 0, 0.3);
  }


  .container__header {
    text-align: center;
    margin: 1rem;
    font-weight: bold;
    color: var(--white);

    line-height: 1.5;
  }
  h2 {
    font-size: 14px;
  }
  h1 {
    font-size: 20px;
  }
  
  @media screen and (min-width: 680px) {
    .userDetails__container {
      margin-bottom: 1rem;
      margin-top: 1rem;
      border-radius: 5px;
    }
  }
`;

export const NavigateToAppButton = styled.button`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  border: none;
  border-radius: 100px;
  padding: 10px;
  min-height: 40px;
  background-color: var(--green-base);
  color: white;
  cursor: pointer;
  display: flex;
    justify-content: center;
    align-items: center;

  font-weight: 700;
  font-family: 'Open Sans', sans-serif;

  transition: all 0.2s;

  &:hover {
    filter: brightness(0.9);
  }

`
