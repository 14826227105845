import styled from 'styled-components';

import { colors, spacing} from "../../../../blackbook-components/quarks/theme"

export const DownloadTheAppContainerStyle = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  height: calc(100vh - 50px);
  width: 100%;
  
  display: ${({isOpen}) => isOpen ? "flex" : "none"};
  justify-content: flex-start;
  align-items: flex-start;

  .downloadApp__container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;

    width: 380px;
    max-width: 380px;
    min-width: 380px;

    margin: 10px 160px 10px 250px;
    max-height: calc(100% - 50px);
    box-sizing: border-box;
    
    background-color: ${colors.black150};

    padding: 60px 30px;
    overflow-x: hidden;
    overflow-y: auto;

    .title {
      color: ${colors.secondary};
      text-align: center;
      font-size: 23px;
      font-family: 'Open Sans', sans-serif;
      font-weight: 700;
      line-height: 30px;
    }

    .qrCode__img {
      max-height: 151px;
      max-width: 151px;
      align-self: center;
    }

    ul {
      color: white;
      padding: inherit;
      font-size: 21px;
      line-height: 28px;
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 400;
      padding-top: 0;
      padding-bottom: 0;
    }
    
    .store__container {
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      color: #999;
      align-self: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      .store__buttons {
        display: flex;
        gap: 20px;

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #303030;
          border-radius: 55px;
          padding: 0 5px;
        }
      }
    }


    

    .default__container {
      display: flex;
      justify-content: center;

      color: white;
    }
  }
`;
