/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { UserContextAplication } from '../../context/useUser';
import { planApi } from '../../services/clients/plan';
import { paymentApi } from '../../services/clients';

import { CreditCardPaymentComponent } from '../../components/atoms/CreditCardPaymentComponent';
import { ButtonComponent } from '../../components/atoms/buttonComponent';

import { Container } from './style';
import { handleRemoveIndicationCouponOfLocalStorage } from '../../services/cache/indicationCoupon';
import { Spinner } from '../../assets/animations/spinner';
import { useSidebar } from '../../context/useSidebar';
import { useTrack } from '../../services/hooks/useTrack';
import { useIsDesktopOrTabletScreen } from '../../services/hooks/useIsDesktopOrTabletScreen';
import { WhatsAppIcon } from '../../assets/icons/WhatsAppIcon';
import { NewDefaultTemplate } from '../../components/templates/NewDefaultTemplate';

import docImage from '../../assets/images/background-doctor-1.png';
import { BBBackArrow } from '../../blackbook-components/quarks/icons';
import { CouponInput } from '../../components/atoms/CouponInput';

const calcPrice = (value) => {
  return (value / 100).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
};

export function Payment() {
  window.scrollTo(0, 0);
  const { trackEvent } = useTrack();
  const { userProperties, fetchUserData, activePlan } = useContext(
    UserContextAplication
  );
  const { fetchSpecialties } = useSidebar();
  const isDesktop = useIsDesktopOrTabletScreen();

  const history = useHistory();

  const [selectedPlan, setSelectedPlan] = useState(null);
  const [appliedCoupon, setAppliedCoupon] = useState(null);
  const [loading, setLoading] = useState(false);
  const planIdentifier = useParams().name;

  async function getPlanByIdentifier() {
    try {
      const response = await planApi.getPlanByIdentifier(planIdentifier);
      setSelectedPlan(response);
    } catch (error) {
      console.log(error);
    }
  }

  function handleGetPlansInfo() {
    getPlanByIdentifier();
  }

  function handleValidateIfUserIsAbleToBuyThisPlan() {
    if (loading) {
      return;
    }

    if (activePlan?.name === 'Free' || !activePlan) {
      return;
    }

    if (
      activePlan?.price?.availablePeriod >
      selectedPlan?.prices[0]?.availablePeriod
    ) {
      toast.warning('Você já possui um plano superior ao selecionado');
      history.push('/app');
      return;
    }

    if (
      activePlan?.price?.availablePeriod ===
      selectedPlan?.prices[0]?.availablePeriod
    ) {
      toast.warning('Você já possui o plano selecionado');
      history.push('/app');
      return;
    }
  }

  useEffect(() => {
    handleValidateIfUserIsAbleToBuyThisPlan();
  }, [activePlan]);

  useEffect(() => {
    handleGetPlansInfo();
  }, []);

  async function handleRedirectToWebApp() {
    await fetchUserData(true);
    await fetchSpecialties();
    history.push(
      `/thanks/${selectedPlan?.prices[0]?.name?.toLowerCase()}_contratado`
    );
  }

  async function handleSubmitPayment(paymentMethodInfos) {
    setLoading(true);
    const payload = {
      origin: 'web',
      payment_info: {
        methods: [paymentMethodInfos],
        original_value: selectedPlan?.prices[0].totalValue,
        payed_value:
          appliedCoupon?.payed_value || selectedPlan?.prices[0].totalValue,
        discount_value: appliedCoupon?.discount_value || 0,
        indicator_code: userProperties?.indicator_code,
        applied_coupon_token: appliedCoupon?.applied_coupon_token,
      },
      cart: {
        total_value: selectedPlan.prices[0].totalValue,
        items: [
          {
            product_type: 'plan',
            product_identify: `${selectedPlan.store_identify}_${selectedPlan.prices[0].store_identify}`,
            quantity: selectedPlan.prices[0].availablePeriod,
            total_value: selectedPlan.prices[0].totalValue,
            unit_value: selectedPlan.prices[0].valuePerMonth,
          },
        ],
      },
    };

    try {
      await paymentApi.payment(payload);

      await handleRedirectToWebApp();

      toast.success('Assinatura enviada com sucesso!');
      handleRemoveIndicationCouponOfLocalStorage();
    } catch (error) {
      trackEvent('Paywall - Erro de pagamento', {
        'status de erro': error?.response?.status,
        device: isDesktop ? 'large' : 'small',
      });
      if (error?.response?.status === 422) {
        toast.error(
          'Não foi possível validar este cartão. Tente outros dados ou fale conosco.'
        );
      } else {
        toast.error(
          'Ocorreu um erro no pagamento! Tente novamente mais tarde.'
        );
      }
      setLoading(false);
    }
  }

  return (
    <NewDefaultTemplate backgroundImage={docImage}>
      <Container>
        <div className="paywall__content">
          <div className="content__first-box">
            <div className="header__buttons">
              <button className="gray" onClick={() => history.push("/plans")}>
                <BBBackArrow color="white" />
                {isDesktop ? "voltar" : ""}
              </button>
            </div>

            <div>
              {!isDesktop && !!selectedPlan && (
                <div className="subscript__price">
                  <h2 className="title">
                    Plano {selectedPlan?.prices[0]?.name?.toUpperCase()}
                  </h2>
                  <div className="price__container">
                    <h1 className="price">
                      {calcPrice(
                        appliedCoupon
                          ? appliedCoupon?.payed_value
                          : selectedPlan?.prices[0]?.totalValue
                      )}
                    </h1>
                    <div className="price__description">
                      {selectedPlan?.prices[0]?.availablePeriod > 1 ? (
                        <span>
                          em até {selectedPlan?.prices[0]?.availablePeriod}{" "}
                          vezes.
                        </span>
                      ) : (
                        <span>por mês.</span>
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div className="giftCardSection">
                <div style={{ width: "100%" }}>
                  <h3 style={{ marginBottom: "10px" }}>
                    Possui algum cupom de desconto? Insira abaixo
                  </h3>
                  {!!selectedPlan && (
                    <CouponInput
                      selectedPlan={selectedPlan}
                      appliedCoupon={appliedCoupon}
                      setAppliedCoupon={setAppliedCoupon}
                    />
                  )}
                </div>
              </div>
              <div className="giftCardSection" style={{ marginTop: "20px" }}>
                <CreditCardPaymentComponent
                  maxInstallments={selectedPlan?.prices[0].availablePeriod}
                  selectedPlan={selectedPlan}
                  handleSubmitPayment={(e) => handleSubmitPayment(e)}
                  planPrice={
                    appliedCoupon
                      ? appliedCoupon.payed_value
                      : selectedPlan?.prices[0].totalValue
                  }
                />
              </div>

              <div className="subscript__container">
                {isDesktop && !!selectedPlan && (
                  <div className="subscript__price">
                    <h2 className="title">
                      Plano {selectedPlan?.prices[0]?.name?.toUpperCase()}
                    </h2>
                    <div className="price__container">
                      <h1 className="price">
                        {calcPrice(
                          appliedCoupon
                            ? appliedCoupon?.payed_value
                            : selectedPlan?.prices[0]?.totalValue
                        )}
                      </h1>
                      <div className="price__description">
                        {selectedPlan?.prices[0]?.availablePeriod > 1 ? (
                          <span>
                            em até {selectedPlan?.prices[0]?.availablePeriod}{" "}
                            vezes.
                          </span>
                        ) : (
                          <span>por mês.</span>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                <ButtonComponent
                  buttonProp={{
                    form: "payment-form",
                    className: "paymentBox__button",
                    type: "submit",
                    disabled: loading && loading,
                  }}
                >
                  {loading ? (
                    <Spinner height="0.75rem" stroke_width="5" />
                  ) : (
                    "ASSINAR"
                  )}
                </ButtonComponent>
              </div>
            </div>

            <p className="footer__text">
              O faturamento começa assim que a avaliação gratuita de{" "}
              <b>7 dias</b> termina. Cancele antes do fim da avaliação e você
              não será cobrado. A assinatura é renovada automaticamente até que
              você faça o cancelamento. <b>Cancele a qualquer momento.</b>{" "}
              Válido para novos assinantes.
            </p>
          </div>

          <div className="download-apps__container">
            <a
              href="https://apps.apple.com/br/app/blackbook/id1496811013"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="AppStore"
                src="https://firebasestorage.googleapis.com/v0/b/public-blackbook/o/templates%2Fappstore.png?alt=media"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=br.com.blackbook"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="PlayStore"
                src="https://firebasestorage.googleapis.com/v0/b/public-blackbook/o/templates%2Fplaystore.png?alt=media"
              />
            </a>
            <a
              // onClick={() => handleTrack('Paywall - Whatsapp')}
              href="https://wa.me/5531996887607?text=Olá,%20preciso%20de%20ajuda."
              className="whatsapp_icon"
              target="_blank"
              rel="noreferrer"
            >
              <WhatsAppIcon />
            </a>
          </div>
        </div>
      </Container>
    </NewDefaultTemplate>
  );
}
