import styled from "styled-components";

export const Popup = styled.div`
  position: absolute;
  border-radius: 5px;
  right: 0;
  left: auto;
  top: 48px;
  background-color: black;
  line-height: 1rem;
  padding-top: 1rem;
  width: 130px;
  margin-top: 10px;
  margin-right: 13px;
  border: 1px solid #999999;
  box-shadow: 0 9px 12px rgba(0, 0, 0, 0.3);

  .popupArrow{
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    
    border-bottom: 5px solid #999999;
    position: absolute;
    right: 17px;
    top: -6px;
  }
  
  .popupOptions {
    padding: 0 10px;
  }

  .popupOptions__option {
    text-align: left;
    margin-bottom: 1rem;
    padding: 0 1rem 1rem 0;
    border-bottom: 1px solid #999999;

    &:last-child {
      border: none;
      margin-bottom: 0;
    }
  }
  .option__content {
    color: #8774FF;
    font-weight: bold;
    font-size: 0.9rem;
    text-decoration: none;
    cursor: pointer;
  }

  @media screen and (min-width: 680px) {
    right: auto;
    top: 70px;
    margin-left: 540px;
  }

  @media screen and ( max-width:710px) and (min-width:681px) {
    top: 50px;
    right: 0;
  }

  @media screen and (max-width: 990px) and (min-width:711px) {
    top: 50px;
    margin-left: 550px;
  }
`;