import React from 'react';
import styled from 'styled-components';
//@ts-ignore
import MainTheme from '../../../styles/MainTheme';

const CIDAccordionContainer = styled.div`
  background-color: transparent;
  padding: 15px;
  border: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom: 1px solid #999;
`;

const Title = styled.h3`
  color: ${MainTheme.color.grayFont};
  font-weight: bold;
  font-size: 18px;
  text-align: left;
`;

const SubTitle = styled.h4`
  color: ${MainTheme.color.grayFont};
  font-size: 16px;
  text-align: left;
`;

export const CidAccordion = ({ data }: { data: any }) => {
  console.log(data)
  return (
    <CIDAccordionContainer>
      <SubTitle>{data.title}</SubTitle>
      <Title>{data.subTitle}</Title>
      <SubTitle>{data.legend}</SubTitle>
    </CIDAccordionContainer>
  );
};
