import PropTypes from 'prop-types';
import { RoutineContentsTemplates } from '../routine-content';
import { AccordionContainer } from './style';

export const AccordionContainerComponent = (props) => {
  return (
    <AccordionContainer backgroundColor={props.backgroundColor}>
      {props.body?.map((child) => {
        return (
          <RoutineContentsTemplates
            className={props.className}
            content={child}
            class_css={props.color}
            isChildren={true}
          />
        );
      })}
    </AccordionContainer>
  );
};

AccordionContainerComponent.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  body: PropTypes.shape({
    title: PropTypes.string,
  }),
};
