import styled from 'styled-components';
import { colors, fontFamily, fontSize, spacing, routineColorSelector } from '../../quarks/theme';

export const RoutineCardStyle = styled.div`
   font-family: ${fontFamily.secondary};
   color: ${colors.black};
   background: ${props => routineColorSelector(props.color).soft};
   padding: 0 ${spacing.xlarge} ${spacing.xlarge};
   padding-bottom: ${props => props.isPaid ? spacing.none : ""};
   margin: 10px 30px;
   
   border-radius: 10px;
   transition: padding 0.2s, border-radius 0.2s;
   position: relative;

   &.active {
      border-radius: 0;
      padding: ${spacing.xlarge} 50px;

   }

   .card__title {
      top: ${props => props.isResumeCard ? 0 : '30px'};
      z-index: 40;
      position: sticky;
      background-color: ${props => routineColorSelector(props.color).soft};
      display: ${props => props.isPaid && "flex"};
      justify-content: space-between;
      align-items: center;

      padding-top: ${props => props.isPaid? spacing.mlarge : spacing.xlarge};
      padding-bottom: ${props => props.isPaid? spacing.mlarge : spacing.medium};
      margin: 0;
      font-size: ${fontSize.xlarge};
      line-height: 1.3;
      font-family: ${fontFamily.primary};
      font-weight: 700;
      border-bottom: solid ${props => props.isPaid ? "0" : "1px"} ${props => routineColorSelector(props.color).dark};
      svg >:not(.favorite_icon){
         min-width: 40px;
         fill: ${colors.primary};
      }

      .paid__button {
         display: flex;
         padding: 0; 
         background-color: transparent;
         border: none;
         cursor: pointer;

         transition: filter 0.2s;
         &:hover {
            filter: brightness(0.9);
         }
      }
   }

   .card__body {
      padding-top: 10px;
      line-height: 1.3;

      p:not(:last-child) {
         margin-bottom: 10px;
      }

      p:first-child>img{
         margin-top: 5px;
      }

      ul, ol {
         margin: 10px 0 10px 20px;

         &:last-child, :first-child {
            margin-bottom: 0;
            margin-top: 0;
         }
      }
   }

   .accordion {
      margin-bottom: ${spacing.medium};
   }

   
`
