import logoFull from '../../../assets/images/logo_full.svg';
import { SignInPage } from './style';
import {
  RegisterSelectComponent,
  RegisterSelectMultipleSpecialtiesComponent,
} from '../../../components/atoms/registerInput';
import { useRef, useState } from 'react';
import { SignUpPhone } from '../../SignUp/Steps/SignUpPhone';
import { CRMInput } from '../../../components/atoms/inputs/CRMInput';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { NewInput } from '../../../components/atoms/inputs/NewInput';
import { BackButtonComponent } from '../../../components/atoms/backButtonComponent';
import { EyeIconOn } from '../../../assets/icons/EyeIconOn';
import { EyeIconOff } from '../../../assets/icons/EyeIconOff';
// import ReCAPTCHA from 'react-google-recaptcha';
import _ from 'lodash';
import { Spinner } from '../../../assets/animations/spinner';
import { useB2B } from '../../../services/hooks/useB2B';
import { Modal } from '../../../components/atoms/Modal';
import {
  handleSetLoginToken,
  getAnonymousToken,
} from '../../../services/tokenService';
import { UseTermsTextBox } from '../../SignUp/Steps/UseTerms/UseTermsTextBox';
import { useTrack } from '../../../services/hooks/useTrack';
import { authApi } from '../../../services/clients';
import { toast } from 'react-toastify';

const currentYear = new Date().getFullYear();
function generateArrayOfYears() {
  const max = currentYear;
  const min = 1970;
  const years = [];

  for (var i = max; i >= min; i--) {
    years.push(i);
  }
  return years;
}

export function CRMDF() {
  const history = useHistory();
  const { trackEvent } = useTrack();

  // const captchaRef = useRef();

  const { handleLoginB2B, handleValidateB2B, handleRegisterB2B } = useB2B();

  const [user, setUser] = useState({
    name: '',
    phone: '',
    country_dial: '',
    graduation_year: '2024',
    actuation_area: '',
    UF: 'DF',
    email: '',
  });

  const [errors, setErrors] = useState({
    name: null,
    phone: null,
    country_dial: null,
    graduation_year: null,
    actuation_area: null,
  });

  const [signupStage, setSignupStage] = useState('landing');
  const [isLoading, setIsLoading] = useState(false);
  const [isUserUnableToPromotion, setIsUserUnableToPromotion] = useState(false);
  const [isUseTermsModalOpen, setIsUseTermsModalOpen] = useState(false);
  const [isFinishFlowOpen, setIsFinishFlowOpen] = useState(false);
  const [recoveryPasswordSended, setRecoveryPasswordSended] = useState(false);

  const [isFieldHide, setIsFieldHide] = useState({
    password: true,
    confirm_password: true,
  });

  function handleChangeUser(field, value) {
    setUser({ ...user, [field]: value });
    setErrors({ ...errors, [field]: null });
  }

  // const [isLandingBtnDisabled, setIsLandingBtnDisabled] = useState(true);

  //  href="http://localhost:3000/partner/crm-df"  referrerpolicy="origin"
  // o link para acessar o blackbook tera que ter o referrerpolicy origin para funcionar
  // if que valida se o usuário veio de um site com .org ou se usou o bypass
  // if (
  //   !document.referrer.includes('org') &&
  //   !window.location.href.includes('bbbypass')
  // ) {
  //   history.push('/app');
  //   return null;
  // }

  // function handleValidatedCaptcha() {
  //   trackEvent('Validou captcha', {
  //     site: 'app.blackbook/partner',
  //     origem: 'crm-df',
  //     etapa: 'validacao',
  //   });
  //   setIsLandingBtnDisabled(false);
  // }

  async function handleValidateFields(fields) {
    let res = {};

    const fieldOtions = {
      name: () => {
        var regexp =
          /[a-zA-ZÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ-áàâãéèêíïóôõöúçñ]+\s+[a-zA-ZÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ-áàâãéèêíïóôõöúçñ]+/g;
        if (!regexp.test(user.name)) {
          res = { ...res, name: 'Nome inválido!' };
        }
      },
      phone: () => {
        if (user.phone === '') {
          res = { ...res, phone: 'Telefone inválido!' };
        }
      },
      country_dial: () => null,
      graduation_year: () => {
        if (user.graduation_year === '') {
          res = { ...res, graduation_year: 'Ano inválido!' };
        }
      },
      actuation_area: () => {
        if (user.actuation_area === '') {
          res = { ...res, actuation_area: 'Área de atuação inválida!' };
        }
      },
      email: async () => {
        try {
          const regx = new RegExp(/^\S+@\S+\.\S+$/gm);
          const test = regx.test(user.email);
          if (!test) throw Error;
          return null;
        } catch (error) {
          res = { ...res, email: 'E-mail inválido.' };
        }
      },
      confirm_email: () => {
        if (user.email !== user.confirm_email) {
          res = { ...res, confirm_email: 'Emails devem ser iguais.' };
        }
      },
      password: () => {
        if (user?.password?.length < 6 || !user?.password) {
          res = { ...res, password: 'Senha menor que 6 caracteres.' };
        }
      },
      confirm_password: () => {
        if (user.password !== user.confirm_password) {
          res = { ...res, confirm_password: 'Senhas devem ser iguais.' };
        }
      },
    };

    const temp = await Promise.all(fields?.map((e) => fieldOtions[e]()));

    return res;
  }

  async function sendErroInputEvents(errors, step) {
    const promises = [];

    Object.keys(errors).forEach((e) =>
      promises.push(
        trackEvent(`Erro - Dados informados incorretos`, {
          site: 'app.blackbook/partner',
          origem: 'crm-df',
          etapa: step,
          erro: errors[e],
        })
      )
    );

    await Promise.all(promises);
    return;
  }

  async function handleFormByStage(step, btn_text) {
    trackEvent(`Clicou em ${btn_text}`, {
      site: 'app.blackbook/partner',
      origem: 'crm-df',
      etapa: step,
    });
    const options = {
      signup: async () => {
        const validate = await handleValidateFields([
          'name',
          'phone',
          'country_dial',
          'graduation_year',
          'password',
          'confirm_password',
        ]);

        if (!_.isEmpty(validate)) {
          setErrors(validate);
          await sendErroInputEvents(validate, step);
          return;
        }

        try {
          let a = JSON.stringify({
            regional_council: {
              code: user.crm,
              uf: user.UF,
              type: 'crm',
            },
            email: user.email,
            name: user.name,
            country_dial: user.country_dial,
            indicator_code: 'crmdf',
            phone_number: user.phone,
            actuation_area_id: '5f7c6e1938d2acd4bf8afec6', // fixo em médico
            graduation_year: user.graduation_year,
            anonymousToken: getAnonymousToken(),
            password: user.password,
            specialties: user?.specialties || [],
          });

          const res = await handleRegisterB2B(a);

          trackEvent(`Finalizou o cadastro`, {
            site: 'app.blackbook/partner',
            origem: 'crm-df',
            etapa: 'cadastro',
          });

          await handleSetLoginToken(res.token);
          localStorage.setItem('BBTIME', new Date().getTime());

          setIsFinishFlowOpen(true);

          setTimeout(() => {
            history.push('/app');
          }, 5000);
        } catch (error) {}
      },
      landing: async () => {
        const validate = await handleValidateFields(['email', 'confirm_email']);
        if (!_.isEmpty(validate)) {
          setErrors(validate);
          await sendErroInputEvents(validate, step);
          return;
        }

        try {
          let a = JSON.stringify({
            regional_council: {
              code: user.crm,
              uf: user.UF,
              type: 'crm',
            },
            email: user.email,
          });

          const res = await handleValidateB2B(a);

          trackEvent(`Concluiu a validação com a API`, {
            site: 'app.blackbook/partner',
            origem: 'crm-df',
            isEnabledToPromotion: res?.isEnabledToPromotion,
            isUserExists: res?.isUserExists,
          });

          if (!res.isEnabledToPromotion) {
            trackEvent(`Mostrou alerta de validação na promoção`, {
              site: 'app.blackbook/partner',
              origem: 'crm-df',
            });
            return setIsUserUnableToPromotion(true);
          }

          if (res.isUserExists) {
            return setSignupStage('login');
          }
          setSignupStage('signup');
        } catch (error) {}
      },

      login: async () => {
        const validate = await handleValidateFields(['password']);
        if (!_.isEmpty(validate)) {
          setErrors(validate);
          await sendErroInputEvents(validate, step);
          return;
        }

        try {
          let a = JSON.stringify({
            regional_council: {
              code: user.crm,
              uf: user.UF,
              type: 'crm',
            },
            email: user.email,
            indicator_code: 'crmdf',
            password: user.password,
            anonymousToken: getAnonymousToken(),
            specialties: user?.specialties || [],
          });

          const res = await handleLoginB2B(a);

          trackEvent(`Finalizou o login`, {
            site: 'app.blackbook/partner',
            origem: 'crm-df',
            etapa: 'login',
          });

          await handleSetLoginToken(res.token);
          localStorage.setItem('BBTIME', new Date().getTime());

          setIsFinishFlowOpen(true);

          setTimeout(() => {
            history.push('/app');
          }, 5000);
        } catch (error) {
          setErrors({ password: 'Dados informados incorretos' });
          trackEvent(`Erro - Dados informados incorretos`, {
            site: 'app.blackbook/partner',
            origem: 'crm-df',
            etapa: step,
            erro: 'Email ou Senha incorretos (api)',
          });
        }
      },
    };

    setIsLoading(true);
    await options[signupStage]();
    setIsLoading(false);
  }

  async function handleSubmitRecoveryPassword() {
    try {
      await authApi.recoveryPassword(user.email);
      toast.success('E-mail de recuperar senha enviado!');
      setRecoveryPasswordSended(true)
    } catch (error) {
      toast.error('Ocorreu um erro!');
      return error;
    }
  }

  const stages = {
    landing: (
      <div className="register">
        <h1 className="page__title">Vamos lá! Preencha os campos a seguir:</h1>
        <div className="form">
          <NewInput
            inputProp={{
              id: 'email',
              className: 'outline__input default__active',
              label: 'Email',
              type: 'email',
              helper: errors?.email || 'Digite um email válido',
              value: user?.email,
              onblur: (e) => {
                trackEvent('Preencheu email', {
                  email: user?.email,
                  site: 'app.blackbook/partner',
                  origem: 'crm-df',
                  etapa: 'validacao',
                });
              },
              onChange: (e) => {
                errors?.email && setErrors({ ...errors, email: undefined });
                handleChangeUser('email', e.target.value);
              },
              isInvalid: errors?.email,
            }}
          />
          <NewInput
            inputProp={{
              id: 'confirm_email',
              className: 'outline__input default__active',
              label: 'Confirmar email',
              type: 'email',
              value: user?.confirm_email,
              helper: errors?.confirm_email || null,
              onblur: (e) => {
                trackEvent('Preencheu confirmar email', {
                  email: user?.confirm_email,
                  site: 'app.blackbook/partner',
                  origem: 'crm-df',
                  etapa: 'validacao',
                });
              },
              onChange: (e) => {
                errors?.confirm_email &&
                  setErrors({ ...errors, confirm_email: undefined });
                handleChangeUser('confirm_email', e.target.value);
              },
              isInvalid: errors?.confirm_email,
            }}
          />

          <CRMInput
            crm={user?.crm}
            UF={user?.UF}
            onBlur={() => {
              trackEvent('Preencheu crm', {
                crm: user?.crm,
                uf: user?.UF,
                site: 'app.blackbook/partner',
                origem: 'crm-df',
                etapa: 'validacao',
              });
            }}
            setCrm={(e) => handleChangeUser('crm', e)}
            setUF={(e) => handleChangeUser('UF', e)}
          />
        </div>

        {/* <div className="captcha__container" style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
          <ReCAPTCHA
            size="compact"
            ref={captchaRef}
            sitekey={process.env.REACT_APP_CAPTCHA_KEY}
            onChange={handleValidatedCaptcha}
            onExpired={() => setIsLandingBtnDisabled(true)}
            type='image'
            badge='inline'
            
          />
        </div> */}

        <button
          disabled={isLoading}
          className="next__btn"
          onClick={() => handleFormByStage('validacao', 'proximo')}
        >
          {isLoading ? (
            <Spinner height="0.75rem" stroke_width="10px" />
          ) : (
            'PRÓXIMO'
          )}
        </button>
        <div className="helpers__container">
          <p>
            Já resgatou? Acesse por{' '}
            <button
              onClick={() => {
                trackEvent('Clicou em "Acesse por AQUI"', {
                  site: 'app.blackbook/partner',
                  origem: 'crm-df',
                  etapa: 'validacao',
                });
                history.push('/app');
              }}
            >
              AQUI!
            </button>
          </p>
          <button
            onClick={() => {
              trackEvent('Clicou em preciso de ajuda', {
                site: 'app.blackbook/partner',
                origem: 'crm-df',
                etapa: 'validacao',
              });
              window.open(
                'https://wa.me/5531996887607?text=Olá,%20preciso%20de%20ajuda.',
                '_blank'
              );
            }}
          >
            PRECISO DE AJUDA
          </button>
        </div>
      </div>
    ),
    login: (
      <div className="register">
        <BackButtonComponent
          className="back__btn"
          onClick={() => {
            trackEvent('Clicou em voltar', {
              site: 'app.blackbook/partner',
              origem: 'crm-df',
              etapa: 'login',
            });

            setSignupStage('landing');
          }}
        />
        <h1 className="page__title">Agora, entre em sua conta Blackbook:</h1>
        <div className="form">
          <NewInput
            inputProp={{
              id: 'email',
              className: 'outline__input default__active',
              label: 'Email',
              onblur: (e) => {
                trackEvent('Preencheu email', {
                  email: user?.email,
                  site: 'app.blackbook/partner',
                  origem: 'crm-df',
                  etapa: 'login',
                });
              },
              type: 'text',
              value: user.email,
            }}
          />

          <NewInput
            inputProp={{
              className: 'outline__input default__active',
              label: 'Senha',
              type: isFieldHide['password'] ? 'password' : 'text',
              name: 'password',
              value: user?.password,
              helper: errors?.password || null,
              onblur: (e) => {
                trackEvent('Preencheu senha', {
                  site: 'app.blackbook/partner',
                  origem: 'crm-df',
                  etapa: 'login',
                });
              },
              onChange: (e) => {
                errors?.password &&
                  setErrors({ ...errors, password: undefined });
                handleChangeUser('password', e.target.value);
              },
              isInvalid: errors?.password,
            }}
            adornment={isFieldHide['password'] ? <EyeIconOn /> : <EyeIconOff />}
            handleAdornmentClick={() =>
              setIsFieldHide({
                ...isFieldHide,
                password: !isFieldHide['password'],
              })
            }
          />

          <RegisterSelectMultipleSpecialtiesComponent
            id="specialties"
            styleClass="outline"
            label="Especialidades"
            value={user.specialties}
            onChange={(e) => {
              handleChangeUser('specialties', e);
              trackEvent('Preencheu especialidade', {
                ano: user?.specalties,
                site: 'app.blackbook/partner',
                origem: 'crm-df',
                etapa: 'cadastro',
              });
            }}
          />
          <button
            className="loginForm__anchor"
            onClick={() => {
              handleSubmitRecoveryPassword();
              trackEvent('Clicou em esqueceu a senha', {
                site: 'app.blackbook/partner',
                origem: 'crm-df',
                etapa: 'login',
              });
            }}
          >
            Esqueceu a senha?
          </button>

          {recoveryPasswordSended && (
            <h3 style={{color: "var(--green-base)", alignSelf: 'flex-end', fontWeight: '700'}}>Email de recuperação de senha enviado!</h3>
          )}
        </div>

        <button
          disabled={isLoading}
          className="next__btn"
          onClick={() => handleFormByStage('login', 'entrar')}
        >
          {isLoading ? (
            <Spinner height="0.75rem" stroke_width="10px" />
          ) : (
            'ENTRAR'
          )}
        </button>
      </div>
    ),
    signup: (
      <div className="register">
        <BackButtonComponent
          className="back__btn"
          onClick={() => {
            trackEvent('Clicou em voltar', {
              site: 'app.blackbook/partner',
              origem: 'crm-df',
              etapa: 'login',
            });
            setSignupStage('landing');
          }}
        />
        <h1 className="page__title">Agora, crie seus dados de acesso:</h1>
        <div className="form">
          <NewInput
            inputProp={{
              autoComplete: 'off',
              className: 'outline__input default__active',
              label: 'Email',
              type: 'email',
              onblur: (e) => {
                trackEvent('Preencheu email', {
                  email: user?.email,
                  site: 'app.blackbook/partner',
                  origem: 'crm-df',
                  etapa: 'cadastro',
                });
              },
              value: user?.email,
              disabled: true,
              isInvalid: errors?.email,
              id: 'email',
            }}
          />
          <NewInput
            inputProp={{
              className: 'outline__input default__active',
              helper: 'Digite nome e sobrenome',
              label: 'Nome completo',
              type: 'text',
              value: user.name,
              onblur: (e) => {
                trackEvent('Preencheu nome', {
                  nome: user?.name,
                  site: 'app.blackbook/partner',
                  origem: 'crm-df',
                  etapa: 'cadastro',
                });
              },
              onChange: (e) => handleChangeUser('name', e.target.value),
              isInvalid: errors?.name,
              id: 'name',
            }}
          />

          <SignUpPhone
            styleClass="outline"
            isDesktop={true}
            desktopCallback={(country_dial, phone) => {
              setErrors({ ...errors, phone: null, country_dial: null });
              setUser({ ...user, phone: phone, country_dial: country_dial });
            }}
            desktopErrorMessage={errors?.phone}
            phoneValue={user?.phone}
            onBlur={() => {
              trackEvent('Preencheu telefone', {
                telefone: user?.phone,
                site: 'app.blackbook/partner',
                origem: 'crm-df',
                etapa: 'cadastro',
              });
            }}
          />

          <RegisterSelectComponent
            defaultValue="2024"
            id="year"
            styleClass="outline"
            error={errors?.graduation_year}
            description="Se for estudante, informe o ano previsto"
            label="Ano de formatura"
            type="select"
            value={user.graduation_year}
            options={generateArrayOfYears()}
            onChange={(e) => {
              handleChangeUser('graduation_year', e.target.value);
              trackEvent('Preencheu ano de formatura', {
                ano: user?.graduation_year,
                site: 'app.blackbook/partner',
                origem: 'crm-df',
                etapa: 'cadastro',
              });
            }}
          />

          <RegisterSelectMultipleSpecialtiesComponent
            id="specialties"
            styleClass="outline"
            label="Especialidades"
            value={user.specialties}
            onChange={(e) => {
              handleChangeUser('specialties', e);
              trackEvent('Preencheu especialidade', {
                ano: user?.specalties,
                site: 'app.blackbook/partner',
                origem: 'crm-df',
                etapa: 'cadastro',
              });
            }}
          />

          <NewInput
            inputProp={{
              id: 'password',
              className: 'outline__input default__active',
              label: 'Senha',
              type: isFieldHide['password'] ? 'password' : 'text',
              name: 'password',
              value: user?.password,
              helper: errors?.password || null,
              onblur: (e) => {
                trackEvent('Preencheu senha', {
                  site: 'app.blackbook/partner',
                  origem: 'crm-df',
                  etapa: 'cadastro',
                });
              },
              onChange: (e) => {
                errors?.password &&
                  setErrors({ ...errors, password: undefined });
                handleChangeUser('password', e.target.value);
              },
              isInvalid: errors?.password,
              min: 6,
            }}
            adornment={isFieldHide['password'] ? <EyeIconOn /> : <EyeIconOff />}
            handleAdornmentClick={() =>
              setIsFieldHide({
                ...isFieldHide,
                password: !isFieldHide['password'],
              })
            }
          />

          <NewInput
            inputProp={{
              id: 'confirm_password',
              className: 'outline__input default__active',
              label: 'Confirmar senha',
              type: isFieldHide['confirm_password'] ? 'password' : 'text',
              name: 'confirm_password',
              value: user?.confirm_password,
              helper: errors?.confirm_password || null,
              onblur: (e) => {
                trackEvent('Preencheu confirmar senha', {
                  site: 'app.blackbook/partner',
                  origem: 'crm-df',
                  etapa: 'cadastro',
                });
              },
              onChange: (e) => {
                errors?.confirm_password &&
                  setErrors({ ...errors, confirm_password: undefined });
                handleChangeUser('confirm_password', e.target.value);
              },
              isInvalid: errors?.confirm_password,
            }}
            adornment={
              isFieldHide['confirm_password'] ? <EyeIconOn /> : <EyeIconOff />
            }
            handleAdornmentClick={() =>
              setIsFieldHide({
                ...isFieldHide,
                confirm_password: !isFieldHide['confirm_password'],
              })
            }
          />
        </div>
        <h5 className="use-terms__container">
          Ao prosseguir, você concorda com as{' '}
          <button type="button" onClick={() => setIsUseTermsModalOpen(true)}>
            Políticas e Diretrizes da Blackbook.
          </button>
          {isUseTermsModalOpen && (
            <Modal
              className="useAndTerms__modal"
              onClose={() => setIsUseTermsModalOpen(false)}
            >
              <UseTermsTextBox />
            </Modal>
          )}
        </h5>

        <button
          disabled={isLoading}
          className="next__btn"
          onClick={() => handleFormByStage('cadastro', 'cadastrar')}
        >
          {isLoading ? (
            <Spinner height="0.75rem" stroke_width="10px" />
          ) : (
            'CADASTRAR'
          )}
        </button>
      </div>
    ),
  };

  return (
    <SignInPage>
      <div className="campaign__container">
        <div className="logo__img">
          <img src={logoFull} alt="Blackbook" />
        </div>

        <div className="campaign__text">
          <p className="description show">
            O <span>CRM-DF</span> disponibiliza especialmente para você o acesso
            Premium ao app <span>Blackbook</span> – a ferramenta que vai
            facilitar as suas decisões clínicas.
          </p>
          <p className="description">
            São conteúdos de Medicina baseada em evidências científicas, com
            rotinas e condutas abrangendo diversas especialidades. Além disso,
            há uma base de medicamentos com comparativo de princípios ativos,
            doses e indicações, disponibilidade no SUS, entre outras informações
            relevantes.
          </p>
          <p className="description">
            O que você encontra no Blackbook:
            <ul>
              <li>
                Conteúdo escrito e revisado por especialistas, a maioria pHD,
                com experiência acadêmica e prática. São informações confiáveis,
                isentas e práticas;
              </li>
              <li>
                Funcionalidades para agilizar seu dia a dia, como as
                calculadoras, Cid-10, Meu Blackbook, busca otimizada e outras;
              </li>
              <li>
                Acesso offline pelo celular: todo conteúdo fica disponível no
                app para você consultar mesmo sem internet;
              </li>
              <li>
                Blackbook web com todo o conteúdo do app para acessar em
                multiplataformas.
              </li>
            </ul>
          </p>
        </div>
      </div>
      {stages[signupStage]}

      {isUserUnableToPromotion && (
        <Modal
          onClose={() => {
            trackEvent('Fechou o alerta de validação', {
              site: 'app.blackbook/partner',
              origem: 'crm-df',
              etapa: 'alerta de validacao',
            });
            setIsUserUnableToPromotion(false);
          }}
        >
          <h1>ATENÇÃO</h1>
          <p>
            Ocorreu um erro ao resgatar o benefício. Em caso de dúvida entre em
            contato com o suporte.
          </p>
          <p>
            Já resgatou? Acesse por{' '}
            <button
              className="linkToSignin"
              onClick={() => {
                trackEvent('Clicou em "Acesse por AQUI"', {
                  site: 'app.blackbook/partner',
                  origem: 'crm-df',
                  etapa: 'validacao',
                });
                history.push('/app');
              }}
            >
              {' AQUI!'}
            </button>
          </p>
          <button
            className="btn"
            onClick={() => {
              trackEvent('Clicou em preciso de ajuda', {
                site: 'app.blackbook/partner',
                origem: 'crm-df',
                etapa: 'alerta de validacao',
              });
              window.open(
                'https://wa.me/5531996887607?text=Olá,%20preciso%20de%20ajuda.',
                '_blank'
              );
            }}
          >
            PRECISO DE AJUDA
          </button>
        </Modal>
      )}

      {isFinishFlowOpen && (
        <Modal className="success" onClose={() => history.push('/app')}>
          <h1>PARABÉNS</h1>
          <p>Seu benefício foi resgatado com sucesso!</p>

          <div class="round-time-bar">
            <div></div>
          </div>
        </Modal>
      )}
    </SignInPage>
  );
}
