import { useContext } from 'react';
import { useHistory } from 'react-router';

import { NewButtonComponent } from '../../../../components/atoms/buttonComponent';
import { ContainerStyle } from '../../../../components/atoms/containerComponent/style';
import {
  SubTitleComponent,
  TitleComponent,
} from '../../../../components/atoms/titleComponent';
import { SignUpStageContext } from '../../../../context/useSignUpStage';
import { getGiftCardOfLocalStorage } from '../../../../services/cache/giftCard';
import { getIndicationCoupon } from '../../../../services/cache/indicationCoupon';
import { ConfirmationIcon } from '../../../../assets/icons/ConfirmationIcon';

import { ThanksForSignUpStyle } from './style';
import { useIsDesktopOrTabletScreen } from '../../../../services/hooks/useIsDesktopOrTabletScreen';
import { useTrack } from '../../../../services/hooks/useTrack';

export const ThanksForSignUp = (props) => {
  const { reset } = useContext(SignUpStageContext);
  const { trackEvent } = useTrack();
  const isDesktopOrLaptop = useIsDesktopOrTabletScreen()
  const history = useHistory();

  if (getGiftCardOfLocalStorage() || getIndicationCoupon()) {
    history.push('/home');
    reset()
  } else if (isDesktopOrLaptop) {
    history.push('/app');
    reset()
  }

  return (
    <ContainerStyle>
      <ThanksForSignUpStyle>
        <ConfirmationIcon />
        <TitleComponent className="center">
          Obrigado por se
          <br />
          cadastrar!
        </TitleComponent>
        <SubTitleComponent>
          Experimente 7 dias grátis na
          <br />
          sua primeira assinatura!
        </SubTitleComponent>

        <div className="buttonArea">
          <NewButtonComponent
            onClick={() => {
              reset();
              trackEvent('Cadastro concluido - Iniciar teste grátis',{'device':'small','site':'app.blackbook/signup'})
              history.push('/home');
            }}
          >
            Iniciar teste grátis
          </NewButtonComponent>
          <NewButtonComponent
            onClick={() => {
              reset();
              trackEvent('Cadastro concluido - Baixar o app', {'device':'small','site':'app.blackbook/signup'})
              history.push('/download');
            }}
          >
            Baixar o app
          </NewButtonComponent>
        </div>

        <p className="thanksPage__footer">
          Em poucos instantes você poderá navegar pelo conteúdo, realizar buscas
          otimizadas e acessar o app sem precisar se contectar à internet!
        </p>
      </ThanksForSignUpStyle>
    </ContainerStyle>
  );
};
