import styled from 'styled-components';
import { colors, routineColorSelector, fontFamily, fontSize, spacing } from '../../quarks/theme';

export const BreadCrumbStyle = styled.div`
   text-align: right;
   padding: ${spacing.medium} ${spacing.large};
   background-color: ${props => props.color ? routineColorSelector(props.color).dark : colors.black150};
   color: ${colors.white};
   font-family: ${fontFamily.secondary};
   font-style: normal;
   font-weight: 900;
   font-size: ${fontSize.medium};
   line-height: ${fontSize.large};
   max-width: 100%;
`
