import styled from 'styled-components';

import { borderRadius, colors, spacing} from "../../../../blackbook-components/quarks/theme"

export const SearchContainerStyle = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  height: calc(100vh - 50px);
  width: 100%;
  

  
  display: ${({isOpen}) => isOpen ? "flex" : "none"};
  justify-content: flex-start;
  align-items: flex-start;

  .searchResult__container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: ${spacing.medium};

    width: 380px;
    max-width: 380px;
    min-width: 380px;

    margin: 10px 160px 10px 250px;
    height: calc(100% - 96px);
    
    background-color: ${colors.black150};

    padding: 20px;
    padding-right: 5px;
    overflow-y: scroll;

    .close__button {
      position: absolute;
      top: -5px;
      right: -5px;

      border: none;
      background: transparent;
      padding: 0;
      cursor: pointer;

      transition: filter 0.2s;
      &:hover {
        filter: brightness(0.8);
      }
    }

    .default__container {
      display: flex;
      justify-content: center;

      color: white;
    }
  }
`;
