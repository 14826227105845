import styled from "styled-components";
import { borderRadius, colors, fontSize, spacing} from "../../blackbook-components/quarks/theme"

export const ContentWrapper = styled.section`
  height: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;

  .content {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    width: 100%;
    z-index: 10;
  }

  .contentSidebar {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    z-index: 30;
  }

  .breadCrumb {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    position: sticky;
    top: ${spacing.none};
    z-index: 20;
  }

  .essentials__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .extracontent__accordion {
    padding: 0 !important; 
    margin: 0;
    margin-bottom: 10px;
    border-bottom: none !important;
    color: white;
    .accordion_content {
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin: 0 !important;
      border: solid ${colors.hardGreen};
      border-width: 0 5px 5px 5px;
      margin-top: -10px !important;
    }

    .sub_accordion {
      top: 80px !important;
      z-index: 5 !important;
    }
  }

  .brandsSection {
    padding: ${spacing.none};
    margin: ${spacing.none} ${spacing.medium};

    &:first-child {
      padding-top: 1px;
    }

    &.essentials {
      display: flex;
      padding-top: 10px;
    }

    &.generics {
      display: flex;
      align-items: flex-start;
    }

    :last-child {
      border-bottom: none;
    }

    small {
      margin-left: ${spacing.small};
      text-indent: ${spacing.none};
    }

    p {
      margin-bottom: 0;
    }
  }

  .brandsSection__header {
    display: flex;
    align-items: flex-start;
    margin-bottom: ${spacing.small};
    &.generics {
      flex-direction: column;
    }
  }

  .brandsSection__icon {
    margin-right: ${spacing.small};
    margin-bottom: ${spacing.medium};
    border-radius: ${borderRadius.small};
    max-height: 48px;
  }

  .brandsSection__title {
    font-weight: bold;

    &.generics {
      margin-bottom: ${spacing.small};
    }
  }

  .brandsSection__presentation {
    padding-left: ${spacing.medium};
    margin-bottom: ${spacing.none};
    line-height: 1.55;

    :last-child {
      padding-bottom: ${spacing.small};
    }
  }

  .doubt {
    display: flex;
    color: rgba(0, 0, 0, 0.5);

    padding: ${spacing.none} ${spacing.medium} ${spacing.medium};

    /* :last-child {
      padding-bottom: ${spacing.medium};
    } */

    :first-child {
      padding-top: ${spacing.medium};
    }
  }

  .doubt__title {
    font-weight: bold;
  }

  .doubt__lab {
    padding-left: ${spacing.small};
    vertical-align: super;
    font-size: 11px;
    font-style: italic;
  }

  .discontinued {
    color: rgba(0, 0, 0, 0.5);
    font-weight: bold;
    padding: ${spacing.medium} ${spacing.medium} ${spacing.none};

    :last-child {
      padding-bottom: ${spacing.medium};
    }
  }

  .dolarSign {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.26);

    .active_dolarSign {
      font-weight: 700;
      color: black;
    }
  }

  .grandchild {
    .accordion_content {
      margin: ${spacing.none} ${spacing.large};
    }
  }

  .default__separator {
    margin: 0;
    margin-left: -4px;
    border: 0;
    height: 5px;
    width: 100%;
    background-size: 12px 5px;
    &.dosage {
      background-image: 
        radial-gradient(1px 1px at center, ${colors.hardGreen} 1px, transparent 2px), 
        radial-gradient(1px 1px at center, ${colors.hardGreen} 1px, transparent 2px);
    }
    &.special_situation {
      background-image: 
      radial-gradient(1px 1px at center, ${colors.hardYellow} 1px, transparent 2px), 
      radial-gradient(1px 1px at center, ${colors.hardYellow} 1px, transparent 2px);
      &.with__icon {
        margin-top: ${spacing.medium};
      }
    }
    &.brand {
      background-image: 
      radial-gradient(1px 1px at center, ${colors.hardBlue} 1px, transparent 2px), 
      radial-gradient(1px 1px at center, ${colors.hardBlue} 1px, transparent 2px);
      padding: 0 10px;
      box-sizing: border-box;
      background-clip: content-box;
    }

    &:last-child {
        display: none;
    }
  }

  .content__style {
    padding: 10px;

    &:not(:last-child) {
      padding-bottom: ${spacing.medium} !important;
    }

    p {
      margin-bottom: ${spacing.small};
      font-size: ${fontSize.large};
    }

    p:last-child{
      margin-bottom: ${spacing.none};
    }

    p,
    li,
    span {
      line-height: 1.3;
    }

    ol {
      list-style: decimal outside;
    }

    ul {
      list-style: disc outside;
    }

    ul > li,
    ol > li {
      margin-left: 16px;
    }

    ul > ul > li {
      padding-left: 20px;
    }

    ul:last-child {
      margin-bottom: 5px;
    }

    ul > ul > li:last-child {
      padding-bottom: 5px;
    }

    ul > li > ul > li {
      list-style-type: circle;
    }

    ul > li:last-child {
      margin-bottom: 0;
    }

    p:not(li > p) {
      text-indent: -10px;
      margin-left: 10px;
    }

    &.dosageContent {
      padding: ${spacing.medium} ${spacing.none};
      padding-bottom: ${spacing.none};
    }

    &.specialContent {
      padding: ${spacing.small} ${spacing.none};
      padding-bottom: ${spacing.small};

      &.iconContent {
        margin: ${spacing.none} ${spacing.medium};
        padding-bottom: ${spacing.none};
      }
    }

    &.contraIndications {
      background-color: ${colors.softOrange};
    }
    
  }

  .special{
    display: flex;
    flex-direction: column;
    padding: 10px;
    
    .special__header {
      display: flex;
      align-items: center;
  
      p{
        margin-bottom: 0;
        font-size: ${fontSize.large};
        line-height: 1.55;
        text-indent: 0;
        margin-left: 0;
      }
  
      .special__icon {
        margin-right: ${spacing.medium};
        border-radius: ${borderRadius.small};
      }
    }
  }


  .extraContent{
    width: 100%;
    display: flex;
    flex-direction: column; 
    align-items: center;
    padding: ${spacing.none};
  }
  
  .extraContent__image {
    padding: ${spacing.medium} ${spacing.none};
    display: block;
    max-width: 320px;
    max-height: 239px;
    width: 100%;
    height: auto;
  }
`;
