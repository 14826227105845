export function CidIconSimple({size = 20}) {
  return (
    <svg
      width={"auto"}
      height={size}
      viewBox="0 0 85 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="14" y="78" width="53" height="9" fill="#999999" />
      <rect x="26" y="66" width="41" height="9" fill="#999999" />
      <rect x="14" y="66" width="9" height="9" fill="#999999" />
      <rect x="14" y="54" width="9" height="9" fill="#999999" />
      <rect x="26" y="54" width="41" height="9" fill="#999999" />
      <rect x="26" y="42" width="41" height="9" fill="#999999" />
      <rect x="14" y="42" width="9" height="9" fill="#999999" />
      <path
        d="M74.375 91.8H10.625V10.2H47.8125V35.7H74.375V91.8H85V30.6L53.125 0H10.625C7.80708 0 5.10456 1.07464 3.11199 2.98751C1.11942 4.90038 0 7.49479 0 10.2V91.8C0 94.5052 1.11942 97.0996 3.11199 99.0125C5.10456 100.925 7.80708 102 10.625 102H74.375H85V91.8H74.375Z"
        fill="#6F6F6F"
      />
      <rect x="23" y="16" width="5" height="20" fill="#999999" />
      <rect
        x="35.4851"
        y="23.441"
        width="5"
        height="20"
        transform="rotate(89.6621 35.4851 23.441)"
        fill="#999999"
      />
    </svg>
  );
}
