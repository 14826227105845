import styled from "styled-components";
import { colors } from "../../quarks/theme";

export const Container = styled.div`
  background: #333;
  width: 100%;
  max-width: 680px;
  margin: 10px auto;
  overflow-x: scroll;

  .historyTable__title {
    text-align: left;
    padding: 1rem;
    color: white;
    font-size: 1.25rem;
    font-weight: bold;
    border-bottom: 1px solid ${colors.secondary};
    @media screen and (max-width: 550px) {
      position: sticky;
      left: 0;
    }
  }

  .historyTable {
    width: 100%;
    row-gap: 10px;

    @media screen and (max-width: 550px) {
      min-width: 550px;
    }
  }

  .historyTable__header {
    th {
      color: white;
      text-align: center;
      vertical-align: middle;
      padding: 10px;
      font-weight: bold;
      border-right: 1px solid ${colors.gray};
      &:last-child {
        border-right: none;
      }
    }
  }
  .historyTable__body {
  }
  .historyTable__row {
    border-top: 1px solid ${colors.gray};
    td {
      color: white;
      text-align: center;
      vertical-align: middle;
      padding: 10px;
      border-right: 1px solid ${colors.gray};
      &:last-child {
        border-right: none;
      }
    }
  }
`;