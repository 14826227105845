import styled from 'styled-components';

export const DesktopSignupContainer = styled.div`
  height: 100vh;
  background-color: #121212;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 20px;
  box-sizing: border-box;
  padding: 1rem 0;
  overflow-y: auto;
  overflow-x: hidden;

  .desktop-header__title {
    width: 100%;
    max-width: 1020px;
    /* text-align: center; */
    font-size: 1.5rem;
    color: white;
    font-weight: 700;
    margin: 0 auto;
    padding-left: 1rem;
    margin-bottom: 1rem;
  }

  .steps__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 3rem;
    gap: 3rem;
    width: 100%;
    max-width: 1020px;
    margin: 0 auto;
  }

  .use-terms__container {
    text-align: center;
    width: 100%;

    color: white;

    font-size: 0.75rem;

    button {
      font-family: inherit;
      background-color: transparent;
      border: none;
      color: white;
      margin: 0;
      padding: 0;
      text-decoration: underline;
      text-decoration-color: var(--purple-500);
      cursor: pointer;

      &:hover {
        text-decoration-color: var(--yellow-500);
      }
    }
  }

  @media screen and (max-width: 900px) {
    
    .steps__container {
      grid-template-columns : 1fr;
    }
  }
`;
