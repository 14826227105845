import JwtDecode from "./jwtDecode";
import uuid from "react-uuid"

export const TOKEN_KEY = 'BLACKBOOK_USER'
export const UPDATE_USER_TOKEN_KEY = 'TEMPORARY_BB_KEY'
const N_TIMES_TRIED = 'QWET#GGRER@$67867'
const ANONYMOUS_TOKEN = 'ANONYMOUS_TOKEN'

export const isAuthenticated = () => {
  const token = JwtDecode();
  if (!token) {
    return false;
  }
  // var dateNow = new Date();
  // if (token.exp < dateNow.getTime() / 1000) {
  //   return false;
  // }
  return true;
};

export const handleSetAnonymousToken = async () => {
  if(getAnonymousToken()){
    return;
  }
  return localStorage.setItem(ANONYMOUS_TOKEN, uuid())
}

export const handleSetLoginToken = async (token) => {
  localStorage.removeItem(ANONYMOUS_TOKEN);
  return localStorage.setItem(TOKEN_KEY, token)
}

export const handleRemoveLocalStorage = async () => {
  await localStorage.removeItem(TOKEN_KEY);
}

export const handleSetTemporaryTokenOnLocalStorage = (token) => {
  localStorage.setItem(UPDATE_USER_TOKEN_KEY, token)
}

export const handleRemoveTemporaryTokenOfLocalStorage = () => {
  localStorage.removeItem(UPDATE_USER_TOKEN_KEY);
}

export const handleSetNTimesTriedOnLocalStorage = (times) => {
  localStorage.setItem(N_TIMES_TRIED, times)
}
export const handleRemoveNTimesTriedOfLocalStorage = () => {
  localStorage.removeItem(N_TIMES_TRIED);
}

export const clearUserTokensOfLocalStorage = () => {
  handleRemoveLocalStorage()
  handleRemoveTemporaryTokenOfLocalStorage()
  localStorage.removeItem('BBTIME');
}

export const getToken = () => localStorage.getItem(UPDATE_USER_TOKEN_KEY) || (localStorage.getItem(TOKEN_KEY) || null);

export const getUserToken = () => (localStorage.getItem(TOKEN_KEY) || null);

export const getTemporaryUserToken = () => localStorage.getItem(UPDATE_USER_TOKEN_KEY);

export const getAnonymousToken = () => localStorage.getItem(ANONYMOUS_TOKEN) || null

export const getNTimesTried = () => localStorage.getItem(N_TIMES_TRIED)