//@ts-ignore
import { useHistory } from "react-router-dom";

interface IProps {
  type: "routine" | "drug" | "calculator";
  origin: string;
  mainId?: string;
  secondaryId?: string;
  contentId?: string;
  calculatorName?: string;
}

export default function useCalcLinks() {
  const history = useHistory();
  const handleNav = (props: IProps) => {
    if (props.type === "routine") {
      history.push(
        `/app/routines/${props.mainId}${
          props.secondaryId !== undefined ? "/" + props.secondaryId : ""
        }${props.contentId !== undefined ? "/" + props.contentId : ""}`
      );
    } else if (props.type === "drug") {
      history.push(
        `/app/drugs/${props.mainId}${
          props.secondaryId !== undefined ? "/" + props.secondaryId : ""
        }${props.contentId !== undefined ? "/" + props.contentId : ""}`
      );
    } else if (props.type === "calculator") {
      history.push(`/app/calculators/calc_${props.calculatorName}`);
    }
  };
  return handleNav;
}
