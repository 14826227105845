import { InputAreaStyle } from "./style";

export const InputComponent = props => (
    <InputAreaStyle>
        <input
            {...props}
        />
        <div onClick={props.onClickIcon} className="icon">
            {props.icon}
        </div>
    </InputAreaStyle>
);
