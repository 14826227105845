import { useState, useEffect, useContext } from 'react';

import { PasswordRegisterInputComponent } from '../../../../components/atoms/registerInput';
import { RegisterOrganism } from '../../../../components/organisms/registerOrganism';
import { SignUpStageContext } from '../../../../context/useSignUpStage';
import { useTrack } from '../../../../services/hooks/useTrack';

export function SignUpPassword({ isDesktop = false }) {
  const { goForward } = useContext(SignUpStageContext);
  const { trackEvent } = useTrack();

  const [password, setPassword] = useState('');
  const [isPasswordCompleted, setIsPasswordCompleted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const MIN_PASSWORD_LENGTH = 6;

  function handleChangePassword(inputPassword) {
    setPassword(inputPassword);
    setIsPasswordCompleted(inputPassword.length >= MIN_PASSWORD_LENGTH);
  }

  function sendPasswordToRegister() {
    if (password) {
      window.sessionStorage.setItem('password', password);
      trackEvent('Cadastro senha - Próximo');
      goForward();
    } else {
      setErrorMessage('Senha inválida');
    }
  }

  useEffect(() => {
    const value = sessionStorage.getItem('password');
    if (value) {
      handleChangePassword(value);
      sessionStorage.removeItem('password');
    }
  }, []);

  if (isDesktop) {
    return (
      <PasswordRegisterInputComponent
        autoFocus
        value={password}
        onChange={(e) => handleChangePassword(e.target.value)}
        error={errorMessage}
        min={MIN_PASSWORD_LENGTH}
      />
    );
  }
  return (
    <RegisterOrganism
      title="Crie sua conta"
      buttonProps={{
        disabled: !isPasswordCompleted,
        className: 'fullWidth',
        type: 'submit',
      }}
      onFinish={() => {
        sendPasswordToRegister();
      }}
    >
      <PasswordRegisterInputComponent
        autoFocus
        value={password}
        onChange={(e) => handleChangePassword(e.target.value)}
        error={errorMessage}
        min={MIN_PASSWORD_LENGTH}
      />
    </RegisterOrganism>
  );
}
