import { getUserToken } from "../tokenService";
import { BaseClient } from "./baseClient";

class TrackAPIClient extends BaseClient {
  constructor() {
    super(process.env.REACT_APP_TRACK_API);
  }

  async sendEvent(title, params) {
    const body = {
      event:{
        title,
        parameters: {
          time: new Date().getTime(),
          ...params
        }
      }
    }
    return this.postDefault(`/track/user`, body, {
      headers: {
        "Authorization": `Bearer ${getUserToken()}`
      }
    });
  }
}

export const trackApi = new TrackAPIClient();
