/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { FeatureButton } from "../../atoms/FeatureButton";
//@ts-ignore
import { ClinicHomeIcon } from "../../../assets/icons/ClinicHomeIcon";
//@ts-ignore
import { PediatricsHomeIcon } from "../../../assets/icons/PediatricsHomeIcon";
//@ts-ignore
import { NursingHomeIcon } from "../../../assets/icons/NursingHomeIcon";
//@ts-ignore
import { HomeDrugIconV2 } from "../../../assets/icons/HomeDrugIconV2";
//@ts-ignore
import { HomeRoutineIconV2 } from "../../../assets/icons/HomeRoutineIconV2";
//@ts-ignore
import { CalcIcon } from "../../../assets/icons/CalcIcon";
//@ts-ignore
import { CIDIcon } from "../../../assets/icons/CIDIcon";
import { gql, useLazyQuery } from "@apollo/client";
//@ts-ignore
import { useSidebar } from "../../../context/useSidebar";
//@ts-ignore
import { useTrack } from "../../../services/hooks/useTrack";
import { FeatureSelectionModal } from "../FeatureSelectionModal";

const GET_SPECIALTIES = gql`
  query getSpecialties {
    sidebar_specialties {
      routine_specialties {
        _id
        title
        routines {
          _id
          title
          legend
          is_blocked
        }
      }
      drug_specialties {
        title
        _id
        therapeutic_groups {
          is_blocked
          title
          _id
        }
      }
    }
  }
`;
const Container = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  column-gap: 10px;
  row-gap: 10px;
  padding-right: 10px;
  @media only screen and (max-width: 1325px) {
    overflow-y: scroll;
    grid-template-columns: 1fr;
    mask-image: linear-gradient(black 75%, transparent);
    padding-bottom: 200px;
  }
  @media only screen and (max-height: 690px) {
    overflow-y: scroll;
    grid-template-columns: 1fr;
    mask-image: linear-gradient(black 75%, transparent);
    padding-bottom: 200px;
  }

  ::-webkit-scrollbar {
    width: 15px;
    border: 1px solid var(--gray-500);
    border-top: none;
    border-bottom: none;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--gray-500);
    border: 1px solid var(--gray-500);
    border-bottom: none;
    border-top: none;
    box-shadow: inset 0 0 0 4px #121212;
  }
`;

export const HomeFeatureList = () => {
  const [fetchSpecialties, { loading, error, data }] = useLazyQuery(
    GET_SPECIALTIES,
    {
      fetchPolicy: "no-cache",
    }
  );
  const { trackEvent } = useTrack();
  const [title, setTitle] = useState("");
  const [lastSelected, setLastSelected] = useState([''])
  const [isSelectorOpen, setIsSelectorOpen] = useState(false);
  const {
    parseCid,
    parseCalculators,
    mapRoutineSpecialties,
    mapDrugSpecialties,
  } = useSidebar();
  const containerRef = useRef(null);

  useEffect(() => {
    fetchSpecialties();
  }, []);

  function featureSelector(type: string, id?: string | undefined) {
    let dataToSet;
    if (id) {
      const wholeData = mapRoutineSpecialties(data);
      const specialtyIndex = wholeData.findIndex(
        (specialty: any) => specialty.key === id
      );
      dataToSet = wholeData[specialtyIndex];
    } else {
      switch (type) {
        case "routine":
          dataToSet = mapRoutineSpecialties(data);
          break;
        case "drugs":
          dataToSet = mapDrugSpecialties(data);
          break;
        case "cid":
          dataToSet = parseCid();
          break;
        case "calculator":
          dataToSet = parseCalculators();
          break;
        default:
          break;
      }
    }
    setIsSelectorOpen(true);
    return dataToSet
  }

  function getSpecialtiesOnClick() {
    const returnValue = featureSelector(lastSelected[0], lastSelected[1]);
    return returnValue;
  }

  return (
    <>
      {isSelectorOpen && (
        <FeatureSelectionModal
          //@ts-ignore
          widthToAdjust={containerRef?.current?.offsetWidth}
          data={getSpecialtiesOnClick}
          title={title}
          loading={loading}
          closeFunction={() => setIsSelectorOpen(false)}
        />
      )}
      <Container ref={containerRef}>
        <FeatureButton
          icon={<ClinicHomeIcon />}
          title="CLÍNICA MÉDICA"
          onClick={() => {
            setIsSelectorOpen(true);
            setLastSelected(["routine", "61bb749164a5ac001261b00a"]);
            setTitle("Clínica médica");
            trackEvent("Clicou em conteúdo", {
              Origem: "Home",
              "Tipo da Origem": "Lista de features",
              Conteúdo: "Clínica Médica",
            });
          }}
        />
        <FeatureButton
          icon={<PediatricsHomeIcon />}
          title="PEDIATRIA"
          onClick={() => {
            setIsSelectorOpen(true);
            setLastSelected(["routine", "61cc77e95968f60012c5ddb8"]);
            setTitle("Pediatria");
            trackEvent("Clicou em conteúdo", {
              Origem: "Home",
              "Tipo da Origem": "Lista de features",
              Conteúdo: "Pediatria",
            });
          }}
        />
        <FeatureButton
          icon={<NursingHomeIcon />}
          title="ENFERMAGEM"
          onClick={() => {
            setIsSelectorOpen(true);
            setLastSelected(["routine", "5fa077500fa74844d803170e"]);
            setTitle("Enfermagem");
            trackEvent("Clicou em conteúdo", {
              Origem: "Home",
              "Tipo da Origem": "Lista de features",
              Conteúdo: "Enfermagem",
            });
          }}
        />
        <FeatureButton
          icon={<HomeDrugIconV2 />}
          title="DROGAS"
          onClick={() => {
            setIsSelectorOpen(true);
            setLastSelected(["drugs"]);
            setTitle("Drogas");
            trackEvent("Clicou em conteúdo", {
              Origem: "Home",
              "Tipo da Origem": "Lista de features",
              Conteúdo: "Drogas",
            });
          }}
        />
        <FeatureButton
          icon={<HomeRoutineIconV2 />}
          title="ROTINAS"
          onClick={() => {
            setIsSelectorOpen(true);
            setLastSelected(["routine"]);
            setTitle("Rotinas");
            trackEvent("Clicou em conteúdo", {
              Origem: "Home",
              "Tipo da Origem": "Lista de features",
              Conteúdo: "Rotinas",
            });
          }}
        />
        <FeatureButton
          icon={<CIDIcon />}
          title="CID"
          onClick={() => {
            setIsSelectorOpen(true);
            setLastSelected(["cid"]);
            setTitle("CID");
            trackEvent("Clicou em conteúdo", {
              Origem: "Home",
              "Tipo da Origem": "Lista de features",
              Conteúdo: "CID",
            });
          }}
        />
        <FeatureButton
          icon={<CalcIcon />}
          title="CALCULADORAS"
          style={{ gridColumn: "1 / 4" }}
          onClick={() => {
            setIsSelectorOpen(true);
            setLastSelected(["calculator"]);
            setTitle("Calculadoras");
            trackEvent("Clicou em conteúdo", {
              Origem: "Home",
              "Tipo da Origem": "Lista de features",
              Conteúdo: "Calculadoras",
            });
          }}
        />
      </Container>
    </>
  );
};
