import styled from 'styled-components';
import { borderRadius, colors, fontSize, spacing } from '../../quarks/theme';


export const ContentSidebarStyle = styled.div`
position: relative;
display: inline-block;
min-height: 100%;
background: ${colors.black150};
width: ${props => props.isOpen ? '240px' : '0px'};
max-width: ${props => props.isOpen ? '240px' : '0px'};
transition: all 0.3s ease;
box-shadow: 0px -5px 10px 5px rgba(0, 0, 0, 0.75);

ul {
    display: ${props => props.isOpen ? 'block' : 'none'};
    width: minmax(max-content, 100%);
    background: ${colors.black150};
    color: ${colors.gray};
    padding: ${spacing.none} ${spacing.medium};
    margin: 0;
    list-style: none;
    height: 100%;
    overflow-y: auto;

    ::-webkit-scrollbar{
        width: 15px;
    }
    ::-webkit-scrollbar-track{
        background-color: #393939;
        width: 1px;
    }

    ::-webkit-scrollbar-thumb{
        background-color: #161616;
        border-radius: 10px;
        border: 1px solid #393939;
    }

    li {
        button {
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-align: left;
            cursor: pointer;
            padding: ${spacing.medium} ${spacing.none} ${spacing.medium} ${spacing.small};
            width: 100%;
            min-height: 40px;            
            background-color: transparent;
            border: none;
            color: ${colors.gray};
            font-size: ${fontSize.small};
            span {
                display: flex;
                align-items: center;
                max-width: 220px;
                svg {
                    min-width: 20px;
                    max-width: 20px;
                    margin-right: ${spacing.medium};
                }
            }
            &.active {
                color: ${colors.white};
                font-weight: bold;
            }
        }
        border-color: ${colors.black};
        border-bottom: 0.5px solid ${colors.black};
        &:first-child {
            border-top: 0;
        }
    }
}
& .sub_item_menu {
    ul {
        li {
            &:first-child {
                border-top: 1px solid ${colors.black};
            }
            &:last-child {
                border-bottom: 0;
            }
        }
    }
            
}
&>button {
    cursor: pointer;
    position: absolute;
    top: 40px;
    left: 100%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 0 ${borderRadius.small} ${borderRadius.small} 0;
    background-color: ${colors.black150};
    color: ${colors.white};
}

.sub_item_menu {
    width: calc(100% - ${spacing.medium});
    padding-left: ${spacing.medium};
}
&.light {
    background-color: ${colors.white};
    color: ${colors.darkGray};
    ul {
        background-color: ${colors.white};
        li {
            button {
                border-color: ${colors.disableGray};
                &.active, &:hover {
                    color: ${colors.disableGray};
                    font-weight: bold;
                }
            }
        }
    }
    &>button {
        background-color: ${colors.white};
        color: ${colors.disableGray};
    }
    .sub_item_menu {
        background-color: ${colors.white};
    }
}
`
