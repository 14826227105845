import { Link } from "react-router-dom";
import { BbCloseIcon } from "../../quarks/icons";
import { IconPopupContainer } from "./style"

export const IconPopupComponent = ({onClose, popup})=>{
  return (
    <IconPopupContainer>
      <div className="iconPopup__header">
        <div className="iconPopupHeader__title">
          <img 
          src={`data:image/png;base64, ${popup.icon}`} 
          alt={`${popup.title}: ${popup.description}`} 
          />
          <p>{popup.title}</p>
        </div>
        <div className="iconPopupHeader__closeIcon" onClick={onClose}>
          <BbCloseIcon />
        </div>
      </div>

      <div className="iconPopup__textContent">
        <p>{popup.description}</p>
      </div>

      {
        popup.link && !popup.link?.includes("5fa0c872ebf973b332cae154") &&
        <div className="iconPopup__link">
          <Link to={popup.link} onClick={onClose}>Ver Guia</Link>
        </div>
      }
    </IconPopupContainer>
  );
}