import React from "react";
import styled from "styled-components";
//@ts-ignore
import bgImage from "../../../assets/images/ReferralBG.jpg";
import { ReferralForm } from "../ReferralForm";

interface IProps {
  isOpen: boolean;
  setIsOpen: () => void;
  indicationInfo: any;
}

interface IOpenProps {
  isOpen: boolean;
}

const Overlay = styled.div<IOpenProps>`
  width: ${({isOpen}) => isOpen ? '100vw' : '0px'};
  height: ${({isOpen}) => isOpen ? '100vh' : '0px'};
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99999;
  background-color: ${({ isOpen }) =>
    isOpen ? "rgba(0, 0, 0, 0.7)" : "transparent"};
`;

const Container = styled.div<IOpenProps>`
  border: ${({isOpen}) => isOpen ? '5px solid #fcde17' : 'none'};
  box-sizing: border-box;
  z-index: 999;
  position: absolute;
  top: 50px;
  bottom: 38px;
  left: 240px;
  right: 0;
  background-image: url(${bgImage});
  background-size: cover;
  transition: border linear 0.2s;
`;

const Content = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: scroll;
  ::-webkit-scrollbar-thumb {
    background-color: #2c2c2c;
    background-clip: content-box;
    border: 2px solid transparent;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

const Title = styled.p`
  color: white;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
`;

const ListContainer = styled.div``;

const Text = styled.p`
  color: white;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 15px;
  text-align: left;
`;

export const ReferralModal = (props: IProps) => {
  return (
    <Overlay isOpen={props.isOpen}>
      <Container isOpen={props.isOpen}>
        {props.isOpen && (
          <Content>
            <TextContainer>
              <Title>
                Indique o Blackbook a seus colegas para ganhar dias grátis no
                aplicativo
              </Title>
              <ListContainer>
                <Text style={{ fontSize: 18 }}>COMO FUNCIONA?</Text>
                <Text>
                  1. Você indica o Blackbook para seus colegas compartilhando
                  seu link ou código de indicação no Whatsapp, em redes sociais
                  ou onde preferir.
                </Text>
                <Text>
                  2. Seus colegas que assinarem o aplicativo ganharão 30 dias
                  grátis.
                </Text>
                <Text>
                  3. Você ganha 30 dias grátis de assinatura para cada colega
                  que assinar o aplicativo.
                </Text>
                <Text>Quanto mais colegas assinarem, mais você ganha!</Text>
                <Text>
                  Os dias grátis serão adicionados ao seu plano assim que seu
                  colega assinar.
                </Text>
                <Text>Que tal começar a indicar agora?</Text>
              </ListContainer>
            </TextContainer>
            <ReferralForm indicationInfo={props?.indicationInfo} setIsOpen={props.setIsOpen} />
          </Content>
        )}
      </Container>
    </Overlay>
  );
};
