import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .construction__animation {
    width: 25%;
    height: 25%;
  }

  .construction__content {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    padding: 1rem;
  }

  .construction__text {
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .construction__button {
    padding: 0.8rem;
    border: none;
    background-color: var(--purple-500);
    border-radius: 15px;
    color: white;
    text-decoration: none;
    &:hover {
      cursor: pointer;
      transform: scale(1.1);
      transition: linear 0.1s;
    }
  }
`;
