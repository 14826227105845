import PropTypes from 'prop-types';
import { LogoComponent } from '../../../../blackbook-components/atoms/logo';
import { NavbarComponent } from '../../../../blackbook-components/atoms/navbar';

import { HeaderStyle } from './style';
import { handleRemoveIndicationCouponOfLocalStorage } from '../../../../services/cache/indicationCoupon';
import { useUser } from '../../../../context/useUser';
import { Link, useLocation } from 'react-router-dom';

export const HeaderComponent = (props) => {
  const { handleClearUserData, activePlan } = useUser();

  const location = useLocation();


  function handleLogoutUser() {
    handleClearUserData();
    handleRemoveIndicationCouponOfLocalStorage();
  }

  return (
    <HeaderStyle {...props}>
      <NavbarComponent>
        <div className="navbar_area">
          <div className="logo_area">
            <LogoComponent />
          </div>

          <div className="user__menu">
            {activePlan?.name === "Free" && (
              <Link
                to="/plans"
                className={`option__content ${
                  location.pathname === "/plans" ? "active" : ""
                }`}
                href=""
              >
                Planos
              </Link>
            )}
            <a
              href="https://wa.me/5531996887607?text=Olá,%20preciso%20de%20ajuda."
              target="_blank"
              rel="noreferrer"
              className="option__content"
            >
              Fale conosco
            </a>
            <a onClick={handleLogoutUser} href="/" className="option__content">
              Sair
            </a>
          </div>
        </div>
      </NavbarComponent>
    </HeaderStyle>
  );
};

HeaderComponent.propTypes = {
  avatar: PropTypes.string,
  userName: PropTypes.string,
  onChange: PropTypes.func,
  onLogoClick: PropTypes.func,
  onInputFocus: PropTypes.func,
  isOpen: PropTypes.bool,
};
