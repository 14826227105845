import { useState } from 'react';
import { toast } from 'react-toastify';

import { CloseIcon } from '../../../assets/icons/CloseIcon';
import { paymentApi } from '../../../services/clients/payment';
import { SubscriptionInfoText } from '../../atoms/SubscriptionInfoText';

import { Container } from './style';

export function ConfigUserPlan({ closeModal }) {
  const [confirmCancelModalIsOpen, setConfirmCancelModalIsOpen] =
    useState(false);

  async function handleCancelUserPlan() {
    try {
      await paymentApi.cancelPlans();
      toast.success('Cancelamento requisitado com sucesso');
      setConfirmCancelModalIsOpen(false);
    } catch (error) {
      toast.error('Ocorreu um erro!');
    }
  }

  function handleOpenHelp() {
    if (window.fcWidget) {
      window.fcWidget.open();
    }
  }

  return (
    <>
      <Container>
        <div className="content">
          <div className="content__header">
            <h1 className="header__title">Meu plano</h1>
            <button className="close__button" onClick={closeModal}>
              <CloseIcon />
            </button>
          </div>
          <SubscriptionInfoText />
          <div className="content__buttons">
            <button
              className="btn"
              onClick={() => setConfirmCancelModalIsOpen(true)}
            >
              CANCELAR ASSINATURA
            </button>
            <button
              className="btn"
              onClick={() =>
                window.open(
                  "https://wa.me/5531996887607?text=Olá,%20preciso%20de%20ajuda.",
                  "_blank"
                )
              }
            >
              PRECISO DE AJUDA
            </button>
          </div>
        </div>
      </Container>

      {confirmCancelModalIsOpen && (
        <Container>
          <div className="confirmCancel__modal">
            <div className="confirmCancel__header">ATENÇÃO</div>
            <div className="confirmCancel__body">
              <h4>
                Tem certeza que deseja
                <br />
                cancelar sua assinatura?
              </h4>
              <div className="confirmCancel__buttons">
                <button className="red" onClick={() => handleCancelUserPlan()}>
                  SIM, CANCELAR
                </button>
                <button
                  className="green"
                  onClick={() => setConfirmCancelModalIsOpen(false)}
                >
                  NÃO
                </button>
              </div>
            </div>
          </div>
        </Container>
      )}
    </>
  );
}
