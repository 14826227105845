import styled from "styled-components";

export const Footer = styled.footer`

  width: 100%;
  position: fixed;
  background-color: black;
  height: var(--footer-height);
  bottom: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;

  .footer__p {
    color: white;
  }

  .whatsapp_icon {
    padding: 0 0.5rem 0 0;
  }

  @media screen and (max-height: 480px) {
    display: none;
  }
`;
