import InputMask from 'react-input-mask';
import { useState } from 'react';
import styled from 'styled-components';

export function NewInput({
  inputProp,
  adornment = null,
  handleAdornmentClick = () => null,
  isActiveByDefault = false
}) {
  if (!inputProp?.className) {
    inputProp.className = 'default__input';
  }

  const [isActive, setIsActive] = useState(isActiveByDefault);

  return (
    <>
      <Container
        isValid={inputProp?.isValid}
        isInvalid={inputProp?.isInvalid}
        isActive={isActive || inputProp?.value}
      >
        {inputProp?.label && <h3>{inputProp?.label}</h3>}
        <InputMask
          {...inputProp}
          autoComplete="new-password"
          onFocus={() => setIsActive(true)}
          onBlur={() => {
            if(inputProp?.onblur){
              inputProp?.onblur();
            }
            setIsActive(false)
          }}
        />
        {adornment && (
          <InputAdornment
            isValid={inputProp?.isValid}
            isInvalid={inputProp?.isInvalid}
            isActive={isActive || inputProp?.value}
            className={inputProp?.className}
            type="button"
            onClick={handleAdornmentClick}
          >
            {adornment}
          </InputAdornment>
        )}

        {inputProp?.helper && (
          <span className="helper">{inputProp.helper}</span>
        )}
      </Container>
    </>
  );
}

const Container = styled.label`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  width: 100%;
  /* height: 100%; */

  h3 {
    color: #999;

    transition: transform 0.2s ease-in-out, background 0.3s ease-in-out;
    transform: ${({ isActive }) =>
      isActive ? 'translateY(-175%)' : 'translateY(0%)'};
    padding: 0 0.5rem;
    background-color: ${({ isActive }) => (isActive ? '#333' : 'transparent')};
    width: fit-content;
    margin-left: 0.5rem;
    font-size: 0.75rem;
    z-index: 1;
    border-radius: 3px;
    position: absolute;
  }

  h3:has(+ .outline__input) {
    background-color: #1e1e1e;
  }

  h3:has(+ .default__active) {
    background-color: #1e1e1e;
    transform:translateY(-175%);
  }

  .temp__input {
    border: 1px solid
      ${({ isValid, isInvalid }) =>
        isValid
          ? `var(--yellow-500)`
          : isInvalid
          ? `var(--error) !important`
          : 'var(--gray-100)'};
    background-color: unset;
    color: ${({ isValid, isInvalid }) =>
      isValid
        ? `var(--yellow-500)`
        : isInvalid
        ? `var(--error) !important`
        : 'white'};

    font-size: 1.2rem;
    font-family: Source Sans Pro;

    padding: 0.7rem;
    border-radius: 3px;
    width: 100%;
    box-sizing: border-box;

    transition: border-color 0.2s;

    &:hover {
      border-color: var(--purple-500);
    }

    &:focus {
      border-color: var(--yellow-500);
      outline: none;
    }

    &::placeholder {
      color: var(--gray-100);
      font-size: 0.75rem;
    }

    /* Disable arrows on numeric input */
    /* Chrome, Safari, Edge, Opera */
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    [type='number'] {
      -moz-appearance: textfield;
    }
  }

  input.outline__input {
    border: 1px solid
      ${({ isValid, isInvalid }) =>
        isValid
          ? `#1D9637`
          : isInvalid
          ? `var(--error) !important`
          : 'var(--gray-100)'};
    background-color: unset;
    color: ${({ isValid, isInvalid }) =>
      isValid ? `#1D9637` : isInvalid ? `var(--error) !important` : 'white'};

    font-size: 1rem;
    font-family: Source Sans Pro;

    padding: 0.7rem;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;

    transition: border-color 0.2s;

    &:hover {
      border-color: var(--purple-500);
    }

    &:focus {
      border-color: var(--yellow-500);
      outline: none;
    }

    &::placeholder {
      color: var(--gray-100);
      font-size: 0.75rem;
    }

    /* Disable arrows on numeric input */
    /* Chrome, Safari, Edge, Opera */
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    [type='number'] {
      -moz-appearance: textfield;
    }
  }

  .helper {
    position: absolute;
    bottom: -1.1rem;
    right: 0;
    color: ${({ isInvalid }) => (isInvalid ? 'var(--error)' : '#666')};
    font-size: 0.75rem;
  }
`;

export const InputAdornment = styled.div`
  align-self: center;
  justify-self: flex-end;
  position: absolute;
  right: 0;

  height: 100%;
  padding: 1rem;
  margin-top: 1rem;

  font-size: 2rem;

  background-color: transparent;
  border: none;

  transition: all 0.2s;

  &.outline__input {
    padding: 0 10px;
    margin: 0;
    height: 100%;
    font-size: 12px;
    font-weight: 300;
    display: flex;
    align-items: center;
    color: ${({ isValid, isInvalid }) =>
      isValid ? `#1D9637` : isInvalid ? `var(--error) !important` : 'white'};
  }

  &:hover {
    filter: brightness(0.8);
  }
`;
