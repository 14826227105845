import { useHistory } from 'react-router-dom';

import mobileImage from '../../assets/images/app-on-mobile-image.png';

import { Container } from './style';
import { useTrack } from '../../services/hooks/useTrack';
import { useEffect } from 'react';

export function DownloadPage() {
  window.scrollTo(0, 0);

  const history = useHistory();
  const { trackEvent } = useTrack();

  useEffect(() => {
    trackEvent('Acessou pagina de Download App');
  }, []);

  return (
    <Container>
      <div className="header">
        <img
          alt="Logo"
          className="header_logo"
          src="https://firebasestorage.googleapis.com/v0/b/public-blackbook/o/templates%2Flogo.png?alt=media"
        />
      </div>

      <div className="body">
        <div className="img_div">
          <img alt="BLACKBOOK APP" className="mobile_img" src={mobileImage} />
        </div>

        <div className="textAndButton_div">
          <div className="text_div">
            <p className="register_text">Obrigado por escolher o Blackbook!</p>
            <p className="app_text">Baixe o app:</p>
          </div>

          <div className="buttons_div">
            <a href="https://apps.apple.com/br/app/blackbook/id1496811013">
              <img
                alt="AppStoreImg"
                className="appStore_img"
                src="https://firebasestorage.googleapis.com/v0/b/public-blackbook/o/templates%2Fappstore.png?alt=media"
              />
            </a>
            <a href="https://play.google.com/store/apps/details?id=br.com.blackbook">
              <img
                alt="PlayStoreImg"
                className="playStore_img"
                src="https://firebasestorage.googleapis.com/v0/b/public-blackbook/o/templates%2Fplaystore.png?alt=media"
              />
            </a>
          </div>
          <div className="home_button">
            <button
              className="button_link"
              onClick={() => history.push('/home')}
            >
              Ir para página inicial
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
}
