import React, { useEffect, useRef, useState } from 'react';
import { SuggestionContainer } from './style';
import { useSidebar } from '../../../context/useSidebar';
import { SearchIcon } from '../../../assets/icons/SearchIcon';
import { Alarm } from '../../../assets/icons/Alarm';
import { BackArrow } from '../../../assets/icons/BackArrow';
import { useTrack } from '../../../services/hooks/useTrack';

function SuggestionButton({
  item,
  onClick,
  onClickAddToInput,
  hasAddToInputBtn,
}) {
  function handleIcon() {
    const option = {
      default: () => <SearchIcon size="18px" />,
      recently: () => <Alarm size="18px" />,
    };

    return option?.[item?.icon]?.() || option.default();
  }
  return (
    <li onClick={onClick}>
      {handleIcon()}
      <span>{item.title}</span>

      <div
        onClick={(e) => {
          e.stopPropagation();
          onClickAddToInput();
        }}
        className="addToInput__container"
      >
        {!!hasAddToInputBtn && (
          <BackArrow color="#d6d6d6" style={{ transform: 'rotate(45deg)' }} />
        )}
      </div>
    </li>
  );
}

export function SuggestionComponent({
  inputValue,
  setInputValue,
  onClickSuggestion,
  setIsSearchComponentOpen,
}) {
  const { searchSuggestions } = useSidebar();
  const { trackEvent } = useTrack();
  const [suggestions, setSuggestions] = useState([]);

  function normalizeText(text) {
    return text.normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .trim()
      .toLowerCase()
  }

  function handleFilterSuggestions(inputValue) {
    let suggestedItems = searchSuggestions || [];

    let term = normalizeText(inputValue);

    let recentlySearched = localStorage.getItem('@recently_seached');
    recentlySearched = !!recentlySearched ? JSON.parse(recentlySearched) : null;

    let suggestedItemsAux = [];

    if (!!recentlySearched?.length) {
      const recentlyList = recentlySearched.filter((e) =>
        normalizeText(e).includes(term)
      );
      const recentlyListLimit =
        recentlyList.length > 15 ? recentlyList.slice(0, 20) : recentlyList;

      let recentlyListParsed = recentlyListLimit.map((e) => {
        return { title: e, _id: e, icon: 'recently', type: 'Histórico' };
      });
      suggestedItemsAux.push(...recentlyListParsed);
    }

    if (!!term) {
      suggestedItemsAux.unshift({
        title: inputValue,
        _id: 'input-value',
        removeAddToInput: true,
        type: 'Input'
      });

      if (!!suggestedItems?.length) {
        const suggestList = suggestedItems.filter((e) =>
          e.title_searchable.startsWith(term) || e.item_id.startsWith(term.toUpperCase())
        );

        const suggestionsOrder = orderByRank(suggestList);
        const suggestSliced = suggestionsOrder?.slice(0, 30) || [];
        const suggestionParced = suggestSliced.map(s=> {
          return {...s, type: "Sugestão"}
        })

        suggestedItemsAux.push(...suggestionParced);
      }
    }

    setSuggestions(suggestedItemsAux);
  }


  function orderByRank(results) {
    return results.sort((a, b) => sortByRankPoints(a, b));
  }

  function sortByRankPoints(prev, current) {
    if (prev.rank_points < current.rank_points) {
      return 1;
    }
    if (prev.rank_points > current.rank_points) {
      return -1;
    }

    if (prev.title_searchable > current.title_searchable) {
      return 1;
    }
    if (prev.title_searchable < current.title_searchable) {
      return -1;
    }
    return 0;
  }

  async function handleClickSuggestion(
    suggestion,
    index
  ) {
    const url = window.location.href;
    await onClickSuggestion(suggestion.title);
    await trackEvent('Busca - digitou um termo', {
      'Termo digitado': suggestion.title,
      'Sugestão 1': suggestions[0]?.title || null,
      'Sugestão 2': suggestions[1]?.title || null,
      'Sugestão 3': suggestions[2]?.title || null,
      'Tipo': suggestion?.type,
      'Página': url,
      'Dados da sugestão': suggestion,
      'Posição da sugetão clicada': index ? index + 1 : null
    });
  }

  useEffect(() => {
    handleFilterSuggestions(inputValue);
  }, [inputValue]);

  const newRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (newRef.current && !newRef.current.contains(event.target)) {
        setIsSearchComponentOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [newRef]);

  return (
    <SuggestionContainer>
      <ul ref={newRef}>
        {suggestions
          ?.slice?.(0, 20)
          ?.map?.((suggestion, index) => (
            <SuggestionButton
              key={suggestion._id}
              item={suggestion}
              onClick={() => handleClickSuggestion(suggestion, index)}
              onClickAddToInput={() => setInputValue(suggestion.title)}
              hasAddToInputBtn={!suggestion.removeAddToInput}
            />
          )) || null}
      </ul>
    </SuggestionContainer>
  );
}
