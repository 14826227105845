import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ContentSliderStyle } from './style';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Navigation } from 'swiper';

export const ContentSliderComponent = (props) => {

  return (
    <ContentSliderStyle>
      <Swiper
        slidesPerView={'auto'}
        centeredSlides={true}
        speed={100}
        grabCursor={true}
        navigation={true}
        modules={[Navigation]}
        spaceBetween={50}
        className="slide"
        onSlideChange={(e) => props.slideFunction(e)}
        onTouchStart={props.touchFunction}
      >
        {props.items.map((item, index) => (
          <SwiperSlide key={index}>
            {({ isActive, isPrev, isNext }) => 
                React.isValidElement(item)
            && React.cloneElement(item, {
                isCurrent: isActive,
                isPrev: isPrev, 
                isNext: isNext
              })
            }
          </SwiperSlide>
        ))}
      </Swiper>
    </ContentSliderStyle>
  );
};

ContentSliderComponent.propTypes = {
  items: PropTypes.arrayOf(PropTypes.node).isRequired,
};
