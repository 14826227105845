import styled from "styled-components";

export const AccountOverview = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background-color: unset;
    padding: 0 1rem 0 1rem;

    .overviewHeader{
        margin-bottom: 1rem;
        color: white;
    }
    .overviewHeader__title{
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 1rem;
    }
    .overviewHeader__subtitle{
        font-size: 0.9rem;
        margin-top: 16px;
        color: #999999;
        align-self: self-start;
    }

    .overviewContent{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
    }
    .overviewContent__table{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;

    }
    .table__row{
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #999999;
        padding: 1rem 0;
    }
    .table__header{
        color: white;
        white-space: nowrap;
        padding-right: 1rem;
        color: #999999;
    }
    .table__cell{
        color: white;
        word-break: break-all;
        text-align: right;
    }
`;