import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .passwordContainer {
    display: flex;
    align-items: center;
  }

  .passwordContainer__icon {
    position: absolute;
    margin-left: 12rem;
    color: #999999;
    font-size: 1.3rem;
    margin-bottom: 0.8rem;
  }

  .loginForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    background-color: #121212;
    border-radius: 5px;
    font-size: 1.1rem;
    margin-top: 3rem;
    margin-bottom: 6rem;
    /* box-shadow: 0 9px 12px rgba(0, 0, 0, 0.3); */

    .loginForm__anchor:active {
      color: black;
    }

    .form__error {
      margin-top: -10px;
      margin-bottom: 5px;
      font-size: 0.8rem;
      align-self: flex-start;
      color: var(--red-300);
    }
  }

  .loginForm__anchor {
    align-self: flex-end;
    font-size: 0.7rem;
    text-decoration: none;
    color: #8774ff;
    margin-top: 5px;
  }

  .createAccount__button {
    width: 90%;
    padding: 1rem;
    font-size: 0.7rem;
    font-weight: bold;
    border: 1px solid #8774ff;
    border-radius: 30px;
    margin-top: 1.2rem;
    background-color: transparent;
    color: #8774ff;
    cursor: pointer;

    &:hover {
      filter: brightness(0.8);
    }
  }

  @media screen and (min-width: 1020px) and (max-width: 1200px) {
    .createAccount__button {
      width: 90%;
      padding: 1rem;
      font-size: 0.7rem;
      font-weight: bold;
      border: 1px solid #8774ff;
      border-radius: 30px;
      background-color: transparent;
      color: #8774ff;
      cursor: pointer;

      &:hover {
        filter: brightness(0.8);
      }
    }
  }
  @media screen and (max-height: 600px) {
    .createAccount__button{
      position: absolute;
      bottom: 0;
    }
    .loginForm{
      margin-bottom: 3rem;
    }
  }
`;
