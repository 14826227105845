import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContextAplication } from '../../../context/useUser';
import { handleRemoveIndicationCouponOfLocalStorage } from '../../../services/cache/indicationCoupon';
import { paymentApi } from '../../../services/clients';

import { Popup } from './style';

export function PopupOptionsComponent({ isOpen, showPlans = false }) {
  const { handleClearUserData } = useContext(UserContextAplication);

  // function handleOpenChat() {
  //   if (window.fcWidget) {
  //     window.fcWidget.open();
  //   }
  // }

  function handleLogoutUser() {
    handleClearUserData()
    handleRemoveIndicationCouponOfLocalStorage()
  }

  return (
    <Popup className={isOpen ? "visible" : ""}>
      <div className="popupArrow"></div>
      <ul className="popupOptions">
        {showPlans && (
          <li className="popupOptions__option">
            <Link to="/plans" className="option__content">
              Planos
            </Link>
          </li>
        )}
        <li className="popupOptions__option">
          <a
            href="https://wa.me/5531996887607?text=Olá,%20preciso%20de%20ajuda."
            target="_blank"
            rel="noreferrer"
            className="option__content"
          >
            Fale conosco
          </a>
        </li>
        <li className="popupOptions__option">
          <a
            href="/signin"
            className="option__content"
            onClick={handleLogoutUser}
          >
            Sair
          </a>
        </li>
      </ul>
    </Popup>
  );
}
