import styled from "styled-components";
import MainTheme from "../../../styles/MainTheme";

export const Button = styled.button`
  width: 100%;
  padding: 1rem;
  font-size: 0.7rem;
  font-weight: bold;
  border: none;
  border-radius: 30px;
  margin-top: 1.2rem;
  background-color: #8774FF;
  color: white;

  &:active {
    background-color: var(--purple-500);
    color: white;
    transition: linear 0.09s;
  }
  &:hover {
    cursor: pointer;
    filter: brightness(0.9);
  }

  &.overviewContent__button {
    margin-top: 1rem;
    width: 100%;
    padding: 0.5rem;
    background-color: transparent;
    border: 1px solid var(--purple-500);
    border-radius: 20px;
    color: white;
    font-size: 1.2rem;

    &:hover {
      background-color: var(--purple-500);
      cursor: pointer;
      transition: 0.2s linear;
    }

    &:active {
      background-color: var(--purple-500);
      scale: 1.1;
    }
    @media screen and (min-width: 768px) {
      align-self: flex-start;
      width: 25%;
      display: inline-block;
    }
  }

  &.paymentBox__button {
    border-radius: 20px;
    background-color: var(--purple-500);
    color: var(--white);
    width: 100%;
    cursor: pointer;
    font-weight: bold;
    transition: filter 0.2s;
    padding: 1rem;

    &:hover {
      filter: brightness(0.9);
    }
  }

  &.outlined__button {
    border-radius: 20px;
    background-color: transparent;
    color: var(--white);
    width: 100%;
    cursor: pointer;
    font-weight: bold;
    transition: filter 0.2s;
    padding: 1rem;
    border: 1px solid var(--yellow-500);
    outline: none;


    &:hover {
      filter: brightness(0.9);
    }
  }

  &.outlinedPurple__button {
    border-radius: 20px;
    background-color: transparent;
    color: var(--purple-500);
    width: 100%;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    transition: all 0.2s;
    padding: 1rem;
    border: 1px solid var(--purple-500);
    outline: none;

    &:hover {
      filter: brightness(0.8);
    }
  }

  &.basicRed__button {
    border-radius: 20px;
    background-color: var(--error);
    border: 1px solid var(--error);
    color: var(--white);
    width: 100%;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    transition: all 0.2s;
    padding: 0.5rem;

    &:hover {
      filter: brightness(0.9);
    }
  }

  &.filter__button {
    background-color: ${({active}) => active ? `var(--purple-500)`: 'transparent'};
    color: var(--white);
    cursor: pointer;
    transition: filter 0.2s;
    margin: 0px;
    padding: 1rem;
    border: 1px solid var(--purple-500);
    outline: none;
    display: flex;
    border-radius: 55px;
    max-width: 100px;
    gap: 10px;
    justify-content: center;
    align-items: center;
    height: 40px;
    font-size: 14px;
    font-weight: 700;

    &:hover {
      filter: brightness(0.9);
      background-color: var(--purple-500);
    }
  }

  &.subscriptionBox__button {
    border-radius: 20px;
    background-color: transparent;
    border: 1px solid var(--yellow-500);
    color: var(--white);
    width: 100%;
    cursor: pointer;
    font-weight: bold;
    transition: color 0.2s, background-color 0.2s;
    padding: 0.5rem;

    &:hover {
      background-color: var(--yellow-500);
      color: var(--black);
    }
  }

  &.cancelSubscriptionBox__button {
    border-radius: 20px;
    background-color: transparent;
    border: 1px solid var(--red-500);
    color: var(--white);
    width: 100%;
    cursor: pointer;
    font-weight: bold;
    transition: color 0.2s, background-color 0.2s;
    padding: 0.5rem;

    &:hover {
      background-color: var(--red-500);
      color: var(--black);
    }
  }

  &.validateCoupon__button {
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid var(--yellow-500);
    color: var(--white);
    cursor: pointer;
    font-weight: bold;
    width: auto;
    align-self: flex-end;
    transition: color 0.2s,
    background-color 0.2s;
    margin: 0 0 0.5rem 0;

    &:hover {
      background-color: var(--yellow-500);
      color: var(--black);
    }
  }

  &.buttonContainer__button {
    width: 100%;
    padding: 1rem;
    font-size: 1.2rem;
    border: none;
    border-radius: 25px;
    margin: 0;
    color: white;
    transition: linear 0.3s;
    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }
`;

const buttonStyle = `
  display: inline-block;
  padding: 17.5px 30px;
  border: none;
  font-weight: bold;
  font-size: 0.7rem;
  color: #fff;
  border-radius: 30px;
  cursor: pointer;
  background-color: var(--purple-500);
  text-transform: uppercase;
  text-decoration: none;

  &:hover {
      filter: brightness(0.9);
  }

  &.fullWidth {
    width: 100%;
  }

  &[disabled] {
    background-color: #6F6F6F;
    cursor: not-allowed;
  }
`;


export const NewButton = styled.button`
  ${buttonStyle}
`;

export const LinkButton = styled.a`
  ${buttonStyle}
`;

export const ButtonSearchFilter = styled.button`
  border: 1px solid ${MainTheme.color.primary};
  color: ${MainTheme.color.primary};
  background-color: transparent;
  border-radius: 25px;
  flex: 1;
  max-width: 130px;
  height: 24px;
  cursor: pointer;
  position: relative;

  transition: all 0.2s;
  &:hover {
    background-color: ${MainTheme.color.primary};
    color: white;
  }
  
  &.active {
    background-color: ${MainTheme.color.primary};
    color: white;
  }

  .flagResult {
    background-color: ${MainTheme.color.secondary};
    min-width: 30px;
    position: absolute;
    right: 10px;
    top: -8px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    .flagResultText {
      color: ${MainTheme.color.fullBlack};
      font-weight: bold;
      font-size: 12px;
      align-items: center;
      justify-content: center;
    }
  }
`;

