import PropTypes from 'prop-types';
import { DrugBrandComponent } from '../../atoms/drug-brand';
import { DrugResumeStyle } from './style';

export const DrugResumeComponent = (props) => (
  <DrugResumeStyle {...props}>
    {props.brands && props.brands.length > 0 && (
      <>
        <div className="reference">
          <h4>Referência:</h4>
          <ul className="brands_list">
            {props.brands.map((brand, index) => (
              <li key={index}>
                <DrugBrandComponent
                  title={brand.title}
                  laboratory={{ title: brand.laboratoryTitle }}
                />
              </li>
            ))}
          </ul>
        </div>
        <hr className="resume__separator" />
      </>
    )}
    {props.icons && props.icons.length > 0 && (
      <>
        <div className="icons">
          <ul className="icons_list">
            {props.icons.map((icon, index) => (
              <li onClick={() => props.iconFunction(icon)} key={index}>
                <img
                  src={`data:image/png;base64, ${icon.icon}`}
                  alt={`${icon.title}: ${icon.description}`}
                />
              </li>
            ))}
          </ul>
        </div>
        <hr className="resume__separator" />
      </>
    )}
    {props.observations && (
      <>
        <div
          className="observations"
          dangerouslySetInnerHTML={{ __html: props.observations }}
        />
        <hr className="resume__separator" />
      </>
    )}
    {props.presentations && props.presentations.length > 0 && (
      <>
        <div className="presentation">
          <h4>Apresentações disponíves:</h4>
          <ul className="presentation_list">
            {props.presentations.map((presentation, index) => (
              <li key={index}>
                <p>{presentation}</p>
              </li>
            ))}
          </ul>
        </div>
        <hr className="resume__separator" />
      </>
    )}
    {props.prices && props.prices.length > 0 && (
      <>
        <div className="price">
          <ul className="prices_list">
            {props.prices.map((price, index) => (
              <li key={index}>
                <p>
                  {price.value} {price.title}
                </p>
              </li>
            ))}
          </ul>
        </div>
        <hr className="resume__separator" />
      </>
    )}
    {props.whiteboard && (
      <div
        className="whiteboard"
        dangerouslySetInnerHTML={{ __html: props.whiteboard }}
      />
    )}
  </DrugResumeStyle>
);

DrugResumeComponent.propTypes = {
  prices: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  icons: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      icon: PropTypes.string,
    })
  ),
  brands: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      laboratoryTitle: PropTypes.string,
    })
  ),
  whiteboard: PropTypes.string,
  observations: PropTypes.string,
  presentations: PropTypes.arrayOf(PropTypes.string),
};
