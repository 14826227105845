import styled from "styled-components";
import { colors } from "../../quarks/theme";

export const IconPopupContainer = styled.section`
  background-color: #2E2E2E;
  border-radius: 5px;
  padding: 20px 0 0 20px;
  max-width:40%;
  margin: auto;
.iconPopup__header{
  display: flex;
  justify-content: space-between;
  .iconPopupHeader__title{
    display: flex;
    align-items: center;
    font-weight: 700;
    img{
      margin-right: 19px;
      width: 50px;
      height: 50px;
    }
  }
  
  .iconPopupHeader__closeIcon{
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 20px;
    svg{
      cursor: pointer;
      width: 20px;
      height: 20px;
    }
  }
}


.iconPopup__textContent{
  padding-top: 15px;
  padding-right: 20px;
  padding-bottom: 20px;
  margin-top: 20px;
  border-top: solid 1px ${colors.secondary};
  line-height: 1.35;
}

.iconPopup__link{
  a{
    color: ${colors.primary};
  }
  padding:0 20px 20px 0;
  text-align: end;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
}

`;