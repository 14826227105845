import styled from "styled-components";

export const BannerSection = styled.section`
  width: 100%;
  height: 100%;
  .banner {
    position: relative;
    height: 100%;
    z-index: -10;
    border-radius: 10px;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .banner__image {
    object-fit: cover;
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    overflow: hidden;
    z-index: -2;
  }

  .textArea {
    box-sizing: border-box;
    padding: 14px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @media screen and (max-height: 400px) {
      padding: 10px;
    }
  }

  .textArea > p {
    margin: 0 14px;
    margin-left: -30px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-align: left;
    color: white;
    text-decoration: none;
    font-size: calc(13px + 1vw);
    line-height: calc(15px + 1.2vw);
    @media screen and (max-height: 500px) {
      -webkit-line-clamp: 2;
      margin-left: -20px;
      font-size: calc(9px + 1vw);
      line-height: calc(5px + 1.2vw);
    }
  }

  .svg {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
`;