import { useRef, useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { AccordionTable } from '../../../components/atoms/AccordionTable';
import { usePopup } from '../../../context/usePopup';
import { useTrack } from '../../../services/hooks/useTrack';
import { AccordionComponent } from '../../atoms/accordion';
import { routineColorSelector } from '../../quarks/theme';
import { AccordionContainerComponent } from '../accordion-container';
import { PopupButtonComponent } from '../popup-button';

const Carousel = ({ content }) => {
  const carouselRef = useRef();

  useEffect(() => {
    let slider = carouselRef?.current?.querySelector('.carousel-items p');

    let mouseDown = false;
    let startX, scrollLeft;

    let startDragging = function (e) {
      mouseDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    };
    let stopDragging = function (event) {
      mouseDown = false;
    };

    if (slider) {
      slider.scrollLeft = 0;

      slider?.addEventListener('mousemove', (e) => {
        e.preventDefault();
        if (!mouseDown) {
          return;
        }
        const x = e.pageX - slider.offsetLeft;
        const scroll = (x - startX) * 2;
        slider.scrollLeft = scrollLeft - scroll;
      });

      slider?.addEventListener('mousedown', startDragging, false);
      slider?.addEventListener('mouseup', stopDragging, false);
      slider?.addEventListener('mouseleave', stopDragging, false);
    }
    return () => {
      slider?.removeEventListener('mousedown', startDragging);
      slider?.removeEventListener('mouseup', stopDragging);
      slider?.removeEventListener('mouseleave', stopDragging);
    };
  }, [carouselRef]);

  return (
    <div
      ref={carouselRef}
      className="carousel-items"
      dangerouslySetInnerHTML={{ __html: content.text }}
    ></div>
  );
};

export const RoutineContentsTemplates = ({
  routine_title,
  tab_title,
  card_title,
  content,
  class_css = null,
  isChildren = false,
  ...rest
}) => {
  const history = useHistory()
  const location = useLocation()
  const { trackEvent } = useTrack();

  useEffect(()=>{
  if (typeof content.text === "string" && content.text.includes("app/calc")) {
    content.text = content.text.replace('<a href="app/calc', '<a class="calcLink" href="app/calc');
  }
  if (typeof content.text === "string" && content.text.includes(".svg")) {
    content.text = content.text.replace("<img", '<img class="svgImage"');
  }
  },[location.key])

  function handleLinksNavigate(e) {
    e?.preventDefault()

    if (e.target.tagName === 'A' || e.target.parentElement.tagName === "A") {
      const target = e.target.tagName === "A" ? e.target : e.target.parentElement
      const href = target.getAttribute('href');

        if (href.includes("http")) {
          window.open(href, "_blank");
        } else if (href.includes("calc")) {
          history.push(`/app/calculators/${href.replace("/app/", "")}`, {
            trackOrigin: "Rotinas",
            trackType: "Link",
          });
        } else {
          history.push(href.replaceAll("/null", ""), {
            trackOrigin: "Rotinas",
            trackType: "Link",
          });
        }
        


    }
  }

  const { setPopup } = usePopup();
  const contentTypeSelector = {
    text: (
      <div
        className="text-content"
        dangerouslySetInnerHTML={{ __html: content.text }}
        onClick={(e) => handleLinksNavigate(e)}
      ></div>
    ),
    caroseul: <Carousel content={content} />,
    scrollable_image: (
      <div
        className="scrollable-image"
        dangerouslySetInnerHTML={{ __html: content.text }}
      ></div>
    ),
    image: (
      <div
        className="default-image"
        dangerouslySetInnerHTML={{ __html: content.text }}
      ></div>
    ),
    container_accordion: (
      <AccordionContainerComponent
        className="containerWithoutTitle"
        body={content.children}
        color={class_css}
        id={content._id}
      />
    ),
    accordion: (
      <AccordionComponent
        id={content._id}
        accordionColor={
          isChildren
            ? routineColorSelector(class_css).hard
            : routineColorSelector(class_css).dark
        }
        bodyColor={routineColorSelector(class_css).soft}
        title={content.title}
        routine_title={routine_title}
        tab_title={tab_title}
        card_title={card_title}
        item_type={"Rotinas"}
        isOpen={true}
        {...rest}
      >
        <div
          className="accordion-text-content"
          dangerouslySetInnerHTML={{ __html: content.text }}
          onClick={(e) => handleLinksNavigate(e)}
        ></div>
      </AccordionComponent>
    ),
    popup_html: (
      <PopupButtonComponent
        id={content._id}
        theme={{
          color: routineColorSelector(class_css).dark,
          fontColor: 'white',
        }}
        title={`${content.title}`}
        clickCallback={() =>
          setPopup({
            title: content.title,
            content: content.text,
            color: class_css,
          })
        }
      />
    ),
    accordion_html: (
      <AccordionComponent
        id={content._id}
        accordionColor={
          isChildren
            ? routineColorSelector(class_css).hard
            : routineColorSelector(class_css).dark
        }
        title={content.title}
        className="extracontent__accordion"
        routine_title={routine_title}
        tab_title={tab_title}
        card_title={card_title}
        item_type={"Rotinas"}
        isOpen={true}
        {...rest}
      >
        <AccordionTable
          extra={{
            title: content.title,
            content: content.text,
            color: class_css,
          }}
        />
      </AccordionComponent>
    ),
    container_accordion_title: (
      <AccordionComponent
        id={content._id}
        accordionColor={routineColorSelector(class_css).dark}
        bodyColor={routineColorSelector(class_css).hard}
        title={content.title}
        isOpen={true}
        routine_title={routine_title}
        tab_title={tab_title}
        card_title={card_title}
        item_type={"Rotinas"}
        className="containterWithTitleHeader"
      >
        <AccordionContainerComponent
          backgroundColor={routineColorSelector(class_css).dark}
          className="containerWithTitle"
          body={content.children}
          color={class_css}
        />
      </AccordionComponent>
    ),
  };

  return contentTypeSelector[content.content_type] || null;
};
