import styled from 'styled-components';
import { fontFamily, fontSize, spacing } from '../../quarks/theme';

const FOOTER_HEIGHT = '36px'
const HEADER_HEIGHT = '50px'
const DRUG_BREADCRUMB_HEIGHT = '36px'
const PA_SEPARATOR_SPACING = '50px'

export const DrugCardStyle = styled.div`
   font-family: ${fontFamily.secondary};

   .drugCard__content {
      padding: ${spacing.none} ${spacing.small} ${spacing.xlarge} ${spacing.xlarge};
      max-height: calc(
         100vh
         - ${FOOTER_HEIGHT} 
         - ${HEADER_HEIGHT} 
         - ${DRUG_BREADCRUMB_HEIGHT} 
         - ${PA_SEPARATOR_SPACING} 
         ${({activePrincipleTitleHeight}) => 
            activePrincipleTitleHeight ? 
               `- ${activePrincipleTitleHeight}px`
                  : ''
         }
      );
      overflow-y: scroll;
      box-sizing: border-box;
      outline: none;
   }

   .drugsCard_title {
      padding: ${spacing.xlarge};
      text-align: center;
      margin: ${spacing.none};
      font-size: ${fontSize.xlarge};
      font-family: Open sans;
      font-weight: bold;
   }
   .accordion {
      margin-bottom: ${spacing.medium};
      &:last-child {
         margin-bottom: ${spacing.none};
      }
   }
`
