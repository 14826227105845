import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { useUser } from '../../context/useUser';

import { PlanCard } from '../../components/molecules/PlanCard';
import { AccountOverviewComponent } from '../../components/molecules/accountOverview';

import { NewDefaultTemplate } from '../../components/templates/NewDefaultTemplate';

import { Container, NavigateToAppButton } from './style';
import { useHistory } from 'react-router-dom';
import { BBLoadingGif } from '../../blackbook-components/quarks/blackbook-loading';
import { paymentApi } from '../../services/clients';

export function Home() {
  window.scrollTo(0, 0);

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 717px)',
  });
  const { userProperties, fetchUserData, activePlan } = useUser();

  const [isLoading, setIsLoading] = useState(false);
  const [hasProcessingPayment, setHasProcessingPayment] = useState(false);

  const history = useHistory();

  if (activePlan?.name === 'Free' && !hasProcessingPayment) {
    history.push('/plans');
  }

  async function handleGetIfHasPaymentInProcess() {
    setIsLoading(true);
    try {
      const processingPaymentResponse =
        await paymentApi.userHasPaymentProcessing();
      setHasProcessingPayment(processingPaymentResponse);
    } catch (error) {}
    setIsLoading(false);
  }

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    handleGetIfHasPaymentInProcess();
  }, [userProperties]);

  return (
    <NewDefaultTemplate>
      {isLoading || !activePlan ? (
        <BBLoadingGif />
      ) : (
        <Container>
          {userProperties && (
            <>
              <div className="userDetails__container">
                <h1 className="container__header">
                  Olá, {userProperties?.name.trim().split(' ')[0]}!
                </h1>
                <PlanCard
                  loading={isLoading}
                  setLoading={setIsLoading}
                  hasProcessingPayment={hasProcessingPayment}
                />
                <AccountOverviewComponent user={userProperties} />
                {/* Comentado até ser definido um layout oficial */}
                {/* <FreshChat
                  token="5b7d3864-cf8e-4554-886a-daf2aedf1316"
                  onInit={(widget) => {
                    widget.user.setProperties({
                      email: userProperties.auth.email,
                      firstName: userProperties.name,
                      phone: userProperties.phone_number,
                    });
                  }}
                /> */}
              </div>
              {isDesktopOrLaptop && (
                <NavigateToAppButton
                  type="button"
                  onClick={() => history.push('/app')}
                >
                  IR PARA O CONTEUDO
                </NavigateToAppButton>
              )}
            </>
          )}
        </Container>
      )}
    </NewDefaultTemplate>
  );
}
