export const INDICATOR_CODE_KEY = 'INDICATOR_CODE'

export const handleSetIndicatorCodeOnLocalStorage = (code) => {
  localStorage.setItem(INDICATOR_CODE_KEY, code)
}

export const handleRemoveIndicatorCodeOfLocalStorage = () => {
  localStorage.removeItem(INDICATOR_CODE_KEY);
}

export const getIndicatorCode = () => (localStorage.getItem(INDICATOR_CODE_KEY) || null);
