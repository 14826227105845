import styled from 'styled-components';

import { borderRadius, colors, spacing} from "../../../blackbook-components/quarks/theme"

export const SearchContainerStyle = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: ${colors.black150};

    padding: 20px;
    padding-right: 15px;
    overflow-y: scroll;

    height: calc(100% - 100px);
    padding-bottom: 100px;

    .default__container {
      display: flex;
      justify-content: center;

      color: white;
    }

    ::-webkit-scrollbar {
      width: 15px;
      /* border-radius: 5px; */
      border: 1px solid var(--gray-500);
      border-top: none;
      border-bottom: none;
    }

    ::-webkit-scrollbar-thumb {
      background-color: var(--gray-500);
      border: 1px solid var(--gray-500);
      border-bottom: none;
      border-top: none;
      box-shadow: inset 0 0 0 4px #1e1e1e;
    }


  .emptySearch__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0.5;

    height: inherit;
    gap: 15px;
    svg {
      height: 40%;
    }

    h3 {
      color: #ccd2d6;
      /* font-weight: bold; */
      text-align: center;
    }
  }

`;
