import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Spinner } from '../../../../blackbook-components/quarks/spinner';
import { SearchContainerStyle } from './style';
import { useSidebar } from '../../../../context/useSidebar';

export function SearchComponent({ isOpen, onClose, response, inputValue}) {
  const parsedInput = inputValue
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .trim()
    .toLowerCase();
  const history = useHistory();


  return (
    <SearchContainerStyle isOpen={false} onClick={onClose}>
      <div className="searchResult__container" onClick={(e) => e.stopPropagation()}>
          <div className="default__container">            
          </div>
      </div>
    </SearchContainerStyle>
  );
}
