import { gql } from "@apollo/client";

export const INDICATION_INFO_QUERY = gql`
  query ShowIndicationInfo {
    showIndicationInfo {
      code
      url
      invitationData {
        invitationsAccepted
        invitationsFinalized
        daysObtained
      }
    }
  }
`;