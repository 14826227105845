import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 1.5rem 1.5rem;
  font-family: Source Sans Pro;
  font-size: 0.75rem;
  font-weight: 400;
  color: #F2F2F2;
  line-height: 1rem;

  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: calc(100vh - var(--footer-height) - 54px - 2rem);
  overflow: auto;

  .contentText__title {
    font-weight: 700;

  }
`;
