import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BreadCrumbComponent } from '../../atoms/breadcrumb';
import { RoutineCardComponent } from '../../molecules/routine-card';
import { RoutineContentsTemplates } from '../../molecules/routine-content';
import { RoutineDetailStyle } from './style';
import _ from 'lodash';

const RoutineItem = ({
  card_id,
  title,
  contents,
  class_css,
  routineScrollTop,
  isResumeCard = false,
  isPaid = false,
  routine_title,
  routine_id,
  tab_title,
  removeFavorite,
  addFavorite,
  favorites
}) => {
  const cardRef = useRef();
  
  return (
    <div
      id={card_id}
      ref={cardRef}
      className={`routine__card ${
        routineScrollTop >= cardRef?.current?.offsetTop - 300 ? 'active' : ''
      }`}
    >
      <RoutineCardComponent
        cardId={card_id}
        routineId={routine_id}
        isResumeCard={isResumeCard}
        cardTitle={title}
        routine_title={routine_title}
        tab_title={tab_title}
        card_title={title}
        color={class_css}
        theme={class_css}
        className={
          routineScrollTop >= cardRef?.current?.offsetTop - 300 ? 'active' : ''
        }
        isPaid={isResumeCard ? false : isPaid}
        removeFavorite={removeFavorite}
        addFavorite={addFavorite}
        isFavorited={favorites.includes(card_id.toString())}
        isBlocked={isPaid}
      >
        {contents?.map((content) => (
          <RoutineContentsTemplates
            content={content}
            class_css={class_css}
            routine_title={routine_title}
            tab_title={tab_title}
            card_title={title}
          />
        ))}
      </RoutineCardComponent>
    </div>
  );
};

function RoutineDetailComponent({
  routineContentRef,
  scrollElementIntoView,
  setHightlightedItem,
  hightlightedItem,
  handleScrollByKeyOrURL,
  favorites,
  removeFavorite,
  addFavorite,
  ...props
}) {
  const location = useLocation();

  const [dynamicBreadcrumbColor, setDynamicBreadcrumbColor] = useState();

  function setCardsTop(contentDivs) {
    const breadcrumbHeight = contentDivs[0].getBoundingClientRect().height;
    contentDivs.forEach((card, i) => {
      if (i === 0) return;
      const CardHeader = card.querySelector('.card__title');
      CardHeader.style.top = breadcrumbHeight + 'px';
      accordionHeaderHeightCalculator(
        card,
        CardHeader.getBoundingClientRect().height,
        breadcrumbHeight
      );
    });
  }

  function accordionHeaderHeightCalculator(
    card,
    cardTitleHeight,
    breadcrumbHeight
  ) {
    card.querySelectorAll('.stickyHeader').forEach((header) => {
      let parentHeader = header
        ?.closest('.containterWithTitleHeader')
        ?.querySelector('.containterWithTitleHeader.stickyHeader')
        ?.getBoundingClientRect().height;
      if (parentHeader) {
        header.style.zIndex = 9;
        header.style.top = `${
          breadcrumbHeight + cardTitleHeight + parentHeader - 1
        }px`;
        return;
      }
      header.style.top = `${breadcrumbHeight + cardTitleHeight}px`;
    });
  }

  function treatmentColorHandler(contentDivs) {
    contentDivs.forEach((card) => {
      if (
        contentDivs[0].classList.contains('breadCrumb__container') &&
        card.getBoundingClientRect().top <=
          contentDivs[0].getBoundingClientRect().bottom &&
        card.getBoundingClientRect().bottom >=
          contentDivs[0].getBoundingClientRect().top &&
        card.classList.contains('routine__card') &&
        contentDivs[0].textContent.includes('Tratamento')
      ) {
        setDynamicBreadcrumbColor(card.children[0].getAttribute('color'));
        return;
      }
      return;
    });
  }

  function scrollHandler() {
    let tabIds = [];
    let cardIds = [];
    routineContentRef.current.childNodes.forEach((tabs) => {
      routineContentRef.current.scrollTop >= tabs.offsetTop - 120 &&
        tabIds.push(tabs.id);

      tabs.querySelectorAll('.routine__card')?.forEach((card) => {
        routineContentRef.current.scrollTop >=
          tabs.offsetTop + card.offsetTop - 120 && cardIds.push(card.id);
      });
    });

    setHightlightedItem({
      tab: tabIds[tabIds.length - 1],
      card: cardIds[cardIds.length - 1],
    });

    routineContentRef.current.childNodes.forEach((child, i) => {
      if (i === 0) return;
      const contentDivs = child.childNodes;
      window.addEventListener('resize', setCardsTop(contentDivs));
      treatmentColorHandler(contentDivs);
    });

    debouceFunction(routineContentRef.current.scrollTop, location.key);
  }

  const debouceFunction = useCallback(
    _.debounce(async (e, i) => {
      sessionStorage.setItem(i, e);
    }, 500),
    [location.key]
  );

  useEffect(() => {
    routineContentRef.current.addEventListener('scroll', scrollHandler);
    return () => {
      window.removeEventListener('resize', setCardsTop);
      routineContentRef.current.removeEventListener('scroll', scrollHandler);
    };
  }, [location.key]);

  useEffect(() => {
    handleScrollByKeyOrURL();
  }, [routineContentRef.current]);

  return (
    <RoutineDetailStyle
      ref={routineContentRef}
      className={props.className}
      onLoad={(e) => {
        handleScrollByKeyOrURL();
      }}
    >
      {props.tabs?.map((tab, index) => (
        <div id={tab._id} style={{ position: 'relative' }}>
          {index !== 0 && (
            <BreadCrumbComponent
              className="breadCrumb__container"
              key={tab._id}
              color={
                tab.title.toLowerCase() === 'tratamento' && dynamicBreadcrumbColor
                  ? dynamicBreadcrumbColor
                  : tab.class_css
              }
            >
              <div className="breadCrumb__content">
                <h3 className="tab__title">{tab.title}</h3>
                <h3>
                  {props.title} - {props.legend}
                </h3>
              </div>
            </BreadCrumbComponent>
          )}
          {tab?.cards.map((card) => (
            <RoutineItem
              {...card}
              card_id={card._id}
              routine_id={props._id}
              isResumeCard={index === 0}
              key={card._id}
              isPaid={props.isBlocked}
              routine_title={`${props.title} - ${props.legend}`}
              tab_title={tab?.title}
              removeFavorite={removeFavorite}
              addFavorite={addFavorite}
              favorites={favorites?.cards || []}
            />
          ))}
        </div>
      ))}
    </RoutineDetailStyle>
  );
}

RoutineDetailComponent.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  legend: PropTypes.string,
  tab: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      cards: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          contents: PropTypes.arrayOf(PropTypes.node),
          color: PropTypes.string,
        })
      ),
    })
  ),
};

export { RoutineDetailComponent };
