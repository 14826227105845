export const Alarm = ({size = "25", color = "#d6d6d6"}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 4.12512C7.03 4.12512 3 8.12711 3 13.0626C3 17.998 7.02 22 12 22C16.97 22 21 17.998 21 13.0626C21 8.12711 16.97 4.12512 12 4.12512ZM12 20.0139C8.13 20.0139 5 16.9057 5 13.0626C5 9.21946 8.13 6.11122 12 6.11122C15.87 6.11122 19 9.21946 19 13.0626C19 16.9057 15.87 20.0139 12 20.0139Z"
      fill={color}
    />
    <path
      d="M22 5.83317L17.4 2L16.11 3.51936L20.71 7.35253L22 5.83317ZM7.88 3.51936L6.6 2L2 5.82324L3.29 7.3426L7.88 3.51936ZM12.5 8.09732H11V14.0556L15.75 16.8858L16.5 15.6643L12.5 13.3108V8.09732Z"
      fill={color}
    />
  </svg>
);
