import styled from "styled-components"
import BBLoading from './blackbook-loading.gif';

export const LoadingContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: max-content;
    width: max-content;
  }
`

export const BBLoadingGif = () => {
  return (
    <LoadingContainer>
          <img className="loading__gif" src={BBLoading} alt="Carregando..." />
        </LoadingContainer>
  )
}