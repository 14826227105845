import { WebAppTemplate } from '../../components/templates/WebAppTemplate';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
import { SearchComponent } from './SearchComponent';
import { useLazyQuery } from '@apollo/client';
import { HANDLE_SEARCH } from '../../components/templates/WebAppTemplate/query';
import { useEffect, useState } from 'react';
import cidList from '../../context/utils/CIDJson/subcat.json';
import calcList from '../../context/utils/calcListForSearch.json';

import { SuggestionComponent } from './SuggestionComponent';
import { useTrack } from '../../services/hooks/useTrack';
import { useSidebar } from '../../context/useSidebar';

export function WebAppSearch() {
  const history = useHistory();
  const params = useParams();

  const { handleGetMeuBlackbook } = useSidebar();
  const { trackEvent } = useTrack();

  const [isSearchComponentOpen, setIsSearchComponentOpen] = useState(false);
  const [searchResult, setSearchResult] = useState(null);

  const [inputValue, setInputValue] = useState('');

  const [handleSearch, searchResponse] = useLazyQuery(HANDLE_SEARCH, {
    fetchPolicy: 'cache-first',
    onCompleted: ({ search }) => {
      const newcid = search?.cid?.data.map(cid => parcedCID(cid)) || [];

      setSearchResult({...search, cid: {data: newcid, length: newcid.length}});

      const trackPayload = {
        'Número de resultados em Drogas': search?.drugs.length,
        'Número de resultados em Rotinas': search?.routines.length,
        'Número de resultados em Calculadoras': search?.calculator?.length,
        'Número de resultados em Cid': search?.cid?.length,
        Termo: inputValue,
      };

      trackEvent('Realizou uma busca', trackPayload);
    },
  });

  function parcedCID(item) {
    return {
      ...item,
      title: `${item.item_id} - ${item.title}`
    }
  }

  function searchForCalcs(input) {
    const calculatorsMatch = calcList
      .filter((e) =>
        e.title
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .trim()
          .toLowerCase()
          .includes(input)
      )
      .sort((a, b) => b?.title - a?.title);
    const temp = [];
    calculatorsMatch.forEach((match) => {
      const obj = {
        item_id: match.ref,
        type: 'calculator',
        title: match.title,
        free_access: true,
      };
      temp.push(obj);
    });
    return { data: temp, length: temp.length };
  }

  function searchForCid(input) {
    const parsedCidInput = input;
    const cidMatches = cidList
      .filter((e) => {
        const descParsed = e.DESCRICAO.normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .trim()
          .toLowerCase();
        const subcatParsed = e.SUBCAT.normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .trim()
          .toLowerCase();

        return (
          descParsed.includes(parsedCidInput) ||
          subcatParsed.includes(parsedCidInput) ||
          (subcatParsed + ' - ' + descParsed).includes(parsedCidInput)
        );
      })
      .sort((a, b) => (a?.SUBCAT > b?.SUBCAT ? 1 : -1));

    const temp = [];
    cidMatches.forEach((match) => {
      const obj = {
        aux: match.DESCRABREV,
        item_id: match.SUBCAT,
        type: 'cid',
        title: match.SUBCAT + ' - ' + match.DESCRICAO,
        free_access: true,
      };
      temp.push(obj);
    });
    return { data: temp, length: cidMatches.length };
  }

  useEffect(() => {
    if (!isSearchComponentOpen && !!inputValue) {
      setIsSearchComponentOpen(true);
    }
  }, [inputValue]);

  useEffect(() => {
    if (!!params?.search) {
      handleSearch({ variables: { term: params.search } });
    }
  }, [params?.search]);

  useEffect(() => {
    handleGetMeuBlackbook();
  }, []);

  function handleClickSuggestions(value) {
    setInputValue(value);
    setTimeout(() => {
      setIsSearchComponentOpen(false);
    }, 500);

    history.push('/app/search/' + value);
    // handleSearch({ variables: { term: value } });

    let recently_searched = localStorage.getItem('@recently_seached');
    if (recently_searched) {
      let parsed = JSON.parse(recently_searched);

      parsed = parsed.filter((e) => e !== value);

      parsed?.unshift(value);
      localStorage.setItem('@recently_seached', JSON.stringify(parsed));
    } else {
      const parsed = JSON.stringify([value]);
      localStorage.setItem('@recently_seached', parsed);
    }
  }

  function handlePressKey(e) {
    const input = document.querySelector('#inputHeader');

    if (e?.code === 'Enter' || e.keyCode === 13) {
      handleClickSuggestions(inputValue);
      input.blur();
    }
  }

  return (
    <WebAppTemplate
      inputValue={inputValue}
      setInputValue={setInputValue}
      isSearchComponentOpen={isSearchComponentOpen}
      setIsSearchComponentOpen={setIsSearchComponentOpen}
      handlePressKey={handlePressKey}
    >
      {isSearchComponentOpen && (
        <SuggestionComponent
          inputValue={inputValue}
          setInputValue={setInputValue}
          onClickSuggestion={handleClickSuggestions}
          setIsSearchComponentOpen={setIsSearchComponentOpen}
        />
      )}
      <SearchComponent
        isOpen={true}
        onClose={() => {
          // setInputValue("");
        }}
        response={{ ...searchResponse, searchResult: searchResult }}
        inputValue={inputValue}
      />
    </WebAppTemplate>
  );
}
