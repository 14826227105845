import styled from 'styled-components';
import { colors, routineColorSelector, fontFamily, fontSize, spacing } from '../../quarks/theme';

export const BreadCrumbStyle = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
   align-items: center;
   text-align: right;
   padding: ${spacing.medium} ${spacing.large};
   background-color: ${props => props.color ? routineColorSelector(props.color).dark : colors.black150};
   line-height: ${fontSize.large};
   max-width: 100%;

   .paid__button {
      display: flex;
      padding: 0; 
      background-color: transparent;
      border: none;
      cursor: pointer;

      transition: filter 0.2s;
      &:hover {
      filter: brightness(0.9);
      }

      svg >:not(.favorite_icon){
         fill: ${colors.primary};
      }
   }

   .title_tg {
      color: ${colors.white};
      font-family: ${fontFamily.secondary};
      font-style: normal;
      font-weight: 900;
      font-size: ${fontSize.medium};
      margin-right: 12px;
   }
`
