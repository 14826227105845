import { gql, useMutation } from "@apollo/client";
import { getAnonymousToken, getUserToken } from "../tokenService";

const TRACK_EVENT = gql`
  mutation SendEvent($args: JSON){
    sendEvent(args: $args)
  }
`;

export function useTrack() {
  const [sendEvent] = useMutation(TRACK_EVENT);

  const trackEvent = async (title, params) => {
    let body = {
      token: getUserToken(),
      anonymous_id: getAnonymousToken(), 
      event: {
        title: title,
        parameters: {
          time: new Date().getTime(),
          web: true,
          ...params,
        },
      },
    };
    await sendEvent({
      variables: {args:body}
    });
  };

  return { trackEvent };
}
