import { FooterComponent } from "../../molecules/footerComponent";
import { HeaderComponent } from "../../organisms/headerComponent";

import { ChildrenComponent } from './style';

export default function DefaultTemplate({children}) {
  return (
    <>
      <HeaderComponent />
      <ChildrenComponent>
        {children}
      </ChildrenComponent>

      <FooterComponent />
    </>
  )
}
