import styled from 'styled-components';

export const Form = styled.form`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;

  color: var(--white);

  gap: 1.25rem;

  .payment__title {
    font-size: 1.25rem;
    font-family: Open Sans;
    font-weight: 700;
  }

  .temp_123 {
    display: flex;
    width: 100%;
    gap: 1.25rem;
  }

  .installments__label {
    display: flex;
    flex-direction: column;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    color: #999;
    position: relative;

    span {
      font-size: 1rem;
      /* padding-left: 1rem; */
    }

    &::after {
      content: "";
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #8774FF;
      position: absolute;
      top: 45%;
      right: 12px;
      z-index: 98;
    }
  }

  select {
    padding: 1rem;
    color: white;
    font-size: 1rem;
    border: none;
    border-bottom: 1px solid var(--gray-100);
    background-color: unset;
    border-radius: 0;
        background-color: transparent;
        appearance: none;

    &:focus {
      outline: none;
    }

    option {
      color: black;
    }
  }

`;
