import React from 'react'
import { CardComponent } from '../../../../components/atoms/cardComponent'
import { TitleComponent } from '../../../../components/atoms/titleComponent'
import { UseTermsStyle } from './style'

export function UseTermsTextBox() {
  return (
    <UseTermsStyle>
        <TitleComponent>Termos de uso</TitleComponent>
        <CardComponent>
          <p className="terms__title">
            <strong>Boas vindas e introdução</strong>
          </p>
          <p>
            Bem vindo e muito obrigado por escolher, assinar e usar o Blackbook
            ("Blackbook", "nós", "nosso", "nosso serviço", "nosso conteúdo'').
          </p>
          <p>
            A Blackbook disponibiliza aos seus usuários assinantes ou
            credenciados conteúdos de suporte a decisão de condutas
            profissionais, ensino, atualização, educação continuada em forma de
            livros, aplicativo por assinatura, websites e outras interfaces,
            software de empresas associadas.
          </p>
          <p>
            Ao acessar e usar qualquer serviço ou conteúdo da Blackbook você
            estará celebrando um contrato vinculativo à pessoa jurídica da
            Blackbook indicada na sessão "Fale Conosco".
          </p>
          <p>
            Seu contrato com a Blackbook inclui os termos presentes e quaisquer
            termos adicionais que você vier a concordar nos termos e condições
            da seção "Íntegra do Acordo Contratual", assim como dos acordos
            celebrados com serviços de terceiros que usem o{' '}
            <strong>nosso conteúdo.</strong>
          </p>
          <p>
            Os termos deste contrato estão disponíveis para consulta do usuário
            no nosso aplicativo e website na seção de cadastro do usuário na aba
            "sobre" sob o título "Termos de uso e política de privacidade".
          </p>
          <p>
            Estão incluídos nestes termos as alterações realizadas
            posteriormente ao "termo de acordo inicial”, renovações automáticas,
            limitações de responsabilidades, privacidade, resolução de disputas
            preferencialmente por arbitragem.
          </p>
          <p>
            Antes de completar seu cadastro e assinatura, você atesta ter lido e
            compreendido os termos deste acordo e concordado em ficar vinculado
            a ele.
          </p>
          <p>
            Se você não concordar com os termos do acordo ou não puder
            cumpri-los, não poderá assinar ou usar nosso conteúdo.
          </p>
          <p>
            Os termos deste contrato não podem afetar nem alterar seus direitos
            legais como consumidor e a Blackbook tem como política da empresa
            procurar atender de forma rápida e plena qualquer demanda razoável
            relacionada à insatisfação de seus usuários ou assinantes.
          </p>
          <p className="terms__title">
            <strong>Alterações nos termos dos acordos</strong>
          </p>
          <p>
            Esporadicamente, dependendo das necessidades, podemos fazer
            alterações nos <strong>acordos e termos de condições</strong> por
            razões razoáveis, como melhorar os recursos tecnológicos e
            científicos existentes, acrescentar novas funções, recursos ou
            plataformas, ou promover ajustes técnicos para assegurar ou melhorar
            operacionalidade, performance ou segurança ao{' '}
            <strong>nosso serviço.</strong>
          </p>
          <p>
            Sempre que realizarmos mudanças nos{' '}
            <strong>acordos e termos de condições</strong>, você receberá
            previamente e com antecedência um aviso que pode ser por notificação
            destacada no aplicativo ou web page ou pelo seu email buscando sua
            confirmação e aceitação dos novos termos. Por isso, é importante que
            você leia cuidadosamente qualquer notificação desse tipo e, se você
            não quiser continuar a utilizar o <strong>nosso serviço</strong>{' '}
            dentro desses novos termos, você poderá encerrar sua conta entrando
            em contato conosco pelos nossos canais de atendimento.
          </p>
          <p>
            Se você tiver recebido uma Versão para Teste, uma assinatura de
            cortesia por tempo limitado ou uma assinatura paga por meio de
            terceiros, você deverá cancelar esta assinatura com a ajuda do
            terceiro como for necessário.
          </p>
          <p className="terms__title">
            <strong>Opções de planos e serviços</strong>
          </p>
          <p>
            Você pode encontrar a descrição dos planos e assinaturas de serviço
            no nosso site ou no aplicativo. Alguns conteúdos e serviços ficam
            disponíveis gratuitamente e a lista desses conteúdos "FREE" variam
            ao longo do tempo.
          </p>
          <p>
            Alguns planos de assinatura podem permitir um período de acesso
            livre sem custo ou a preço promocional por tempo definido, com
            extensão automática como "Assinatura Paga", como definido nos termos
            iniciais e se a assinatura não for cancelada antes do prazo
            pactuado.
          </p>
          <p>
            Outras opções exigem pagamento prévio para ter acesso e são chamadas
            de "Assinaturas Pagas", com diferentes níveis de acesso, ou seja,
            alguns planos podem dar acesso a apenas parte do "nosso conteúdo" e
            isso será explicado quando você assina os{' '}
            <strong>"nossos serviços"</strong>.
          </p>
          <p>
            Nós também podemos eventualmente oferecer planos, assinaturas ou
            serviços promocionais especiais, inclusive ofertas de serviços e
            produtos de terceiros em conjunto com ou através do{' '}
            <strong>"nosso serviço"</strong>. Não nos responsabilizamos pelos
            produtos e serviços prestados por terceiros.
          </p>
          <p>
            Você poderá comprar ou receber uma{' '}
            <strong>"Assinatura paga por código"</strong> por diversas formas
            que disponibilizam um código de acesso, como em forma de cartão
            pré-pago adquirido em livrarias e lojas on-line, cartão presente,
            oferta pré-paga, código de acesso de livro impresso comprado e
            cadastrado no nosso website, ou código comprado através de terceiros
            e, também nesses casos, se compromete com os mesmos termos e
            condições ao fazer o primeiro acesso aos nossos serviços e estará
            concordando em cumprir os presentes termos e condições. Termos
            adicionais ou condições distintas poderão ser aplicáveis pelo
            Serviço parceiro que você contratou.
          </p>
          <p>
            Nos reservamos o direito de modificar, encerrar ou alterar nossos
            planos de assinatura e ofertas promocionais a qualquer momento de
            acordo com estes <strong>"termos de condições"</strong>.
          </p>
          <p className="terms__title">
            <strong>Testes ou degustação</strong>
          </p>
          <p>
            Algumas vezes, a Blackbook ou terceiros em nosso nome poderão
            oferecer testes de "Assinaturas Pagas" sem pagamento ou com uma taxa
            reduzida por um tempo de teste ou degustação especificado.
          </p>
          <p>
            A Blackbook se reserva o direito de confirmar ou não sua
            elegibilidade para o teste ou degustação gratuita ou interrompê-lo a
            qualquer momento e sem aviso prévio ou responsabilizações, de acordo
            com o previsto na legislação aplicável.
          </p>
          <p>
            Mesmo nos testes ou degustações, a Blackbook exige que você faça seu
            cadastro e forneça seus dados de pagamento para iniciar o primeiro
            acesso. Ao fornecer esses dados, você concorda que podemos começar a
            cobrar automaticamente por uma Assinatura Paga no primeiro dia
            seguinte ao fim do prazo pactuado de Teste ou Degustação, em uma
            base mensal recorrente, ou outro intervalo que antecipadamente
            divulgarmos para você.
          </p>
          <p>
            SE VOCÊ NÃO QUISER CONTINUAR COM O SERVIÇO PAGO, VOCÊ DEVE CANCELAR
            A ASSINATURA PAGA AUTOMÁTICA ANTES DO FIM DO TESTE, UTILIZANDO O
            PRÓPRIO APLICATIVO OU NOSSOS CANAIS DE ATENDIMENTO OU, SE VOCÊ
            RECEBEU O ACESSO PARA TESTE ATRAVÉS DE TERCEIROS, VOCÊ DEVE CANCELAR
            A ASSINATURA PAGA AUTOMÁTICA ATRAVÉS DESTE SERVIÇO DE TERCEIRO.
          </p>
          <p className="terms__title">
            <strong>Pagamento</strong>
          </p>
          <p>
            Você pode adquirir sua Assinatura Paga do Blackbook pela APPLE
            STORE, GOOGLE PLAY ou outro serviço terceirizado ou diretamente da
            Blackbook pagando uma taxa de assinatura mensal, trimestral,
            semestral, anual ou com outra periodicidade definida nos planos de
            pagamento disponíveis no momento da compra.{' '}
          </p>
          <p>
            Alguns planos garantem um período de teste ou degustação e o início
            do período de Assinatura Paga poderá ser evitado se a assinatura for
            cancelada antes do fim deste período de teste.
          </p>
          <p>
            Qualquer forma de pagamento antecipado do tipo pré-pago garante o
            acesso apenas pelo período especificado ou enquanto durar o crédito
            específico.
          </p>
          <p className="terms__title">
            <strong>Alterações de preço das assinaturas</strong>
          </p>
          <p>
            A Blackbook poderá, periodicamente, alterar o preço das Assinaturas
            Pagas, incluindo taxas de assinatura recorrentes, do Período
            Pré-pago (para os períodos ainda não pagos) ou das formas de
            <strong>Assinatura paga por código</strong>, desde que comunique
            antecipadamente qualquer alteração de preço para você e explicite as
            formas de cancelamento da assinatura se você não aceitar as
            alterações em questão. Mudanças de preço só poderão surtir efeitos
            após o fim do período da assinatura vigente ou na primeira renovação
            automática prevista no plano contratado após a data da alteração do
            preço.
          </p>
          <p>
            Você estará aceitando o novo preço ao continuar a usar o{' '}
            <strong>nosso serviço</strong> e acessando{' '}
            <strong>nosso conteúdo</strong>, após a mudança de preço surtir
            efeitos. Se você não concordar com uma alteração de preço, você tem
            o direito de rejeitar a alteração cancelando sua{' '}
            <strong>Assinatura Paga</strong>
            antes que a alteração de preço entre em efeito.
          </p>
          <p>
            Se você se cadastrar para uma Assinatura Paga, você poderá mudar de
            ideia ou se arrepender da compra por qualquer motivo e receber um
            reembolso total de todos os valores pagos dentro de um período de
            até catorze 14 dias a contar do dia em que você assinou o serviço
            ("Período de Arrependimento").
          </p>
          <p>
            Se você se cadastrou para um Teste ou Degustação adquirido por
            qualquer forma, você estará concordando que este Período de
            Arrependimento para a Assinatura Paga da qual você está recebendo um
            Teste termina após 14 dias do início do Teste.{' '}
          </p>
          <p>
            Se você não cancelar a Assinatura Paga antes do fim do Teste, você
            perde este o seu direito de desistência e autoriza a Blackbook a
            cobrar mensalmente ou na periodicidade definida o preço acordado,
            até que você cancele a Assinatura Paga.
          </p>
          <p>
            Se você comprar uma "Assinatura Paga" sem período de Teste ou
            degustação, você autorizará a Blackbook a fazer a cobrança
            automaticamente a cada mês, até que você cancele a assinatura. Você
            concorda que o Período de Arrependimento será de 14 dias após sua
            compra, mas será perdido quando você usar o serviço Blackbook após o
            fim deste período.
          </p>
          <p className="terms__title">
            <strong>Renovação e cancelamento da assinatura</strong>
          </p>
          <p>
            A "Assinatura Paga" adquirida através da APPLE STORE ou GOOGLE PLAY
            ou serviços similares ou compradas diretamente da Blackbook será
            renovada automaticamente ao final do período da assinatura a menos
            que você faça o cancelamento dessa assinatura antes do seu
            encerramento.
          </p>
          <p>
            A assinatura por algum sistema pré-pago ou{' '}
            <strong>"Assinatura paga por código"</strong> não será renovada
            automaticamente e sua assinatura será encerrada automaticamente ao
            fim do período indicado código, mas seu acesso e uso dos nossos
            serviços poderá ser retomado com nova assinatura usando o mesmo
            LOGIN e SENHA anterior do usuário, com manutenção de seus dados
            ("Meu Blackbook” e outros).
          </p>
          <p>
            Se você comprou uma assinatura por preço não promocional, você tem
            direito de interromper ou cancelar sua assinatura com um acordo
            direto com a Blackbook entre o tempo do final do “Período de
            Arrependimento” e o final do período contratado na assinatura
            requerendo a devolução dos valores pagos proporcionais já abatidos
            dos custos cobrados por terceiros pela intermediação da compra.
          </p>
          <p>
            Qualquer assinatura adquirida através de terceiros só poderá ser
            cancelada diretamente com este terceiro em questão.
          </p>
          <p>
            Se você comprou uma assinatura por preço promocional vinculado a um
            maior tempo de assinatura, nós não reembolsaremos os valores e taxas
            pagas após o “Período de Arrependimento”.
          </p>
          <p>
            Qualquer reembolso será realizado preferencialmente pelo mesmo
            método que foi utilizado para o pagamento, salvo concordância da
            Blackbook com a forma escolhida pelo assinante.
          </p>
          <p className="terms__title">
            <strong>Diretrizes e limites do uso</strong>
          </p>
          <p>
            Os serviços da Blackbook são principalmente o acesso a conteúdos de
            propriedade da Blackbook ou licenciados a esta por terceiros. Ao
            assinante é concedida uma permissão limitada, revogável, não
            exclusiva para fazer uso do serviço Blackbook e acessar nosso
            conteúdo para dele fazer uso pessoal e não comercial apenas.
          </p>
          <p>
            Ao comprar uma Assinatura Paga ou ter acesso a um teste ou
            degustação, você concorda e promete que vai usar nosso serviço e
            conteúdo apenas para uso pessoal e não comercial e que não irá
            distribuir, compartilhar ou transferir esse serviço ou conteúdo.
          </p>
          <p>
            O conteúdo, software ou aplicativos Blackbook não são vendidos ou
            transferidos para você e a Blackbook ou seus parceiros licenciantes
            de conteúdo retêm a titularidade de todas as cópias dos aplicativos,
            softwares e direitos autorais relacionados aos conteúdos em forma de
            texto, imagem, áudio ou códigos, mesmo após a instalação destes em
            seus telefones celulares, tablets, computadores ou outros
            dispositivos de gravação ou de interface.
          </p>
          <p>
            Nosso conteúdo somente pode ser usado pelos usuários titulares da
            assinatura para os objetivos explicados acima. É proibido para o
            usuário fornecer a terceiros ou divulgar sua senha de acesso ou
            vender, alugar, sublicenciar qualquer parte do nosso conteúdo ou
            serviços.
          </p>
          <p>
            Também é proibido divulgar ou comunicar a terceiro qualquer parte do
            nosso conteúdo ou serviços sem o consentimento prévio e por escrito
            da Blackbook e nem estes podem ser utilizados para criar qualquer
            software, aplicativo ou serviço substancialmente similar em sua
            expressão a qualquer parte do conteúdo ou serviço Blackbook.
          </p>
          <p>
            Você entende que está proibido, por qualquer motivo ou meio, de
            copiar, transferir, distribuir, reproduzir, "ripar", descompilar,
            gravar, disponibilizar ou facilitar o acesso ao público (cache,
            drive, nuvem) qualquer parte do nosso conteúdo ou serviço, nem criar
            sistemas capazes de realizar "crawling" dos serviços Blackbook ou
            usar quaisquer formas automatizadas, incluindo bots, scrapers e
            spiders para visualizar, acessar e coletar qualquer parte do{' '}
            <strong>nosso conteúdo</strong>.
          </p>
          <p>
            Todas as marcas, nomes comerciais, logotipos, nomes de domínio e
            quaisquer outras <strong>características da marca Blackbook</strong>{' '}
            são de propriedade exclusiva da Blackbook ou de seus parceiros
            licenciantes. Os Acordos não concedem a você quaisquer direitos de
            usar quaisquer <strong>características da marca Blackbook</strong>,
            tanto para uso comercial ou não comercial.
          </p>
          <p>
            A Blackbook não concede ao assinante nenhum direito, título ou
            interesse relativos ao Serviço Blackbook ou ao nosso conteúdo, além
            do que está explicitado neste{' '}
            <strong>contrato e termos de condições</strong>.
          </p>
          <p>
            Sua senha protege sua conta de usuário e você é o único responsável
            por mantê-la em confidencialidade e segurança. Você entende que é
            responsável por todo o uso, mesmo não autorizado do seu nome de
            usuário e senha e que caso você acredite que estes tenham sido
            perdidos ou roubados, você deve nos notificar imediatamente e
            alterar sua senha assim que possível.
          </p>

          <p className="terms__title">
            <strong>
              Aplicativos, softwares, conteúdos e serviços de terceiros
            </strong>
          </p>
          <p>
            Alguns serviços e produtos propriedade de terceiros, como
            bibliotecas de conteúdos, videoaulas, podcasts, acessos a serviços,
            softwares específicos, podem ser disponibilizados de forma integrada
            aos nossos serviços para quem detém alguma forma de "Assinatura
            Paga" Blackbook válida e ativa.
          </p>
          <p>
            Estes aplicativos, softwares, conteúdos ou dispositivos de terceiros
            podem ter seus próprios termos e condições de uso e políticas de
            privacidade, cujos termos são publicados na seção de ajuda e de
            configurações dos nossos aplicativos, sites ou softwares ou no site
            do parceiro licenciante.
          </p>
          <p>
            Você entende e concorda que a Blackbook não endossa e não é
            responsável pelo comportamento, conteúdo destes recursos e conteúdos
            adicionais produzidos e disponibilizados por terceiros e nem
            tampouco garante sua compatibilidade inicial ou contínua com os
            aplicativos e dispositivos usados pelo assinante.
          </p>
          <p className="terms__title">
            <strong>Conteúdos por links de sites externos</strong>
          </p>
          <p>
            Dentro do nosso conteúdo pode ser usado como informação complementar
            ou como referência de fonte para estudo complementar links para
            sites de terceiros contendo conteúdos em diversos formatos
            disponíveis na web, como os em forma de textos técnicos, rotinas ou
            artigos científicos, vídeos ou calculadoras.
          </p>
          <p>
            Estes conteúdos podem ter direitos autorais de terceiros que
            precisam ser respeitados pelos usuários conforme descrito nestas
            mesmas fontes.
          </p>
          <p>
            Se você acredita que a disponibilização de link para qualquer desses
            conteúdos de terceiros viola seus direitos de propriedade
            intelectual ou outros direitos, entre imediatamente em contato
            conosco nos comentários do próprio aplicativo ou nos nossos canais
            de atendimento para que o link seja removido ou ajustes de
            licenciamento sejam realizados.
          </p>
          <p className="terms__title">
            <strong>Conteúdo gerados pelo usuário</strong>
          </p>
          <p>
            A Blackbook pode disponibilizar aos seus assinantes ou postar em
            seus site ou redes sociais contribuições autorais de terceiros,
            sobretudo de assinantes, que assim solicitarem e autorizarem. Nesse
            caso, essa autoria será sempre devidamente identificada junto com um
            resumo de seu currículo acadêmico e profissional.
          </p>
          <p>
            Quando você enviar algum conteúdo para ser compartilhado, você
            concorda que está transferindo o direito de uso de sua propriedade
            intelectual, autoria e personalidade de uma forma não exclusiva e
            não permanente, mas que essa colaboração não implica em nenhum
            direito de terceiro, nem qualquer filiação nem endosso da Blackbook
            ao que foi publicado.
          </p>
          <p>
            A Blackbook possui uma rigorosa política de proteção de seu conteúdo
            de interesses comerciais e conflitos de interesse da indústria
            farmacêutica, de diagnóstico, insumos e equipamentos médicos e, por
            isso, é proibido que qualquer usuário submeta para publicação textos
            ou conteúdos com objetivo de favorecer terceiros para obter qualquer
            tipo de vantagem ou pagamento ou que tenha conflitos de interesse
            relacionados. A Blackbook não pede e nem aceita qualquer tipo de
            pagamento ou vantagem destes agentes. Se você acredita que qualquer
            parte do nosso conteúdo não está em conformidade com estas
            diretrizes ou que algum colaborador em nossos conteúdos possui algum
            conflito de interesse nesse sentido, por favor entre imediatamente
            em contato conosco nos comentários do próprio texto no aplicativo ou
            nos nossos canais de atendimento (Fale Conosco).
          </p>
          <p>
            Apesar de fazer uma rigorosa monitorização da qualidade dos
            conteúdos de terceiros, a Blackbook não tem a obrigação de
            monitorar, revisar, corrigir ou editar o Conteúdo de Usuário. Em
            todos os casos, a Blackbook se reserva o direito de remover ou
            desabilitar o acesso a qualquer Conteúdo de Usuário, por qualquer
            motivo, incluindo Conteúdo de Usuário que, a critério exclusivo da
            Blackbook, viole os Acordos. A Blackbook pode tomar essas ações sem
            notificação prévia para você ou qualquer terceiro. Remover ou
            desabilitar o acesso ao Conteúdo de Usuário será de nosso exclusivo
            critério, e não prometemos remover ou desabilitar o acesso a
            qualquer Conteúdo de Usuário específico.
          </p>
          <p>
            A Blackbook respeita e garante os direitos dos titulares de
            propriedade intelectual e espera que seus assinantes e colaboradores
            façam o mesmo. Se você acredita que qualquer conteúdo publicado pela
            Blackbook viola seus direitos de propriedade intelectual ou outros
            direitos, entre imediatamente em contato conosco nos comentários do
            próprio aplicativo ou nos nossos canais de atendimento para que o
            problema possa ser resolvido. Caso o autor, colaborador Blackbook ou
            usuário autor de conteúdo assinado achar que o conteúdo não é
            ilícito, poderá dentro de limites razoáveis, apresentar uma
            contra-notificação ao reclamante, podendo a Blackbook a seu
            critério, remover o conteúdo questionado do ar até a resolução do
            problema.
          </p>
          <p>
            A Blackbook poderá a seu critério remover ou desabilitar o conteúdo
            de terceiros e se compromete a retirar tais conteúdos à pedido dos
            autores dentro de um prazo tecnicamente razoável.
          </p>
          <p>
            O autor desse tipo de conteúdo de terceiros, cuja autoria seja
            devidamente identificada, é o único e exclusivo responsável por
            todos os conteúdos que postar e concorda que caso alguém mova ação
            contra a Blackbook relacionada a estes conteúdos de sua autoria,
            deverá indenizar e manter a Blackbook como indene de quaisquer
            danos, perdas e despesas, incluindo custos de honorários de
            advogados decorrentes de tal ação.
          </p>
          <p>
            Os termos do presente acordo continuarão a ser aplicáveis até que
            sejam encerrados por você ou pela Blackbook, entretanto, você
            reconhece e concorda que a licença concedida por você em relação a
            algum conteúdo do usuário, incluindo o Feedback, é irrevogável e
            perdurará após a expiração ou término do acordo, por qualquer
            motivo.
          </p>
          <p className="terms__title">
            <strong>Contrapartidas técnicas</strong>
          </p>
          <p>
            Em contraprestação aos direitos de uso dos nossos serviços e
            conteúdos, você nos concede o direito de usar o processador, banda
            larga e armazenamento do seu telefone celular, computador, tablet ou
            outro dispositivo eletrônico que usar para acessar nosso conteúdo.
          </p>
          <p>
            Se você fornecer feedback, elogios, críticas, ideias ou sugestões
            para a Blackbook em relação aos nossos serviços ou conteúdo, você
            entende que esse seu feedback não é confidencial e autoriza a
            Blackbook usá-lo sem restrições e sem necessidade de pagamento a
            você. Isso significa que, exceto se no próprio texto de feedback
            estiver explicitado que se trata de uma mensagem confidencial, seu
            conteúdo será tratado pela Blackbook da mesma forma que os demais
            <strong>conteúdos gerados pelos usuários</strong> como descrito
            neste termo de acordo.
          </p>
          <p>
            Para permitir contatos de contratação, renovação de assinatura,
            promoções; assim como informações complementares sobre otimização do
            uso e de novos recursos ou novos conteúdos, o usuário concorda que
            sejam usados contatos por email, telefone ou aplicativos de
            mensagem.
          </p>
          <p className="terms__title">
            <strong>Suporte e garantias</strong>
          </p>
          <p>
            A Blackbook fará todos os esforços técnicos razoáveis para manter
            seu serviço operacional. No entanto, é possível que algumas
            dificuldades técnicas, atualizações e manutenção regulares,
            adaptação a mudanças de legislação ou por determinação legal,
            implementação de testes ou de melhorias tecnológicas para aprimorar
            operabilidade e segurança possam causar alterações operacionais
            relevantes ou mesmo causar interrupções temporárias.
          </p>
          <p>
            A Blackbook fará todos os esforços para aprimorar, ampliar e manter
            atualizados seus serviços e conteúdos, mas você entende e concorda
            que você está assinando nosso serviço "como está" e "conforme
            disponível".
          </p>
          <p>
            O suporte ao cliente em questões relacionadas a conta, assinatura e
            pagamento será feito pelos canais internos do aplicativo, telefone,
            e-mail ou Whatsapp discriminados no "Fale conosco". Faremos todos os
            esforços razoáveis para responder a todas as consultas de suporte ao
            cliente no menor prazo possível, dentro de um período tecnicamente
            razoável.
          </p>
          <p>
            Se algum problema técnico relacionado aos nossos serviços prejudicar
            seu acesso por prazo mais prolongado, você poderá solicitar que a
            Blackbook prolongue sua assinatura por prazo equivalente sem custos
            para você.
          </p>
          <p>
            Você entende e concorda que em caso de insatisfação com o produto ou
            seu desempenho você poderá cancelar sua assinatura e desinstalar o
            aplicativo do seu dispositivo. Neste caso seu feedback do motivo da
            insatisfação será bem vindo e a Blackbook poderá comunicá-lo quando
            o problema tiver sido resolvido e convidá-lo para retomar a
            assinatura.
          </p>
          <p className="terms__title">
            <strong>Limitação de responsabilidade</strong>
          </p>
          <p>
            A Blackbook é cuidadosa e meticulosa nos cuidados para evitar erros
            no seu conteúdo, sobretudo os que possam resultar em riscos
            relacionados ao diagnóstico e tratamento dos pacientes. Apesar de
            todos os nossos cuidados, é possível que alguma informação errada
            possa ser publicada e que não tenha sido detectada a tempo por nosso
            corpo editorial ou por comunicação dos nossos assinantes. Por isso,
            e também por causa da rápida evolução das ciências médicas,
            sobretudo nas áreas de diagnósticos e posologias de tratamento,
            recomendamos que informações críticas em termos de segurança para o
            paciente sejam confirmadas de forma independente em outras fontes ou
            confrontadas com as informações do fabricante de medicamentos e
            produtos médicos. É importante que os profissionais usem como base
            seu conhecimento e experiência para avaliar de forma crítica
            qualquer informação, proposta de conduta, dados sobre medicamentos e
            outros produtos.
          </p>
          <p>
            É muito importante que o assinante nos informe de qualquer dúvida
            sobre a exatidão de informações críticas como posologia de
            medicamentos, parâmetros de diagnóstico ou condutas que possam
            alterar a evolução e mortalidade. É possível fazer esse
            questionamento em cada bloco de informação do nosso aplicativo
            (ícone roxo em cima à direita de cada CARD, na aba "reportar
            erros").
          </p>
          <p>
            Assim como as demais editoras de livros médicos e produtoras de
            conteúdos médicos, para todos os efeitos legais, a Blackbook, seus
            autores, editores, colaboradores, revisores, diretores e
            proprietários não assumem responsabilidade por qualquer dano ou
            prejuízo causado à pessoas ou propriedades envolvendo
            responsabilidade ou forma de uso ou aplicação de produtos,
            instruções ou ideias contidos ou publicados no seu conteúdo.
          </p>
          <p className="terms__title">
            <strong>Eleição de foro ou juízo e arbitragem obrigatória</strong>
          </p>
          <p>
            Você e a Blackbook concordam em resolver qualquer demanda ou disputa
            de forma amigável ou, quando isso não for possível, usar de forma
            preferencial acordos em sistemas de arbitragem ou de direitos do
            consumidor antes de utilizar os tribunais para demandas legais.
            Neste último caso, você e a Blackbook elegem o foro de Belo
            Horizonte para a solução judicial de qualquer demanda ou disputa
            relacionada a este contrato.
          </p>
        </CardComponent>
      </UseTermsStyle>
  )
}
