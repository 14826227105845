import { gql } from "@apollo/client";
import { handleSetLoginToken } from '../../tokenService';
import { client } from "./Apollo";

const REFRESH_TOKEN_MUTATION = gql`
  mutation RefreshToken {
    refreshToken {
      token
      _id
    }
  }
`;

export async function RefreshToken() {
  function isAbleToRefresh() {
    const twelveHours = 1000 * 60 * 60 * 12;
    const refreshedAt = localStorage.getItem('BBTIME')
    return !refreshedAt || new Date().getTime() > refreshedAt + twelveHours;
  }

  if (isAbleToRefresh()) {
    try {
      const res = await client.mutate({
        mutation: REFRESH_TOKEN_MUTATION
      })

      await handleSetLoginToken(res.data.refreshToken.token)
      localStorage.setItem('BBTIME', new Date().getTime())
    } catch (error) {

    }
  }
  return true

}