import { useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import DefaultTemplate from '../../components/templates/DefaultTemplate';
import { CreditCardPaymentComponent } from "../../components/atoms/CreditCardPaymentComponent";
import { ButtonComponent } from "../../components/atoms/buttonComponent";
import { paymentApi } from "../../services/clients";
import { Spinner } from "../../blackbook-components/quarks/spinner";

import { Container } from './style';


export function UpdatePayment() {
  const history = useHistory()

  const [loading, setLoading] = useState(false);
  

  async function handleSubmitMethod(method) {
    setLoading(true);
    const payload = {
      type: method.type,
      card_token: method.card_token,
    };  

    try {
      await paymentApi.changePaymentMethod(payload);

      toast.success('Solicitação de alteração de método de pagamento enviada com sucesso!', {
        autoClose: 7000
      });
      history.push('/manageplan')
    } catch (error) {
 
      if (error?.response?.status === 422) {
        toast.error(
          'Não foi possível validar este cartão. Tente outros dados ou fale conosco.'
        );
      } else {
        toast.error(
          'Ocorreu um erro! Tente novamente mais tarde.'
        );
      }
    }
    setLoading(false);
  }

  
  return (
    <DefaultTemplate>
      <Container>
        <h2 className="changeMethod__title">Atualiação de método de pagamento</h2>
        <CreditCardPaymentComponent
          showInstallments={false}
          handleSubmitPayment={(e) => handleSubmitMethod(e)}
        />

        <ButtonComponent
          buttonProp={{
            form: 'payment-form',
            className: 'paymentBox__button',
            type: 'submit',
            disabled: loading && loading,
          }}
        >
          {loading ? (
            <Spinner height="0.75rem" stroke_width="5" />
          ) : (
            'ALTERAR MÉTODO DE PAGAMENTO'
          )}
        </ButtonComponent>
        <ButtonComponent
          buttonProp={{
            className: 'outlined__button',
            type: 'button',
            onClick: () => history.push('/manageplan')
          }}
        >
          CANCELAR
        </ButtonComponent>
      </Container>
    </DefaultTemplate>
  );
}
