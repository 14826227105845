import { getUserToken } from "../tokenService";
import { BaseClient } from "./baseClient";


class PlanApiClient extends BaseClient {

  constructor() {
    super(process.env.REACT_APP_PLAN_API);
  }

  async getActivePlan() {
    return this.get(`/user/active-plan`, {headers: {'Authorization': 'Bearer ' + getUserToken()}})
  }

  async getSuggestedPlans() {
    return this.get(`/user/suggested-plans`, {headers: {'Authorization': 'Bearer ' + getUserToken()}})
  }

  async getPlanByIdentifier(planIdentifier) {
    return this.get(`/plan/by-identify/${planIdentifier}`, {headers: {'Authorization': 'Bearer ' + getUserToken()}})
  }

  async getPlanHistory(){
    return this.get(`/user/history-plans`, {headers: {'Authorization': 'Bearer ' + getUserToken()}})
  }
}

export const planApi = new PlanApiClient();
