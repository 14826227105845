import DefaultTemplate from '../../components/templates/DefaultTemplate';

import { Container, NavigateToAppButton } from './style';
import { useHistory, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { DownloadPage } from '../DownloadPage';
import { useEffect } from 'react';
import { useTrack } from '../../services/hooks/useTrack';
import { useIsDesktopOrTabletScreen } from '../../services/hooks/useIsDesktopOrTabletScreen';

export function HiredPlanThanks() {
  const history = useHistory();
  const location = useLocation();

  const { trackEvent } = useTrack()
  const isDesktop = useIsDesktopOrTabletScreen();

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 717px)',
  });

  window.dataLayer.push({
    event: "pageview",
  });

  useEffect(()=>{
    let plan = ''
    if(location.pathname.includes('giftcard')){
       const giftPlan = localStorage.getItem("GIFT_TIME");
       switch (giftPlan) {
        case 1:
          plan = "Mensal" 
          break;
        case 3: 
          plan = "Trimestral"
          break;
        case 6:
          plan = "Semestral"
          break;
        case 12:
          plan = "Anual"
          break;
        default:
          plan = giftPlan
          break;
       }
    } else {
      const planString = location.pathname.replace('/thanks/', '').replace('_contratado','')
      plan = planString.charAt(0).toUpperCase() + planString.slice(1);
    }
    trackEvent('Paywall - Assinatura concluída', {'device': isDesktop ? 'large':'small', 'plano':plan, 'site':'app.blackbook/thanks'})
    localStorage.removeItem('GIFT_TIME')
  },[])

  if (isDesktopOrLaptop) {
    return (
      <DefaultTemplate>
        <Container>
          <div className="userDetails__container">
            <h2 className="container__header">
              Seu plano foi contratado com sucesso!
            </h2>
            <p></p>
            <h1 className="container__header">
              Obrigado por escolher o Blackbook!
            </h1>
            <NavigateToAppButton
              type="button"
              onClick={() => history.push('/app')}
            >
              IR PARA O CONTEUDO
            </NavigateToAppButton>
          </div>
        </Container>
      </DefaultTemplate>
    );
  }

  return <DownloadPage />;
}
