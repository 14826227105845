import { useParams } from "react-router-dom";
import { SubAccordionComponent } from "../../../blackbook-components/atoms/accordion";
import { PopupButtonComponent } from "../../../blackbook-components/molecules/popup-button";
import { colors } from "../../../blackbook-components/quarks/theme";
import { usePopup } from "../../../context/usePopup";
import { AccordionTable } from "../../atoms/AccordionTable";

function parseHTML(content, popup) {
  return { __html: content };
}

const extraContentTypes = {
  "604919f56b2a04ced212fef4": (extra) => (
    <SubAccordionComponent title={extra.title} type="green" className="extracontent__accordion">
      <AccordionTable extra={extra} />
    </SubAccordionComponent>
  ),
  "604919f56b2a04ced212fef6": (extra, clickFunction) => (
    <PopupButtonComponent
      theme={{
        color: `${colors.hardGreen}`,
        fontColor: 'white',
      }}
      title={`${extra.title}`}
      clickCallback={() => clickFunction(extra)}
    />
  ),
  "604919f56b2a04ced212fef5": (extra) => (
    <div className="extraContent">
      <img className="extraContent__image" src={`${extra.content}`} alt="" />
    </div>
  )
}


export const DosageTemplates = ({ dosage, title }) => {
  const { setPopup } = usePopup();
  const { section_id } = useParams()

  function validateifIsOpenByURL(id) {
    return id === section_id
  }

  if (dosage?.length === 0) return null;

  return (
    <SubAccordionComponent title={title} type="green" isOpen={true}>
      {dosage
        ?.slice()
        .sort((a, b) => Number(b.is_usual) - Number(a.is_usual))
        .map((item) => (
          <SubAccordionComponent
            id={item._id}
            data-parent="dosage"
            title={item.recommendation}
            isOpen={item.is_usual || validateifIsOpenByURL(item._id)}
            type="green grandchild"
          >
            <hr className="default__separator dosage" />
            <div
              className="content__style dosageContent"
              dangerouslySetInnerHTML={parseHTML(item.content)}
            ></div>
            {item.extra_content.length > 0 && (
              <div>
                {item.extra_content
                  ?.slice()
                  .sort((a, b) => a.order - b.order)
                  .map((extra) =>
                    extraContentTypes[extra.type._id](extra, setPopup)
                  )}
              </div>
            )}
          </SubAccordionComponent>
        ))}
    </SubAccordionComponent>
  );
};