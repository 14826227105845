import { getTemporaryUserToken, getToken } from "../tokenService";
import { BaseClient } from "./baseClient";


class AuthApiClient extends BaseClient {

  constructor() {
    super(process.env.REACT_APP_AUTH_API);
  }

  async login(email, password) {
    const token_id_push = 'web_login';
    return await this.post('auth/login', { email, password, token_id_push });
  }

  async recoveryPassword(email) {
    return this.post('auth/send-recovery', { email });
  }

  async getUser() {
    const userToken = getToken();
    return this.get('/user', {headers: {'Authorization': `Bearer ${userToken}`}});
  }

  async createUser(data) {
    return this.post('/auth', data);
  }

  async updateUser(data) {
    const tempToken = getTemporaryUserToken()
    const token = getToken();

    return this.put('/user', data, {headers: {'Authorization': `Bearer ${tempToken || token}`}});
  }

  async getStates() {
    return this.get('/utils/states');
  }

  async getActuationAreas() {
    return this.get('/utils/actuation-area');
  }

  async getValidEmail(email) {
    return this.get(`/auth/check-email/`, { params: { email: email }});
  }
}

export const authApi = new AuthApiClient();
