import styled from 'styled-components';

export const ContainerStyle = styled.div`
 background-color: #121212;
 display: flex;
 flex-direction: column;

 @media screen and (min-width: 1020px) {
    /* width: 375px; */
    margin: 0 auto;
 }

 @media screen and (max-width: 717px) {
    /* width: 375px; */
   min-height: 100vh;

 }
 
`
