/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import { paymentApi, planApi } from '../../services/clients';
import { PlanComponent } from '../../components/molecules/planComponent';
import { PlanManagerComponent } from '../../components/molecules/PlanManagerComponent';

import { Container } from './style';
import { useHistory } from 'react-router-dom';
import { HistoryTable } from '../../blackbook-components/molecules/history-table';
import { useUser } from '../../context/useUser';
import { NewDefaultTemplate } from '../../components/templates/NewDefaultTemplate';

export function ManagePlan() {
  window.scrollTo(0, 0);
  const history = useHistory();
  const { activePlan } = useUser()

  const [suggestedPlans, setSuggestedPlans] = useState(null);

  const getPlans = async () => {
    const response = await planApi.getSuggestedPlans();
    return setSuggestedPlans(response);
  };

  async function handleGetIfHasPaymentInProcess() {
    try {
      const processingPaymentResponse = await paymentApi.userHasPaymentProcessing()
      if(processingPaymentResponse) {
        history.push('/')
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (!suggestedPlans && activePlan && (activePlan?.origin !== 'play_store' && activePlan?.origin !== 'app_store')) {
      getPlans();
    }
    handleGetIfHasPaymentInProcess()
  }, [activePlan]);

  return (
    <NewDefaultTemplate>
      <Container>
        <div className="first__container">
          <PlanManagerComponent />
          <HistoryTable />
          <button
            className="giftCard__button"
            onClick={() => history.push('/giftcard')}
          >
            TENHO UM GIFT CARD
          </button>
          <button 
            className="giftCard__button"
            style={{backgroundColor: "var(--green-base)"}}
            onClick={() => {
              history.push('/app')
            }}
          >
            SAIR E VOLTAR PARA CONTEÚDO
          </button>
        </div>
        {suggestedPlans && (
          <div className="suggestedPlans__container">
            <h2 className="suggestedPlans__title">Faça um upgrade: </h2>
            <div className="suggestedPlans__content">
              {suggestedPlans?.prices
                .sort((a, b) => b.availablePeriod - a.availablePeriod)
                .map((plan) => (
                  <PlanComponent
                    key={plan.name}
                    planParent={{
                      name: suggestedPlans.name,
                      store_identify: suggestedPlans.store_identify,
                    }}
                    plan={plan}
                  />
                ))}
            </div>
          </div>
        )}
      </Container>
    </NewDefaultTemplate>
  );
}
