import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { UserContextAplication } from '../../../context/useUser';
import defaultUserImage from '../../../assets/images/default-avatar.png';
import { handleRemoveIndicationCouponOfLocalStorage } from '../../../services/cache/indicationCoupon';

import { UserSection } from './style';

export function UserSectionComponent({ togglePopup, showPlans = false }) {
  const { userProperties, handleClearUserData } = useContext(
    UserContextAplication
  );

  const location = useLocation()

  // function handleOpenChat() {
  //   if (window.fcWidget) {
  //     window.fcWidget.open({
  //       name: 'Support',
  //       replyText: 'Thank you for the help',
  //     });
  //   }
  // }

  function handleLogoutUser() {
    handleClearUserData()
    handleRemoveIndicationCouponOfLocalStorage()
  }

  return (
    <UserSection>
      <div className="userSection__options">
        <ul className="userSection__optionList">
          {showPlans && (
            <li className="optionList__option">
              <Link
                to="/plans"
                className={`option__content ${
                  location.pathname === "/plans" ? "active" : ""
                }`}
                href=""
              >
                Planos
              </Link>
            </li>
          )}
          <li className="optionList__option">
            <a
              href="https://wa.me/5531996887607?text=Olá,%20preciso%20de%20ajuda."
              target="_blank"
              rel="noreferrer"
              className="option__content"
            >
              Fale conosco
            </a>
          </li>
          <li className="optionList__option">
            <a onClick={handleLogoutUser} href="/" className="option__content">
              Sair
            </a>
          </li>
        </ul>
        <div className="userSection__verticalLine"></div>
      </div>
      <img
        alt={userProperties?.name}
        className="userSection__avatar"
        onClick={() => togglePopup()}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = defaultUserImage;
        }}
        src={`https://firebasestorage.googleapis.com/v0/b/content-blackbook-dev/o/profiles%2F${userProperties?._id}?alt=media`}
      />
    </UserSection>
  );
}
