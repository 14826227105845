import logo from "../../assets/images/logo.png";
import logoName from "../../assets/images/logo-text.svg";
import { SignInForm } from "../../components/molecules/signInForm/";
import { SignInPage } from "./style";

export function SignIn() {
  window.scrollTo(0, 0);
  return (
    <>
      <SignInPage>
        <div className="loginTopDiv">
          <img
            src={logo}
            alt=""
            className="loginTopDiv__image"
          />
          <img
            src={logoName}
            alt=""
            className="loginTopDiv__imageName"
          />
        </div>

        <main className="loginMain">
          <SignInForm />
        </main>
      </SignInPage>
    </>
  );
}
