import styled from 'styled-components';
import { borderRadius, colors, spacing } from '../../quarks/theme';

export const InputAreaStyle = styled.div`
    position: relative;
    background: ${colors.white};
    max-width: 100%;
    border-radius: ${borderRadius.large};
    overflow: hidden;
    position: relative;
    height: 20px;
    padding: ${spacing.small} ${spacing.large};
    display: flex;
    input {
        color: ${colors.gray};
        border: none;
        width: 100%;
        &:focus {
            outline: none;
        }
        &::placeholder {
            color: ${colors.gray};
        }
    }
    .icon {
        position: absolute;
        right: 15px;
        color: ${colors.primary};
        display: flex;
        align-items: center;
        justify-content: center;
        height: inherit;
    }

    .search_input_clear_icon {
        border: none;
        background: transparent;
        padding: 0;
        margin: 0;
        align-self: center;
        display: flex;
    }
`
