import styled from 'styled-components';

export const InputAreaStyle = styled.div`
    width: 100%;
    position: relative;

    .area {
        width: 100%;
        display: flex;
        align-items: center;

        border-bottom: 2px solid #8774FF;

        input {
            height: 48px;
            font-size: 1rem;
            color: white;
            background-color: #121212;
            border: none;
            width: 100%;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            &:focus {
                outline: none;
            }
        }

        &.error {
            color: #D53B30;
            border-bottom: 2px solid #D53B30;
        }

        .icon {
            color: #444444;
            width: 20px;
            height: 14px;
            cursor: pointer;
        }
    }

    .helper {
        width: 100%;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        color: #6F6F6F;
        font-size: 14px;
        margin-top: 0.8rem;
        position: absolute;
    }

    .error {
        color: #D53B30;
    }
`;

export const InputContainerStyle = styled.div`
    padding: 0 1rem;
    display: flex;
    flex-direction: column;

    label {
        max-width: 80%;
    }

    .description {
        color: white;
    }

    &.space-between {
        justify-content: space-between;
    }

    .helper {
        width: 100%;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        color: #6F6F6F;
        font-size: 14px;
        margin-top: 0.8rem;
    }

    .error {
        color: #D53B30;
    }
`

export const SelectAreaStyle = styled.div`
    .area {
        position: relative;
        margin-bottom: 0.5rem;
        border-bottom: 2px solid #8774FF;
        width: 100%;
        display: flex;
        align-items: center;

        &::after {
            content: "";
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid #8774FF;
            position: absolute;
            top: 45%;
            right: 12px;
            z-index: 98;
        }
    }

    select {
        height: 48px;
        width: 100%;
        background-color: transparent;
        color: white;
        border: none;
        appearance: none;
        font-size: 1rem;

        &:focus {
            outline: none;
        }

        option {
            color: black;
            width: 100%;
            padding: 10px;
        }
    }

    .helper {
        width: 100%;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        color: #6F6F6F;
        font-size: 14px;
        margin-top: 0.8rem;
    }

    .error {
        color: #D53B30;
        border-color: #D53B30;
    }
`

export const InputContainerStyleOutline = styled.div`

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    position: relative;
    width: 100%;
    margin-right: 10px;
    background-color: transparent;
    color: white;
    cursor: pointer;

    border: 1px solid var(--gray-100);
    background-color: transparent;
    color: white;


    border: 1px solid
    ${({ isValid, isInvalid }) =>
        isValid
            ? `#1D9637`
            : isInvalid
                ? `var(--error) !important`
                : 'var(--gray-100)'};
    background-color: unset;
    color: ${({ isValid, isInvalid }) =>
        isValid ? `#1D9637` : isInvalid ? `var(--error) !important` : 'white'};

    font-size: 1rem;
    font-family: Source Sans Pro;

    height: 100%;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;

    transition: border-color 0.2s;

    &:hover {
    border-color: var(--purple-500);
    }

    &:focus {
    border-color: var(--yellow-500);
    outline: none;
    }

    &::placeholder {
    color: var(--gray-100);
    font-size: 0.75rem;
    }

    .select__container {
        width: 100%;
        .area {
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;

            &::after {
                content: "";
                width: 0;
                height: 0;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 6px solid #8774FF;
                position: absolute;
                top: 45%;
                right: 12px;
                z-index: 98;
            }
        }

        select {
            width: 100%;
            background-color: transparent;
            color: white;
            border: none;
            appearance: none;
            font-size: 1rem;
            padding: 0.7rem;

            &:focus {
                outline: none;
            }

            option {
                color: black;
                width: 100%;
                padding: 10px;
            }
        }

        .helper {
            width: 100%;
            text-align: right;
            display: flex;
            justify-content: flex-end;
            color: #6F6F6F;
            font-size: 14px;
            margin-top: 0.8rem;
        }

        .error {
            color: #D53B30;
            border-color: #D53B30;
        }
    }

    h3 {
        color: #999;

        transition: transform 0.2s ease-in-out, background 0.3s ease-in-out;
        transform: ${({ isActive }) =>
            isActive ? 'translateY(-175%)' : 'translateY(0%)'};
        padding: 0 0.2rem 0 0.2rem;
        margin-left: 0.5rem;
        background-color: ${({ isActive }) =>
            isActive ? '#1e1e1e' : 'transparent'};
        width: fit-content;

        font-size: 12px;
        z-index: 1;
        border-radius: 3px;
        position: absolute;
    }
`