import styled from 'styled-components';

export const Container = styled.div`
  max-width: 680px;
  background-color: #333;
  height: fit-content;

  display: flex;
  flex-direction: column;
  margin: 2rem auto;
  
  padding: 2rem;

  .changeMethod__title {
    font-size: 1.5rem;
    color: white;
    margin-bottom: 1.5rem;
    font-weight: 700;
    box-sizing: border-box;
    text-align: center;
  }
`;
