import styled from 'styled-components';
import { colors, fontFamily, spacing } from '../../quarks/theme';

export const ContainerStyle = styled.div`
   position: relative;
   background-color: ${colors.black100};
   font-family: ${fontFamily.secondary};
   min-height: 100vh;
   max-width: 100vw;

   a {
      text-decoration: none;
   }

   .small {
      width: 375px;
      margin: ${spacing.none} auto;
   }
`
