import { Link } from "react-router-dom";

import { Container } from "./style";

export function NotFound() {

  return (
    <Container>
      <div className="notFound">
        <div className="notFound__animation"></div>
        <h1 className="notFound__title">Erro 404</h1>
        <p className="notFound__text">Não encontramos essa página.</p>
        <Link to="/home" className="notFound__button">
          Voltar
        </Link>
      </div>
    </Container>
  );
}
