import styled from "styled-components";

export const Container = styled.div`
 

  background-color: ${({isAnnualPlan}) => isAnnualPlan ? '#252525' : '#080808'} ;
  border: ${({isAnnualPlan}) => isAnnualPlan ? "1.5px solid #FCDE17" : "1.5px solid #fff"};
  border-radius: 10px;

  padding: 25px 0 0;
  position: relative;

  .title__content {
    color: ${({isAnnualPlan}) => isAnnualPlan ? "#FCDE17" : "white"};
    font-size: 18px;
    font-weight: bold;
    font-family: 'Open Sans';
    text-align: center;
  }

  .planCardsContainerBody__content {
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;

    .coupon__description {
      color: var(--yellow-500);
      text-align: center;
      font-weight: bold;
    }
  }

  .content__price {
    color: ${({isAnnualPlan}) => isAnnualPlan ? "#FCDE17" : "white"};
    font-weight: bold;
    font-size: 24px;
    align-self: center;
    display: flex;
    align-items: baseline;

    vertical-align: bottom;
    span:nth-child(4), span:nth-child(5) {
      font-size: 48px;
    }
  }

  .subtitle_div {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 12px;
  }

  .contentPrice__subtitle {
    width: max-content;
    color: #999999;
  }

  .contentPrice__subtitleValue {
    margin-top: 5px;
    color: #999999;
  }

  .content__description {
    margin-top: 1rem;
    align-self: center;
  }

  .button__div {
    padding: 0 1rem;
    position: absolute;
    transform: translateY(50%);
    bottom: 0;
    right: 0;
    left: 0;
  }

  .subcribe__button {
    box-shadow: 0 9px 12px rgba(0, 0, 0, 0.3);
    background-color: #8774FF;
    width: 100%;
    height: 32px;
    font-size: 12px;
    font-weight: bold;
    border: none;
    border-radius: 25px;
    margin: 0;
    color: white;
    transition: linear 0.2s;
    &:hover {
      cursor: pointer;
      filter: brightness(0.9);
    }
  }

  @media screen and (max-width: 720px) {
    .title__content {
      font-size: 11px;
    }

    .content__price {
      font-size: 11px;

      span:nth-child(4), span:nth-child(5) {
        font-size: 32px;
      }
    }

    .subcribe__button {
      font-size: 11px;
    }

    .planCardsContainerBody__content {
      padding: 1rem 0;
    }
  }
`;