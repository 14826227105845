import { ApolloClient, HttpLink, ApolloLink, InMemoryCache, useQuery } from '@apollo/client';
import { onError } from '@apollo/client/link/error'
import { clearUserTokensOfLocalStorage, getUserToken, getAnonymousToken } from '../../tokenService';

const httpLink = new HttpLink({ uri: process.env.REACT_APP_BFF_URI });

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      Authorization:
        getUserToken(),
    },
  }));

  return forward(operation);
})

function forceUserLogout() {
  clearUserTokensOfLocalStorage()
  window.location.href = '/signin'
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach((err) => {
      if (err.extensions.code === 401) { // validate if user is correctly authenticated
        if (getUserToken()) {
          forceUserLogout()
        }
      }
    }
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Routine: {
        keyFields: false
      },
      Tab: {
        keyFields: false
      }
    },
  }),
  link: errorLink.concat(authMiddleware.concat(httpLink)),
});
