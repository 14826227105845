import styled from 'styled-components';
import { spacing } from '../../quarks/theme';

export const AccordionContainer = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1px;
  &:not(:last-child){
    margin-bottom: ${spacing.medium};
  }

  background-color: ${props => props.backgroundColor}

`;