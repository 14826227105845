import { RiCloseLine } from 'react-icons/ri';
import { CloseIcon } from '../../../assets/icons/CloseIcon';
import { SubscriptionInfoText } from '../../atoms/SubscriptionInfoText';
import { Container } from './style';

export function DescriptionPlansModal({ setIsModalOpen }) {
  return (
    <Container>
      <div className="plansInfo__div">
        <div
          className="closePlansInfo__button"
          onClick={() => setIsModalOpen(false)}
        >
          <CloseIcon />
        </div>
        <SubscriptionInfoText />
      </div>
    </Container>
  );
}
