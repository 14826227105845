import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #8774FF;
  padding: 0.3rem 0 0.3rem 0.3rem;
  color: var(--white);
  font-size: 12px;
  border-radius: 40px 0 0 40px;
  margin-left: 30px;
  margin-top: 20px;

  .planBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem;
    cursor:pointer;
    margin-left: 8px;
  }

  .planBox__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-transform: uppercase;
  }

  .planBox__subtitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  .planBox__plan {
    font-size: 0.6rem;
    font-weight: bold;
    font-style: italic;
    margin-right: 5px;
    margin-top: 1px;
  }

  .planBox__free {
    font-size: 0.8rem;
    font-weight: bold;
    font-style: italic;
  }

  .planBox__subscribe {
    align-self: center;
    font-size: 0.8rem;
    font-weight: bold;
    font-style: italic;
    margin-left: 3.5rem;
    margin-right: 10px;
    color: #FCDE17;
  }

  .planBox__symbol {
    font-size: 1.5rem;
    font-weight: bold;
    font-style: italic;
    color: #FCDE17;
  }

  .planBox__price {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 300;
    margin-top: 5px;
  }

  .planBox__type {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
    margin-bottom: 8px;
  }

  .planBox__description {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
    margin-bottom: 8px;
  }

  .planBox__expires {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .planBox__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
  }

  .dataCard__link {
    color: var(--white);
    margin-top: 0.9rem;
    align-self: flex-end;
    text-decoration-color: var(--yellow-500);
    &:hover {
      color: var(--yellow-500);
    }
    font-size: 15px;
  }

  .loading__div {
    display: flex;
    flex-direction: column;
    align-items: center;    
  }
`;
