import { useEffect, useState, useContext } from 'react';

import { authApi } from '../../../../services/clients';

import { RegisterOrganism } from '../../../../components/organisms/registerOrganism';
import { RegisterInputComponent } from '../../../../components/atoms/registerInput';
import { SignUpStageContext } from '../../../../context/useSignUpStage';
import { useTrack } from '../../../../services/hooks/useTrack';

export function SignUpEmail({ isDesktop = false }) {
  const { goForward } = useContext(SignUpStageContext);
  const { trackEvent } = useTrack();

  const [email, setEmail] = useState('');
  const [isEmailCompleted, setIsEmailCompleted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChangeEmail = (inputEmail) => {
    setEmail(inputEmail);
    setIsEmailCompleted(inputEmail.length > 0);
  };

  async function sendEmailToApiValidation() {
    try {
      await authApi.getValidEmail(email);
      window.sessionStorage.setItem('email', email);
      trackEvent('Cadastro email - Próximo', { Email: email });
      goForward();
    } catch (error) {
      switch (error.response.data.code) {
        case 403:
          trackEvent('Cadastro email - Erro já cadastrado', { Email: email });
          break;
        case 422:
          trackEvent('Cadastro email - Erro email inválido', { Email: email });
          break;
        default:
          break;
      }
      setErrorMessage(error.response.data.message[0]);
    }
  }

  useEffect(() => {
    const value = sessionStorage.getItem('email');
    if (value) {
      handleChangeEmail(value);
      sessionStorage.removeItem('email');
    }
  }, []);

  if (isDesktop) {
    return (
      <RegisterInputComponent
        autoFocus
        error={errorMessage}
        helper="Digite um email válido"
        label="Vamos lá! Qual é o seu email?"
        type="text"
        value={email}
        onChange={(e) => handleChangeEmail(e.target.value)}
      />
    );
  }

  return (
    <RegisterOrganism
      title="Crie sua conta"
      buttonProps={{
        disabled: !isEmailCompleted,
        className: 'fullWidth',
        type: 'submit',
      }}
      onFinish={() => {
        sendEmailToApiValidation();
      }}
    >
      <RegisterInputComponent
        autoFocus
        error={errorMessage}
        helper="Digite um email válido"
        label="Vamos lá! Qual é o seu email?"
        type="text"
        value={email}
        onChange={(e) => handleChangeEmail(e.target.value)}
      />
    </RegisterOrganism>
  );
}
