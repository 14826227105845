import { RegisterOrganismStyle } from "./style";
import { RegisterHeaderComponent } from "../../molecules/registerHeaderComponent";
import { NewButtonComponent } from "../../atoms/buttonComponent";
import { ContainerStyle } from "../../atoms/containerComponent/style";

export const RegisterOrganism = props => {

    return (
        <ContainerStyle style={{width: '100%'}}>
            <RegisterOrganismStyle>
                <RegisterHeaderComponent title={props.title} showSigninButton={props.showSigninButton} onClick={props?.onClickHeaderBackButton}/>
                <form autoComplete="off" onSubmit={e => {
                    e.preventDefault();
                    props.onFinish();
                }}>
                    {props.children}
                    <div className="nextStepButtonContainer">
                        {/* {props.useTermsChildren} */}
                        {props.buttonProps && (
                            <NewButtonComponent
                                {...props.buttonProps}
                                type="submit"
                            >
                                {props.buttonProps.text || "Próximo"}
                            </NewButtonComponent>
                        )}
                        
                        {props.stepsCount}
                    </div>
                </form>
            </RegisterOrganismStyle>
        </ContainerStyle>
    );
}
