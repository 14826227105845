import { CloseIcon } from '../../../assets/icons/CloseIcon';
import { Container } from './style';

export function ModalPaymentInProcess({ setIsModalOpen, contentIsForAnotherPlatform, platformForText = 'web' }) {

  let modalTitle = ""
  let modalText = ""


  const options = {
    web: () => {
      modalTitle = "Seu pagamento está sendo processado";
      modalText = "Esta funcionalidade estará disponível quando seu pagamento for confirmado. Mas não se preocupe: você já pode buscar, navegar e acessar todo o conteúdo Blackbook normalmente."
    },
    mobile: () => {
      modalTitle = "Não é possível gerenciar seus planos por aqui."
      modalText = "Você assinou o Blackbook por uma loja mobile (Google Play ou Apple Store). Só será possível gerenciar seus planos acessando por lá"
    },
    b2b: () => {
      modalTitle = "Não é possível gerenciar seus planos."
      modalText = "Você assinou o Blackbook através de um dos nossos parceiros. Não é possível fazer quaisquer alterações no plano."
    }
  }

  options[platformForText]()

  return (
    <Container onClick={() => setIsModalOpen(false)}>
      <div
        className="modal__content"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="header">
          <h1>
            {modalTitle}
          </h1>
          <button onClick={() => setIsModalOpen(false)}>
            <CloseIcon />
          </button>
        </div>
        <div className="body">
          {modalText}
        </div>
      </div>
    </Container>
  );
}
