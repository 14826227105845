import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from '../services/tokenService';

import { useGetQuery } from '../services/hooks/useGetQuery';
import { getIndicationCoupon, handleSetIndicationCouponOnLocalStorage } from '../services/cache/indicationCoupon';
import { getGiftCardOfLocalStorage, handleSetGiftCardOnLocalStorage } from '../services/cache/giftCard';
import { useIsDesktopOrTabletScreen } from '../services/hooks/useIsDesktopOrTabletScreen';

const PublicRoute = ({ component: Component, ...rest }) => {
  const query = useGetQuery();
  const isDesktopOrLaptop = useIsDesktopOrTabletScreen()

  // coupon
  if(query.get('c')) {
    handleSetIndicationCouponOnLocalStorage(query.get('c'));
  }
  
  // gift card
  if(query.get('g')) {
    handleSetGiftCardOnLocalStorage(query.get('g'));
  }

  function handleRedirectUser() {
    const coupon = getIndicationCoupon()
    const giftCard = getGiftCardOfLocalStorage()
    if(coupon) {

      return '/home'
    }
    
    if(giftCard){
      return '/giftcard'
    }

    return isDesktopOrLaptop ? '/app': '/home'
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Redirect
            to={{ pathname: handleRedirectUser(), state: { from: props.location } }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

PublicRoute.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.object,
};

export default PublicRoute;
