import styled from "styled-components";
import { colors, fontSize, spacing, borderRadius } from "../../quarks/theme";

export const CardStyle = styled.div`
  background-color: ${(props) => props.backgroundColor || colors.black200};
  color: ${colors.white};
  overflow-x: scroll;
  overflow-y: scroll;
  padding: ${(props) => props.padding || spacing.large};
  margin: ${(props) => props.margin || ""};
  border-radius: ${borderRadius.small};
  max-height: 100%;

  &.containerWithoutTitle {
    border-radius: 0;
    margin-bottom: 1px !important;
    color: ${colors.black};
    .title{
      font-weight: 400;
    }
    &:first-child {
      border-radius: ${borderRadius.small} ${borderRadius.small} 0 0;
    }
    &:last-child {
      border-radius: 0 0 ${borderRadius.small} ${borderRadius.small};
    }
  }

  &.containerWithTitle {
    border-radius: 0;
    margin-bottom: 0 !important;
    color: ${colors.black};
    .title{
      font-weight: 400;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  strong {
    font-weight: 700;
  }
  p {
    &:not(li > p) {
      text-indent: -${spacing.medium};
      margin-left: ${spacing.medium};
    }
  }

  &.resume {
    background-color: ${colors.hardBlack};
  }
  &.blue {
    background-color: ${colors.darkBlue};
  }
  &.green {
    background-color: ${colors.darkGreen};
  }
  &.yellow {
    background-color: ${colors.darkYellow};
  }
  &.orange {
    background-color: ${colors.darkOrange};
  }
  &.red {
    background-color: ${colors.darkRed};
  }
  &.indigo {
    background-color: ${colors.darkIndigo};
  }

  &.search_result_card {
    overflow: unset;
    max-height: unset;
  }

  &.drugAccordion {
    overflow-y: visible;
    overflow-x: visible;
  }
`;