import styled from "styled-components";

export const Container = styled.label`
  display: flex;
  flex-direction: column;
  position: relative;

  h3 {
    color: #ccc;
    transform: translateY(40%);
    padding: 0 1rem;
    background-color: #545454;
    width: fit-content;
    margin-left: 0.5rem;
    font-size: 0.8rem;
    z-index: 1;
    border-radius: 5px;
  }

  .temp__input {
    border: 1px solid var(--gray-100);
    background-color: unset;
    color: var(--white);
    font-size: 1.2rem;
    padding: 0.7rem;
    margin-bottom: 0.5rem;
    border-radius: 5px;

    transition: border-color 0.2s;

    &:hover {
      border-color: var(--yellow-500);
    }

    &:focus {
      outline: none;
      border-color: var(--purple-500);
    }

    &::placeholder {
      color: var(--gray-100);
      font-size: 0.8rem;
    }

    /* Disable arrows on numeric input */
    /* Chrome, Safari, Edge, Opera */
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    [type=number] {
      -moz-appearance: textfield;
    }
  }

  .default__input {
    border: 1px solid var(--gray-100);
    background-color: unset;
    color: var(--white);
    font-size: 1.2rem;
    padding: 0.7rem;
    margin-bottom: 0.5rem;
    border-radius: 5px;

    transition: border-color 0.2s;

    &:hover {
      border-color: var(--yellow-500);
    }

    &:focus {
      outline: none;
      border-color: var(--purple-500);
    }

    &::placeholder {
      color: var(--gray-100);
      font-size: 0.8rem;
    }

    /* Disable arrows on numeric input */
    /* Chrome, Safari, Edge, Opera */
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    [type=number] {
      -moz-appearance: textfield;
    }
  }

  .form__input {
    padding: 1rem;
    margin: 0.5rem 0;
    border-radius: 5px;
    border: none;
    background-color: #121212;
    border: 1px solid #999999;
    color: white;
    font-size: 1rem;

    &::placeholder {
      color: var(--gray-100);
      font-size: 0.8rem;
    }

    &:focus {
      outline: none;
    }

    &.--error {
      margin-bottom: 1rem;
      border: 1px solid;
      border-color: var(--red-300);
      font-size: 1rem;
    }
  }

  .form__input--error {
    padding: calc(0.5rem - 1px);
    margin-bottom: 1rem;
    border-radius: 5px;
    border: 1px solid;
    background-color: var(--gray-300);
    border-color: var(--red-300);
    color: white;
    font-size: 1rem;
    &::placeholder {
      color: white;
      font-size: 0.8rem;
    }
    &:focus {
      outline: none;
    }
  }

  .radio__input {}

  .phoneDial__input {
    border: 1px solid var(--gray-100);
    background-color: unset;
    color: var(--white);
    font-size: 1rem;
    padding: 0.8rem;
    margin-bottom: 0.5rem;
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: space-around;

    position: relative;

    cursor: pointer;

    @media (max-width: 480px) {
      justify-content: space-between;
    }

    h2 {
      font-size: 1.2rem;
    }
  }

  .input__component {
    border: 1px solid var(--gray-100);
    background-color: unset;
    color: var(--white);
    font-size: 1.2rem;
    padding: 0.8rem;
    margin-bottom: 0.5rem;
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    position: relative;

    cursor: pointer;

    h2 {
      font-size: 1.2rem;
    }
  }

  .invalid {
    border-color: var(--red-500) !important;
  }
`;

export const InputAdornment = styled.button`
  align-self: center;
  justify-self: flex-end;
  position: absolute;
  right: 0;

  height: 100%;
  padding: 1rem;
  margin-top: 0.2rem;

  font-size: 2rem;

  background-color: transparent;
  border: none;

  cursor: pointer;

  transition: all 0.2s;
  &:hover {
    filter: brightness(0.8);
  }
`

export const CountrySelectComponent = styled.div`
  position: absolute;
  
  max-width: 350px;
  top: -25%;
  left: -5%;
  right: -200%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  border-radius: 5px;

  z-index: 10;

  background-color: var(--gray-700);
  color: white;
  padding: 0.5rem;

  @media (max-width: 480px) {
    right: 0;
    left: 0;
    justify-content: space-between;
    max-width: none;
  }

  .country__search {
    display: flex;
    flex-direction: column;

    width: 100%;

    padding-top: 0.5rem;

    input {
      background-color: transparent;
      border: none;
      border-bottom: 1px solid #121212;
      padding: 0.2rem 0.5rem;
      color: white;

      outline: none;
    }
  }

  .country__list {
    overflow-y: scroll;
    max-height: 50vh;
    width: 100%;

    ::-webkit-scrollbar{
    width: 10px;
    }
    ::-webkit-scrollbar-track{
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb{
        background-color: var(--gray-100);
        border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb:hover{
        background-color: var(--gray-300);
    }
    
    .country__item {
      width: calc(100% - 1rem);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 0.5rem;
      
      cursor: pointer;
      
      transition: background 0.2s;
      
      &:hover {
        background-color: var(--gray-900);
      }
    }
  }
`

export const OptionSelectComponent = styled.div`
  position: absolute;
  
  top: calc(100% - 0.5rem);
  left: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  border-radius: 5px;

  z-index: 10;

  background-color: var(--gray-700);
  color: white;
  padding: 0.5rem;

  .option__list {
    overflow-y: scroll;
    max-height: 20vh;
    width: 100%;

    ::-webkit-scrollbar{
    width: 10px;
    }
    ::-webkit-scrollbar-track{
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb{
        background-color: var(--gray-100);
        border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb:hover{
        background-color: var(--gray-300);
    }
    
    .option__item {
      width: calc(100% - 1rem);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 0.5rem;
      
      cursor: pointer;
      
      transition: background 0.2s;
      
      &:hover {
        background-color: var(--gray-900);
      }
    }
  }
`