import {
  InputAreaStyle,
  InputContainerStyle,
  InputContainerStyleOutline,
  SelectAreaStyle,
} from './style';
import { useState } from 'react';
import { BsEyeFill } from 'react-icons/bs';
import { BsFillEyeSlashFill } from 'react-icons/bs';
import { LabelStyle } from '../titleComponent/style';
import InputMask from 'react-input-mask';

import ReactSelect, { components } from 'react-select';
import { ArrowDownIcon } from '../../../assets/icons/ArrowDown';

import especialidades_medicas from '../../../services/utils/especialidades_medicas.json';

export const SimpleRegisterInput = (props) => (
  <InputAreaStyle>
    <div className={`${!props.error ? 'default' : 'error'} area`}>
      <InputMask
        {...props}
        className={`${!props.error ? 'default' : 'error'}`}
      />
      <div onClick={props.onClickIcon} className="icon">
        {props.icon}
      </div>
    </div>
    {props.helper && !props.error && <p className="helper">{props.helper}</p>}
    {props.error && <p className="helper error">{props.error}</p>}
  </InputAreaStyle>
);

export const RegisterInputComponent = (props) => {
  return (
    <InputContainerStyle>
      <LabelStyle>{props.label}</LabelStyle>
      <SimpleRegisterInput {...props} />
    </InputContainerStyle>
  );
};

export const PasswordRegisterInputComponent = ({
  label = (
    <span>
      Agora crie
      <br />
      uma senha
    </span>
  ),
  ...props
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  function handleClickIcon() {
    setIsPasswordVisible(!isPasswordVisible);
  }

  return (
    <RegisterInputComponent
      {...props}
      helper={`No mínimo ${props.min} caracteres`}
      label={label}
      type={isPasswordVisible ? 'text' : 'password'}
      icon={isPasswordVisible ? <BsEyeFill /> : <BsFillEyeSlashFill />}
      onClickIcon={() => handleClickIcon()}
    />
  );
};

export const RegisterSelectComponent = (props) => {
  const { options } = props;
  const [isActive, setIsActive] = useState(false);

  if (props?.styleClass === 'outline') {
    return (
      <InputContainerStyleOutline
        isActive={isActive || props.value}
        className={props?.className}
        onFocus={() => setIsActive(true)}
        onBlur={() => setIsActive(false)}
      >
        <h3>{props.label}</h3>
        <div className="select__container">
          <div className={`${!props.error ? 'default' : 'error'} area`}>
            <select
              {...props}
              className={`${!props.error ? 'default' : 'error'}`}
            >
              <option value=""></option>
              {options &&
                options.map((option) => (
                  <option
                    key={option}
                    value={
                      props?.field_value ? option[props.field_value] : option
                    }
                  >
                    {props?.field_title ? option[props.field_title] : option}
                  </option>
                ))}
            </select>
            <div onClick={props.onClickIcon} className="icon">
              {props.icon}
            </div>
          </div>
          {props.helper && !props.error && (
            <p className="helper">{props.helper}</p>
          )}
          {props.error && <p className="helper error">{props.error}</p>}
        </div>
      </InputContainerStyleOutline>
    );
  }
  return (
    <InputContainerStyle className={props?.className}>
      <LabelStyle>{props.label}</LabelStyle>
      <p className="description">{props.description}</p>
      <SelectAreaStyle>
        <div className={`${!props.error ? 'default' : 'error'} area`}>
          <select
            {...props}
            className={`${!props.error ? 'default' : 'error'}`}
          >
            <option value=""></option>
            {options &&
              options.map((option) => (
                <option
                  key={option}
                  value={
                    props?.field_value ? option[props.field_value] : option
                  }
                >
                  {props?.field_title ? option[props.field_title] : option}
                </option>
              ))}
          </select>
          <div onClick={props.onClickIcon} className="icon">
            {props.icon}
          </div>
        </div>
        {props.helper && !props.error && (
          <p className="helper">{props.helper}</p>
        )}
        {props.error && <p className="helper error">{props.error}</p>}
      </SelectAreaStyle>
    </InputContainerStyle>
  );
};

export const RegisterSelectMultipleSpecialtiesComponent = (props) => {
  const options = especialidades_medicas;

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowDownIcon 
          style={{ scale: '1.2' }} 
          rotate={"rotate(0)"}  
        />
      </components.DropdownIndicator>
    );
  };

  if (props.styleClass === 'underline'){
    return (
      <InputContainerStyle
        isActive={true}
        className={props?.className}
        style={{border: 'none'}}
      >
        <LabelStyle>{props?.label}</LabelStyle>
        <p className="description">{props?.description}</p>
        <ReactSelect
          placeholder=""
          components={{ DropdownIndicator }}
          isMulti
          name="specialties"
          options={options}
          className="basic-multi-select"
          classNamePrefix="select"
          defaultValue={props?.defaultValue}
          onChange={(e) =>
            props.onChange(e?.map?.(a => a.value))}
          styles={{
            container: (baseStyles, state) => ({
              ...baseStyles,
              flex: 1,
              width: '100%',
            }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: 'transparent',
              outline: 'none',
              width: '100%',
              marginTop: '6px',
              boxShadow: 'none',
              border: 'none',
              borderRadius: '0px',
              borderBottom: `2px solid ${props.error ? "#D53B30" : "#8774FF"}`,
              ':hover': {
                borderColor: `${props.error ? "#D53B30" : "#8774FF"}`,
              },
              // padding: '5px 0'
            }),
            indicatorSeparator: (baseStyles, state) => ({
              ...baseStyles,
              display: 'none',
            }),
            valueContainer: (baseStyles, state) => ({
              ...baseStyles,
              marginTop: 5
            }),
            option: (baseStyles, state) => ({
              ...baseStyles,
              zIndex: 98,
            }),
            menu: (baseStyles) => ({
              ...baseStyles,
              color: 'black',
              zIndex: 98,
            }),
            multiValue: (baseStyles) => ({
              ...baseStyles,
              color: 'white',
              backgroundColor: "#242424",
              border: '1px solid var(--purple-500)',
              borderRadius: '10px'
            }),
            multiValueLabel: (baseStyles) => ({
              ...baseStyles,
              color: '#ddd',
              zIndex: 98,
            }),
            multiValueRemove: (baseStyles) => ({
              ...baseStyles,
              color: '#ddd',
              borderTopRightRadius: '10px',
              borderBottomRightRadius: '10px',
              zIndex: 98,
              ':hover': {
                backgroundColor: 'var(--error)',
              },
            }),
            input: (baseStyles) => ({
              ...baseStyles,
              color: '#ddd',
            }),
          }}
        />
        {props?.error && <p className="helper error">{props?.error}</p>}
      </InputContainerStyle>
    );
  }

  return (
    <InputContainerStyleOutline
        isActive={true}
        className={props?.className}
        style={{border: 'none'}}
      >
        <h3 style={{top: '1rem'}}>{props.label}</h3>
    <ReactSelect
      placeholder=""
      components={{ DropdownIndicator }}
      isMulti
      name="specialties"
      options={options}
      className="basic-multi-select"
      classNamePrefix="select"
      onChange={(e) =>
        props.onChange(e?.map?.(a => a.value))}
      styles={{
        container: (baseStyles, state) => ({
          ...baseStyles,
          flex: 1,
          width: '100%'
        }),
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: state.isFocused
            ? 'var(--yellow-500)'
            : 'var(--gray-100)',
          backgroundColor: 'transparent',
          outline: 'none',
          width: '100%',
          boxShadow: 'none',
          ':hover': {
            borderColor: 'var(--purple-500)',
          },
          // padding: '5px 0'
        }),
        valueContainer: (baseStyles, state) => ({
          ...baseStyles,
          marginTop: 5
        }),
        option: (baseStyles, state) => ({
          ...baseStyles,
          zIndex: 98,
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          color: 'black',
          zIndex: 98,
        }),

        multiValue: (baseStyles) => ({
          ...baseStyles,
          color: 'white',
          backgroundColor: "#242424",
          border: '1px solid var(--purple-500)'
        }),
        multiValueLabel: (baseStyles) => ({
          ...baseStyles,
          color: '#ddd',
          zIndex: 98,
        }),
        multiValueRemove: (baseStyles) => ({
          ...baseStyles,
          color: '#ddd',
          zIndex: 98,
          ':hover': {
            backgroundColor: 'var(--error)',
          },
        }),
        input: (baseStyles) => ({
          ...baseStyles,
          color: '#ddd',
        }),
      }}
    />
    </InputContainerStyleOutline>

  );
};
