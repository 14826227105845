import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 680px;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  z-index: 11;

  height: 100%;
  max-height: 100%;
  padding: 2rem 0;
  overflow-y: auto;
  box-sizing: border-box;

  @media screen and (min-width: 680px){
      max-width: none;

      .suggestedPlans__content {
        justify-content: center !important;
      }
  }

  .first__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .giftCard__button {
    box-shadow: 0 9px 12px rgba(0, 0, 0, 0.3);
    background-color: #8774ff;
    width: 100%;
    max-width: 680px;
    padding: 1rem;
    font-size: 0.75rem;
    font-weight: bold;
    border: none;
    border-radius: 25px;
    margin: 0;
    color: white;
    transition: linear 0.2s;
    margin-top: 21px;
    margin-bottom: 21px;
    &:hover {
      cursor: pointer;
      filter: brightness(0.9);
    }
  }

  .suggestedPlans__container {
    height: 100%;
    width: 100%;

    justify-content: center;
    align-items: center;
    
    .suggestedPlans__title {
      color: white;
      font-size: 1.25rem;
      text-align: center;
      font-weight: 700;
      padding: 1rem 0;
    }
    
    .suggestedPlans__content {
      /* height: 100%; */
      margin: 0 auto 50px;
      padding: 0 20px 50px;
      gap: 20px;


      max-width: 680px;

      display: grid;
      grid-template-columns: 1fr;
      &:has(> :last-child:nth-child(2)) {
        grid-template-columns: 1fr 1fr;
      }

      &:has(> :last-child:nth-child(3)) {
        grid-template-columns: 1fr 1fr 1fr;
      }

      ::-webkit-scrollbar {
        background-color: transparent;
      }

      ::-webkit-scrollbar-thumb{
        background-color: transparent;
      }
    }
  }

  
`;
