
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import styled from "styled-components";
import MainTheme from "../../../styles/MainTheme";
import { Modal } from '../../atoms/Modal';
import { ButtonComponent } from '../../atoms/buttonComponent';

const Container = styled.div`
  .favorite__modal {
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
      max-width: 500px;
      min-width: 300px;
      height: auto;
      border-radius: 20px;
      margin: 20px;
      padding: 10px;
      border: 1px solid ${MainTheme.color.blackSettingsOption};
    }

    .close-modal__button {
      background-color: #121212;
      border: none;
      margin-right: 10px;
    }
  }
  
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;
  padding: 10px;
`;

const ModalHeaderTitle = styled.text`
  color: ${MainTheme.color.disabledSoft};
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  min-height: 40px;
  margin: 5px -20px 10px -20px;
  padding: 0px 25px;
  border-bottom: 1px solid ${MainTheme.color.secondaryDark};
`;

const ModalContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ContentCard = styled.div`
  background-color: ${MainTheme.color.blackSettingsOption};
  border: 1px solid ${MainTheme.color.disabled};
  border-radius: 10px;
  overflow: hidden;
  margin: 20px;
  padding: 10px 20px;
`;

const TitleContent = styled.text`
  color: ${MainTheme.color.primaryFont};
  font-size: 16px;
  font-weight: 900;
  text-align: left;
`;

const LegendContent = styled.text`
  color: ${MainTheme.color.disabledSoft};
  font-size: 16px;
  font-weight: 400;
  text-align: left;
`;

const SeparatorContent = styled.div`
  background-color: ${MainTheme.color.disabled};
  height: 1px;
  margin: 10px 0px;
`;


const LinkedContentContainer = styled.div`
  margin:  0px 20px 10px 20px;
  display: flex;
  flex-direction: column;
`;

const LinkedContentText = styled.text`
  color: ${MainTheme.color.grayFont};
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 10px;
`;

const LinkedContentTextRed = styled.text`
  color: ${MainTheme.color.red};
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  flex-direction: row;
  justify-content: center;
  display: flex;
  gap: 20px;
  justify-content: space-between;
`;

export function FavoriteModal({
  title = "",
  titleContent = "",
  legendContent = "",
  isLoadingButton = false,
  isLinkedContentAlert = false,
  successOnPress,
  cancelOnPress,
}) {
  return (
    <Container>
      <Modal
        onClose={() => {cancelOnPress();}}
        className='favorite__modal'
      >
        <ModalContainer>
          <ModalHeaderTitle>
            {title}
          </ModalHeaderTitle>
          <ModalContainerContent>
            <Content>
              <ContentCard>
                <TitleContent>{titleContent}</TitleContent>
                <SeparatorContent />
                <LegendContent>{legendContent}</LegendContent>
              </ContentCard>
              {
                isLinkedContentAlert &&
                <LinkedContentContainer>
                  <LinkedContentText>
                    Este item pode possuir outros conteúdos atrelados que também estão no Meu Blackbook.
                  </LinkedContentText>
                  <LinkedContentText>
                    Estes conteúdos também serão removidos.
                  </LinkedContentText>
                  <LinkedContentTextRed>
                    Deseja mesmo removê-los?
                  </LinkedContentTextRed>
                </LinkedContentContainer>
              }
            </Content>
            <ButtonContainer>
              <ButtonComponent
                buttonProp={{
                  type: 'button',
                  className: `basicRed__button`,
                  disabled: false,
                  onClick: () => {successOnPress();},
                }}
                children="REMOVER"
              />
              <ButtonComponent
                buttonProp={{
                  type: 'button',
                  className: 'outlinedPurple__button',
                  disabled: false,
                  onClick: () => {cancelOnPress();},
                }}
                children="MANTER"
              />
            </ButtonContainer>

          </ModalContainerContent>
        </ModalContainer>
      </Modal>
    </Container>
  );
}
