/* eslint-disable react-hooks/exhaustive-deps */
//@ts-ignore
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
//@ts-ignore
import { WebAppTemplate } from '../../components/templates/WebAppTemplate';
//@ts-ignore
import { useParams } from 'react-router-dom';
//@ts-ignore
import chapterJson from '../../context/utils/CIDJson/capitulos.json';
//@ts-ignore
import groupJson from '../../context/utils/CIDJson/grupos.json';
//@ts-ignore
import catJson from '../../context/utils/CIDJson/categoria.json';
//@ts-ignore
import subCatJson from '../../context/utils/CIDJson/subcat.json';
import styled from 'styled-components';
//@ts-ignore
import MainTheme from '../../styles/MainTheme';
//@ts-ignore
import { BBArrowRightIcon } from '../../blackbook-components/quarks/icons/';
import { CidAccordion } from '../../components/molecules/CidAccordion';
//@ts-ignore
import { useTrack } from '../../services/hooks/useTrack';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 20px;
  column-gap: 20px;
  margin: 0 10px 10px;
  background-color: #232323;
  padding: 15px;
  overflow-y: auto;
  max-height: calc(100% - 122px);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

const CIDButton = styled.button`
  background-color: #333;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  padding: 5px 10px 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h3`
  color: ${MainTheme.color.grayFont};
  font-weight: bold;
  font-size: 18px;
  text-align: left;
`;

const SubTitle = styled.h4`
  color: ${MainTheme.color.disabledSoft};
  font-size: 16px;
  text-align: left;
`;

const HeaderContainer = styled.div`
  background-color: #363636;
  margin: 10px 10px 0 10px;
  padding: 15px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
`;

const HeaderTitle = styled.h1`
  color: ${MainTheme.color.grayFont};
  font-weight: bold;
  font-size: 24px;
  text-align: left;
`;

const HeaderSubTitle = styled.h2`
  color: ${MainTheme.color.disabledSoft};
  font-size: 18px;
  text-align: left;
`;

const ButtonText = styled.div``;

export function WebAppCID() {
  const { cid_chapter, cid_group, cid_category } = useParams();
  const [whatToDisplay, setWhatToDisplay] = useState<any>();
  const [currentGroup, setCurrentGroup] = useState<any>();
  const [currentCat, setCurrentCat] = useState<any>();
  const currentChapter = cid_chapter - 1;
  const history = useHistory();
  const { trackEvent } = useTrack();

  function displayDataParser(dataToAdd: any) {
    if (dataToAdd[0].SUBCAT) {
      const temp: any = [];
      dataToAdd.forEach((data: any) => {
        const tempObj = {
          title: data.SUBCAT,
          subTitle: data.DESCRICAO,
          legend:
            'Classificação: ' +
            (data.CLASSIF === '*'
              ? 'Por Manifestação'
              : data.CLASSIF === '+'
              ? 'Por Etiologia'
              : 'Única'),
          death: data.CAUSAOBITO === 'N' ? 'NÃO' : 'SIM',
          gender: data.RESTRSEXO,
          headerTitle: (
            <p>
              {cid_category}{' '}
              <b style={{ fontSize: 16, fontWeight: 300 }}>
                {cid_group} / Capítulo {cid_chapter}
              </b>
            </p>
          ),
          headerSubTitle: currentCat,
        };
        temp.push(tempObj);
      });
      setWhatToDisplay(temp);
    } else if (dataToAdd[0].CAT) {
      const temp: any = [];

      dataToAdd.forEach((data: any) => {
        const tempObj = {
          title: data.CAT,
          subTitle: data.DESCRICAO,
          headerTitle: (
            <p>
              {cid_group}{' '}
              <b style={{ fontSize: 16, fontWeight: 300 }}>
                Capítulo {cid_chapter}
              </b>
            </p>
          ),
          headerSubTitle: currentGroup,
          onClick: () => {
            setCurrentCat(data.DESCRICAO);
            trackEvent('CID - Clicou na categoria', {
              Categoria: `${data.CAT} - ${data.DESCRICAO}`,
            });
            history.push(`${cid_group}/${data.CAT}`);
          },
        };
        temp.push(tempObj);
      });
      setWhatToDisplay(temp);
    } else {
      const temp: any = [];
      dataToAdd.forEach((data: any) => {
        const tempObj = {
          title: data.CATINIC + ' - ' + data.CATFIM,
          subTitle: data.DESCRICAO,
          headerTitle: chapterJson[currentChapter].DESCRICAO,
          headerSubTitle:
            chapterJson[currentChapter].CATINIC +
            ' - ' +
            chapterJson[currentChapter].CATFIM,
          onClick: () => {
            setCurrentGroup(data.DESCRICAO);
            trackEvent('CID - Clicou no grupo', {
              Grupo: `${data.CATINIC} - ${data.CATFIM} ${data.DESCRICAO}`,
            });
            history.push(`${cid_chapter}/${data.CATINIC + '-' + data.CATFIM}`);
          },
        };
        temp.push(tempObj);
      });
      setWhatToDisplay(temp);
    }
  }

  useEffect(() => {
    if (cid_category) {
      const filtered = subCatJson.filter((e: any) =>
        e.SUBCAT.includes(cid_category)
      );
      displayDataParser(filtered);
    } else if (cid_group) {
      const GRUPO = cid_group.split('-');
      const GRUPINICIO = GRUPO[0];
      const GRUPFIM = GRUPO[1];
      const filtered = catJson.filter(
        (e: any) => e.CAT >= GRUPINICIO && e.CAT <= GRUPFIM
      );
      displayDataParser(filtered);
    } else {
      const CATINICIO = chapterJson[currentChapter].CATINIC;
      const CATFIM = chapterJson[currentChapter].CATFIM;
      const filtered = groupJson.filter(
        (e: any) => e.CATINIC >= CATINICIO && e.CATFIM <= CATFIM
      );
      displayDataParser(filtered);
    }
  }, [cid_chapter, cid_group, cid_category]);
  return whatToDisplay ? (
    <WebAppTemplate>
      <HeaderContainer>
        <HeaderTitle>{whatToDisplay[0].headerTitle}</HeaderTitle>
        <HeaderSubTitle>{whatToDisplay[0].headerSubTitle}</HeaderSubTitle>
      </HeaderContainer>
      <Container>
        {!cid_category
          ? whatToDisplay.map((data: any) => (
              <CIDButton onClick={() => data.onClick()}>
                <ButtonText style={{ flex: 1 }}>
                  <Title>{data.title}</Title>
                  <SubTitle>{data.subTitle}</SubTitle>
                </ButtonText>
                <BBArrowRightIcon height={16} width={8} />
              </CIDButton>
            ))
          : whatToDisplay.map((data: any) => <CidAccordion data={data} />)}
      </Container>
    </WebAppTemplate>
  ) : (
    <WebAppTemplate></WebAppTemplate>
  );
}
