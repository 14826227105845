const MainTheme = {
  color: {
    primary: "#8774FF",
    primaryFont: "#FFFFFF",
    primaryDark: "#5F51B3",

    secondary: "#FCDE17",
    secondaryDark: "#ECA511",

    red: "#D53B30",
    green: "#55BE54",

    modalBackground: "#2E2E2E",

    overlayFullBlack: "rgba(0,0,0,0.9)",
    fullBlack: "#000000",
    blackBackground: "#121212",
    blackBackgroundLight: "#212121",
    blackSettings: "#232323",
    blackSettingsOption: "#272727",
    grayFont: "#D9D9D9",
    grayBackground: "#2a272b",
    disabled: "#444444",
    disabledIcon: "#8C8C8C",
    disabledBackground: "#3b3b3b",
    disabledHighlight: "#343434",
    disabledFont: "#6F6F6F",
    disabledSoft: "#999999",
  },
};

export default MainTheme;