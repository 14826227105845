import logo from '../../assets/images/logo.png';
import logoName from '../../assets/images/logo-text.svg';
import { SignInForm } from '../../components/molecules/signInForm';
import { SignInPage } from './style';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { CRMDF } from './B2B';
import { useTrack } from "../../services/hooks/useTrack";
import { useEffect } from 'react';

export function Partner() {
  window.scrollTo(0, 0);
  const params = useParams();
  const { trackEvent } = useTrack();

  useEffect(() => {
    const origin = document.referrer
    trackEvent(
      'Acessou pagina B2B', 
      {
        'site':'app.blackbook/partner',
        'origem': params?.campaign ? params?.campaign : 'indefinido',
        'url':  origin,
      }
    );
  }, []);

  const components = {
    default: (
      <SignInPage>
        <div className="loginTopDiv">
          <img src={logo} alt="" className="loginTopDiv__image" />
          <img src={logoName} alt="" className="loginTopDiv__imageName" />
        </div>

        <main className="loginMain">
          <SignInForm />
        </main>
      </SignInPage>
    ),

    'crm-df': <CRMDF />,
  };

  return components?.[params?.campaign] || components.default;
}
