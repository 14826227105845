function parseHTML(content, popup) {
  return { __html: content };
}

export const contraIndicationsTemplates = {
  default: (contra) =>
    contra?.length > 0 && (
        <div
          className="content__style contraIndications"
          dangerouslySetInnerHTML={parseHTML(contra)}
        ></div>
    ),
};
