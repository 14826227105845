import styled from "styled-components";

export const Container = styled.div`
  .notFound {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .notFound__animation {
    width: 25%;
    height: 25%;
  }

  .notFound__title {
    font-size: 2rem;
    font-weight: bold;
    color: white;
  }

  .notFound__text {
    font-size: 1.5rem;
    color: white;
  }

  .notFound__button {
    background-color: var(--purple-500);
    padding: 0.5rem;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    margin-top: 1rem;
  }
`;
