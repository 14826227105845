import { useHistory, useLocation } from "react-router-dom"

export function usePaywall() {
  const history = useHistory()
  const location = useLocation()

  const redirectTo = {
    pathname: '/plans',
    state: { previousUrl: location.pathname }
  }

  function redirectToPaywall() {
    return history.push(redirectTo)
  }

  function getBackToContent() {

    if(location?.state?.previousUrl) {
      return history.push(location.state.previousUrl)
    }

    return null
  }

  function itCanGetBackToContent() {
    if(location.state?.previousUrl) {
      return true;
    }
    return false;
  }

  return {
    redirectToPaywall,
    getBackToContent,
    itCanGetBackToContent
  }
}
