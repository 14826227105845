export const ClinicHomeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width="100%"
      height="100%"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
      style={{marginLeft: -10}}
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="white"
        stroke="none"
      >
        <path d="M1051 4974 c-161 -55 -271 -163 -328 -324 l-23 -65 0 -1821 c0 -1789 0 -1823 20 -1886 41 -132 129 -234 255 -294 97 -46 162 -54 448 -54 l247 0 0 -185 c0 -172 1 -186 20 -205 12 -12 33 -20 53 -20 28 0 50 18 182 150 82 83 154 150 160 150 6 0 78 -67 160 -150 132 -132 154 -150 182 -150 20 0 41 8 53 20 19 19 20 33 20 205 l0 185 723 0 c794 0 800 1 918 61 76 38 182 141 221 215 61 114 58 -1 58 1964 0 1464 -3 1804 -14 1845 -47 180 -191 323 -369 371 -43 11 -297 14 -1482 14 l-1430 -1 -74 -25z m257 -1791 l2 -1673 -98 0 c-134 0 -248 -36 -330 -104 -16 -15 -33 -26 -36 -26 -11 0 -7 3102 5 3165 36 195 195 318 404 312 l50 -2 3 -1672z m2652 1666 c160 -26 281 -144 311 -303 5 -31 8 -630 7 -1541 l-3 -1490 -1407 -3 -1408 -2 0 1675 0 1675 1218 0 c801 0 1239 -4 1282 -11z m314 -3679 c0 -178 -2 -200 -23 -252 -31 -80 -106 -159 -189 -200 l-67 -33 -747 -3 -748 -3 0 136 0 135 575 0 576 0 24 25 c30 29 32 64 6 96 l-19 24 -1102 0 -1102 0 -19 -24 c-26 -32 -24 -67 6 -96 22 -23 31 -25 125 -25 l100 0 0 -136 0 -135 -282 3 c-282 3 -283 3 -339 29 -111 52 -177 138 -200 259 -33 181 100 362 286 390 28 4 745 7 1595 6 l1545 -1 -1 -195z m-1924 -506 l0 -287 -115 112 c-152 147 -142 147 -297 0 l-118 -111 0 286 0 286 265 0 265 0 0 -286z" />
        <path d="M2815 4283 c-451 -23 -833 -309 -988 -740 -76 -210 -77 -493 -3 -709 93 -274 292 -502 551 -632 160 -80 301 -114 485 -115 295 -1 547 97 759 298 521 492 444 1331 -157 1723 -142 93 -322 157 -471 168 -36 3 -79 6 -96 7 -16 1 -52 1 -80 0z m275 -174 c91 -21 240 -87 316 -140 82 -56 193 -169 249 -254 370 -555 78 -1297 -572 -1457 -117 -29 -304 -31 -417 -4 -509 118 -827 597 -735 1106 62 343 316 626 658 734 157 50 336 55 501 15z" />
        <path d="M2665 3826 c-67 -41 -79 -72 -83 -223 l-4 -131 -132 -4 c-150 -4 -185 -17 -224 -86 -20 -36 -22 -53 -22 -198 0 -146 2 -162 23 -198 14 -25 39 -48 66 -63 39 -20 58 -23 166 -23 l123 0 4 -134 c3 -124 5 -136 30 -173 43 -65 71 -73 261 -73 154 0 166 1 197 22 68 47 75 65 78 219 l4 139 122 0 c107 0 127 3 166 23 81 41 100 89 99 257 0 178 -18 226 -99 268 -39 19 -59 22 -166 22 l-122 0 -4 138 c-3 135 -4 140 -32 176 -44 59 -73 66 -253 66 -149 0 -160 -2 -198 -24z m335 -249 c0 -110 3 -129 23 -168 38 -71 69 -83 227 -89 l135 -5 0 -130 0 -130 -140 -6 c-77 -3 -141 -7 -142 -8 -23 -15 -77 -69 -86 -85 -6 -12 -14 -81 -17 -156 l-5 -135 -110 -3 c-166 -4 -153 -17 -157 146 -3 126 -5 140 -27 171 -40 56 -87 71 -228 71 l-123 0 0 134 0 134 139 4 c122 3 143 6 167 24 62 46 69 65 72 202 2 70 5 133 7 140 3 9 39 12 135 12 l130 0 0 -123z" />
      </g>
    </svg>
  );
};
