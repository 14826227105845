import { useHistory, useLocation } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';

import { useUser } from '../../../context/useUser';

import { ContainerComponent } from '../../../blackbook-components/atoms/container';
import { HeaderComponent } from './Header';
import { FooterComponent } from '../../../blackbook-components/molecules/footer';

import { FaFacebookF, FaInstagram, FaLinkedin } from 'react-icons/fa';

import Logo from '../../../assets/images/logo.png';

import { Container } from './style';
import _ from 'lodash';
import { useTrack } from '../../../services/hooks/useTrack';
import { socket } from '../../../services/socket';
import { getToken } from '../../../services/tokenService';
import { useIsDesktopOrTabletScreen } from '../../../services/hooks/useIsDesktopOrTabletScreen';

export function NewDefaultTemplate({
  backgroundImage,
  ...props
}) {
  const isDesktop = useIsDesktopOrTabletScreen();

  const { userProperties, activePlan, setActivePlan } = useUser();
  const { trackEvent } = useTrack();

  const location = useLocation();
  const history = useHistory();

  function handleUpdateUserPlanState(e) {
    if (e?.level_access > activePlan?.level_access) {
      setActivePlan(e);
      history.go(location.pathname);
    }
  }

  useEffect(() => {
    const token = getToken();
    if (userProperties?._id && token) {
      const hendleSocket = (response) => {
        handleUpdateUserPlanState(response);
      };

      socket.on(`${userProperties?._id}/update_plan`, hendleSocket);
      // return () => socket.off(`${userProperties?._id}/update_plan`, hendleSocket);
    }
  }, [userProperties]);

  return (
    <ContainerComponent>
      <HeaderComponent
        userName={userProperties?.name}
        onClick={null}
        isOpen={false}
        onLogoClick={(obj) => null}
        avatar={`https://firebasestorage.googleapis.com/v0/b/content-blackbook-dev/o/profiles%2F${userProperties?._id}?alt=media`}
        inputValue={""}
        onChange={(e) => null}
        onClearInput={() => null}
        onInputFocus={() => null}
      />

      <Container backgroundImage={backgroundImage}>
        <main className="content">
          <div className="bgs">
            <div className="bg1"></div>
            <div className="bg2"></div>
          </div>
          {props.children}
        </main>
      </Container>
      <FooterComponent
        onClick={(e) => {
          trackEvent(`Clicou em ${e.target.dataset.track}`);
        }}
        links={
          isDesktop
            ? [
                {
                  text: "Sobre a Blackbook",
                  url: "#",
                  trackId: "Sobre a Blackbook",
                },
                {
                  text: "Termos de uso",
                  url: "#",
                  trackId: "Termos de uso",
                },
                {
                  text: "Contato",
                  url: "https://wa.me/5531996887607?text=Olá,%20preciso%20de%20ajuda.",
                  trackId: "Contato",
                },
              ]
            : [
                {
                  text: "Sobre",
                  url: "#",
                  trackId: "Sobre a Blackbook",
                },
                {
                  text: "Termos de uso",
                  url: "#",
                  trackId: "Termos de uso",
                },
                {
                  text: "Contato",
                  url: "https://wa.me/5531996887607?text=Olá,%20preciso%20de%20ajuda.",
                  trackId: "Contato",
                },
              ]
        }
        social_links={[
          {
            icon: <FaFacebookF data-track={"Facebook"} />,
            url: "https://www.facebook.com/BlackbookEditora/",
            trackId: "Facebook",
          },
          {
            icon: <FaInstagram data-track={"Instagram"} />,
            url: "https://www.instagram.com/blackbookoficial/",
            trackId: "Instagram",
          },
          {
            icon: <FaLinkedin data-track={"Linkedin"} />,
            url: "https://www.linkedin.com/company/blackbookoficial/",
            trackId: "Linkedin",
          },
        ]}
      />
    </ContainerComponent>
  );
}
