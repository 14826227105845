import { useState, useContext, useEffect } from 'react';
import { RegisterSelectMultipleSpecialtiesComponent } from '../../../../components/atoms/registerInput';
import { RegisterOrganism } from '../../../../components/organisms/registerOrganism';
import { SignUpStageContext } from '../../../../context/useSignUpStage';
import { useTrack } from '../../../../services/hooks/useTrack';

export function SignUpSpecialties({ isDesktop = false, ...props }) {
  const { goForward } = useContext(SignUpStageContext);
  const { trackEvent } = useTrack();
  const [specialties, setSpecialties] = useState([]);
  const [defaultValue, setDefaultValue] = useState(null);

  const handleChangeSpecialties = (value) => {
    setSpecialties(value);
  };

  function sendSpecialtiesToRegister() {
    window.sessionStorage.setItem('specialties', JSON.stringify(specialties));
    trackEvent('Cadastro especialidades - Próximo', {
      'Especialidade': specialties,
    });
    goForward();
  }

  useEffect(() => {
    const value =  window.sessionStorage.getItem('specialties');
    const especialtiesStorage = value ? JSON.parse(value): []
    const toDefaultValue = especialtiesStorage?.map(v => {return {label: v, value: v}}) || [];
    setDefaultValue(toDefaultValue);
    handleChangeSpecialties(especialtiesStorage);
    window.sessionStorage.removeItem('specialties');
  }, []);

  return (
    <RegisterOrganism 
      title="Crie sua conta" 
      buttonProps={{
        disabled: specialties?.length <= 0,
        className: 'fullWidth',
        type: 'submit',
      }}
      onFinish={() => {
        sendSpecialtiesToRegister();
      }}
    >
      {defaultValue ?
        <RegisterSelectMultipleSpecialtiesComponent
          id="specialties"
          className="space-between"
          styleClass="underline"
          label="Possui alguma especialidade?"
          defaultValue={defaultValue}
          onChange={(e) => {
            handleChangeSpecialties(e);
          }}
        />
      : null}
    </RegisterOrganism>
  );
}
