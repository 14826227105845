import { Link } from "react-router-dom";

import { Container } from "./style";

export function Construction() {

  return (
    <Container>
      <div className="construction__animation"></div>
      <div className="construction__content">
        <p className="construction__text">
          Esta página ainda está sob construção!
        </p>
        <Link to="/home" className="construction__button">
          Voltar
        </Link>
      </div>
    </Container>
  );
}
