import React, { useState } from 'react';
import { NewInput } from '../NewInput';
import styled from 'styled-components';

const Container = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 100px;
`;
const UFInput = styled.label`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  width: 100%;
  h3 {
    color: #999;

    transition: transform 0.2s ease-in-out, background 0.3s ease-in-out;
    transform: ${({ isActive }) =>
      isActive ? 'translateY(-175%)' : 'translateY(0%)'};
    padding: 0 0.5rem;
    background-color: ${({ isActive }) =>
      isActive ? '#1e1e1e' : 'transparent'};
    width: fit-content;
    margin-left: 0.5rem;
    font-size: 0.75rem;
    z-index: 2;
    border-radius: 3px;
    position: absolute;
  }

  .select__input {
    border: 1px solid var(--gray-100);
    background-color: transparent;
    color: white;

    border: 1px solid
      ${({ isValid, isInvalid }) =>
        isValid
          ? `#1D9637`
          : isInvalid
          ? `var(--error) !important`
          : 'var(--gray-100)'};
    background-color: unset;
    color: ${({ isValid, isInvalid }) =>
      isValid ? `#1D9637` : isInvalid ? `var(--error) !important` : 'white'};

    font-size: 1rem;
    font-family: Source Sans Pro;

    padding: 0.7rem;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;

    position: relative;
    z-index: 1;

    transition: border-color 0.2s;

    &:hover {
      border-color: var(--purple-500);
    }

    &:focus {
      border-color: var(--yellow-500);
      outline: none;
    }

    &::placeholder {
      color: var(--gray-100);
      font-size: 0.75rem;
    }

    /* Disable arrows on numeric input */
    /* Chrome, Safari, Edge, Opera */
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    [type='number'] {
      -moz-appearance: textfield;
    }

    option {
      background-color: #121212;
      color: white;
    }
    
  }
  &::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #8774FF;
    position: absolute;
    top: 45%;
    right: 12px;
    z-index: 0;
  }
`;

export function CRMInput({ crm, UF, setCrm, setUF, onBlur }) {
  const [isUFActive, setIsUFActive] = useState(false);
  return (
    <Container>
      <NewInput
        inputProp={{
          className: 'outline__input default__active',
          label: 'CRM',
          type: 'text',
          mask: '999999',
          maskChar: null,
          onblur: () => {
            if(onBlur){
              onBlur();
            }
          },
          onChange: (e) => {
            setCrm(e.target.value);
          },
          value: crm,
        }}
      />
     
      <UFInput isActive={isUFActive || !!UF} className="selectInput__container">
        <h3>UF</h3>
        <select
          value={UF}
          onFocus={() => setIsUFActive(true)}
          onBlur={() => setIsUFActive(false)}
          id="address_state"
          class="select__input"
          name="address_state"
          onChange={(e) => setUF(e.target.value)}
        >
          <option selected></option>
          <option value="AC">AC</option>
          <option value="AL">AL</option>
          <option value="AP">AP</option>
          <option value="AM">AM</option>
          <option value="BA">BA</option>
          <option value="CE">CE</option>
          <option value="DF">DF</option>
          <option value="ES">ES</option>
          <option value="GO">GO</option>
          <option value="MA">MA</option>
          <option value="MT">MT</option>
          <option value="MS">MS</option>
          <option value="MG">MG</option>
          <option value="PA">PA</option>
          <option value="PB">PB</option>
          <option value="PR">PR</option>
          <option value="PE">PE</option>
          <option value="PI">PI</option>
          <option value="RJ">RJ</option>
          <option value="RN">RN</option>
          <option value="RS">RS</option>
          <option value="RO">RO</option>
          <option value="RR">RR</option>
          <option value="SC">SC</option>
          <option value="SP">SP</option>
          <option value="SE">SE</option>
          <option value="TO">TO</option>
        </select>
      </UFInput>
    </Container>
  );
}
