/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { MyBlackbookButton } from "../../atoms/MyBlackbookButton";
import styled from "styled-components";
//@ts-ignore
import logo from "../../../assets/images/logo.png";
//@ts-ignore
import {Spinner} from "../../../assets/animations/spinner"
//@ts-ignore
import { useMyBlackbook } from "../../../services/hooks/useMyBlackbook";
//@ts-ignore
import { useHistory } from "react-router-dom";
//@ts-ignore
import {useTrack} from '../../../services/hooks/useTrack';

const Container = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  @media only screen and (min-height: 1000px), screen and (max-width: 1100px) {
    overflow: hidden;
  }
  @media only screen and (max-height: 650px) {
    overflow-x: hidden;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
`;

const TitleWithLogo = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
`;

const Title = styled.p`
  color: white;
  font-size: 18px;
  font-weight: 700;
`;

const Image = styled.div`
  width: 26px;
  height: 24px;
`;

const MyBlackbookContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  width: 100%;
  height: 100%;

  grid-template-rows: 1fr 1fr;
  overflow-x: auto;
  overflow-y: hidden;
  box-sizing: border-box;
  padding-bottom: 20px;

  @media only screen and (max-height: 650px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    overflow-y: hidden;
    mask-image: linear-gradient(to right, black 75%, transparent);
    padding-right: 50px;
  }
  /* @media only screen and (max-width: 1100px), screen and (min-height: 1000px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    overflow-x: hidden;
    overflow-y: scroll;
    mask-image: linear-gradient(to bottom, black 75%, transparent);
    padding-bottom: 200px;
  } */

  ::-webkit-scrollbar {
    width: 15px;
    border: 1px solid var(--gray-500);
    border-right: none;
    border-left: none;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--gray-500);
    border: 1px solid var(--gray-500);
    border-right: none;
    border-left: none;
    box-shadow: inset 0 0 0 4px #121212;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const SeeAll = styled.button`
  background-color: transparent;
  border: none;
  color: #8774ff;
  cursor: pointer;
`;

export const MyBlackbookCarousel = () => {
  const { getMyBlackbookHome } = useMyBlackbook();
  const [loading, setLoading] = useState(true);
  
  const history = useHistory()
  const [myBlackbookData, setMyBlackbookData] = useState<any>([
    {
      title: "",
    },
    {
      title: "",
    },
    {
      title: "",
    },
    {
      title: "",
    },
    {
      title: "",
    },
    {
      title: "",
    },
    {
      title: "",
    },
    {
      title: "",
    },
    {
      title: "",
    },
    {
      title: "",
    },
  ]);
  const {trackEvent} = useTrack();


  function favoritesSetter(arrayToSet: any) {
    const aux = 10 - arrayToSet.length;
    if(aux > 0) {
      for (let i = 0; i < aux; i++) {
        arrayToSet.push({
          title: "",
        })
      }
    }
    setMyBlackbookData(arrayToSet);
  }

  async function handleFavorites() {
    setLoading(true);
    const data = await getMyBlackbookHome();
    if (data) {
      favoritesSetter(data);
    }
    setLoading(false);
  }

  useEffect(() => {
    handleFavorites();
  }, []);

  if (loading)
    return (
      <LoadingContainer>
        <Spinner stroke_width="5" />
      </LoadingContainer>
    );
  else
    return (
      <Container>
        <TitleContainer>
          <TitleWithLogo
            onClick={() => {
              history.push("/myblackbook");
              trackEvent("Clicou em ver todas", {
                Origem: "Home",
                "Tipo da origem": "Meu Blackbook",
              });
            }}
          >
            <Image>
              <img
                style={{ height: "100%", aspectRatio: "32/27" }}
                src={logo}
                alt="Logo da Blackbook"
              />
            </Image>
            <Title>Meu Blackbook</Title>
          </TitleWithLogo>
          <SeeAll
            onClick={() => {
              history.push("/myblackbook");
              trackEvent("Clicou em ver todas", {
                Origem: "Home",
                "Tipo da origem": "Meu Blackbook",
              });
            }}
          >
            VER TODOS
          </SeeAll>
        </TitleContainer>
        <MyBlackbookContainer>
          {myBlackbookData.map((favorite: any) => (
            <MyBlackbookButton
              title={favorite.title}
              image={favorite.image ? favorite.image : favorite.home_image}
              data={{
                id: favorite.item_id,
                subId: favorite.legend_id,
                type: favorite.sub_type,
              }}
            />
          ))}
        </MyBlackbookContainer>
      </Container>
    );
};
