/* eslint-disable react-hooks/exhaustive-deps */
import styled from "styled-components";
import MainTheme from "../../../styles/MainTheme";
import { A1Icon, A3Icon, AMIcon, B1Icon, B2Icon, BasicSusIcon, BbBreadcrumbIcon, BbDrugCardIcon, BbDrugIcon, BbRoutineIcon, C1Icon, C2Icon, C3Icon, C5Icon, CategoryAGestationIcon, CategoryBGestationIcon, CategoryCGestationIcon, CategoryDGestationIcon, CategoryXGestationIcon, ClearRiskBreastfeedingIcon, DemonstratedChildRiskBreastfeedingIcon, FavoriteIcon, GenericIcon, HospitalSusIcon, MinimumChildRiskBreastfeedingIcon, PopularPharmacyIcon, PossibleChildRiskBreastfeedingIcon, SntpIcon, SpecializedSusIcon, StrategicSusIcon, UndefinedRiskCategoryBreastfeedingIcon } from "../../quarks/icons";
import { FaArrowLeft } from "react-icons/fa";

const FavoriteIconContainer = styled.div`
  position: absolute;
  top: -8px;
  right: 10px;
  cursor: pointer;

  transition: all 0.2s;

  &:hover {
    filter: brightness(1.5);
  }
`;

const ContentBodyCard = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding: 16px 20px;
  background-color:  ${MainTheme.color.blackSettingsOption};
  border: 1px solid ${MainTheme.color.blackSettingsOption};
  width: 100%;
  max-height: 220px;
  min-height: 210px;
  border-radius: 10px;
  position: relative;
  transition: all 0.2s;
  box-sizing: border-box;
  cursor: pointer;

  :hover {
    border: 1px solid ${MainTheme.color.primary};
    filter: brightness(0.9);
  }
`;

const ContentBodyCardDivider= styled.div`
  border-bottom: 1px solid ${MainTheme.color.disabledFont};
`;

const ContentBodyCardTitle = styled.text`
  color: ${MainTheme.color.primaryFont};
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  line-height: 20px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  height: 82px;

  svg {
    margin-right: 5px;
    margin-bottom: -2px;
  }
`;

const ContentBodyCardDescription = styled.text`
  color: ${MainTheme.color.grayFont};
  font-weight: normal;
  font-size: 12px;
  text-align: left;
  margin-bottom: 5px;
`;

const ContentBodyCardRoute = styled.text`
  color: ${MainTheme.color.grayFont};
  font-weight: normal;
  font-size: 12px;
  text-align: left;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  height: 25px;
`;

const ViewAll = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:  ${MainTheme.color.primary};
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  color: #fff;
  font-weight: 700;
  height: 40px;
  font-size: 16px;
  line-height: 18px;
  border: none;
  
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  gap: 5px;

  transition: all 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`

const IconContainer = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: row;
  flex-wrap: wrap;
`;

const IconImage = styled.div`
  width: 30px;
  height: 30px;
`;

export function MyBlackbookCard({
  title = "",
  description = "",
  category = "",
  icons = [],
  type = "",
  subItems = [],
  handleRedirect,
  handleSeeAll,
  handleFavoriteClick
}) {

  function getIconTitleByType() {
    const iconMap = {
      "routine": <BbRoutineIcon width="18" heigth="18"/>,
      "therapeutic_group": <BbDrugIcon width="18" heigth="18"/>,
      "active_principle": <BbDrugCardIcon width="18" heigth="18"/>
    };
    
    return iconMap[type] || null;
  }

  function getIconActivePrinciple(iconId) {
    const iconMap = {
      "6019708726b48efbfb2b26a7": <AMIcon /> ,
      "601970e126b48efbfb2b26a8": <C1Icon />,
      "6019711926b48efbfb2b26a9": <C2Icon />,
      "6019715c26b48efbfb2b26aa": <C3Icon />,
      "6019719126b48efbfb2b26ab": <C5Icon />,
      "601971c626b48efbfb2b26ac": <A1Icon />,
      "601971f226b48efbfb2b26ad": <A3Icon />,
      "6019722026b48efbfb2b26ae": <B1Icon />,
      "6019724b26b48efbfb2b26af": <B2Icon />,
      "600f013a23b468b7ca7b75e6": <GenericIcon />,
      "600f064d23b468b7ca7b75e8": <BasicSusIcon />,
      "600f06a023b468b7ca7b75e9": <StrategicSusIcon />,
      "600f06b123b468b7ca7b75ea": <SpecializedSusIcon />,
      "600f06c023b468b7ca7b75eb": <HospitalSusIcon />,
      "600f06d023b468b7ca7b75ec": <PopularPharmacyIcon />,
      "600f06dd23b468b7ca7b75ed": <SntpIcon />,
      "6019955777d20179fdefdc20": <MinimumChildRiskBreastfeedingIcon />,
      "601995ac77d20179fdefdc21": <PossibleChildRiskBreastfeedingIcon />,
      "601995fc77d20179fdefdc22": <DemonstratedChildRiskBreastfeedingIcon />,
      "6019963377d20179fdefdc23": <UndefinedRiskCategoryBreastfeedingIcon />,
      "6019967377d20179fdefdc24": <ClearRiskBreastfeedingIcon />,
      "6019a48777d20179fdefdc25": <CategoryAGestationIcon />,
      "6019a5fd77d20179fdefdc26": <CategoryBGestationIcon />,
      "6019a64977d20179fdefdc27": <CategoryCGestationIcon />,
      "6019a69a77d20179fdefdc28": <CategoryDGestationIcon />,
      "6019a6e177d20179fdefdc29": <CategoryXGestationIcon />,
    };

    return iconMap[iconId] || null;
  }


  return (
    <ContentBodyCard
      onClick={() => handleRedirect ? handleRedirect() : null}
    >
      <FavoriteIconContainer
        onClick={(e) => {
          e.stopPropagation(); 
          handleFavoriteClick?.();
        }}
      >
        <FavoriteIcon />
      </FavoriteIconContainer>
      <ContentBodyCardDescription>{category}</ContentBodyCardDescription>
      <ContentBodyCardTitle
        style={icons.length > 0 ? {
          height: "40px",
          "WebkitLineClamp": 2
        }: {}}
        title={title}
      >
        {getIconTitleByType()}
        {title}
      </ContentBodyCardTitle>

      {icons?.length > 0 ?
        <IconContainer>
          {icons.map(icon => 
            <IconImage 
              title={icon?.tooltip || icon?.title}
            >
              {getIconActivePrinciple(icon?._id)}
            </IconImage>
          )}
        </IconContainer>
        : null
      }
      <ContentBodyCardDivider />
      <ContentBodyCardRoute
        title={description}
      >
        <BbBreadcrumbIcon/>
        {description}
      </ContentBodyCardRoute>
      {subItems.length > 0 && handleSeeAll?
        <ViewAll
          onClick={
            () => handleSeeAll()
          }
        >
          Ver Mais <FaArrowLeft style={{transform: "rotate(135.61deg)"}} />
        </ViewAll>
        : null
      }
    </ContentBodyCard>
  );
}
