import { gql } from "@apollo/client";

export const GET_HOME_INFO = gql`
  query Home {
    home {
      banners {
        active
        _id
        title
        banner_template_id
        banner_link_id
        banner_external_link
        banner_text
        position
      }
      drug_specialties {
        title
        _id
        specialty_id
        cover
        position
      }
      routine_specialties {
        title
        _id
        specialty_id
        cover
        position
      }
    }
  }
`;
