import { gql } from "@apollo/client";

export const GET_THERAPEUTIC_GROUP = gql`
  query Query($therapeuticGroupId: String!) {
    therapeutic_group_populated(therapeuticGroupId: $therapeuticGroupId) {
      active_principles {
        _id
        title
        contra_indications
        resume {
          prices {
            description
            value
            range
          }
          brands {
            title
            laboratory
          }
          icons {
            _id
            icon
            description
            title
            link {
              _id
              activePrinciple_id
              card_id
              deleted
              dosage_id
              field_origin
              link_id
              link_origin_id
              link_origin_text
              tab_id
              therapeutic_group_id
              type
              routine_id
            }
          }
          whiteboard
          observations
          presentations {
            pharmaceutical_form {
              title
            }
          }
        }
        brands_and_presentations {
          reference {
            _id
            title
            classification {
              title
            }
            laboratory {
              title
            }
            presentations {
              parsed_text
              observations
            }
            status
          }
          generic {
            _id
            title
            laboratory {
              title
            }
            classification {
              title
              icon {
                icon
              }
            }
            presentations {
              parsed_text
              observations
            }
            status
          }
          essential {
            _id
            title
            presentations {
              parsed_text
              observations
            }
            classification {
              icon {
                _id
                icon
                description
                title
                link {
                  _id
                  activePrinciple_id
                  card_id
                  deleted
                  dosage_id
                  field_origin
                  link_id
                  link_origin_id
                  link_origin_text
                  tab_id
                  therapeutic_group_id
                  type
                  routine_id
                }
              }
            }
          }
          similar {
            _id
            title
            laboratory {
              title
            }
            presentations {
              parsed_text
              observations
            }
          }
          biological {
            _id
            title
            laboratory {
              title
            }
            presentations {
              parsed_text
              observations
            }
          }
          specific {
            _id
            title
            laboratory {
              title
            }
            presentations {
              parsed_text
              observations
            }
          }
          herbal_medicines {
            _id
            title
            laboratory {
              title
            }
            presentations {
              parsed_text
              observations
            }
          }
          others {
            _id
            title
            laboratory {
              title
            }
            presentations {
              parsed_text
              observations
            }
          }
          state_owned {
            _id
            title
            laboratory {
              title
            }
            presentations {
              parsed_text
              observations
            }
          }
          manipulated {
            _id
            title
            laboratory {
              title
            }
            presentations {
              parsed_text
              observations
            }
          }
          imported {
            _id
            title
            laboratory {
              title
            }
            presentations {
              parsed_text
              observations
            }
          }
          doubt {
            _id
            title
            laboratory {
              title
            }
          }
          discontinued {
            _id
            title
            laboratory {
              title
            }
          }
        }
        dosage_and_indications {
          adult {
            _id
            extra_content {
              title
              content
              type {
                _id
                title
              }
              order
            }
            recommendation
            content
            is_usual
          }
          child {
            _id
            extra_content {
              title
              content
              type {
                _id
                title
              }
              order
            }
            recommendation
            content
            is_usual
          }
          old_man {
            _id
            extra_content {
              title
              content
              type {
                _id
                title
              }
              order
            }
            recommendation
            content
            is_usual
          }
          teenager {
            _id
            extra_content {
              content
              title
              type {
                _id
                title
              }
              order
            }
            recommendation
            content
            is_usual
          }
        }
        special_situation {
          renal_adjustments {
            _id
            item {
              title
              is_adjust
            }
            icon {
              _id
              icon
              description
              title
              link {
                _id
                activePrinciple_id
                card_id
                deleted
                dosage_id
                field_origin
                link_id
                link_origin_id
                link_origin_text
                tab_id
                therapeutic_group_id
                type
                routine_id
              }
            }
            observation
          }
          hepatic_adjustments {
            _id
            item {
              title
              is_adjust
            }
            icon {
              title
              description
              icon
            }
            observation
          }
          breast_feeding {
            _id
            item {
              title
              is_adjust
            }
            icon {
              title
              description
              icon
              tooltip
            }
            observation
          }
          gestation {
            _id
            item {
              title
              is_adjust
            }
            icon {
              title
              description
              icon
              tooltip
            }
            observation
          }
          intoxication_antidote {
            _id
            item {
              title
              is_adjust
            }
            icon {
              title
              description
              icon
            }
            observation
          }
          drug_interactions {
            _id
            item {
              title
              is_adjust
            }
            icon {
              title
              description
              icon
            }
            observation
          }
        }
        adverse_effects {
          adverse_reactions {
            _id
            content
          }
          toxicity {
            _id
            content
          }
          by_administration {
            _id
            content
          }
          dependence_tolerance_abstinence {
            _id
            content
          }
        }
        use_and_security {
          preparation_administration {
            _id
            content
          }
          patient_advice {
            _id
            content
          }
          monitoring {
            _id
            content
          }
          warnings_precautions {
            _id
            content
          }
        }
      }
      title
      _id
      free_access
      is_blocked
    }
  }
`;
