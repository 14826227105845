import styled from "styled-components";

export const SignInPage = styled.div`
  background-color: #121212;
  max-width: 100%;
  margin: auto;
  height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  .loginTopDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
  }

  .loginTopDiv__image {
    width: 46px;
    height: 38px;
    margin-top: 3rem;
    margin-bottom: 12px;
  }

  .loginTopDiv__imageName {
    width: 200px;
    height: 21px;
  }

  @media screen and (max-height: 600px) {
    .loginTopDiv {
      position: absolute;
      top: 0;
    }
  }
  @media screen and (max-height: 490px) {
    .loginMain {
      position: absolute;
      bottom: 1rem;
    }
  }
`;
