import { useHistory } from 'react-router-dom';
import { BackButtonComponent } from '../../atoms/backButtonComponent';
import { NewButtonComponent } from '../../atoms/buttonComponent';
import { HeaderStyle } from './style';

export const RegisterHeaderComponent = (props) => {
  const history = useHistory();
  return (
    <HeaderStyle>
      <BackButtonComponent onClick={props.onClick}/>
      <p className="headerText">{props.title}</p>
      {props.showSigninButton && (
        <NewButtonComponent
          onClick={() => history.push('/signin')}
          type="submit"
        >
          ENTRAR
        </NewButtonComponent>
      )}
    </HeaderStyle>
  );
};
