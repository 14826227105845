import styled from "styled-components";

export const Header = styled.header`
  background-color: black;
  min-height: 54px;
  position: relative;

  .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 1rem;
    margin: 0 auto;
  }

  .headerContainer__logo {
    cursor: pointer;
  }

  @media screen and (min-width: 990px) {
    .headerContainer {
      height: 70px;
    }

    .headerContainer__logo {
      width: 209px;
      height: 26px;
    }
  }
`;
